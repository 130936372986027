<template>
<div>
    <div class="subscription-loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
    </div>
    <div v-for="sub in lstSubscription" :key="sub.SubscriptionID">

        <div v-if="sub.ProductCode == 'NF00000001'" class="subscription-heading">FundInvestor Subscription</div>
        <div v-if="sub.ProductCode == 'NM00000001'" class="subscription-heading" style="margin-top: 32px;">StockInvestor Subscription</div>
        <div v-if="sub.ProductCode == 'NDI0000001'" class="subscription-heading" style="margin-top: 32px;">DividendInvestor Subscription</div>
        <div v-if="sub.ProductCode == 'NET0000001'" class="subscription-heading" style="margin-top: 32px;">ETFInvestor Subscription</div>

        <div v-if="sub.DigitalAccess == true">
            <mds-layout-grid>
                <mds-row>
                    <mds-col :cols="12">
                        <div class="subscription-box">
                            <div style="margin:23px">
                                <div class="subscription-product-title">Digital Access Active</div>
                            </div>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
        </div>
        <div v-if="sub.DigitalAccess == false">
            <mds-layout-grid>
                <mds-row>
                    <mds-col :cols="12">
                        <div class="subscription-box">
                            <div style="margin:23px">
                                <div class="subscription-product-title">{{sub.Term}}</div>
                                <!-- <div v-if="sub.ProductCode == 'NF00000001'" class="subscription-product-title">FundInvestor Term</div>
                                <div v-if="sub.ProductCode == 'NM00000001'" class="subscription-product-title">StockInvestor Term</div>
                                <div v-if="sub.ProductCode == 'NDI0000001'" class="subscription-product-title">DividendInvestor Term</div>
                                <div v-if="sub.ProductCode == 'NET0000001'" class="subscription-product-title">ETFInvestor Term</div> -->
                                <div class="subscription-term" v-html="sub.ProductTerm">
                                    <!-- {{sub.ProductTerm}} -->
                                </div>

                                <div class="subscription-product-title" style="margin-top: 18px;">
                                    Subscription Cost
                                </div>
                                <div class="subscription-term">${{checkTotalAmount(sub.TotAmount)}}</div>

                                <div class="subscription-product-title" style="margin-top: 18px;">
                                    <span v-if="sub.IsTrial"> Trial End Date</span>
                                    <span v-else> Subscription End Date</span>
                                </div>
                                <div class="subscription-term">{{formatDate(sub.EndDate)}} </div>

                                <div v-if="sub.CurrentMonth">
                                    <div class="subscription-border"></div>

                                    <div style="margin-top: 11px;">
                                        <mds-icon class="exclaim-circle-fill" name="exclaim-circle-fill" size="small"></mds-icon>
                                        <span v-if="sub.IsTrial" class="subscription-term" style="margin-left: 5px;">Your free trial will end on {{ formatDate(sub.EndDate) }}.</span>
                                        <span v-else class="subscription-term" style="margin-left: 5px;">Your subscription will end on {{ formatDate(sub.EndDate) }}.</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
        </div>
    </div>
    <div v-if="NoActiveSubscription == true">
            <mds-layout-grid>
                <mds-row>
                    <mds-col :cols="12">
                        <div class="subscription-box">
                            <div style="margin:23px">
                                <div class="subscription-product-title">No Active Subscriptions</div>
                            </div>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
        </div>

</div>
</template>

<script>
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(VueAxios, axios);
import {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
} from "@mds/layout-grid";
import MdsIcon from '@mds/icon';
import MdsLoader from "@mds/loader";
export default {
    name: "subscription",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsIcon,
        MdsLoader
    },
    data() {
        return {
            isLoading: true,
            userID: "",
            userDetails: [],
            lstSubscription: [],
            lstMFI: [],
            lstMSI: [],
            lstMDI: [],
            lstMEI: [],
            MEIProductAccess: false,
            MDIProductAccess: false,
            MSIProductAccess: false,
            MFIProductAccess: false,
            productDataRoles: "",
            NoActiveSubscription: false,
        }
    },
    mounted() {
        this.productDataRoles = this.$auth0.user["https://morningstar.com/uim_roles"];
        if(this.productDataRoles != undefined && this.productDataRoles !=null) {
        this.MEIProductAccess = this.productDataRoles.includes("EI_MEMBER");
        this.MDIProductAccess = this.productDataRoles.includes("DI_MEMBER");
        this.MSIProductAccess = this.productDataRoles.includes("SI_MEMBER");
        this.MFIProductAccess = this.productDataRoles.includes("FI_MEMBER");
        }

        this.userID = this.$auth0.user["https://morningstar.com/mstar_id"];
        if (this.userID) {
            this.getUserDetails();    
        }
    
        if(!this.MEIProductAccess && !this.MDIProductAccess && !this.MSIProductAccess && !this.MFIProductAccess)
    {
        this.NoActiveSubscription = true;
    }
    else {
        this.NoActiveSubscription = false;
    }
  

    },
    methods: {
        getUserDetails() {
            var getUserDetailsAPIURL = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetUserDetails;
            getUserDetailsAPIURL = getUserDetailsAPIURL.replace("{userId}", this.userID);
            try {
                Vue.axios
                    .get(getUserDetailsAPIURL, {
                        headers: {
                            Accept: "application/json",
                        },
                    })
                    .then((response) => {
                        if (response) {
                            if (response.status == 200) {
                                if (response.data) {
                                    this.userDetails = JSON.parse(response.data);
                                    if (this.userDetails) {
                                        if (this.userDetails["CustomerID"]) {
                                            this.getSubscriptionList(this.userDetails["CustomerID"]);
                                        }
                                    }
                                }

                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally();
            } catch (ex) {
                console.log(ex.message);
            }
        },
        getSubscriptionList(customerId) {
            // this.isLoading = true;
           var GetSubscriptionUrl = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_SubscriptionListUrl;
            //var GetSubscriptionUrl = "https://localhost:50377/subscription-list?customerId={customerId}";
            GetSubscriptionUrl = GetSubscriptionUrl.replace('{customerId}', customerId);

            try {
                Vue.axios
                    .get(GetSubscriptionUrl)
                    .then((response) => {
                        if (response) {
                            if (response.status == 200) {

                                if (response.data) {
                                    var responseFilter = response.data;
                                    var MFI = responseFilter.filter(function (el) {
                                        return el["ProductCode"] == "NF00000001" && el["TempStatus"] == "AS";
                                    });
                                    var MSI = responseFilter.filter(function (el) {
                                        return el["ProductCode"] == "NM00000001" && el["TempStatus"] == "AS";
                                    });
                                    var MDI = responseFilter.filter(function (el) {
                                        return el["ProductCode"] == "NDI0000001" && el["TempStatus"] == "AS";
                                    });
                                    var MEI = responseFilter.filter(function (el) {
                                        return el["ProductCode"] == "NET0000001" && el["TempStatus"] == "AS";
                                    });

                                    if (MFI != null && MFI.length > 0) {
                                        var ProductCodeMFI = "";
                                        var TotAmountMFI = "";
                                        var MFIEndDate = "";
                                        var MFITerm = "";

                                        if (MFI[0]["ProductCode"]) {
                                            ProductCodeMFI = MFI[0]["ProductCode"];
                                        }
                                        if (MFI[0]["TotAmount"]) {
                                            TotAmountMFI = MFI[0]["TotAmount"]
                                        }
                                        if (MFI[0]["EndDate"]) {
                                            MFIEndDate = MFI[0]["EndDate"];
                                        }
                                        if (MFI[0]["Term"]) {
                                            MFITerm = MFI[0]["Term"];
                                        }
                                        if (MFI[0]["Term"]) {
                                            MFITerm = MFI[0]["Term"];
                                        }
                                        this.lstSubscription.push({
                                            'ProductCode': ProductCodeMFI,
                                            'TotAmount': TotAmountMFI,
                                            'Term': 'FundInvestor Term',
                                            'EndDate': MFIEndDate,
                                            'CurrentMonth': this.checkCurrentMonth(MFIEndDate),
                                            'ProductTerm': MFITerm,
                                            'IsTrial': MFI[0]["IsTrial"],
                                            'DigitalAccess': false
                                        });
                                    } else {
                                        if (this.MFIProductAccess) {
                                            this.lstSubscription.push({
                                                'ProductCode': "NF00000001",
                                                'TotAmount': "",
                                                'Term': "",
                                                'EndDate': "",
                                                'CurrentMonth': "",
                                                'ProductTerm': "",
                                                'DigitalAccess': true
                                            });
                                        }
                                    }

                                    if (MSI != null && MSI.length > 0) {
                                        var ProductCodeMSI = "";
                                        var TotAmountMSI = "";
                                        var MSIEndDate = "";
                                        var MSITerm = "";
                                        if (MSI[0]["ProductCode"]) {
                                            ProductCodeMSI = MSI[0]["ProductCode"];
                                        }
                                        if (MSI[0]["TotAmount"]) {
                                            TotAmountMSI = MSI[0]["TotAmount"]
                                        }
                                        if (MSI[0]["EndDate"]) {
                                            MSIEndDate = MSI[0]["EndDate"];
                                        }
                                        if (MSI[0]["Term"]) {
                                            MSITerm = MSI[0]["Term"];
                                        }
                                        this.lstSubscription.push({
                                            'ProductCode': ProductCodeMSI,
                                            'TotAmount': TotAmountMSI,
                                            'Term': 'StockInvestor Term',
                                            'EndDate': MSIEndDate,
                                            'CurrentMonth': this.checkCurrentMonth(MSIEndDate),
                                            'ProductTerm': MSITerm,
                                            'IsTrial': MSI[0]["IsTrial"],
                                            'DigitalAccess': false
                                        });
                                    }
                                    else {
                                        if (this.MSIProductAccess) {
                                            this.lstSubscription.push({
                                                'ProductCode': "NM00000001",
                                                'TotAmount': "",
                                                'Term': "",
                                                'EndDate': "",
                                                'CurrentMonth': "",
                                                'ProductTerm': "",
                                                'DigitalAccess': true
                                            });
                                        }
                                    }

                                    if (MDI != null && MDI.length > 0) {
                                        var ProductCodeMDI = "";
                                        var TotAmountMDI = "";
                                        var MDIEndDate = "";
                                        var MDITerm = "";

                                        if (MDI[0]["ProductCode"]) {
                                            ProductCodeMDI = MDI[0]["ProductCode"];
                                        }
                                        if (MDI[0]["TotAmount"]) {
                                            TotAmountMDI = MDI[0]["TotAmount"]
                                        }
                                        if (MDI[0]["EndDate"]) {
                                            MDIEndDate = MDI[0]["EndDate"];
                                        }
                                        if (MDI[0]["Term"]) {
                                            MDITerm = MDI[0]["Term"];
                                        }
                                        this.lstSubscription.push({
                                            'ProductCode': ProductCodeMDI,
                                            'TotAmount': TotAmountMDI,
                                            'Term': 'DividendInvestor Term',
                                            'EndDate': MDIEndDate,
                                            'CurrentMonth': this.checkCurrentMonth(MDIEndDate),
                                            'ProductTerm': MDITerm,
                                            'IsTrial': MDI[0]["IsTrial"],
                                            'DigitalAccess': false
                                        });
                                    }
                                    else {
                                        if (this.MDIProductAccess) {
                                            this.lstSubscription.push({
                                                'ProductCode': "NDI0000001",
                                                'TotAmount': "",
                                                'Term': "",
                                                'EndDate': "",
                                                'CurrentMonth': "",
                                                'ProductTerm': "",
                                                'DigitalAccess': true
                                            });
                                        }
                                    }


                                    if (MEI != null && MEI.length > 0) {
                                        var ProductCodeMEI = "";
                                        var TotAmountMEI = "";
                                        var MEIEndDate = "";
                                        var MEITerm = "";
                                        if (MEI[0]["ProductCode"]) {
                                            ProductCodeMEI = MEI[0]["ProductCode"];
                                        }
                                        if (MEI[0]["TotAmount"]) {
                                            TotAmountMEI = MEI[0]["TotAmount"]
                                        }
                                        if (MEI[0]["EndDate"]) {
                                            MEIEndDate = MEI[0]["EndDate"];
                                        }
                                        if (MEI[0]["Term"]) {
                                            MEITerm = MEI[0]["Term"];
                                        }
                                        this.lstSubscription.push({
                                            'ProductCode': ProductCodeMEI,
                                            'TotAmount': TotAmountMEI,
                                            'Term': 'ETFInvestor Term',
                                            'EndDate': MEIEndDate,
                                            'CurrentMonth': this.checkCurrentMonth(MEIEndDate),
                                            'ProductTerm': MEITerm,
                                            'IsTrial': MEI[0]["IsTrial"],
                                            'DigitalAccess': false
                                        });
                                    }
                                    else {
                                        if (this.MEIProductAccess) {
                                            this.lstSubscription.push({
                                                'ProductCode': "NET0000001",
                                                'TotAmount': "",
                                                'Term': "",
                                                'EndDate': "",
                                                'CurrentMonth': "",
                                                'ProductTerm': "",
                                                'DigitalAccess': true
                                            });
                                        }
                                    }
                                }

                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => (this.isLoading = false));
            } catch (ex) {
                console.log(ex.message);
            }
        },
        formatDate(strDate) {
            if (strDate) {
                var temp = strDate.split('/');
                var date = "";
                var Month = "";
                var year = "";
                if (temp) {
                    if (temp[0]) {
                        Month = temp[0];
                    }
                    if (temp[1]) {
                        date = temp[1];
                    }
                    if (temp[2]) {
                        year = temp[2];
                    }
                }
                var str = "";
                if (Month == "1") {
                    str = "January";
                } else if (Month == "2") {
                    str = "February";
                } else if (Month == "3") {
                    str = "March";
                } else if (Month == "4") {
                    str = "April";
                } else if (Month == "5") {
                    str = "May";
                } else if (Month == "6") {
                    str = "June";
                } else if (Month == "7") {
                    str = "July";
                } else if (Month == "8") {
                    str = "August";
                } else if (Month == "9") {
                    str = "September";
                } else if (Month == "10") {
                    str = "October";
                } else if (Month == "11") {
                    str = "November";
                } else if (Month == "12") {
                    str = "December";
                }
                return str + " " + date + ", " + year;
            }
            return "";
        },
        checkCurrentMonth(EndDate) {
            var bValid = false;

            if (EndDate) {
                var temp = EndDate.split('/');
                var Month = "";
                var year = "";
                var day = "";
                if (temp) {
                    if (temp[0]) {
                        if (parseInt(temp[0]) < 10) {
                            Month = '0' + temp[0];
                        } else {
                            Month = temp[0];
                        }
                    }
                    if (temp[2]) {
                        year = temp[2];
                    }
                    if (temp[1]) {
                        if (parseInt(temp[1]) < 10) {
                            day = '0' + temp[1];
                        } else {
                            day = temp[1];
                        }
                    }
                    EndDate = Month + "/" + day + "/" + year;
                }

            }

            const today = new Date();
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;

            const formattedToday = mm + '/' + dd + '/' + yyyy;

            var date1 = new Date(formattedToday);
            var date2 = new Date(EndDate);

            var Difference_In_Time = date2.getTime() - date1.getTime();

            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
            if (Difference_In_Days <= 30) {
                bValid = true;
            } else {
                bValid = false;
            }
            return bValid;
        },
        checkTotalAmount(Amt) {
            var Number;
            if (Amt == 0) {
                Number = Amt + ".00";
            } else if (Amt > 0) {
                var varName = Amt;
                var check = varName % 1;
                if (check) {
                    Number = Amt;
                } else {
                    Number = Amt + ".00";
                }
            }
            return Number;
        }
    }

}
</script>

<style scoped>
body,
body * {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}

.subscription-heading {
    color: var(--text-primary, #1E1E1E);
    /* Heading/Level 7: Light */
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
}

.subscription-box {
    border-radius: 6px;
    border: 1px solid #CCC;
    background: var(--background-primary, #FFF);
    width: 100%;
    margin-top: 10px;
}

.subscription-product-title {
    color: var(--text-primary, #1E1E1E);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
}

.subscription-term {
    color: var(--text-primary, #1E1E1E);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
}

.subscription-border {
    border: 1px solid #E5E5E5;
    margin-top: 20px;
}

.subscription-loader {
    width: "276px";
    height: "69px";
}

</style><style lang="scss">
@import "@mds/constants";
@import "@mds/icon/src/icon_mixins";

.exclaim-circle-fill {
    /* @include mds-icon-color(#FFFF00);*/
    @include mds-icon-color(#F5c400);
    padding-top: 1.8px;

}
</style>
<style>
.subscription-list-style::before{
    color: #ababab;
    content: "•";
    left: 0;
    line-height: 19.5px;
    vertical-align: baseline;
    margin-right: 3px;
    font-size: 13px;
}
</style>
