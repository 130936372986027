<template>
<div class="mei-model-portfolio-details-container">
    <div class="loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
    </div>

    <div>
        <div class="mei-portfolio-details-title">Portfolio Details</div>
    </div>

    <div v-if="isDisplay">
        <div>
            <div class="mei-portfolio-details-div-border"></div>
            <div class="mei-portfolio-details-basic-portfolio">
                <img src="../../assets/Images/mei/basic.svg" class="mei-port-img" />
                Basic Portfolio
            </div>
            <div class="mei-portfolio-details-middle-text">
                Harness the market's collective wisdom with ultra-low-cost funds.
            </div>
            <PortfolioHoldings :portfolios="basicPortfolios"></PortfolioHoldings>
        </div>
        <div>   
            <div class="mei-portfolio-details-div-border"></div>
            <div class="mei-portfolio-details">
                <img src="../../assets/Images/mei/defensive.svg" class="mei-port-img" />
                Defensive Portfolio
            </div>
            <div class="mei-portfolio-details-middle-text">
                Seeks lower volatility, better downside protection, and better
                risk-adjusted performance than the basic portfolio over the long term.
            </div>
            <PortfolioHoldings :portfolios="defensivePortfolios"></PortfolioHoldings>
        </div>
        <div>
            <div class="mei-portfolio-details-div-border"></div>
            <div class="mei-portfolio-details" style="margin-top:8px;">
                <img src="../../assets/Images/mei/factor.svg" class="mei-port-img" />
                Factor Portfolio
            </div>
            <div class="mei-portfolio-details-middle-text">
                Seeks to earn higher returns than the basic portfolio over the long
                term.
            </div>
            <PortfolioHoldings :portfolios="factorPortfolios"></PortfolioHoldings>
        </div>
        <div>
            <div class="mei-portfolio-details-div-border"></div>
            <div class="mei-portfolio-details">
                <img src="../../assets/Images/mei/income.svg" class="mei-port-img" />
                Income Portfolio
            </div>
            <div class="mei-portfolio-details-middle-text">
                Seeks to earn a higher distribution yield than the basic portfolio
                without taking much more risk.
            </div>
            <PortfolioHoldings :portfolios="incomePortfolios"></PortfolioHoldings>
        </div>
        <div>
            <div class="mei-portfolio-details-div-border"></div>
            <div class="mei-portfolio-details">
                <img src="../../assets/Images/mei/esg.svg" class="mei-port-img" /> ESG
                Portfolio
            </div>
            <div class="mei-portfolio-details-middle-text">
                Target firms with strong corporate governance and sustainable
                environmental and social practices, while seeking competitive returns
                and similar risk to the basic portfolio.
            </div>
            <PortfolioHoldings :portfolios="ESGPortfolios"></PortfolioHoldings>
        </div>
    </div>
</div>
</template>

  
<script>
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import PortfolioHoldings from "@/components/MEI/PortfolioHoldings.vue";
import MdsLoader from "@mds/loader";

Vue.use(VueAxios, axios);
export default {
    name: "portfolio-details",
    components: {
        PortfolioHoldings,
        MdsLoader,
    },
    data() {
        return {
            basicPortfolios: [],
            defensivePortfolios: [],
            incomePortfolios: [],
            factorPortfolios: [],
            ESGPortfolios: [],
            PortfolioDetailsURL: process.env.VUE_APP_BackEndAPI +
                process.env.VUE_APP_ModelPortfoliosDetailsURL,
            isLoading: true,
            isDisplay: false,
        };
    },
    methods: {
        GetPortfolioDetailsData() {
            try {
                Vue.axios
                    .get(this.PortfolioDetailsURL)
                    .then((response) => {
                        if (response) {
                            if (response.status == 200) {
                                if (response.data) {
                                    this.basicPortfolios = response.data.filter(
                                        (p) => p.PortfolioName == "Basic"
                                    );
                                    this.defensivePortfolios = response.data.filter(
                                        (p) => p.PortfolioName == "Defensive"
                                    );
                                    this.incomePortfolios = response.data.filter(
                                        (p) => p.PortfolioName == "Income"
                                    );
                                    this.factorPortfolios = response.data.filter(
                                        (p) => p.PortfolioName == "Factor"
                                    );
                                    this.ESGPortfolios = response.data.filter(
                                        (p) => p.PortfolioName == "ESG"
                                    );
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
            } catch (ex) {
                console.log(ex.message);
            }
        },
    },
    mounted() {
        this.GetPortfolioDetailsData();
    },
};
</script>

  
<style lang="scss" scoped>
@import "@mds/constants";
body {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}

 .mei-model-portfolio-details-container {
    width: 1280px;
    margin: 0 auto;
} 

@media only screen and (min-width: 320px) and (max-width: 1415px) { 

.mei-model-portfolio-details-container {
    width: 90%;
    margin-left:5%;
    margin-right:5%;
} 
}



.mei-portfolio-details-middle-text {
    color: #5e5e5e;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    margin-top: 8px;
    padding-bottom: 20px;
}

.mei-portfolio-details-title {
    font-weight: 700;
    font-size: 32px;
    padding-top: 44px;
    padding-bottom: 30px;
}

.mei-portfolio-details-div-border {
    border: solid 1px #e5e5e5;
    width: 100%;
}

.mei-port-img {
    margin-right: 10px;
}

.mei-portfolio-details-basic-portfolio {
    margin-top: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #5e5e5e;
}

.mei-portfolio-details{
    margin-top: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #5e5e5e;
}

@media only screen and (min-width: 320px) and (max-width: 428px) {

.mei-portfolio-details-title {
  font-size: 28px;
}

.mei-portfolio-details-basic-portfolio{
    font-size:20px;
}

.mei-portfolio-details-middle-text{
    font-size:16px;
}
}
</style>
