
<template>
  <div>
    <div v-if="isMobileContentToggle" class="article-main-responsive-div">
      
      <div class="top-border-Latest-commentory-responsive"></div>
      <div class="latest-commentary-heading-responsive">Latest Commentary</div>
      <div class="main-responsive-loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
      </div>
      <div>
        <mdc-section style="width: 100%">
          <mdc-carousel-article class="mdc-carousal-main-div">
            <mdc-carousel-Items-article
              v-for="(article, index) in articles"
              :key="index"
              class="main-carousal-div"
             
            >
              <mdc-card style="word-wrap: break-word; overflow: hidden">
                <template slot="secondary-text">
                  <div class="article-tiltles-responsive">
                    {{ article.Subject }}
                  </div>
                </template>

                <template slot="metadata">
                  <div class="article-author-info-responsive">
                    <span class="articles-authorname-responsive"
                      >by {{ article.PostAuthorName }}</span
                    >
                    <span class="articles-authorname">{{
                      article.PostDate | formatDateValForHomePage
                    }}</span>
                    <span class="articles-authorname">{{
                      article.PostDate | formatTime
                    }}</span>
                  </div>
                </template>

                <template slot="caption">
                  <div
                    v-if="CurrentProduct.toUpperCase() == 'MEI'"
                    class="article-mei-content-style-responsive"
                  >
                    <span
                      v-html="trimContent(article.FormattedBody)"
                      class="article-content-responsive"
                    ></span>
                  </div>
                  <div
                    v-if="CurrentProduct.toUpperCase() == 'MFI'"
                    class="article-mfi-content-style-responsive"
                  >
                    <span
                      v-html="trimContent(article.FormattedBody)"
                      class="article-content-responsive"
                    ></span>
                  </div>
                  <div
                    v-if="CurrentProduct.toUpperCase() == 'MDI'"
                    class="article-mdi-content-style-responsive"
                  >
                    <span
                      v-html="trimContent(article.FormattedBody)"
                      class="article-content-responsive"
                    ></span>
                  </div>
                  <div
                    v-if="CurrentProduct.toUpperCase() == 'MSI'"
                    class="article-msi-content-style-responsive"
                  >
                    <span
                      v-html="trimContent(article.FormattedBody)"
                      class="article-content-responsive"
                    ></span>
                  </div>
                  <div class="home-page-articles-bottom-fade"></div>
                  <!--<div v-if="CurrentProduct.toUpperCase() == 'MFI'" style="height: 174px;word-wrap:break-word;overflow:hidden;margin-top:21px;">
                                    <span v-html="article.FormattedBody" class="article-content"></span>
                                </div>-->
                </template>
                <template slot="readmore">
                  <div class="article-readMore-style-responsive">
                    <!-- <mds-button class="articles-readmore" variation="flat" size="small" icon-right="caret-right" @click="sendPostToCommentaryPage(index)">
                          Read More
                        </mds-button> -->
                    <a
                      class="articles-readmore-responsive"
                      tabindex="0"
                      @keydown.enter="sendPostToCommentaryPage(index)"
                      @click="sendPostToCommentaryPage(index)"
                    >
                      Read More
                      <span style="position: absolute; margin-top: 3px">
                        <mds-icon name="angle-right" size="small"></mds-icon>
                      </span>
                    </a>
                  </div>
                </template>
              </mdc-card>
            </mdc-carousel-Items-article>
          </mdc-carousel-article>
        </mdc-section>
      </div>
    </div>

   

    <div class="main-div-article-non-responsive" v-else>
      <div>
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
      </div>
    

      <div class="latest-commentary-heading">Latest Commentary</div>
      <mds-row v-if="isDisplay">
        <mdc-section style="width: 100%">
          <mdc-carousel class="mdc-main-carousel-div">
            <mdc-carouselItems
              v-for="(article, index) in articles"
              :key="index"
              class="mdc-caruselitem-main-div-for-width"
            >
              <mdc-card style="word-wrap: break-word; overflow: hidden">
                <template slot="secondary-text">
                  <div>{{ article.Subject }}</div>
                </template>
                <template slot="metadata">
                  <span class="articles-authorname-author"
                    >by {{ article.PostAuthorName }}</span
                  >
                  <span class="articles-authorname">{{
                    article.PostDate | formatDateValForHomePage
                  }}</span>
                  <span class="articles-authorname">{{
                    article.PostDate | formatTime
                  }}</span>
                </template>
                <template slot="caption">
                  <div
                    v-if="CurrentProduct.toUpperCase() == 'MEI'"
                    class="article-mei-content-style"
                  >
                    <span
                      v-html="trimContent(article.FormattedBody)"
                      class="article-content"
                    ></span>
                  </div>
                  <div
                    v-if="CurrentProduct.toUpperCase() == 'MFI'"
                    class="article-mfi-content-style"
                  >
                    <span
                      v-html="trimContent(article.FormattedBody)"
                      class="article-content"
                    ></span>
                  </div>
                  <div
                    v-if="CurrentProduct.toUpperCase() == 'MDI'"
                    class="article-mdi-content-style"
                  >
                    <span
                      v-html="trimContent(article.FormattedBody)"
                      class="article-content"
                    ></span>
                  </div>
                  <div
                    v-if="CurrentProduct.toUpperCase() == 'MSI'"
                    class="article-msi-content-style"
                  >
                    <span
                      v-html="trimContent(article.FormattedBody)"
                      class="article-content"
                    ></span>
                  </div>
                  <div class="home-page-articles-bottom-fade"></div>
                  <!--<div v-if="CurrentProduct.toUpperCase() == 'MFI'" style="height: 174px;word-wrap:break-word;overflow:hidden;margin-top:21px;">
                                    <span v-html="article.FormattedBody" class="article-content"></span>
                                </div>-->
                </template>
                <template slot="readmore">
                  <div class="article-readMore-style">
                    <!-- <mds-button class="articles-readmore" variation="flat" size="small" icon-right="caret-right" @click="sendPostToCommentaryPage(index)">
                          Read More
                        </mds-button> -->
                    <a
                      class="articles-readmore"
                      tabindex="0"
                      @keydown.enter="sendPostToCommentaryPage(index)"
                      @click="sendPostToCommentaryPage(index)"
                    >
                      Read More
                      <span style="position: absolute; margin-top: 3px">
                        <mds-icon name="angle-right" size="small"></mds-icon>
                      </span>
                    </a>
                  </div>
                </template>
              </mdc-card>
            </mdc-carouselItems>
          </mdc-carousel>
        </mdc-section>
      </mds-row>
    </div>
   
  </div>
</template>
    
    <script>
import MdsIcon from "@mds/icon";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import MdcCarousel from "../../../CustomControls/MdcCarousel.vue";
import MdcCarouselArticle from "../../../CustomControls/MdcCarouselArticle.vue";
import MdcCarouselItems from "../../../CustomControls/MdcCarouselItems.vue";
import MdcCarouselItemsArticle from "../../../CustomControls/MdcCarousalItemsArticle.vue";
import MdcSection from "../../../CustomControls/MdcSection.vue";
import MdcCard from "../../../CustomControls/MdcCard.vue";
//import { MdsButton } from "@mds/button";
import { mapState } from "vuex";
import { MdsRow } from "@mds/layout-grid";
import MdsLoader from "@mds/loader";

Vue.use(VueAxios, axios);

export default {
  name: "articles",
  components: {
    MdcCarousel,
    MdcCarouselItems,
    MdcSection,
    MdcCard,
    MdsRow,
    MdsLoader,
    MdsIcon,
    MdcCarouselArticle,
    MdcCarouselItemsArticle,
  },
  data() {
    return {
      articles: [],
      LatestArticlesURL:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_LatestArticlesURL,
      CurrentProduct: this.$session.get("current-product"),
      isDisplay: false,
      isLoading: true,
      isLeftDiv: false,
      isMobileContentToggle: null,
      
      viewPortWidth: null,
    };
  },
  methods: {
    newWindows(value) {
      var newValue = "";
      if (value.includes("<a")) {
        newValue = "<a target=_blank";
        return value.replaceAll("<a", newValue);
      }
    },
    trimContent(content) {
      if (content != null) {
        var htmlData = content.replace(/<((?!p)(?!ul)(?!ol)(?!a)(?!li)(?!b)(?!BR)(?!span)(?!sup)(?!sub)(?!i)(?!strong)(?!CENTER)(?!h)(?!em)(?!table)(?!tbody)(?!tr)(?!td)(?!th)(?!hr)\s*\b\/?)[^>]+>/g, '');
        //eslint-disable-next-line
        var trimInlineCss = htmlData.replace(/(style=\"[^\"]*\")/g, '');
        return trimInlineCss;
        //return htmlData;
      }
    },
   
    GetLatestArticles() {
      try {
        Vue.axios
          .get(
            this.LatestArticlesURL +
              this.$session.get("current-product").toUpperCase()
          )
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.articles = response.data;
                  this.isLoading = false;
                  this.isDisplay = true;
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
      } catch (ex) {
        console.log(ex.message);
      }
    },
   
    sendPostToCommentaryPage(index) {
      const postId = this.articles[index].PostID;
      this.$router
        .push({ name: "commentary", params: { pid: postId } })
        .catch((err) => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(err);
          }
        });
    },
  },
  mounted() {
    this.isMobileContentToggle = this.$store.state.responsive.isMobileContent;
    this.GetLatestArticles();
   
  },

  computed: mapState({
    isMobileContent: (state) => state.responsive.isMobileContent,
    isMobileContentAlias: "isMobileContent",
    isMobileContentState(state) {
      return state.responsive.isMobileContent;
    },
    

  }),

  watch: {
    isMobileContentState() {
      this.isMobileContentToggle = this.isMobileContentState;
    },
   
  },
};
</script>
    
    <style>
.main-carousal-div {
  width: 90%;
 
}

.mdc-main-carousel-div{
  width: 100%;
}
.main-div-article-non-responsive {
  margin-top: 19px;
  margin-right: 16px;
  width: 96%;
}
.mdc-carousal-main-div {
  width: 100%;
}

.mdc-caruselitem-main-div-for-width {
  width: 60%;

  margin-right: 1px !important;
}

.mds-thead .mds-thead__group .mds-th__inner {
  border-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.article-content sup {
  font-size: 10px;
  font-weight: 500;
}
.article-content > p > span,
.article-content {
  font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  color: #000000 !important;
}

.article-content > p {
  margin-left: 0pt !important;
  font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 20px !important;
  /* line-height: 26px !important; */
  color: #000000 !important;
  margin-top: 0px !important;
  margin-bottom: 15px !important;
  padding-top: 0px !important;
}

.article-content > p > strong {
  font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 20px !important;
  /* line-height: 26px !important; */
  color: #000000 !important;
  font-weight: 700 !important;
}

/*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}*/

.article-content a {
  color: #000000 !important;
  /* border-bottom: 1px solid #ababab !important;*/

  text-decoration-thickness: 1px !important;
  text-underline-offset: 2px !important;
  text-decoration-color: #ababab !important;
}
.articles-authorname {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  padding-left: 0px;
}


.mdc-caruselitem-main-div-for-width:nth-child(2) {
  padding-left: 30px;
}
.mdc-caruselitem-main-div-for-width:nth-child(3) {
  padding-left: 30px;
  
}
.mdc-caruselitem-main-div-for-width:nth-child(4) {
  padding-left: 30px;
}
.mdc-caruselitem-main-div-for-width:nth-child(5) {
  padding-left: 30px;
}


.articles-readmore {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  cursor: pointer;
}

.articles-authorname-author {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  padding-left: 8px;
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .articles-authorname-author {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  padding-left: 8px;
}

}
@media only screen and (min-width: 1280px) and (max-width: 12800px) {
  .articles-readmore {
    font-style: normal;
    font-weight: 500;
    margin-left: 11px;
    font-size: 14px;
    line-height: 21px;
    color: #5e5e5e;
    cursor: pointer;
  }

  .articles-authorname-author {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  padding-left: 11px;
}

}

/*
    .class_if_is_true .mdc-carousel__items>li:nth-child(4){
        width: 64%;
        background-color: aqua;
    }
    */
/*
    .else_class{
        background-color: red;
    }
    */
.mdc-card__secondary-text {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  padding-left: 11px;
}
.article-msi-content-style {
  height: 160px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}
.article-mfi-content-style {
  height: 199px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}

.article-mei-content-style {
  height: 148px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}
.article-readMore-style {
  margin-top: 16px;
  padding-left: 8px;
}
.latest-commentary-heading {
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 27px;
  color: #1e1e1e;
  margin-left: -6px;
  margin-bottom: 30px;
  margin-top: -6px;
}

.latest-commentary-heading-responsive {
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 27px;
  color: #1e1e1e;
  margin-left: 0px;
  margin-bottom: 19px;
  margin-top: -6px;
}
.articles-authorname-responsive {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  padding-left: 0px;
}
.article-msi-content-style-responsive {
  height: 160px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-right: 20px;
}
.article-mfi-content-style-responsive {
  height: 155px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 0px;
}
.article-mdi-content-style-responsive {
  height: 160px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 0px;
}
.article-mei-content-style-responsive {
  height: 148px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 0px;
}

.articles-readmore-responsive {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  cursor: pointer;
}
.article-readMore-style {
  margin-top: 16px;
  padding-left: 0px;
}

.article-main-responsive-div {
  width: 90%;
  margin-left: 16px;
  margin-right: 0px;
}

.article-readMore-style-responsive {
  margin-top: 10px;
}

.article-tiltles-responsive {
  margin-left: -10px;
}

.top-border-Latest-commentory {
  border: 1px solid #5e5e5e;
  margin-top: 42px;
  margin-bottom: 12px;
}

.top-border-Latest-commentory-responsive {
  width: 100%;
  border-top: 1px solid #5e5e5e;
  margin-top: 42px;
  margin-bottom: 12px;
}
@media only screen and (min-width: 600px) and (max-width: 800px) {

.article-mdi-content-style {
  height: 202px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}
}
@media only screen and (min-width: 1000px) and (max-width: 1200px) {

.article-mdi-content-style {
  height: 202px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {

.article-mdi-content-style {
  height: 150.5px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 6px;
}
}

@media only screen and (min-width: 1280px) and (max-width: 12800px) {

.article-mdi-content-style {
  height: 150.5px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 10px;
}
}

.article-mdi-content-style ul,ol{
  padding-left: 18px;
}
.article-mdi-content-style-responsive ul,ol{
  padding-left: 15px;
}

.article-msi-content-style ul,ol{
  padding-left: 18px;
}
.article-msi-content-style-responsive ul,ol{
  padding-left: 15px;
}

.article-mei-content-style ul,ol{
  padding-left: 18px;
}
.article-mei-content-style-responsive ul,ol{
  padding-left: 15px;
}

.article-mfi-content-style ul,ol{
  padding-left: 18px;
}
.article-mfi-content-style-responsive ul,ol{
  padding-left: 15px;
}

@media only screen and (min-width: 800px) and (max-width: 1000px) {

.article-mdi-content-style {
  height: 191.5px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}
}

@media only screen and (min-width: 600px) and (max-width: 1280px) {
 

  .article-msi-content-style {
    height: 160px;
    word-wrap: break-word;
    overflow: hidden;
    margin-top: 21px;
    padding-left: 5px;
  }
  .article-mfi-content-style {
  height: 191px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 5px;
}

  .article-mei-content-style {
    height: 148px;
    word-wrap: break-word;
    overflow: hidden;
    margin-top: 21px;
    padding-left: 5px;
  }
  .mdc-card__secondary-text {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    padding-left: 5px;
  }
  .latest-commentary-heading {
    font-style: normal;
    font-weight: 300;
    font-size: 23px;
    line-height: 27px;
    color: #1e1e1e;
    margin-left: -11px;
    margin-bottom: 30px;
    margin-top: -6px;
  }
  .articles-authorname {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5e5e5e;
    padding-left: 0px;
  }
 
  
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {

.articles-readmore {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5e5e5e;
    cursor: pointer;
    padding-left: 8px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {

.articles-readmore {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5e5e5e;
    cursor: pointer;
    padding-left: 5px;
  }
}


@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .articles-readmore {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5e5e5e;
    cursor: pointer;
    padding-left: 8px;
  }
  

}


@media only screen and (min-width: 600px) and (max-width: 640px) {
  .article-msi-content-style {
  height: 160px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.article-mei-content-style {
  height: 150px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.main-div-article-non-responsive {
  margin-top: 19px;
  margin-right: 16px;
  width: 96%;
}

.mdc-caruselitem-main-div-for-width:nth-child(1) {
  width: 80%;
    
    padding-left: 0px;
  }

  .mdc-caruselitem-main-div-for-width:nth-child(2) {
    width: 70%;
    margin-left: 20px;
    padding-left: 0px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(3) {
    width: 85%;
    margin-left: 150px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(4) {
    width: 83%;
    margin-left: 85px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(5) {
    width: 85%;
    margin-left: 30px;
  }

  .articles-opacity-left {
    position: absolute;
    width: 50px;
    height: 310px;
    margin-top: 60px;
    margin-left: 25px;
    left: 0%;
    /* background:aquamarine;*/
    background: linear-gradient(
      90deg,
      #ffffff 22.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
  }

  .articles-opacity-right {
    position: absolute;
    width: 70px;
    height: 350px;
    right: 0%;
    margin-right: 25px;
    margin-top: 60px;
    /* background-color: aqua;*/
    background: linear-gradient(
      90deg,
      #ffffff 22.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
    transform: rotate(180deg);
  }
}






@media only screen and (min-width: 310px) and (max-width: 365px) {

  
.article-msi-content-style-responsive {
height: 173px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-mdi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-msi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-main-responsive-div {
margin-top: 19px;

width: 93%;
}

.home-commentary-angle-right{

}

.mdc-carousal-main-div{
width: 285px;
padding-right: 12px;
}

.mdc-carousal-main-div> div> ul > li:nth-child(1) {
 padding: 0px;
 margin: 0px;

}

.mdc-carousal-main-div> div> ul > li:nth-child(2) {
  width: 90%;
  padding-left: 20px;
 margin: 0px;

  margin-left: 68px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(3) {
  width: 88%;
  padding-left: 30px;
 margin: 0px;
  margin-left: 333px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(4) {
  width: 89%;
  padding-left: 30px;
 margin: 0px;

  margin-left: 265px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(5) {
  width: 90%;
  padding-left: 10px;
 margin: 0px;
 margin-left: 462px;
    
}
}



@media only screen and (min-width: 365px) and (max-width: 390px) {
.article-msi-content-style-responsive {
height: 173px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-mdi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-msi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-main-responsive-div {
margin-top: 19px;

width: 93%;
}

.home-commentary-angle-right{

}

.mdc-carousal-main-div{
width: 320px;
padding-right: 20px;
}

.mdc-carousal-main-div> div> ul > li:nth-child(1) {
 padding: 0px;
 margin: 0px;

}

.mdc-carousal-main-div> div> ul > li:nth-child(2) {
  width: 93%;
  padding-left: 18px;
 margin: 0px;

  margin-left: 32px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(3) {
  width: 93%;
  padding-left: 20px;
 margin: 0px;
  margin-left: 300px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(4) {
  width: 93%;
  padding-left: 21px;
 margin: 0px;

  margin-left: 230px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(5) {
  width: 93%;
  padding-left: 20px;
 margin: 0px;
 margin-left: 10px;
    
}
}

@media only screen and (min-width: 390px) and (max-width: 415px) {
.article-msi-content-style-responsive {
height: 173px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-mdi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-msi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-main-responsive-div {
margin-top: 19px;

width: 91%;
}

.home-commentary-angle-right{

}

.mdc-carousal-main-div{
width: 340px;
padding-right: 20px;
}

.mdc-carousal-main-div> div> ul > li:nth-child(1) {
 padding: 0px;
 margin: 0px;

}

.mdc-carousal-main-div> div> ul > li:nth-child(2) {
  width: 94%;
  padding-left: 20px;
 margin: 0px;

  margin-left: 12px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(3) {
  width: 90%;
  padding-left: 20px;
 margin: 0px;
  margin-left: 280px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(4) {
  width: 92%;
  padding-left: 30px;
 margin: 0px;

  margin-left: 210px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(5) {
  width: 93%;
  padding-left: 20px;
 margin: 0px;
 margin-left: 18px;
    
}
}

@media only screen and (min-width: 415px) and (max-width: 440px) {
.article-msi-content-style-responsive {
height: 173px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-mdi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-msi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-main-responsive-div {
margin-top: 19px;

width: 91%;
}

.home-commentary-angle-right{

}

.mdc-carousal-main-div{
width: 345px;
padding-right: 20px;
}

.mdc-carousal-main-div> div> ul > li:nth-child(1) {
  
 padding: 0px;
 margin: 0px;
margin-left: 3px;
margin-right: 10px;

}

.mdc-carousal-main-div> div> ul > li:nth-child(2) {
  width: 94%;
  padding-left: 20px;
 margin: 0px;

  margin-left: -5px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(3) {
  width: 93%;
  padding-left: 20px;
 margin: 0px;
  margin-left: 273px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(4) {
  width: 93%;
  padding-left: 25px;
 margin: 0px;

  margin-left: 203px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(5) {
  width: 93%;
  padding-left: 20px;
 margin: 0px;

 margin-left: 415px;
    
}
}


@media only screen and (min-width: 440px) and (max-width: 480px) {
.article-msi-content-style-responsive {
height: 173px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-mdi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-msi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-main-responsive-div {
margin-top: 19px;

width: 91%;
}

.home-commentary-angle-right{

}

.mdc-carousal-main-div{
width: 340px;
padding-right: 20px;
}

.mdc-carousal-main-div> div> ul > li:nth-child(1) {
  width: 99%;
 padding: 0px;
 margin: 0px;
margin-left: 3px;
margin-right: 10px;
}

.mdc-carousal-main-div> div> ul > li:nth-child(2) {
  width: 90%;
  padding-left: 48px;
 margin: 0px;

  margin-left: -25px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(3) {
  width: 90%;
  padding-left: 57px;
 margin: 0px;
  margin-left: 255px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(4) {
  width: 95%;
  padding-left: 48px;
 margin: 0px;

  margin-left: 190px;
}
.mdc-carousal-main-div> div> ul > li:nth-child(5) {
  width: 95%;
  padding-left: 43px;
 margin: 0px;

 margin-left: 415px;
    
}
}

@media only screen and (min-width: 480px) and (max-width: 600px) {
.main-carousal-div {
  width: 90%;
  padding-right: 15px !important;
   margin-right: 0px;
}

.article-msi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-mdi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

.article-mfi-content-style-responsive {
height: 170px;
word-wrap: break-word;
overflow: hidden;
padding: 0px;
 margin: 0px;
}

}

@media only screen and (min-width: 0px) and (max-width: 480px) {
.main-carousal-div {
  width: 100%;
  padding-right: 1px !important;
   margin-right: 0px;
}
}

@media only screen and (min-width: 600px) and (max-width: 480000px) {
.main-carousal-div {
  width: 100%;
  
}
}


@media only screen and (min-width: 640px) and (max-width: 680px) {
  .article-msi-content-style {
  height: 160px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.article-mei-content-style {
  height: 150px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.main-div-article-non-responsive {
  margin-top: 19px;
  margin-right: 16px;
  width: 96%;
}

.mdc-caruselitem-main-div-for-width:nth-child(1) {
  width: 80%;
    
    padding-left: 0px;
  }

  .mdc-caruselitem-main-div-for-width:nth-child(2) {
    width: 60%;
    margin-left: 20px;
    padding-left: 0px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(3) {
    width: 85%;
    margin-left: 160px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(4) {
    width: 85%;
    margin-left: 70px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(5) {
    width: 85%;
    margin-left: 30px;
  }

  .articles-opacity-left {
    position: absolute;
    width: 60px;
    height: 310px;
    margin-top: 60px;
    margin-left: 25px;
    left: 0%;
    /* background:aquamarine;*/
    background: linear-gradient(
      90deg,
      #ffffff 22.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
  }

  .articles-opacity-right {
    position: absolute;
    width: 70px;
    height: 350px;
    right: 0%;
    margin-right: 25px;
    margin-top: 60px;
    /* background-color: aqua;*/
    background: linear-gradient(
      90deg,
      #ffffff 22.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
    transform: rotate(180deg);
  }
}

@media only screen and (min-width: 680px) and (max-width: 720px) {
  .article-msi-content-style {
  height: 160px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}

.article-mei-content-style {
  height: 150px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.main-div-article-non-responsive {
  margin-top: 19px;
  margin-right: 16px;
  width: 96%;
}

.mdc-caruselitem-main-div-for-width:nth-child(1) {
  width: 70%;
    
    padding-left: 0px;
  }

  .mdc-caruselitem-main-div-for-width:nth-child(2) {
    width: 75%;
    margin-left: 20px;
    padding-left: 0px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(3) {
    width: 85%;
    margin-left: 80px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(4) {
    width: 85%;
    margin-left: 30px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(5) {
    width: 85%;
    margin-left: 10px;
  }

  .articles-opacity-left {
    position: absolute;
    width: 50px;
    height: 310px;
    margin-top: 60px;
    margin-left: 25px;
    left: 0%;
    /* background:aquamarine;*/
    background: linear-gradient(
      90deg,
      #ffffff 22.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
  }

  .articles-opacity-right {
    position: absolute;
    width: 70px;
    height: 350px;
    right: 0%;
    margin-right: 25px;
    margin-top: 60px;
    /* background-color: aqua;*/
    background: linear-gradient(
      90deg,
      #ffffff 22.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
    transform: rotate(180deg);
  }
}

@media only screen and (min-width: 720px) and (max-width: 760px) {
  .article-msi-content-style {
  height: 160px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.article-mei-content-style {
  height: 150px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.main-div-article-non-responsive {
  margin-top: 19px;
  margin-right: 16px;
  width: 96%;
}

.mdc-caruselitem-main-div-for-width:nth-child(1) {
  width: 70%;
    
    padding-left: 0px;
  }

  .mdc-caruselitem-main-div-for-width:nth-child(2) {
    width: 75%;
    margin-left: 20px;
    padding-left: 0px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(3) {
    width: 85%;
    margin-left: 20px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(4) {
    width: 85%;
    margin-left: 20px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(5) {
    width: 85%;
    margin-left: 10px;
  }

  .articles-opacity-left {
    position: absolute;
    width: 50px;
    height: 310px;
    margin-top: 60px;
    margin-left: 25px;
    left: 0%;
    /* background:aquamarine;*/
    background: linear-gradient(
      90deg,
      #ffffff 22.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
  }

  .articles-opacity-right {
    position: absolute;
    width: 70px;
    height: 350px;
    right: 0%;
    margin-right: 25px;
    margin-top: 60px;
    /* background-color: aqua;*/
    background: linear-gradient(
      90deg,
      #ffffff 22.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
    transform: rotate(180deg);
  }
}

@media only screen and (min-width: 760px) and (max-width: 800px) {
  .article-msi-content-style {
  height: 160px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.article-mei-content-style {
  height: 150px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.main-div-article-non-responsive {
  margin-top: 19px;
  margin-right: 16px;
  width: 96%;
}

.mdc-caruselitem-main-div-for-width:nth-child(1) {
  width: 70%;
    
    padding-left: 0px;
  }

  .mdc-caruselitem-main-div-for-width:nth-child(2) {
    width: 70%;
    margin-left: 20px;
    padding-left: 0px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(3) {
    width: 85%;
    margin-left: 20px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(4) {
    width: 80%;
    margin-left: 10px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(5) {
    width: 85%;
    margin-left: 10px;
  }

  .articles-opacity-left {
    position: absolute;
    width: 50px;
    height: 310px;
    margin-top: 60px;
    margin-left: 25px;
    left: 0%;
    /* background:aquamarine;*/
    background: linear-gradient(
      90deg,
      #ffffff 22.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
  }

  .articles-opacity-right {
    position: absolute;
    width: 90px;
    height: 350px;
    right: 0%;
    margin-right: 25px;
    margin-top: 60px;
    /* background-color: aqua;*/
    background: linear-gradient(
      90deg,
      #ffffff 22.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
    transform: rotate(180deg);
  }
}



@media only screen and (min-width: 800px) and (max-width: 1000px) {

  .main-div-article-non-responsive {
  margin-top: 19px;
  margin-left: 0px;
  width: 99%;
}
  .article-msi-content-style {
  height: 160px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.article-mei-content-style {
  height: 150px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}

.mdc-caruselitem-main-div-for-width:nth-child(1) {
    width: 510px;
    
    padding-left: 0px;
  }

  .mdc-caruselitem-main-div-for-width:nth-child(2) {
    width: 500px;
    margin-left: 30px;
    padding-left: 0px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(3) {
    width: 520px;
    margin-left: 10px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(4) {
    width: 520px;
    margin-left: 30px;
  }
  .mdc-caruselitem-main-div-for-width:nth-child(5) {
    width: 520px;
    margin-left: 30px;
  }



  .articles-opacity-left {
    position: absolute;
    width: 100px;
    height: 310px;
    margin-top: 60px;
    margin-left: 30px;
    left: 0%;
    /* background:aquamarine;*/
    background: linear-gradient(
    85.79deg,
    #ffffff 12.47%,
    rgba(255, 255, 255, 0) 112.75%
  );
    opacity: 1;
    z-index: 2;
  }

  .articles-opacity-right {
    position: absolute;
    width: 100px;
    height: 350px;
    right: 0%;
    margin-right: 28px;
    margin-top: 60px;
    /* background-color: aqua;*/
    background: linear-gradient(
      90deg,
      #ffffff 12.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
    transform: rotate(180deg);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
.main-div-article-non-responsive {
  margin-top: 19px;
  margin-left: 46px;
 
  width: 84%;
}
.mdc-caruselitem-main-div-for-width:nth-child(1) {
  width: 550px;
 
}

.article-mei-content-style {
  height: 150px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}

.mdc-caruselitem-main-div-for-width:nth-child(2) {
  width: 550px;
  padding-left: 30px;
}
.mdc-caruselitem-main-div-for-width:nth-child(3) {
  padding-left: 30px;
  width: 550px;
  
}
.mdc-caruselitem-main-div-for-width:nth-child(4) {
  padding-left: 30px;
  width: 550px;
}
.mdc-caruselitem-main-div-for-width:nth-child(5) {
  padding-left: 30px;
  width: 550px;
}
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
.main-div-article-non-responsive {
  margin-top: 19px;
  margin-left: 0px;
  width: 99%;
}
.article-mei-content-style {
  height: 150px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}


.mdc-caruselitem-main-div-for-width:nth-child(1) {
  width: 470px;
 
}

.mdc-caruselitem-main-div-for-width:nth-child(2) {
  width: 420px;
  padding-left: 30px;
}
.mdc-caruselitem-main-div-for-width:nth-child(3) {
  padding-left:120px;
  width: 530px;
  
}
.mdc-caruselitem-main-div-for-width:nth-child(4) {
  padding-left: 140px;
  width: 520px;
}
.mdc-caruselitem-main-div-for-width:nth-child(5) {
  padding-left: 30px;
  width: 520px;
}



  .articles-opacity-right {
    position: absolute;
    width: 70px;
    height: 350px;
    right: 0%;
    margin-right: 28px;
    margin-top: 60px;
    /* background-color: aqua;*/
    background: linear-gradient(
      90deg,
      #ffffff 12.47%,
      rgba(255, 255, 255, 0) 112.75%
    );
    opacity: 1;
    z-index: 2;
    transform: rotate(180deg);
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
.main-div-article-non-responsive {
  margin-top: 19px;
  margin-left: 0px;
  width: 99%;
}
.article-mei-content-style {
  height: 150px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 8px;
}

.mdc-caruselitem-main-div-for-width:nth-child(1) {
  width: 470px;
 
}

.mdc-caruselitem-main-div-for-width:nth-child(2) {
  width: 480px;
  padding-left: 30px;
}
.mdc-caruselitem-main-div-for-width:nth-child(3) {
  padding-left:90px;
  width: 530px;
  
}
.mdc-caruselitem-main-div-for-width:nth-child(4) {
  padding-left: 110px;
  width: 520px;
}
.mdc-caruselitem-main-div-for-width:nth-child(5) {
  padding-left: 30px;
  width: 520px;
}
}

@media only screen and (min-width: 1280px) and (max-width: 12000px) {
.article-mei-content-style {
  height: 150px;
  word-wrap: break-word;
  overflow: hidden;
  margin-top: 21px;
  padding-left: 11px;
}
}


@media only screen and (min-width: 0px) and (max-width: 600px) {


.mdc-carousal-main-div>li::nth-child(1){
padding-left: 0px;

}

  .article-tiltles-responsive {
    width: 98%;
    word-wrap: break-word;
    font-size: 20px;
    line-height: 23px;
    padding-left: 0px;
  }

  

  .article-mfi-content-style-responsive {
    height: 193px;
   
    margin-top: 18px;
    margin-left: 1px;
  
  }

  .article-content-responsive {
    font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px;
    line-height: 21px;
    color: #000000 !important;
  }

  .article-content-responsive > p {
    margin-left: 0pt !important;
    font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 16px;
    line-height: 21px;
    color: #000000 !important;
    margin-top: 0px !important;
    margin-bottom: 21px;
    padding-top: 0px !important;
  }

  .article-content-responsive > p > strong {
    font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
      sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px;
    line-height: 21px;
    color: #000000 !important;
    font-weight: 700 !important;
  }
  .article-content-responsive a {
    color: #000000 !important;
    /* border-bottom: 1px solid #ababab !important;*/

    text-decoration-thickness: 1px !important;
    text-underline-offset: 2px !important;
    text-decoration-color: #ababab !important;
  }

  .article-author-info-responsive {
    margin-top: -8px;
    padding-left: 1px;
    margin-bottom: 18px;
  }

  .articles-readmore-responsive {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5e5e5e;
    cursor: pointer;
    padding-left: 2px;
  }
  .articles-authorname {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5e5e5e;
    padding-left: 5px;
  }

}










.main-responsive-loader{
  width: 100%;
}




@media only screen and (min-width: 0px) and (max-width: 6000px) {
.article-msi-content-style-responsive {

padding-left: 2px;
}
.article-mei-content-style-responsive {

padding-left: 2px;
}
.article-mfi-content-style-responsive {

padding-left: 2px;
}
.article-mdi-content-style-responsive {

padding-left: 2px;
}

}

.home-page-articles-bottom-fade{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100px;
    margin-top: -99px;
    background: linear-gradient(0deg, #FFFFFF 6%, rgba(0, 0, 0, 0) 59.75%);
}
</style>
    