<template>
    <div>
        <div v-if="isMobileContentToggle" class="main-editor-more-div-responsive">
            <div class="editor-top-border-responsive"></div>
            <div class="editor-section-heading-responsive">About the Editor</div>
            <div v-if="CurrentProduct.toUpperCase() == 'MSI'">
                <div style="display: flex;margin-top: 19px;">
                    <div class="editor-image">
                        <img src="https://im.morningstar.com/im/msi_editor.png" class="editor-image-style" />
                    </div>
                    <div class="editor-text">
                        <div>
                            <b class="editor-text-header">David Harrell</b> <br />
                            <div class="editor-text-style">Editor,<br />
                                <i>Morningstar<br />
                                    StockInvestor</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="editor-description-responsive">
                    David Harrell is the editor of the <i>Morningstar StockInvestor</i>,
                    a monthly newsletter that
                    focuses on a wide-moat stock investing
                    strategy. For illustration purposes, issues
                    highlight activities pertaining to accounts
                    owned by Morningstar, Inc. invested in
                    accordance with a strategy that seeks to focus
                    on companies with stable or growing
                    competitive advantages.
                </div>

            </div>

            <div v-if="CurrentProduct.toUpperCase() == 'MDI'">
                <div style="display: flex;margin-top: 19px;">
                    <div class="editor-image">
                        <img src="https://im.morningstar.com/im/mdi_editor.png" class="editor-image-style" />
                    </div>
                    <div class="editor-text">
                        <div>
                            <b class="editor-text-header">David Harrell</b> <br />
                            <div class="editor-text-style">Editor,<br />
                                <i>Morningstar<br />
                                    DividendInvestor</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="editor-description-responsive">
                    David Harrell is the editor of <i>Morningstar DividendInvestor</i>,
                    a monthly newsletter that highlights activities pertaining
                    to a Morningstar, Inc. account invested in accordance with
                    a strategy that takes a concentrated, best-ideas approach
                    when investing in select common stocks of dividend-paying
                    companies and other securities. The strategy seeks firms
                    with wide or narrow moats that we believe are in a stronger
                    competitive position than their peers and that are trading
                    at a reasonable price.
                </div>

            </div>

            <div v-if="CurrentProduct.toUpperCase() == 'MFI'">
                <div style="display: flex;margin-top: 19px;">
                    <div class="editor-image">
                        <img src="https://im.morningstar.com/im/mfi_editor.png" class="editor-image-style" />
                    </div>
                    <div class="editor-text">
                        <div>
                            <b class="editor-text-header">Russel Kinnel</b> <br />
                            <div class="editor-text-style">
                                Director of<br />
                                Manager Research<br />and Editor,<br />
                                <i>Morningstar<br />
                                    FundInvestor</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="editor-description-responsive">
                    Russel Kinnel is director of manager research for
                    Morningstar Research Services LLC and editor of <i>Morningstar FundInvestor</i>,
                    a monthly print newsletter for individual investors. He also writes
                    the Fund Spy column for Morningstar.com, the company's investment Web site.
                </div>
            </div>

            <div v-if="CurrentProduct.toUpperCase() == 'MEI'">
                <div style="display: flex;margin-top: 19px;">
                    <div class="editor-image">
                        <img src="https://im.morningstar.com/im/mei_editor.png" class="editor-image-style" />
                    </div>
                    <div class="editor-text">
                        <div>
                            <b class="editor-text-header">Bryan Armour, CFA</b> <br />
                            <div class="editor-text-style">
                                Director,<br />
                                Passive Strategies <br />
                                North America and Editor<br />
                                <i>Morningstar ETFInvestor</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="editor-description-responsive">
                    Bryan Armour is director of passive strategies research for North
                    America at Morningstar Research Services LLC,
                    a wholly owned subsidiary of Morningstar, Inc. Before joining
                    Morningstar in 2021, Armour spent seven years working for the
                    Financial Industry Regulatory Authority, conducting regulatory
                    trade surveillance and investigations, specializing in exchange-traded funds.
                </div>

            </div>

            <div style="margin-top: 14px;">
                <span @keydown.enter="toggle = !toggle" @click="toggle = !toggle" class="editor-readmore" tabindex="0">
                    Read More
                    <span style="position: absolute;margin-top: 3px;"><mds-icon name="angle-right"
                            size="small"></mds-icon></span>
                </span>
            </div>
            <mds-modal v-model="toggle" :width="'600px'" aria-labelledby="title-id" class="editor-read-more-pop-up">
                <mds-section class="with-nested-sections" border="none">

                    <h1 class="title-responsive-editor" id="title-id">About the Editor</h1>

                    <template #mds-section-actions>

                        <mds-button-container right-aligned>
                            <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>

                    </template>


                    <h1 class="border-editor-more-moddal"></h1>


                    <mds-section border="none" v-if="CurrentProduct.toUpperCase() == 'MSI'"
                        class="editor-read-more-content">
                        <p>
                            David Harrell is the editor of the <i>Morningstar StockInvestor,</i> a monthly newsletter
                            that
                            focuses on a
                            wide-moat stock investing strategy. For illustration purposes, issues highlight activities
                            pertaining
                            to accounts owned by Morningstar, Inc. invested in accordance with a strategy that seeks to
                            focus on
                            companies with stable or growing competitive advantages. David served in several senior
                            research
                            and
                            product development roles and was part of the editorial team that created and launched
                            Morningstar.com.
                            He was the co-inventor of Morningstar's first investment advice software.
                        </p>

                        <p>
                            David joined Morningstar in 1994. He holds a bachelor's degree in biology from Skidmore
                            College
                            and a
                            master's degree in biology from the University of Illinois at Springfield.
                        </p>

                        <span class="PortManager_SubHeading">Our Portfolio Manager</span>

                        <p style="margin-top:0px;">
                            Michael Corty, CFA, is the Head of U.S. Equity Strategies and a Portfolio Manager for
                            Morningstar
                            Investment Management LLC. Michael joined the group as a portfolio manager in December 2013.
                        </p>

                        <p>
                            Previously, he was a senior equity analyst in Morningstar, Inc.'s equity research department
                            where
                            he also served as a voting member of the economic moat committee. Michael holds a bachelor's
                            degree
                            from Loyola Marymount University and an MBA from Johnson Graduate School of Management at
                            Cornell University.
                        </p>

                        <p>
                            Grady Burkett, CFA, is a Portfolio Manager with Morningstar Investment Management LLC. Grady
                            joined the group as a portfolio manager in December 2022.
                        </p>

                        <p>
                            Prior to joining Morningstar Investment Management, Grady was an analyst and portfolio
                            manager
                            at Diamond Hill,
                            an independent and registered investment adviser. Grady started his investment career in
                            Morningstar,
                            Inc.'s equity research department where he progressed in several roles on the technology
                            sector
                            team as an equity
                            analyst, strategist, and director of the team. Grady received his B.S. and M.S. in
                            Mathematics
                            from Wright
                            State University. He is a member of the CFA Institute and the CFA Society of Columbus, Ohio
                        </p>
                    </mds-section>
                    <mds-section border="none" v-if="CurrentProduct.toUpperCase() == 'MDI'"
                        class="editor-read-more-content">
                        <p>
                            David Harrell is the editor of <i>Morningstar DividendInvestor,</i> a monthly newsletter
                            that
                            highlights
                            activities pertaining to a Morningstar, Inc. account invested in accordance with a strategy
                            that
                            takes a concentrated, best-ideas approach when investing in select common stocks of
                            dividend-paying
                            companies and other securities. The strategy seeks firms with wide or narrow moats that we
                            believe
                            are in a stronger competitive position than their peers and that are trading at a reasonable
                            price.
                        </p>

                        <p>
                            David served in several senior research and product development roles and was part of the
                            editorial
                            team that created and launched Morningstar.com. He was the co-inventor of Morningstar's
                            first
                            investment advice software. David joined Morningstar in 1994. He holds a bachelor's degree
                            in
                            biology
                            from Skidmore College and a master's degree in biology from the University of Illinois at
                            Springfield.
                        </p>

                        <span class="PortManager_SubHeading">Our Portfolio Manager</span>
                        <p style="margin-top:0px;">
                            George Metrou is an equity portfolio manager for Mornigstar Investment Management LLC.
                            Metrou
                            joined
                            the team as a portfolio manager in August 2018. Before joining Morningstar Investment
                            Management,
                            he was an equity portfolio manager with Perritt Capital, and as a portfolio manager with
                            Perritt
                            Capital Management. Prior to that he served as Director of Research and as an equity analyst
                            at
                            Perritt Capital, and as a portfolio manager with Windgate Wealth Management. He holds a
                            Bachelor's
                            degree in finance form DePaul University, and he also holds the Chartered Financial Analyst®
                            designation.
                        </p>
                    </mds-section>
                    <mds-section border="none" v-if="CurrentProduct.toUpperCase() == 'MFI'"
                        class="editor-read-more-content">
                        <p>
                            Russel Kinnel is director of manager research
                            for Morningstar Research Services LLC and editor of <i>Morningstar
                                FundInvestor</i>, a monthly print newsletter
                            for individual investors. He also writes the Fund
                            Spy column for Morningstar.com, the
                            company's investment Web site.
                        </p>

                        <p>
                            Since joining the company in 1994, Kinnel has covered the Fidelity, Janus, T. Rowe Price,
                            and
                            Vanguard mutual fund families. He helped develop the new Morningstar Rating for funds and
                            the
                            new Morningstar Style Box methodology. He also is co-author of the company's first book,
                            <i>The
                                Morningstar Guide to Mutual Funds: 5-Star Strategies for Success</i>, which was
                            published in January
                            2003.
                        </p>
                    </mds-section>
                    <mds-section border="none" v-if="CurrentProduct.toUpperCase() == 'MEI'"
                        class="editor-read-more-content">
                        <p>
                            Bryan Armour is director of passive strategies research for North America at Morningstar
                            Research
                            Services LLC, a wholly owned subsidiary of Morningstar, Inc.
                        </p>

                        <p>
                            Before joining Morningstar in 2021, Armour spent seven years working for the Financial
                            Industry
                            Regulatory Authority, conducting regulatory trade surveillance and investigations,
                            specializing
                            in exchange-traded funds. Prior to Finra, he worked for a proprietary trading firm as an
                            options
                            trader at the Chicago Mercantile Exchange.
                        </p>

                        <p>
                            Armour holds a bachelor's degree in economics from the University of Illinois at
                            Urbana-Champaign.
                            He also holds the Chartered Financial Analyst® designation.
                        </p>
                    </mds-section>
                </mds-section>
            </mds-modal>
        </div>



        <div v-else class="editor-section">
            <div class="editor-section-heading">About the Editor</div>
            <div v-if="CurrentProduct.toUpperCase() == 'MSI'">
                <div style="display: flex;margin-top: 19px;">
                    <div class="editor-image">
                        <img src="https://im.morningstar.com/im/msi_editor.png" class="editor-image-style" />
                    </div>
                    <div class="editor-text">
                        <div>
                            <b class="editor-text-header">David Harrell</b> <br />
                            <div class="editor-text-style">Editor,<br />
                                <i>Morningstar<br />
                                    StockInvestor</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="editor-description">
                    David Harrell is the editor of the <i>Morningstar StockInvestor</i>,
                    a monthly newsletter that
                    focuses on a wide-moat stock investing
                    strategy. For illustration purposes, issues
                    highlight activities pertaining to accounts
                    owned by Morningstar, Inc. invested in
                    accordance with a strategy that seeks to focus
                    on companies with stable or growing
                    competitive advantages.
                </div>

            </div>

            <div v-if="CurrentProduct.toUpperCase() == 'MDI'">
                <div style="display: flex;margin-top: 19px;">
                    <div class="editor-image">
                        <img src="https://im.morningstar.com/im/mdi_editor.png" class="editor-image-style" />
                    </div>
                    <div class="editor-text">
                        <div>
                            <b class="editor-text-header">David Harrell</b> <br />
                            <div class="editor-text-style">Editor,<br />
                                <i>Morningstar<br />
                                    DividendInvestor</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="editor-description">
                    David Harrell is the editor of <i>Morningstar DividendInvestor</i>,
                    a monthly newsletter that highlights activities pertaining
                    to a Morningstar, Inc. account invested in accordance with
                    a strategy that takes a concentrated, best-ideas approach
                    when investing in select common stocks of dividend-paying
                    companies and other securities. The strategy seeks firms
                    with wide or narrow moats that we believe are in a stronger
                    competitive position than their peers and that are trading
                    at a reasonable price.
                </div>

            </div>

            <div v-if="CurrentProduct.toUpperCase() == 'MFI'">
                <div style="display: flex;margin-top: 19px;">
                    <div class="editor-image">
                        <img src="https://im.morningstar.com/im/mfi_editor.png" class="editor-image-style" />
                    </div>
                    <div class="editor-text">
                        <div>
                            <b class="editor-text-header">Russel Kinnel</b> <br />
                            <div class="editor-text-style">
                                Director of<br />
                                Manager Research<br />and Editor,<br />
                                <i>Morningstar<br />
                                    FundInvestor</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="editor-description">
                    Russel Kinnel is director of manager research for
                    Morningstar Research Services LLC and editor of <i>Morningstar FundInvestor</i>,
                    a monthly print newsletter for individual investors. He also writes
                    the Fund Spy column for Morningstar.com, the company's investment Web site.
                </div>
            </div>

            <div v-if="CurrentProduct.toUpperCase() == 'MEI'">
                <div style="display: flex;margin-top: 19px;">
                    <div class="editor-image">
                        <img src="https://im.morningstar.com/im/mei_editor.png" class="editor-image-style" />
                    </div>
                    <div class="editor-text">
                        <div>
                            <b class="editor-text-header">Bryan Armour, CFA</b> <br />
                            <div class="editor-text-style">
                                Director,<br />
                                Passive Strategies <br />
                                North America and Editor<br />
                                <i>Morningstar ETFInvestor</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="editor-description">
                    Bryan Armour is director of passive strategies research for North
                    America at Morningstar Research Services LLC,
                    a wholly owned subsidiary of Morningstar, Inc. Before joining
                    Morningstar in 2021, Armour spent seven years working for the
                    Financial Industry Regulatory Authority, conducting regulatory
                    trade surveillance and investigations, specializing in exchange-traded funds.
                </div>

            </div>

            <div style="margin-top: 14px;" class="read-more-div-for-medium">
                <!-- <mds-button
          @click="toggle = !toggle"
          variation="flat"
          size="small"
          icon-right="caret-right"
          class="editor-readmore"
          >
          Read More
        </mds-button> -->
                <span @keydown.enter="toggle = !toggle" @click="toggle = !toggle" class="editor-readmore" tabindex="0">
                    Read More
                    <span style="position: absolute;margin-top: 3px;"><mds-icon name="angle-right"
                            size="small"></mds-icon></span>
                </span>
            </div>
            <mds-modal v-model="toggle" :width="'600px'" aria-labelledby="title-id" class="editor-read-more-pop-up">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id-responsive">About the Editor</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" v-if="CurrentProduct.toUpperCase() == 'MSI'"
                        class="editor-read-more-content">
                        <p>
                            David Harrell is the editor of the <i>Morningstar StockInvestor,</i> a monthly newsletter
                            that
                            focuses on a
                            wide-moat stock investing strategy. For illustration purposes, issues highlight activities
                            pertaining
                            to accounts owned by Morningstar, Inc. invested in accordance with a strategy that seeks to
                            focus on
                            companies with stable or growing competitive advantages. David served in several senior
                            research
                            and
                            product development roles and was part of the editorial team that created and launched
                            Morningstar.com.
                            He was the co-inventor of Morningstar's first investment advice software.
                        </p>

                        <p>
                            David joined Morningstar in 1994. He holds a bachelor's degree in biology from Skidmore
                            College
                            and a
                            master's degree in biology from the University of Illinois at Springfield.
                        </p>

                        <span class="PortManager_SubHeading">Our Portfolio Manager</span>

                        <p style="margin-top:0px;">
                            Michael Corty, CFA, is the Head of U.S. Equity Strategies and a Portfolio Manager for
                            Morningstar
                            Investment Management LLC. Michael joined the group as a portfolio manager in December 2013.
                        </p>

                        <p>
                            Previously, he was a senior equity analyst in Morningstar, Inc.'s equity research department
                            where
                            he also served as a voting member of the economic moat committee. Michael holds a bachelor's
                            degree
                            from Loyola Marymount University and an MBA from Johnson Graduate School of Management at
                            Cornell University.
                        </p>

                        <p>
                            Grady Burkett, CFA, is a Portfolio Manager with Morningstar Investment Management LLC. Grady
                            joined the group as a portfolio manager in December 2022.
                        </p>

                        <p>
                            Prior to joining Morningstar Investment Management, Grady was an analyst and portfolio
                            manager
                            at Diamond Hill,
                            an independent and registered investment adviser. Grady started his investment career in
                            Morningstar,
                            Inc.'s equity research department where he progressed in several roles on the technology
                            sector
                            team as an equity
                            analyst, strategist, and director of the team. Grady received his B.S. and M.S. in
                            Mathematics
                            from Wright
                            State University. He is a member of the CFA Institute and the CFA Society of Columbus, Ohio
                        </p>
                    </mds-section>
                    <mds-section border="none" v-if="CurrentProduct.toUpperCase() == 'MDI'"
                        class="editor-read-more-content">
                        <p>
                            David Harrell is the editor of <i>Morningstar DividendInvestor,</i> a monthly newsletter
                            that
                            highlights
                            activities pertaining to a Morningstar, Inc. account invested in accordance with a strategy
                            that
                            takes a concentrated, best-ideas approach when investing in select common stocks of
                            dividend-paying
                            companies and other securities. The strategy seeks firms with wide or narrow moats that we
                            believe
                            are in a stronger competitive position than their peers and that are trading at a reasonable
                            price.
                        </p>

                        <p>
                            David served in several senior research and product development roles and was part of the
                            editorial
                            team that created and launched Morningstar.com. He was the co-inventor of Morningstar's
                            first
                            investment advice software. David joined Morningstar in 1994. He holds a bachelor's degree
                            in
                            biology
                            from Skidmore College and a master's degree in biology from the University of Illinois at
                            Springfield.
                        </p>

                        <span class="PortManager_SubHeading">Our Portfolio Manager</span>
                        <p style="margin-top:0px;">
                            George Metrou is an equity portfolio manager for Mornigstar Investment Management LLC.
                            Metrou
                            joined
                            the team as a portfolio manager in August 2018. Before joining Morningstar Investment
                            Management,
                            he was an equity portfolio manager with Perritt Capital, and as a portfolio manager with
                            Perritt
                            Capital Management. Prior to that he served as Director of Research and as an equity analyst
                            at
                            Perritt Capital, and as a portfolio manager with Windgate Wealth Management. He holds a
                            Bachelor's
                            degree in finance form DePaul University, and he also holds the Chartered Financial Analyst®
                            designation.
                        </p>
                    </mds-section>
                    <mds-section border="none" v-if="CurrentProduct.toUpperCase() == 'MFI'"
                        class="editor-read-more-content">
                        <p>
                            Russel Kinnel is director of manager research
                            for Morningstar Research Services LLC and editor of <i>Morningstar
                                FundInvestor</i>, a monthly print newsletter
                            for individual investors. He also writes the Fund
                            Spy column for Morningstar.com, the
                            company's investment Web site.
                        </p>

                        <p>
                            Since joining the company in 1994, Kinnel has covered the Fidelity, Janus, T. Rowe Price,
                            and
                            Vanguard mutual fund families. He helped develop the new Morningstar Rating for funds and
                            the
                            new Morningstar Style Box methodology. He also is co-author of the company's first book,
                            <i>The
                                Morningstar Guide to Mutual Funds: 5-Star Strategies for Success</i>, which was
                            published in
                            January
                            2003.
                        </p>
                    </mds-section>
                    <mds-section border="none" v-if="CurrentProduct.toUpperCase() == 'MEI'"
                        class="editor-read-more-content">
                        <p>
                            Bryan Armour is director of passive strategies research for North America at Morningstar
                            Research
                            Services LLC, a wholly owned subsidiary of Morningstar, Inc.
                        </p>

                        <p>
                            Before joining Morningstar in 2021, Armour spent seven years working for the Financial
                            Industry
                            Regulatory Authority, conducting regulatory trade surveillance and investigations,
                            specializing
                            in exchange-traded funds. Prior to Finra, he worked for a proprietary trading firm as an
                            options
                            trader at the Chicago Mercantile Exchange.
                        </p>

                        <p>
                            Armour holds a bachelor's degree in economics from the University of Illinois at
                            Urbana-Champaign.
                            He also holds the Chartered Financial Analyst® designation.
                        </p>
                    </mds-section>
                </mds-section>
            </mds-modal>
        </div>
    </div>
</template>

<script>
//import MdcCard from "./MdcCard.vue";
import { MdsButton } from "@mds/button";
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import PlaceholderImageMSI_MDI from "@/assets/DavidHarrell.png";
import PlaceholderImageMFI from "@/assets/RussKinnel.png";
import PlaceholderImageMEI from "@/assets/BryanArmour.gif";
import MdsIcon from "@mds/icon";
import { mapState } from "vuex";
export default {
    name: 'editor-read-more',
    props: [],
    data() {
        return {
            toggle: false,
            CurrentProduct: this.$session.get("current-product"),
            isMobileContentToggle: null,
            viewPortWidth: null,
        }
    }, methods: {
    },
    components: {
        MdsButton,
        MdsModal,
        MdsSection,
        // MdcCard
        MdsIcon
    },
    mounted() {
        this.isMobileContentToggle = this.$store.state.responsive.isMobileContent;

    },
    computed: mapState({
        PlaceholderImageMSI_MDI() {
            return PlaceholderImageMSI_MDI;
        },
        PlaceholderImageMFI() {
            return PlaceholderImageMFI;
        },
        PlaceholderImageMEI() {
            return PlaceholderImageMEI;
        },
        isMobileContent: state => state.responsive.isMobileContent,
        isMobileContentAlias: 'isMobileContent',
        isMobileContentState(state) {
            return state.responsive.isMobileContent
        }

    }),

    watch: {
        isMobileContentState() {
            this.isMobileContentToggle = this.isMobileContentState;
        }
    }
}
</script>

<style scoped>
.PortManager_SubHeading {
    font-weight: bold;
}

.editor-section-heading {
    font-style: normal;
    font-weight: 300;
    font-size: 23px;
    line-height: 27px;
    color: #1E1E1E;
    margin-bottom: 30px;
}

.title-responsive-editor {
    font-size: 20px;
    margin-top: -40px;
    font-weight: 700;
    margin-bottom: 20px;
}

.border-editor-more-moddal {
    margin-top: -10px;
    border: 1px solid #5e5e5e;
}

.modal-editor-button {
    padding-right: 0px;
}

.editor-read-more-content p {
    margin-bottom: 21px;
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .editor-section {
        width: 100%;
        margin-left: 20%;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1300px) {
    .editor-section {
        width: 100%;
        margin-left: 2%;
    }
}


.editor-image {
    display: flex;
}

.editor-text {
    display: flex;
    margin-left: 35px;
}

.editor-image-style {
    width: 126px;
    height: 126px;
    border-radius: 50%;
}

.editor-text-header {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #5E5E5E;
    font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.editor-text-style {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #5E5E5E;
    font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

@media only screen and (min-width: 1000px) and (max-width: 1400px) {

    .editor-description {
        width: 100%;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        margin-top: 37px;

    }
}

@media only screen and (min-width: 600px) and (max-width: 700px) {

    .editor-description {
        margin-left: 320px;
        margin-top: -128px;
        width: 40%;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;


    }

    .read-more-div-for-medium {
        margin-left: 320px;
    }

}


@media only screen and (min-width: 700px) and (max-width: 800px) {

    .editor-description {
        margin-left: 320px;
        margin-top: -128px;
        width: 50%;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;


    }

    .read-more-div-for-medium {
        margin-left: 320px;
        margin-bottom: 25px;
    }

}

@media only screen and (min-width: 800px) and (max-width: 900px) {

    .editor-description {
        margin-left: 320px;
        margin-top: -128px;
        width: 60%;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;


    }

    .read-more-div-for-medium {
        margin-left: 320px;
        margin-bottom: 25px;
    }

}

@media only screen and (min-width: 900px) and (max-width: 999px) {

    .editor-description {
        margin-left: 320px;
        margin-top: -128px;
        width: 60%;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;


    }

    .read-more-div-for-medium {
        margin-left: 320px;
        margin-bottom: 45px;
    }

}

.editor-readmore {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5E5E5E;
    cursor: pointer;
}

.editor-read-more-div {
    margin-top: 14px;
}

.editor-top-border-responsive {
    width: 90%;
    margin-top: 33px;
    border-top: 1px solid #5e5e5e;
}

.main-editor-more-div-responsive {
    width: 100%;
    margin-right: 16px;
    margin-left: 16px;
}

.editor-section-heading-responsive {
    font-style: normal;
    font-weight: 300;
    font-size: 23px;
    line-height: 27px;
    color: #1E1E1E;
    margin-bottom: 30px;
    margin-top: 12px;
}

.editor-description-responsive {
    width: 92%;
    padding-right: 26px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    margin-top: 37px;
}

@media only screen and (min-width: 1400px) and (max-width: 13000px) {

    .editor-description {
        width: 260px;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: #000000;
        font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        margin-top: 37px;

    }
}

.editor-read-more-pop-up>div>div>section {
    overflow-y: scroll;
}

.editor-read-more-pop-up>div>div>section::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

.editor-read-more-pop-up>div>div>section::-webkit-scrollbar-thumb {
    background: rgba(171, 171, 171, 0.3);
    border-radius: 6px;
}
</style>

<style>
.editor-read-more-pop-up>div:first-child {
    max-height: 95vh !important;
}
</style>