<template>
  <div :key="this.$route.fullPath">
    <div class="search-results-container">
      <ad currentPage="search"></ad>
      <div class="search-results-header-title">Search Results</div>
      <div class="search-results-product-dropdown">
        <mds-button icon-only @click="toggle = !toggle" id="product-popover-trigger"
          class="search-results-dropdown-button" icon-right="caret-down">Newsletters</mds-button>

        <mds-popover triggered-by="product-popover-trigger" v-model="toggle" :position="['bottom-right']"
          @mds-popover-dismissed="productChanged()">
          <mds-form>
            <mds-checkbox :disabled="!productAccess.access" class="search-results-product-checkbox"
              @change="updateProductsList(index)" :checked="productAccess.checked"
              v-for="(productAccess, index) in productsAccessList"
              :style="!productAccess.access ? 'cursor:not-allowed;' : ''"
              :key="productAccess.checked + ' ' + productAccess.name">
              <span :style="productAccess.checked ? 'font-weight: 700;' : ''">{{
                productAccess.product
                }}</span>
            </mds-checkbox>
          </mds-form>
        </mds-popover>
      </div>     

      <div v-if="this.isLoading == false" class="search-results-total-count">
        {{ this.totalRecords }} Results for "{{ this.keyword }}"
      </div>     
      <div class="search-results-button-group">
        <mds-button-group :size="isSearchResultsResponsiveToggle ? 'large' : 'medium'" :content="searchTabs"
          @mds-button-group-item-active="changeSearchTab($event)"></mds-button-group>
      </div>
      <div>
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
      </div>
      <div v-if="this.isError">
        <span class="search-results-no-results-text">No results found</span>
      </div>     
      <div class="search-results-table-div">
        <mds-table style="width: 1280px" show-sortable>
          <mds-thead>
            <mds-th v-for="(header, index) in headers" :key="index" :right-aligned="header.align === 'right'"
              :sortable="header.sortable" :sorted="header.sorted" :style="header.style" @mds-th-sort-changed="
                handleSortEvent(index, header.fieldName, $event)
              ">
              <span v-html="header.text" class="search-tbl-header-color"></span>
            </mds-th>
          </mds-thead>

          <mds-tbody v-if="isDisplay">
            <mds-tr v-for="(result, index) in searchResults" :key="index">
              <mds-td left-aligned>
                <div class="search-results-columns">
                  {{ result.Date | formatDateValForFundSpy }}
                </div>
              </mds-td>
              <mds-td left-aligned text-match>
                <div class="search-results-title" @click="resultClicked(result)">
                  <span style="cursor: pointer">{{ result.Title }}</span>
                </div>
                <div class="search-result-body-content" v-html="searchArea == 'video' && (result.Body == null || !result.Body.includes(keyword)) ? modifyBody(result.VideoTranscript) : modifyBody(result.Body)"></div>
                <div class="search-result-product-code">
                  {{
                  result.ProductCode == "MDI"
                  ? "DividendInvestor"
                  : result.ProductCode == "MSI"
                  ? "StockInvestor"
                  : result.ProductCode == "MFI"
                  ? "FundInvestor"
                  : result.ProductCode == "MEI" ||
                  result.ProductCode == "ETF"
                  ? "ETFInvestor"
                  : ""
                  }}
                </div>
              </mds-td>
              <mds-td left-aligned>
                <div class="search-results-columns">{{ result.Author }}</div>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
      </div>
      <div v-if="!this.isError" class="search-results-pagination">
        <mds-pagination v-if="!this.isLoading" :size="paginationButtonSize" :pageSizes="this.pageSizes"
          :pageSize="this.recordsPerPage" :page="this.pageNumber" :total-items="this.totalRecords"
          @mds-pagination-page-changed="pageChange($event)"></mds-pagination>
      </div>     
    </div>
  </div>
</template>

<script defer>
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import MdsLoader from "@mds/loader";
import MdsPagination from "@mds/pagination";
import Ad from "@/components/Common/Ad/Ad.vue";
import MdsPopover from "@mds/popover";
import { MdsButton } from "@mds/button";
import MdsForm from "@mds/form";
import MdsCheckbox from "@mds/checkbox";
import MdsButtonGroup from "@mds/button-group";
import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd,
} from "@mds/data-table";

import { mapState } from "vuex";

Vue.use(VueAxios, axios);

export default {
  name: "search-results",
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsLoader,
    MdsPagination,
    Ad,
    MdsPopover,
    MdsButton,
    MdsForm,
    MdsCheckbox,
    MdsButtonGroup,
  },
  data() {
    return {
      isProductChecked: false,
      isLoading: true,
      isDisplay: false,
      isError: false,
      isSearchResultsResponsiveToggle: null,
      totalRecords: 0,
      pageNumber: 1,
      pageSizes: [10, 20],
      sortField: "PostDate",
      sortType: "DESC",
      sortOrder:0,
      sortColIndex: 0,
      recordsPerPage: 10,
      searchURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_Search,
      downloadIssueUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DownloadPdfUrl,
      CombinedVideoDataURL:process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_Combined_Video_Data_Search,
      TitleVideoDataURL: process.env.VUE_APP_BackEndAPI +process.env.VUE_APP_Search_Video_From_title,
      issuesSearchUrl: process.env.VUE_APP_Newsletters_Utilities + process.env.VUE_APP_Issues_Search,
      productCode: "",
      keyword: "",
      toggle: false,
      productsAccessList: [],
      MEIAccess: false,
      MSIAccess: false,
      MFIAccess: false,
      MDIAccess: false,
      currentProduct: "",
      searchArea: "commentary",
      searchTabs: [
        {
          id: "1",
          text: "Commentary",
          active: true,
          "aria-controls": "panel-1",
        },
        { id: "2", text: "PDFs", active: false, "aria-controls": "panel-2" },
        { id: "3", text: "Videos", active: false, "aria-controls": "panel-3" },
      ],
      headers: [
        {
          text: "Date",
          fieldName: "PostDate",
          style: {
            width: "30px",
          },
          sortable: true,
          sorted: 1,
        },
        {
          text: "Title",
          fieldName: "Subject",
          style: {
            width: "280px",
            "padding-left": "8px",
          },
          align: "left",
          sortable: true,
          sorted: 0,
        },
        {
          text: "Author",
          fieldName: "PostAuthorName",
          style: {
            width: "50px",
            "padding-left": "8px",
          },
          align: "left",
          sortable: true,
          sorted: 0,
        },
      ],      
      searchResults: [],
      viewPortWidth: null,
      paginationButtonSize: "small",  
      Skip: 0,
      SortType: "desc",
      page: 1,
      pages: [],   
      currentPage: 1,
      pageSize: 10,
      records:[],
      totalSearchResults:[]
    };
  },
  methods: {         
    addProductOrder() {
      this.ProductsRoles =
        this.$auth0.user["https://morningstar.com/uim_roles"];
      if (this.ProductsRoles != undefined && this.ProductsRoles != null) {
        this.MFIAccess = this.ProductsRoles.includes("FI_MEMBER");
        this.MSIAccess = this.ProductsRoles.includes("SI_MEMBER");
        this.MDIAccess = this.ProductsRoles.includes("DI_MEMBER");
        this.MEIAccess = this.ProductsRoles.includes("EI_MEMBER");
      }
      this.productsAccessList.push({
        name: "MFI",
        checked:
          (this.currentProduct.toLowerCase() == "common" && this.MFIAccess) ||
          this.currentProduct.toUpperCase() == "MFI"
            ? true
            : false,
        access: this.MFIAccess,
        product: "FundInvestor",
      });
      this.productsAccessList.push({
        name: "MSI",
        checked:
          (this.currentProduct.toLowerCase() == "common" && this.MSIAccess) ||
          this.currentProduct.toUpperCase() == "MSI"
            ? true
            : false,
        access: this.MSIAccess,
        product: "StockInvestor",
      });
      this.productsAccessList.push({
        name: "MDI",
        checked:
          (this.currentProduct.toLowerCase() == "common" && this.MDIAccess) ||
          this.currentProduct.toUpperCase() == "MDI"
            ? true
            : false,
        access: this.MDIAccess,
        product: "DividendInvestor",
      });
      this.productsAccessList.push({
        name: "MEI",
        checked:
          (this.currentProduct.toLowerCase() == "common" && this.MEIAccess) ||
          this.currentProduct.toUpperCase() == "MEI"
            ? true
            : false,
        access: this.MEIAccess,
        product: "ETFInvestor",
      });
    },
    productChanged() {
      if (this.isProductChecked) {
        this.pageNumber = 1;
        this.getSearchResults();
        this.isProductChecked = false;
      }
    },
  
    getSearchResults() {      
      this.isLoading = true;
      this.isDisplay = false;
      this.isError = false;
      this.searchResults = [];
      let Url = this.searchURL;
      if(this.searchArea === "pdf"){
        Url = this.issuesSearchUrl
      }      
      this.keyword = this.$route.params.keyword;
      this.productCode = "";
      this.productsAccessList.forEach(function (product) {
        if (product.checked) {
          this.productCode =
            this.productCode == ""
              ? product.name
              : this.productCode + "," + product.name;
        }
      }, this);


      if (this.productCode == "") {
        this.isError = true;
        this.isLoading = false;
        this.totalRecords = 0;
      } 
      else {
        Url = Url.replace("{ProductCode}", this.productCode);
        Url = Url.replace("{Keyword}", this.keyword);
        Url = Url.replace("{RecordsPerPage}", this.recordsPerPage);
        Url = Url.replace("{PageNumber}", this.pageNumber);
        Url = Url.replace("{SortField}", this.sortField);
        Url = Url.replace("{SortType}", this.sortType);
        Url = Url.replace("{SearchArea}", this.searchArea);

      
        try {
          Vue.axios
            .get(Url)
            .then((response) => {
              if (response) {
                if (response.status == 200) {
                  if (response.data) {
                    var results = response.data;
                    if (results && results.length > 0) {
                      if (this.searchArea === "pdf" || this.searchArea === "video") {
                        this.totalSearchResults = results;
                        const startIndex = (this.pageNumber - 1) * this.recordsPerPage;
                        this.searchResults = this.totalSearchResults.slice(startIndex, startIndex + this.recordsPerPage);
                      }                     
                      else {
                        this.searchResults = results;
                      }
                      this.totalRecords = this.searchResults[0] != null ? this.searchResults[0].TotalRecords: 0;
                      this.isError = false;
                      return true;
                    }
                     else {
                      this.isError = true;
                      this.totalRecords = 0;
                      this.isLoading = false;
                      this.isDisplay = true;
                    }
                  }
                }
              }
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.status == 500) {
                this.isError = true;
                this.totalRecords = 0;
              }
              if (error.response.status == 504) {
                this.isLoading = false
                this.isError = true;
                this.totalRecords = 0;
              }
              return false;
            })
            .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
        } catch (ex) {
          this.isLoading = false;
        }
      }
    },

    redirectVideoTranscript(result) {         
      localStorage.setItem("SearchVideoTitle", result.Title);
      localStorage.setItem("SelectedProductVideoTranscript", result.ProductCode);
      var title = window.encodeURIComponent(result.Title);
      title = title.replaceAll('%20','-');
      localStorage.setItem("SearchVideo", "true");     
 
      this.$router
        .push({ name: "videoTranscript", params: { title: title } })
        .catch((err) => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(err);
          }
        });
        
    },  

    modifyBody(body) {
      if(body!=null){
      let indexOfFirstChar;
      let foundKeyword;
      var removeTagsRegex = /(<([^>]+)>)/gi;
      body = body.replace(removeTagsRegex, "");
      body.replace("&nbsp;", " ");

      const keywordArr = this.keyword.split(" ");
      if (keywordArr.length > 1) {
        keywordArr.every((keywordValue) => {
          indexOfFirstChar = body
            .toUpperCase()
            .indexOf(keywordValue.toUpperCase());
          if (indexOfFirstChar != -1) {
            foundKeyword = keywordValue;
            return false;
          } else {
            return true;
          }
        });
      } else {
        indexOfFirstChar = body
          .toUpperCase()
          .indexOf(this.keyword.toUpperCase());
      }
      if (
        indexOfFirstChar != 0 &&
        body
          .toUpperCase()
          .indexOf(
            keywordArr.length > 1 && foundKeyword != undefined
              ? foundKeyword.toUpperCase()
              : this.keyword.toUpperCase()
          ) != 0
      ) {
        body = body.substring(indexOfFirstChar);
      }

      if (body.length > 200) {
        var pos = body.indexOf(" ", 200 - 1);
        if (pos > 0) body = body.substring(0, pos);
      }
      let highlightRegex;
      if (keywordArr.length > 1 && body.includes(this.keyword)) {
        highlightRegex = new RegExp(this.keyword, "gi");
      } else {
        highlightRegex = new RegExp(
          keywordArr.length > 1 ? foundKeyword : this.keyword,
          "gi"
        );
      }

      body = body.replace(
        /(<mark class="search-results-highlight">|<\/mark>)/gim,
        ""
      );
      body = body.replace(
        highlightRegex,
        '<mark class="search-results-highlight">$&</mark>'
      );

      body += "...";
      body = body.trim();

      
    }
    return body;
    },

    changeSearchTab($event) {
      if (
        !(
          (this.searchArea === "commentary" &&
            $event.currentTarget.id === "1") ||
          (this.searchArea === "pdf" && $event.currentTarget.id === "2") ||
          (this.searchArea === "video" && $event.currentTarget.id === "3")
        )
      ) {
        this.searchTabs.forEach((item) => {
          if (item.id === $event.currentTarget.id) {
            item.active = true;
          } else {
            item.active = false;
          }
        });
        if (this.searchTabs[0].active) {
          this.searchArea = "commentary";
        } else if (this.searchTabs[1].active) {
          this.searchArea = "pdf";
        } else {
          this.searchArea = "video";
        }
        this.pageNumber = 1;
        this.getSearchResults();
        // if(this.searchArea === "video"){
        //   this.getVideoResult();
        // }
      
      }
    },
    pageChange($event) {
      this.pageNumber = $event.page;
      this.recordsPerPage = $event.pageSize;
      if ((this.searchArea === "pdf" || this.searchArea === "video") && this.totalSearchResults != [] && this.totalSearchResults != null) {
        const startIndex = (this.pageNumber - 1) * this.recordsPerPage;
        this.searchResults = this.totalSearchResults.slice(startIndex, startIndex + this.recordsPerPage);
      } 
     else {
        this.getSearchResults();
      }
    },
    handleSortEvent(colIndex, colName) {
      if (colName != "DocType") {
        this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

        if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
          this.$set(this.headers[this.sortColIndex], "sorted", 0);
          this.sortOrder = 1;
        }

        this.sortColIndex = colIndex;
        this.$set(this.headers[colIndex], "sorted", this.sortOrder);
        this.sortField = this.headers[colIndex].fieldName;
        this.sortType = this.sortOrder == -1 ? "ASC" : "DESC";
        if (this.searchArea === "pdf" || this.searchArea === "video") {
          this.totalSearchResults.sort((a, b) => {
            // Determine the sort order based on sortType
            let sortOrderLocal = 1;
            if (this.sortType === 'DESC') sortOrderLocal = -1;

            if (this.sortField === 'PostAuthorName') this.sortField = "Author";
            if (this.sortField === 'Subject') this.sortField = "Title";
            if (this.sortField === 'PostDate') this.sortField = "Date";

            // Compare the values of the sortField property
            if (typeof a[this.sortField] === 'string') {
              // For string values, convert to lowercase for case-insensitive comparison
              if (a[this.sortField].toLowerCase() < b[this.sortField].toLowerCase()) return -1 * sortOrderLocal;
              if (a[this.sortField].toLowerCase() > b[this.sortField].toLowerCase()) return 1 * sortOrderLocal;
            } else if (typeof a[this.sortField] === 'number') {
              // For numeric values, subtract b from a
              if (a[this.sortField] < b[this.sortField]) return -1 * sortOrderLocal;
              if (a[this.sortField] > b[this.sortField]) return 1 * sortOrderLocal;
            } else if (this.isDate(a[this.sortField])) {
              // Convert both dates to Date objects for comparison
              const dateA = new Date(a[this.sortField]);
              const dateB = new Date(b[this.sortField]);

              // Subtract dates to get the difference in milliseconds
              return dateA.getTime() - dateB.getTime();
            }
          });
          const startIndex = (this.pageNumber - 1) * this.recordsPerPage;
          this.searchResults = this.totalSearchResults.slice(startIndex, startIndex + this.recordsPerPage);
        } 
         else {
          this.getSearchResults();
        }
      }
    },
    isDate(value) {
      return value instanceof Date && !isNaN(value);
    },
    resultClicked(result) {
      if (this.searchArea == "pdf") {
        this.downloadIssue(result);
      } else if (this.searchArea == "video") {
        this.redirectVideoTranscript(result)
      } else {
        this.goToCommentary(result);
      }
    },
    downloadIssue(result) {
      try {
        let Url = this.downloadIssueUrl;
        result.ProductCode.replace("ETF", "MEI");
        Url = Url.replace("{ProductCode}", result.ProductCode);
        Url = Url.replace("{FileName}", result.FileName);
        Url = Url.replace("{Type}", "ISSUES");

        Vue.axios
          .get(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      link.setAttribute("target", "_blank");
                      link.setAttribute("download", this.FileName);
                      link.click();
                    }
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },
    goToCommentary(result) {
      this.$router
        .push({
          name: "commentary",
          params: {
            currentproduct: result.ProductCode,
            pid: result.Id,
          },
        })
        .catch((err) => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            console.log(err);
          }
        });
    },
    updateProductsList(index) {
      this.productsAccessList[index].checked =!this.productsAccessList[index].checked;
      this.isProductChecked = true;
      //this.toggle = !this.toggle;
      //this.getSearchResults();
    },
    checkViewPortSize() {
      this.viewPortWidth = window.innerWidth;
    },
  },
  updated() {
    if (this.keyword != this.$route.params.keyword) {
      this.pageNumber = 1;
      this.totalRecords = 0;
      this.currentProduct = this.$session.get("current-product");
      this.getSearchResults();    
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm._backRoute = from.path
    })
  },

  mounted() {
    if (window.innerWidth <= 900) {
      this.paginationButtonSize = "large";
    } else {
      this.paginationButtonSize = "small";
    }
    document.body.onresize = this.checkViewPortSize;
    this.isSearchResultsResponsiveToggle =
      this.$store.state.responsive.isMobileContent;
    this.currentProduct = this.$session.get("current-product");
    this.addProductOrder();
    if(this._backRoute){
      var preUrl = this._backRoute;
      let result = preUrl.includes("videos");
      if(result){
          this.searchTabs[0].active = false;
          this.searchTabs[1].active = false;
          this.searchTabs[2].active = true;
          this.searchArea = "video";
      }
    }
    this.getSearchResults();
  },

  
  computed: mapState({
    isSearchResultsResponsive: (state) => state.responsive.isMobileContent,
    isSearchResultsResponsiveAlias: "isMobileContent",
    isSearchResultsResponsiveState(state) {
      return state.responsive.isMobileContent;
    },
  }),
  watch: {
    isSearchResultsResponsiveState() {
      this.isSearchResultsResponsiveToggle =
        this.isSearchResultsResponsiveState;
    },    
    viewPortWidth() {
      if (this.viewPortWidth <= 900) {
        this.paginationButtonSize = "large";
      } else {
        this.paginationButtonSize = "small";
      }
    },
  },
};
</script>

<style lang="scss" scoped>

@import "@mds/constants";

.search-results-table-div
  > table
  > tbody
  > tr
  > td:nth-child(2)
  > div
  > div
  > a:focus {
  box-shadow: none !important;
}

.search-results-product-dropdown {
  width: 20px;
  margin-top: 4px;
}

.search-results-product-checkbox {
  margin-top: 10px;
}

.search-results-table-div {
  width: 100%;
  margin-top: 25px;
  overflow-x: scroll !important;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.search-results-columns {
  padding-top: 6px !important;
  padding-bottom: 10px !important;
}

.search-results-container {
  max-width: 1280px;
  margin: 0 auto;
}

.search-tbl-header-color {
  color: #5e5e5e;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.search-results-title {
  font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
  padding-top: 6px;
}

.search-result-body-content {
  font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  color: #1e1e1e !important;
  padding-bottom: 4px;
  word-wrap: break-word;
  overflow: hidden;
}

.second-product-table-border {
  border-top: #cccccc solid 1px;
}

.search-result-product-code {
  font-family: "Univers", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #5e5e5e !important;
  line-height: 18px;
  padding-bottom: 6px;
}

.search-results-header-title {
  font-weight: 700;
  font-size: 32px;
  color: #1e1e1e;
  line-height: 36px;
  font-style: normal;
  padding-top: 30px;
  padding-bottom: 12px;
}

.search-results-no-results-text {
  font-weight: 700;
  font-size: 28px;
  color: #1e1e1e;
  line-height: 36px;
  font-style: normal;
  padding-left: 600px;
  padding-top: 12px;
  height: 60px;
  display: block;
}

.search-results-total-count {
  color: #5e5e5e;
  font-weight: 400;
  font-size: 15px;
  padding-top: 12px;
  line-height: 26px;
}

.search-results-button-group {
  padding-top: 28px;
}

.search-results-pagination {
  width: 92.6%;
  margin-top: 20px;
  margin-bottom: 40px;
}

.search-results-dropdown-button {
  background-color: $mds-interactive-color-secondary-active !important;
  color: #ffffff !important;
  box-shadow: none;
}

.search-results-pagination {
      margin-top: 20px;
    }
    .search-results-pagination button {
      // margin: 0 5px;
      // padding: 5px 10px;
      // border: none;
      // background-color: #4CAF50;
      // color: white;
      // border-radius: 3px;
      // cursor: pointer;
    }
    .search-results-pagination button:disabled {
      // background-color: #ddd;
      // color: #666;
      // cursor: not-allowed;
    }

@media only screen and (min-width: 320px) and (max-width: 1415px) {
  .search-results-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 900px) {
  .search-results-no-results-text {
    font-size: 20px;
    width: 1280px;
    padding-left: 30vw;
  }
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .search-results-dropdown-button {
    width: 150px;
    height: 42px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
  }
}
// .search-button-pagination :hover{
//     background-color: none !important;
//     color: black !important;
//  }
//  .search-button-pagination :focus{
//     background-color: none !important;
//     color: black !important;
//  }

</style>
<style>
.search-button-icon{
  position: absolute;
  margin-left: 2px;
}
.search-button-icon-left{
  position: absolute;
  margin-left: -15px;
}





.search-select .mds-select{
 
  width: 70% !important;
}

.search-select .mds-select, .mds-select::before, .mds-select::after {
    box-sizing: border-box;
}
.search-select .mds-select .mds-select__input{
  background-color: white !important;
}
.search-select .mds-select {
    background-clip: padding-box;
    background-color: none;
    display: inline-block;
    position: relative;
    width: 100%;
}
.search-select .mds-select__input {
    background-color: transparent;
    padding-right: 24px;
    transition: 0.2s linear;
}

/* .search-select .mds-select .mds-select__input {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    background-clip: padding-box;
    
    border: 0;
    border-radius: 0;
    box-shadow: inset 0 -1px 0 0 gray;
    color: #1e1e1e;
    cursor: pointer;
    height: 29px;
    overflow: hidden;
    padding: 0 32px 0 8px;
    position: relative;
    transition: 200ms linear, outline-width 100ms linear, outline-offset 100ms linear;
    width: 100%;
    z-index: 0;
}
.search-select .mds-select *, .mds-select *::before, .mds-select *::after {
    box-sizing: border-box;
}

.search-select .mds-doc *, .mds-doc *::before, .mds-doc *::after {
    box-sizing: border-box;
}
.search-select .mds-doc-example *, .mds-doc-example *::before, .mds-doc-example *::after {
    box-sizing: border-box;
}
.search-select select:not(:-internal-select-author-button) {
    appearance: auto;
    box-sizing: border-box;
    align-items: center;
    -webkit-rtl-ordering: logical;
    color: fieldtext;
    background-color: field;
    cursor: default;
    white-space: pre;
    border-width: 1px;
    border-style: solid;
    border-color: light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
    border-radius: 0px;
} */


/* .videoTbl th .sort-icon {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      background-color: red;
      width: 100px;
      height: 100px;
    }
    .videoTbl th.sorted-asc .sort-icon::after {
      content: "▲";
    }
    .videoTbl th.sorted-desc .sort-icon::after {
      content: "▼";
    } */

.class-for-sorting-button-with-sort
  .mds-th--sortable.mds-th--sorted-ascending
  .mds-th__inner::before {
}
.class-for-sorting-button
  .mds-th--sortable.mds-th--sorted-ascending
  .mds-th__inner::before {
  content: none;
}
.search-results-dropdown-button > span:nth-child(2) > svg > path {
  stroke: #ffffff !important;
}

.search-results-highlight {
  background-color: rgba(0, 119, 207, 0.15) !important;
  color: black;
}

.search-results-button-group > div > button:nth-child(n) {
  box-shadow: none;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .search-results-dropdown-button > span {
    margin: 0 auto;
  }
  .search-results-dropdown-button > span:nth-child(2) {
    margin-right: 0px;
  }
}

 .search-button-pagination {
  background-color: white !important;
  border-color: none;
  color: black !important;
}
 
.search-button-pagination:hover {
  background-color: white !important;
  border-color: none;
  color: black !important;
  
}


</style>

    

   
    