<template>
  <div>
    <div v-if="!this.$auth0.isLoading">
      <div v-if="this.$route.name === 'home' || this.$route.name === 'combined-home'">
        <div v-for="(json, index) in JsonData" :key="index">
          <div v-if="json.Alert == 'Error'"
            :class="[json.Description.includes('<li>') ? 'banner-alerts-main-div' : 'banner-alerts-main-div-class']">
            <div v-if="isBeforeCurrentTime(json.StartDate)"></div>
            <div v-else-if="isBeforeCurrentTime(json.EndDate)">
              <div v-if="json.modal" aria-label="Default Modal" actionRequired :width="'100%'">
                <mds-banner variation="error" :persistent="json.Persistent == 'true' ? true : false"
                  @mds-banner-dismissed="CloseAlertError(index)">
                  <div v-html="json.Description"></div>
                </mds-banner>
              </div>
            </div>
            <div v-else></div>
          </div>

          <div v-if="json.Alert == 'Warning'"
            :class="[json.Description.includes('<li>') ? 'banner-alerts-main-div' : 'banner-alerts-main-div-class']">
            <div v-if="isBeforeCurrentTime(json.StartDate)"></div>
            <div v-else-if="isBeforeCurrentTime(json.EndDate)">
              <div v-if="json.modal" aria-label="Default Modal" actionRequired :width="'100%'">
                <mds-banner variation="warning" :persistent="json.Persistent == 'true' ? true : false"
                  @mds-banner-dismissed="CloseAlertWarning(index)">
                  <div v-html="json.Description"></div>
                </mds-banner>
              </div>
            </div>
            <div v-else></div>
          </div>

          <div v-if="json.Alert == 'Info'"
            :class="[json.Description.includes('<li>') ? 'banner-alerts-main-div' : 'banner-alerts-main-div-class']">
            <div v-if="isBeforeCurrentTime(json.StartDate)"></div>
            <div v-else-if="isBeforeCurrentTime(json.EndDate)">
              <div v-if="json.modal" aria-label="Default Modal" actionRequired :width="'100%'">
                <mds-banner :persistent="json.Persistent == 'true' ? true : false"
                  @mds-banner-dismissed="CloseAlertInfo(index)">
                  <div v-html="json.Description"></div>
                </mds-banner>
              </div>
            </div>
            <div v-else></div>
          </div>
        </div>
      </div>
      <header-section v-if="this.$store.state.layout.isHeaderRequired"></header-section>
      <router-view></router-view>
      <footer-section v-if="this.$store.state.layout.isFooterRequired"></footer-section>
    </div>
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KXKC2HP" height="0" width="0"
        style="display: none; visibility: hidden"></iframe></noscript>
  </div>
</template>
<script async :src="googleGtagUrl"></script>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
import MdsIcon from "@mds/icon";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import HeaderSection from "./Headers/HeaderSection.vue";
import FooterSection from "./FooterSection.vue";
import { mapState } from "vuex";
import MdsModal from "@mds/modal";
import MdsBanner from "@mds/banner";

export default {
  name: "master-page",
  components: {
    HeaderSection,
    FooterSection,
    mapState,
    MdsModal,
    MdsBanner,
    MdsIcon,
    MdsButton,
    MdsButtonContainer,
  },
  data() {
    return {
      dataLayer: [],
      CurrentProduct: this.$session.get("current-product"),
      googleGtagId: process.env.VUE_APP_GoogleGtagId,
      googleGtagUrl: process.env.VUE_APP_GoogleGtagScriptUrl,
      bannerAlertBox: true,
      allBannersData:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GET_ALL_BANNERS,
      JsonData: [],
      PersistentForbanner: true,
      toggle: false,
      UlinData: false,
      bannerToggle: true,
      bannerToggleError: true,
      bannerToggleWarning: true,
      bannerToggleInfo: true,
      currentDateTime: "",
    };
  },

  created() {
    this.GetAllBannersData();
  },
  mounted() {
    this.updateDateTime(); // Call updateDateTime method when the component is mounted
    setInterval(this.updateDateTime, 1000);
    document.title = "Morningstar Investor Newsletters";
    window.scrollTo(0, 0);

    // ******* G Tag *********************
    window.dataLayer = window.dataLayer || [];
    this.dataLayer = window.dataLayer;
    this.gtag("js", new Date());
    this.gtag("config", this.googleGtagId);
    // ******* G Tag *********************

    // ********** GTM Tag *****************
    this.GTMFunc(window, document, "script", "dataLayer", "GTM-KXKC2HP");
    // ********** GTM Tag *****************
  },
  methods: {
    isBeforeCurrentTime(dateTime) {
      return new Date() < new Date(dateTime);
    },
    updateDateTime() {
      const now = new Date(); // Get current date and time
      const year = now.getFullYear(); // Get full year (YYYY)
      const month = this.padZero(now.getMonth() + 1); // Get month and pad with zero if needed
      const day = this.padZero(now.getDate()); // Get day and pad with zero if needed
      const hours = this.padZero(now.getHours()); // Get hours and pad with zero if needed
      const minutes = this.padZero(now.getMinutes()); // Get minutes and pad with zero if needed

      this.currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}`; // Format date and time as YYYY-MM-DDTHH:mm
      //console.log(this.currentDateTime)
    },
    padZero(value) {
      return value < 10 ? `0${value}` : value; // Add leading zero if value is less than 10
    },
    CloseAlertError(index) {
      this.JsonData[index].modal = false;
      //this.$store.state.bannerstate.AlertBox = false;
    },
    CloseAlertWarning(index) {
      this.JsonData[index].modal = false;
     // this.$store.state.bannerstate.AlertBox = false;
    },
    CloseAlertInfo(index) {
      this.JsonData[index].modal = false;
      //this.$store.state.bannerstate.AlertBox = false;
    },
    trimContent(content) {
      if (content != null) {
        var htmlData = content.replace(
          /<((?!p)(?!ul)(?!ol)(?!a)(?!li)(?!b)(?!BR)(?!span)(?!sup)(?!sub)(?!i)(?!strong)(?!CENTER)(?!h)(?!em)(?!table)(?!tbody)(?!tr)(?!td)(?!th)(?!hr)\s*\b\/?)[^>]+>/g,
          ""
        );
        //eslint-disable-next-line
        var trimInlineCss = htmlData.replace(/(style=\"[^\"]*\")/g, "");
        return trimInlineCss;
        //return htmlData;
      }
    },
    GetAllBannersData() {
      if (this.$store.state.bannerstate.AlertBox == true) {
        Vue.axios.get(this.allBannersData).then((response) => {
         
          var lstData = response.data;
          for (var i = 0; i < lstData.length; i++) {
            var obj = lstData[i];
          
            if (
              this.CurrentProduct == "Common" &&
              obj.IsDraft == "No" &&
              obj.Combined == "Yes" &&
              obj.Type == "Banner"
            ) {
              var Description = "";
              var Alert = "";
              var Persistent = "";
              var StartDate = "";
              var EndDate = "";
              var modal = "";
              if (obj.Description) {
                Description = obj.Description;
              }

              if (obj.Alert) {
                Alert = obj.Alert;
              }
              if (obj.Persistent) {
                Persistent = obj.Persistent;
              }
              if (obj.StartDate) {
                StartDate = obj.StartDate;
              }
              if (obj.EndDate) {
                EndDate = obj.EndDate;
              }
              this.JsonData.push({
                Description: Description,
                Alert: Alert,
                Persistent: Persistent,
                StartDate: StartDate,
                EndDate: EndDate,
                modal: true,
              });
             
              
            } if (
              this.CurrentProduct == "msi" &&
              obj.IsDraft == "No" &&
              obj.Msi == "Yes" &&
              obj.Type == "Banner"
            ) {
              var Description = "";
              var Alert = "";
              var Persistent = "";
              var StartDate = "";
              var EndDate = "";
              var modal = "";
              if (obj.Description) {
                Description = obj.Description;
              }
              if (obj.Alert) {
                Alert = obj.Alert;
              }
              if (obj.Persistent) {
                Persistent = obj.Persistent;
              }
              if (obj.StartDate) {
                StartDate = obj.StartDate;
              }
              if (obj.EndDate) {
                EndDate = obj.EndDate;
              }
              this.JsonData.push({
                Description: Description,
                Alert: Alert,
                Persistent: Persistent,
                StartDate: StartDate,
                EndDate: EndDate,
                modal: true,
              });
            } if (
              this.CurrentProduct == "mdi" &&
              obj.IsDraft == "No" &&
              obj.Mdi == "Yes" &&
              obj.Type == "Banner"
            ) {
              var Description = "";
              var Alert = "";
              var Persistent = "";
              var StartDate = "";
              var EndDate = "";
              var modal = "";
              if (obj.Description) {
                Description = obj.Description;
              }
              if (obj.Alert) {
                Alert = obj.Alert;
              }
              if (obj.Persistent) {
                Persistent = obj.Persistent;
              }
              if (obj.StartDate) {
                StartDate = obj.StartDate;
              }
              if (obj.EndDate) {
                EndDate = obj.EndDate;
              }
              this.JsonData.push({
                Description: Description,
                Alert: Alert,
                Persistent: Persistent,
                StartDate: StartDate,
                EndDate: EndDate,
                modal: true,
              });
            } if (
              this.CurrentProduct == "mfi" &&
              obj.IsDraft == "No" &&
              obj.Mfi == "Yes" &&
              obj.Type == "Banner"
            ) {
              var Description = "";
              var Alert = "";
              var Persistent = "";
              var StartDate = "";
              var EndDate = "";
              var modal = "";
              if (obj.Description) {
                Description = obj.Description;
              }
              if (obj.Alert) {
                Alert = obj.Alert;
              }
              if (obj.Persistent) {
                Persistent = obj.Persistent;
              }
              if (obj.StartDate) {
                StartDate = obj.StartDate;
              }
              if (obj.EndDate) {
                EndDate = obj.EndDate;
              }
              this.JsonData.push({
                Description: Description,
                Alert: Alert,
                Persistent: Persistent,
                StartDate: StartDate,
                EndDate: EndDate,
                modal: true,
              });
            } if (
              this.CurrentProduct == "mei" &&
              obj.IsDraft == "No" &&
              obj.Mei == "Yes" &&
              obj.Type == "Banner"
            ) {
              var Description = "";
              var Alert = "";
              var Persistent = "";
              var StartDate = "";
              var EndDate = "";
              var modal = "";
              if (obj.Description) {
                Description = obj.Description;
              }
              if (obj.Alert) {
                Alert = obj.Alert;
              }
              if (obj.Persistent) {
                Persistent = obj.Persistent;
              }
              if (obj.StartDate) {
                StartDate = obj.StartDate;
              }
              if (obj.EndDate) {
                EndDate = obj.EndDate;
              }
              this.JsonData.push({
                Description: Description,
                Alert: Alert,
                Persistent: Persistent,
                StartDate: StartDate,
                EndDate: EndDate,
                modal: true,
              });
            }
          }
        });
      }
    },
    gtag() {
      this.dataLayer.push(arguments);
    },
    GTMFunc(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    },
  },

  computed: mapState({
    extractNames() {
      // Extract names from JsonData array
      return this.JsonData.map((record) => record.Description);
    },
  }),
  watch: {
    $route(to, from) {
      this.$store.state.search.InvalidSearchTerm = false;
      if (
        to.name == "autorenew" ||
        to.name == "billingaddress" ||
        to.name == "purchase" ||
        to.name == "subscription" ||
        to.name == "subscriptionexists" ||
        to.name == "reviewsubscription" ||
        to.name == "subscriptionconfirmation" ||
        to.name == "print-registration" ||
        to.name == "print-registration-subscribe" ||
        to.name == "print-registration-confirmation"
      ) {
        this.$store.state.layout.isHeaderRequired = false;
        this.$store.state.layout.isFooterRequired = false;
      } else {
        this.$store.state.layout.isHeaderRequired = true;
        this.$store.state.layout.isFooterRequired = true;
        //Registrations all data clear
        localStorage.removeItem("userDetails");
        localStorage.removeItem("MFIProductSubscription");
        localStorage.removeItem("MSIProductSubscription");
        localStorage.removeItem("MDIProductSubscription");
        localStorage.removeItem("MEIProductSubscription");
        localStorage.removeItem("ChaseErrorCode");
        localStorage.removeItem("CompleteChasePaymentData");
        localStorage.removeItem("PrintRegistrationDetails");
        //END
      }
      if (this.$store.state.access.PageNotFound) {
        this.$store.state.access.PageNotFound = false;
        this.$router
          .replace({
            name: "pageNotFound",
          })
          .catch((err) => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(err);
            }
          });
      } else if (
        to.name != "print-registration" &&
        to.name != "print-registration-subscribe" &&
        to.name != "print-registration-confirmation" &&
        to.name != "myAccount" &&
        to.name != "subscriptionconfirmation" &&
        to.name != "reviewsubscription" &&
        to.name != "autorenew" &&
        to.name != "billingaddress" &&
        to.name != "subscriptionexists" &&
        to.name != "subscription" &&
        to.name != "purchase" &&
        to.name != "marketing-landing" &&
        to.name != "searchResults" &&
        to.name != "videos" &&
        to.name != "videoTranscript" &&
        to.name != "msi-disclosure" &&
        to.name != "mdi-disclosure" &&
        to.hash == "" &&
        to.path !== from.path
      ) {
        this.$router
          .replace({
            path: to.path.toLowerCase(),
          })
          .catch((err) => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              console.log(err);
            }
          });
        if (this.$auth0.isAuthenticated && this.$session.exists()) {
          this.$store.state.user.LoggedIn = true;
        } else {
          this.$store.state.user.LoggedIn = false;
        }

        if (this.$store.state.user.LoggedIn) {
          this.$store.state.article.ArticleCounter = 0;
          if (
            to.params.currentproduct != undefined &&
            to.params.currentproduct !== this.$session.get("current-product")
          ) {
            if (
              to.params.currentproduct.toUpperCase() == "MSI" ||
              to.params.currentproduct.toUpperCase() == "MDI" ||
              to.params.currentproduct.toUpperCase() == "MEI" ||
              to.params.currentproduct.toUpperCase() == "MFI" ||
              to.params.currentproduct == "Common"
            ) {
              if (to.params.currentproduct != "Common") {
                to.params.currentproduct =
                  to.params.currentproduct.toLowerCase();
              }
              this.$session.set("current-product", to.params.currentproduct);
              this.$store.state.product.CurrentProduct =
                to.params.currentproduct;
            } else if (to.name !== "accessDenied" && to.name !== "logout") {
              this.$router
                .push({
                  name: "pageNotFound",
                  params: {
                    currentproduct: this.$store.state.product.CurrentProduct,
                  },
                })
                .catch((err) => {
                  if (
                    err.name !== "NavigationDuplicated" &&
                    !err.message.includes(
                      "Avoided redundant navigation to current location"
                    )
                  ) {
                    console.log(err);
                  }
                });
            }
          }

          if (from.name == "analyst" && to.name != "analyst") {
            this.$session.set("CurrentSecID", "");
            this.$session.set("CurrentPortfolioName", "");
          }

          if (to.name == "combined-home" && from.name == "home") {
            this.$session.set("current-product", "Common");
            this.$store.state.product.CurrentProduct =
              this.$session.get("current-product");
          } else if (to.path == "/logout") {
            this.$auth0.logout();
          } else if (
            this.$session.get("current-product") == "Common" ||
            this.$session.get("current-product") == "" ||
            this.$session.get("current-product") == undefined
          ) {
            this.$router
              .push({
                name: "combined-home",
                params: {
                  currentproduct: "Common",
                },
              })
              .catch((err) => {
                if (
                  err.name !== "NavigationDuplicated" &&
                  !err.message.includes(
                    "Avoided redundant navigation to current location"
                  )
                ) {
                  console.log(err);
                }
              });
            this.$store.state.product.CurrentProduct =
              this.$session.get("current-product");
          } else {
            this.$store.state.product.CurrentProduct =
              this.$session.get("current-product");
          }
          this.$forceUpdate();
          //Registrations all data clear
          localStorage.removeItem("userDetails");
          localStorage.removeItem("MFIProductSubscription");
          localStorage.removeItem("MSIProductSubscription");
          localStorage.removeItem("MDIProductSubscription");
          localStorage.removeItem("MEIProductSubscription");
          localStorage.removeItem("ChaseErrorCode");
          localStorage.removeItem("CompleteChasePaymentData");
          localStorage.removeItem("PrintRegistrationDetails");
          //END
        } else {
          this.$store.state.product.CurrentProduct = "";
          this.$router
            .push({
              name: "combined-home",
              params: {
                currentproduct: "Common",
              },
            })
            .catch((err) => {
              if (
                err.name !== "NavigationDuplicated" &&
                !err.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                console.log(err);
              }
            });
        }
        if (to.name != "marketing-landing") {
          this.$store.state.marketing.isMarketing = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.landing-combine {
  max-width: 1280px;
  margin: 0 auto;
}

.main-class-for-banner-alerts {
  align-content: center;
}

.banner-alerts-main-div {
  width: 100%;
  margin-top: 0px;
  margin-left: 0%;
  align-content: center;
  position: relative;
}

.banner-alerts-main-div-class {
  width: 100%;
  margin-top: 0px;
  margin-left: 0%;
  align-content: center;
  position: relative;
}

.add-main-class {}

@media only screen and (min-width: 660px) and (max-width: 60000px) {
  .main-alert-dismiss-button {
    margin-right: -80%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 660px) {
  .main-alert-dismiss-button {
    margin-right: -50%;
  }
}
</style>
<style>
.banner-alerts-main-div .mds-modal__content {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}

.banner-alerts-main-div-class .mds-modal__content {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}


.banner-alerts-main-div .mds-modal {
  top: -37%;
  position: relative !important;
  z-index: 1;
}

.banner-alerts-main-div-class .mds-modal {
  top: -37%;
  position: relative !important;
  z-index: 1;
}

.banner-alerts-main-div .mds-banner__message {
  padding-left: 30px;
}

.banner-alerts-main-div .mds-modal .mds-modal__wrapper {
  box-shadow: none !important;
  width: 100%;
  border-radius: 0px;
}

.banner-alerts-main-div-class .mds-modal .mds-modal__wrapper {
  box-shadow: none !important;
  width: 100%;
  border-radius: 0px;
}

.banner-alerts-main-div .mds-modal>div:nth-child(2) {
  background: none;
  position: absolute;
  left: none !important;
  top: none !important;
  right: none !important;
  visibility: hidden;
  z-index: 1;
}

.banner-alerts-main-div-class .mds-modal>div:nth-child(2) {
  background: none;
  position: absolute;
  left: none !important;
  top: none !important;
  right: none !important;
  visibility: hidden;
  z-index: 1;
}

.main-alert-class-for-banners .mds-alert__list li::before {
  content: none;
}

.main-alert-class-for-banners .mds-alert__dismiss-button {
  top: 17px;
}

.main-alert-class-for-banners .mds-alert__body {
  padding-top: 6px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  color: FF1E1E1E;
}

.main-alert-class-for-banners .mds-alert__variation {
  padding-left: 17px;
  padding-right: 17px;
}

.main-alert-class-for-banners .mds-alert__list {
  padding-left: 0px;
}

.main-alert-class-for-banners-bullet-point .mds-alert__dismiss-button {
  top: 17px;
}

.main-alert-class-for-banners-bullet-point .mds-alert__body {
  padding-top: 6px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  color: FF1E1E1E;
}

.main-alert-class-for-banners-bullet-point .mds-alert__variation {
  padding-left: 17px;
  padding-right: 17px;
}
</style>