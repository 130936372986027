<template>
  <div class="newsletter-type-main">
    <div v-if="isResponsiveToggle">
      <div class="individual-newsletter-image-slider">
        <div class="combine-articles-opacity-right" v-if="this.$store.state.article.ArticleCounter !=3 && !this.$store.state.header.isResponsiveSubscriptionModalActive">

        </div>
        <div class="combine-articles-opacity-left" v-if="this.$store.state.article.ArticleCounter == 3 && (!this.$store.state.header.isResponsiveHeaderActive && !this.$store.state.header.isResponsiveSubscriptionModalActive)">

        </div>
        <mds-layout-grid class="slider-grid">
          <mds-row class="slider-grid-row">
            <mds-col class="slider-grid-col">
              <mdc-carousel class="slider-grid-coro">
                <mdc-carouselItems v-for="(product, index) in productData" :key="index" class="slider-grid-coro-item">
                  <template>
                    <img :src="product.productimage" class="slider-images" @click="getProductName(product.productname)"/>
                  </template>
                </mdc-carouselItems>
              </mdc-carousel>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
      <div :class="[this.$store.state.article.ArticleCounter==3 ? sliderBorderLeft : '', sliderBorderRight]"></div>
    </div>
    <div v-else-if="productData != null && productData != undefined && productData != '' " class="newsletter-container">
      <div class="newsletter-image-column newsletter-image-column-left">
        <div
          v-bind:class="[
          isfirstimageaddClass ? 'selected-product-div' : '',
          '',
          ]"
          @click="addfirstimageClass(productData[0].productname)"
        >
          <div class="product-image-container">
            <img
              v-bind:class="[
              isfirstimageaddClass ? 'selected-product' : '',
              'product-image',
              ]"
              :src="productData[0].productimage"
            />
          </div>
          <p
            v-bind:class="[
            isfirstimageaddClass ? 'selected-product-name' : '',
            'product-name',
            ]"
          >
          {{productData[0].productname }}
          </p>
          <div
            v-bind:class="[isfirstimageaddClass ? 'select-product-row' : '']"
          >
          </div>
        </div>
      </div>
      <div class="newsletter-image-column">
        <div
          v-bind:class="[
          issecondimageaddClass ? 'selected-product-div' : '',
          'product-div',
          ]"
          @click="addsecondimageClass(productData[1].productname)"
        >
            <div class="product-image-container">
              <img
                v-bind:class="[
                  issecondimageaddClass ? 'selected-product' : '',
                  'product-image',
                ]"
                :src="productData[1].productimage"
              />
            </div>
              <p
                v-bind:class="[
                  issecondimageaddClass ? 'selected-product-name' : '',
                  'product-name',
                ]"
              >
                {{ productData[1].productname }}
              </p>
              <div
                v-bind:class="[issecondimageaddClass ? 'select-product-row' : '']"
              ></div>
          </div>
      </div>
      <div class="newsletter-image-column">
        <div
          v-bind:class="[
          isthirdimageaddClass ? 'selected-product-div' : '',
          'product-div',
          ]"
          @click="addthirdimageClass(productData[2].productname)"
        >
          <div class="product-image-container">
            <img
              v-bind:class="[
              isthirdimageaddClass ? 'selected-product' : '',
              'product-image',
              ]"
              :src="productData[2].productimage"
            />
          </div>
          <p
            v-bind:class="[
              isthirdimageaddClass ? 'selected-product-name' : '',
              'product-name',
            ]"
          >
          {{ productData[2].productname }}
          </p>
          <div
            v-bind:class="[isthirdimageaddClass ? 'select-product-row' : '']"
          ></div>
        </div>
      </div>
      <div class="newsletter-image-column">
        <div
          v-bind:class="[
          isfourthimageaddClass ? 'selected-product-div' : '',
          'product-div',
          ]"
          @click="addfourthimageClass(productData[3].productname)"
        >
          <div class="product-image-container">
            <img
              v-bind:class="[
              isfourthimageaddClass ? 'selected-product' : '',
              'product-image',
              ]"
              :src="productData[3].productimage"
            />
          </div>
          <p
            v-bind:class="[
            isfourthimageaddClass ? 'selected-product-name' : '',
            'product-name',
            ]"
          >
            {{ productData[3].productname }}
          </p>
          <div
            v-bind:class="[isfourthimageaddClass ? 'select-product-row' : '']"
          ></div>
        </div>
      </div>
    </div>
    <div class="border-line"></div>
    <combine-newsletter-type
      :productName="selectedProduct"
    ></combine-newsletter-type>
  </div>
</template>
<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import CombineNewsletterType from "@/components/Common/CombinedHome/CombineNewsletterType.vue";
import MdcCarousel from "@/components/CustomControls/MdcCarouselResponsive.vue";
import MdcCarouselItems from "@/components/CustomControls/MdcCarouselItems.vue";
import { mapState } from "vuex";
export default {
  name: "individual-newsletter-images",
  components: {
    CombineNewsletterType,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdcCarousel,
    MdcCarouselItems,
  },
  data() {
    return {
      selectedProduct: [],
      productData: [],
      MEIAccess: false,
      MDIAccess: false,
      MSIAccess: false,
      MFIAccess: false,
      isfirstimageaddClass: true,
      issecondimageaddClass: false,
      isthirdimageaddClass: false,
      isfourthimageaddClass: false,
      isResponsiveToggle:null,
      sliderBorderRight:'slider-bottom-border',
      sliderBorderLeft:'slider-bottom-border-right',
    };
  },
  methods: {
    addfirstimageClass(product) {
      this.selectedProduct = product;
      this.isfirstimageaddClass = true;
      this.issecondimageaddClass = false;
      this.isthirdimageaddClass = false;
      this.isfourthimageaddClass = false;
      this.$store.state.product.SelectedProductBanner = product;
    },
    addsecondimageClass(product) {
      this.selectedProduct = product;
      this.isfirstimageaddClass = false;
      this.issecondimageaddClass = true;
      this.isthirdimageaddClass = false;
      this.isfourthimageaddClass = false;
      this.$store.state.product.SelectedProductBanner = product;
    },
    addthirdimageClass(product) {
      this.selectedProduct = product;
      this.isfirstimageaddClass = false;
      this.issecondimageaddClass = false;
      this.isthirdimageaddClass = true;
      this.isfourthimageaddClass = false;
      this.$store.state.product.SelectedProductBanner = product;
    },
    addfourthimageClass(product) {
      this.selectedProduct = product;
      this.isfirstimageaddClass = false;
      this.issecondimageaddClass = false;
      this.isthirdimageaddClass = false;
      this.isfourthimageaddClass = true;
      this.$store.state.product.SelectedProductBanner = product;
    },
    getProductImage(productName) {
      //var images = require.context("../../../assets/Images", false, /\.png$/);
      //return images("./" + productName + ".png");
      //var images = require.context("../../../assets/Images/ProductImages", false, /\.jpg$/);
      var images = "";
      if(productName == "FundInvestor"){
        images = "https://im.morningstar.com/im/mfi_combo_homepage.png";
      }
      if(productName == "StockInvestor"){
        images = "https://im.morningstar.com/im/msi_combo_homepage.png";
      }
      if(productName == "DividendInvestor"){
        images = "https://im.morningstar.com/im/mdi_combo_homepage.png";
      }
      if(productName == "ETFInvestor"){
        images = "https://im.morningstar.com/im/mei_combo_homepage.png";
      }
      return images;
      //return images("./" + productName + ".jpg");
      
    },
    addProductOrder() {
      if (this.$store.state.user.LoggedIn) {
        if (this.MFIAccess) {
          this.productData.push({
            productimage: this.getProductImage("FundInvestor"),
            productname: "Morningstar FundInvestor",
          });
        }
        if (this.MSIAccess) {
          this.productData.push({
            productimage: this.getProductImage("StockInvestor"),
            productname: "Morningstar StockInvestor",
          });
        }
        if (this.MDIAccess) {
          this.productData.push({
            productimage: this.getProductImage("DividendInvestor"),
            productname: "Morningstar DividendInvestor",
          });
        }
        if (this.MEIAccess) {
          this.productData.push({
            productimage: this.getProductImage("ETFInvestor"),
            productname: "Morningstar ETFInvestor",
          });
        }
        if (!this.MFIAccess) {
          this.productData.push({
            productimage: this.getProductImage("FundInvestor"),
            productname: "Morningstar FundInvestor",
          });
        }
        if (!this.MSIAccess) {
          this.productData.push({
            productimage: this.getProductImage("StockInvestor"),
            productname: "Morningstar StockInvestor",
          });
        }
        if (!this.MDIAccess) {
          this.productData.push({
            productimage: this.getProductImage("DividendInvestor"),
            productname: "Morningstar DividendInvestor",
          });
        }
        if (!this.MEIAccess) {
          this.productData.push({
            productimage: this.getProductImage("ETFInvestor"),
            productname: "Morningstar ETFInvestor",
          });
        }
      } else {
        this.productData.push(
          {
            productimage: this.getProductImage("FundInvestor"),
            productname: "Morningstar FundInvestor",
          },
          {
            productimage: this.getProductImage("StockInvestor"),
            productname: "Morningstar StockInvestor",
          },
          {
            productimage: this.getProductImage("DividendInvestor"),
            productname: "Morningstar DividendInvestor",
          },
          {
            productimage: this.getProductImage("ETFInvestor"),
            productname: "Morningstar ETFInvestor",
          }
        );
      }
      this.selectedProduct = this.productData[0].productname;
      this.$store.state.product.SelectedProductBanner = this.selectedProduct;
    },
    getProductName(productNameInfo){
      this.selectedProduct = productNameInfo;
      this.$store.state.product.SelectedProductBanner = productNameInfo;
    },
    sliderActiveProduct(productNumber){
      for(let i=0;i<this.productData.length;i++)
      {
        if(i == productNumber)
        {
          this.selectedProduct = this.productData[i].productname;
          this.$store.state.product.SelectedProductBanner = this.productData[i].productname;
        }
      }
    }
  },
  mounted() {
    this.isResponsiveToggle = this.$store.state.responsive.isResponsive;
    if (this.$store.state.user.LoggedIn) {
      this.productDataRoles =
        this.$auth0.user["https://morningstar.com/uim_roles"];
        if(this.productDataRoles != undefined && this.productDataRoles !=null) {
      this.MEIAccess = this.productDataRoles.includes("EI_MEMBER");
      this.MDIAccess = this.productDataRoles.includes("DI_MEMBER");
      this.MSIAccess = this.productDataRoles.includes("SI_MEMBER");
      this.MFIAccess = this.productDataRoles.includes("FI_MEMBER");
    }
  }
    this.addProductOrder();
    this.$forceUpdate();
  },
  computed:mapState({
    ArticleCounter: state => state.article.ArticleCounter,
    ArticleCounterAlias:'ArticleCounter',
    sliderProductNumber(state){
      return state.article.ArticleCounter
    },
    isResponsive: state => state.responsive.isResponsive,
      isResponsiveAlias:'isResponsive',
      isResponsiveState(state){
      return state.responsive.isResponsive
    }
    
  }),
  watch:{
    sliderProductNumber(){
      this.sliderActiveProduct(this.sliderProductNumber);
    },
    isResponsiveState(){
      this.isResponsiveToggle = this.isResponsiveState;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@mds/constants";
.newsletter-type-main {
  width:100%;
  margin:0;
  padding:0;
}
.newsletter-container{
 
  height:100%;
  /* width:83.2%;
  margin-left:8.4%;
  margin-right:8.4%;*/
  display:flex;
}
.newsletter-image-column {
  height:100%;
  width:19.7%;
  margin-left:7%;
}
.newsletter-image-column:hover{
  cursor:pointer;
}
.newsletter-image-column-left{
  margin-left:0;
}
.selected-product-div {

}
.selected-product {
  width:115% !important;
}
.product-div {
  /*margin-left: 81px;*/
}
.product-image-container{
  width:100%;
  height:59%;
  margin-top:52%;
}
.product-image {
  width:100%;
  height:100%;
  transition: width 0.5s, height 0.5s;
}
.border-line {
  /*width:83.2%;
  margin-left:8.4%;
  margin-right:8.4%;*/
  height: 2px;
  background: #cccccc;
}
.select-product-row {
  width:calc(100% - 79.5%);
  max-width:289px;
  height: 6px;
  background: #000000;
  position: absolute;
  margin-top:-3px;
}
.product-name {
  margin-top: 22px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1e1e1e;
}
.selected-product-name {
  margin-top: 19.75px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1e1e1e;
  margin-bottom: 40px;
  margin-left:40px;
}
@media only screen and(max-width:900px){
  .newsletter-type-main{
    width:100%;
    margin:0;
    padding-top:70px;
  }
  .individual-newsletter-image-slider{
    width:100%;
    height:500px;
    margin-bottom:30px;
  }
  .slider-grid-coro-item{
    width:400px;
    height:500px;
    margin-left:5%;
    margin-right:15px;
  }
  .slider-images{
    width:400px;
    height:500px;
  }
  .combine-articles-opacity-right{
    position: absolute;
    width: 50%;
    height: 500px;
    right: 0%;
    opacity: 1;
    z-index: 2;
    background: linear-gradient(90deg, #FFFFFF 0.47%, rgba(0, 0, 0, 0) 59.75%);
    transform: rotate(180deg);
  }
  .combine-articles-opacity-left {
    position: absolute;
    width: 50%;
    height: 500px;
    left: 0%;
    background: linear-gradient(90deg, #FFFFFF 0.47%, rgba(0, 0, 0, 0) 59.75%);
    opacity: 1;
    z-index: 2;
  }
  .slider-bottom-border{
    width:400px;
    height:6px;
    background-color:#000000;
    margin-left:5%;
    margin-top: -3px;
    position: absolute;
  }
  .slider-bottom-border-right{
    width:400px;
    height:6px;
    background-color:#000000;
    margin-left:49.5%;
  }
  .border-line{
    width:90%;
    margin-left:5%;
    margin-right:5%;
  }
}
@media only screen and (max-width:850px){
  .slider-bottom-border-right{
    width:400px;
    height:6px;
    background-color:#000000;
    margin-left:44%;
  }
}
@media only screen and(max-width:600px){
  .individual-newsletter-image-slider{
    width:100%;
    height:350px;
    margin-bottom:30px;
  }
  .slider-grid-coro-item{
    width:300px;
    height:350px;
    margin-left:5%;
    margin-right:15px;
  }
  .slider-images{
    width:300px;
    height:350px;
  }
  .combine-articles-opacity-right{
    position: absolute;
    width: 50%;
    height: 350px;
    right: 0%;
    opacity: 1;
    z-index: 2;
    background: linear-gradient(90deg, #FFFFFF 0.47%, rgba(0, 0, 0, 0) 59.75%);
    transform: rotate(180deg);
  }
  .combine-articles-opacity-left {
    position: absolute;
    width: 50%;
    height: 350px;
    left: 0%;
    background: linear-gradient(90deg, #FFFFFF 0.47%, rgba(0, 0, 0, 0) 59.75%);
    opacity: 1;
    z-index: 2;
  }
  .slider-bottom-border{
    width:300px;
    height:6px;
    background-color:#000000;
    margin-left:5%;
  }
  .slider-bottom-border-right{
    width:300px;
    height:6px;
    background-color:#000000;
    margin-left:44%;
  }
}
@media only screen and (max-width:500px){
  .slider-bottom-border-right{
    width: 195px;
    height: 6px;
    background-color: #000000;
    margin-left: 54%;
  }
}
@media only screen and (max-width: 480px){
    .newsletter-type-main{
      width:100%;
      margin:0;
      padding-top:36px;
    }
    .individual-newsletter-image-slider{
      width:100%;
      height:269px;
      margin-bottom:30px;
    }
    .slider-grid-coro-item{
      width:205px;
      height:269px;
      margin-left:5%;
      /*margin-right:-15px;*/
      margin-right:0px;
    }
    .slider-images{
      width:205px;
      height:269px;
    }
    .combine-articles-opacity-left {
      position: absolute;
      width: 50%;
      height: 269px;
      left: 0%;
      background:linear-gradient(90deg, #FFFFFF 0.47%, rgba(0, 0, 0, 0) 59.75%);
      opacity: 1;
      z-index: 2;
    }
    .combine-articles-opacity-right{
      position: absolute;
      width: 50%;
      height: 269px;
      right: 0%;
      opacity: 1;
      z-index: 2;
      background: linear-gradient(90deg, #FFFFFF 0.47%, rgba(0, 0, 0, 0) 59.75%);
      transform: rotate(180deg);
    }
    .slider-bottom-border{
      width:204px;
      height:6px;
      background-color:#000000;
      margin-left:5%;
    }
    .slider-bottom-border-right{
      width:190;
      height:6px;
      background-color:#000000;
      margin-left:45%;
    }
    .border-line{
      width:90%;
      margin-left:5%;
      margin-right:5%;
    }
}
@media only screen and (max-width:320px){
  .slider-bottom-border-right{
    width:145px !important;
    height:6px;
    background-color:#000000;
    margin-left:52% !important;
  }
}
@media only screen and (min-width:321px) and (max-width:360px){
  .slider-bottom-border-right{
    width:165px !important;
    height:6px;
    background-color:#000000;
    margin-left:49% !important;
  }
}
@media only screen and (min-width:400px) and (max-width:430px){
  .slider-bottom-border-right{
    width:190px;
    height:6px;
    background-color:#000000;
    margin-left:50%;
  }
}
@media only screen and (min-width:901px) and (max-width:1290px){
  .newsletter-container{
    height:100%;
    width:90%;
    margin-left:5%;
    margin-right:5%;
    display:flex;
  }
  .border-line {
    width:90%;
    margin-left:5%;
    margin-right:5%;
    height: 2px;
    background: #cccccc;
  }
}
</style>
<style>
@media only screen and (min-width: 320px) and (max-width:900px)
  {
    .slider-grid-coro > div:nth-child(2) > div:nth-child(2) {
      margin-right:20px;
    }
    /*.slider-grid-coro > div:nth-child(2) > div > div > span {
      margin-left : 20px;
    }*/
    .slider-grid-coro > div:nth-child(2) > div {
      margin-left:20px;
    }
}
</style>
