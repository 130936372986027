<template>
  <div>
    <mds-page-shell :logo="getProductLogo()" :logo-responsive="getProductLogo()" logo-href="/"
      logo-alt="Back to home page">
      <div class="review-subscription-loader">
        <mds-loader v-if="isLoading" aria-label="Large Loader" size="large"></mds-loader>
      </div>
      <div class="review-form-main">
        <div class="review-form">
          <div class="review-form-container">
            <div>
              <h1 class="review-page-title">Activate your subscription</h1>
            </div>
            <div class="review-page-border"></div>
            <div>
              <h1 class="review-form-field">Account</h1>
              <span class="review-form-value">{{ UserDetails.Email }}</span>
            </div>
            <div v-if="isMFISubscribe">
              <div class="review-page-border"></div>
              <h1 class="review-form-field">Morningstar FundInvestor</h1>
              <div class="tax-details">
                <span class="review-form-value-term">{{
                  MFIProductSubscriptionTerm
                }}</span>
                <span class="review-form-value-price">${{ MFIProductPrice | customDataFilter }}</span>
              </div>
              <div class="tax-details">
                <span class="review-form-value-term">Tax</span>
                <span class="review-form-value-price">${{ MFISubscriptionTax | customDataFilter }}</span>
              </div>
            </div>
            <div v-if="isMSISubscribe">
              <div class="review-page-border"></div>
              <h1 class="review-form-field">Morningstar StockInvestor</h1>
              <div class="tax-details">
                <span class="review-form-value-term">{{
                  MSIProductSubscriptionTerm
                }}</span>
                <span class="review-form-value-price">${{ MSIProductPrice | customDataFilter }}</span>
              </div>
              <div class="tax-details">
                <span class="review-form-value-term">Tax</span>
                <span class="review-form-value-price">${{ MSISubscriptionTax | customDataFilter }}</span>
              </div>
            </div>
            <div v-if="isMDISubscribe">
              <div class="review-page-border"></div>
              <h1 class="review-form-field">Morningstar DividendInvestor</h1>
              <div class="tax-details">
                <span class="review-form-value-term">{{
                  MDIProductSubscriptionTerm
                }}</span>
                <span class="review-form-value-price">${{ MDIProductPrice | customDataFilter }}</span>
              </div>
              <div class="tax-details">
                <span class="review-form-value-term">Tax</span>
                <span class="review-form-value-price">${{ MDISubscriptionTax | customDataFilter }}</span>
              </div>
            </div>
            <div v-if="isMEISubscribe">
              <div class="review-page-border"></div>
              <h1 class="review-form-field">Morningstar ETFInvestor</h1>
              <div class="tax-details">
                <span class="review-form-value-term">{{
                  MEIProductSubscriptionTerm
                }}</span>
                <span class="review-form-value-price">${{ MEIProductPrice | customDataFilter }}</span>
              </div>
              <div class="tax-details">
                <span class="review-form-value-term">Tax</span>
                <span class="review-form-value-price">${{ MEISubscriptionTax | customDataFilter }}</span>
              </div>
            </div>
            <div class="review-total-price-summary">
              <div class="review-page-border"></div>
              <h1 class="review-form-field">Total</h1>
              <div v-if="isDueOnDisplay" class="tax-details">
                <span class="review-due-price">Due {{ dueDate | formatDateRegistration }}</span>
                <span class="review-total-price">${{ totalPrice - dueToday | customDataFilter }}</span>
              </div>
              <div class="tax-details">
                <span class="review-due-today">Due Today</span>
                <span class="review-due-today-price">${{ dueToday | customDataFilter }}</span>
              </div>
            </div>
            <div class="review-price-summary-info">
              <span>You will automatically be billed after your 30-day trial, if eligible, for
                the remainder of your annual subscription term.</span>
            </div>
            <div class="review-price-summary-details">
              <span>Annual subscriptions are billed once per year.</span><br /><span>
                Quarterly subscriptions are billed every three months.</span>
            </div>
            <mds-alert mds-alert-dismissed variation="error" class="registrationError" v-if="registrationError"
              @mds-alert-dismissed="closeAlert()" :tinted="registrationError">
              We were unable to process your transaction. Please try again.
            </mds-alert>
            <span class="FormsError" v-if="chaseError" v-html="ChaseErrorMessage"></span>
            <div class="review-page-border-last"></div>
            <div class="Paymentloader">
              <mds-loader v-if="isPaymentLoading" aria-label="Large Loader" size="large"></mds-loader>
            </div>
            <div class="chase-integration">
              <iframe :src="this.chaseURL" id="ccform" runat="server" class="paymentformstyle"></iframe>
              <div class="back-button">
                <mds-button v-if="isbackbuttonShow" variation="secondary" size="large" @click="
                  $router.push({
                    name: 'autorenew',
                    params: { referid: $route.params.referid, productcode: $route.params.productcode },
                  })
                  ">
                  Back
                </mds-button>
              </div>
            </div>
            <div v-show="!showbutton">
              <div>
                <mds-button-container left-aligned>
                  <!-- Icon On Left -->
                  <mds-button variation="secondary" size="large" id="loadLoader" @click="LoadPaymentLoader()"
                    v-show="!showbutton">
                  </mds-button>
                  <mds-button variation="secondary" size="large" id="loadButton" @click="LoadPage()" v-show="!showbutton">
                  </mds-button>
                  <mds-button variation="secondary" size="large" id="cancelButton" @click="CancelEvent()"
                    v-show="!showbutton">
                    Cancel
                  </mds-button>
                  <mds-button variation="secondary" size="large" @click="ContinueEvent()" v-show="!showbutton"
                    id="submitButton">
                    Continue
                  </mds-button>
                </mds-button-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mds-page-shell>
  </div>
</template>
<script>
const recaptchaScript = document.createElement("script");
recaptchaScript.setAttribute("src", process.env.VUE_APP_ChaseHPFscriptURL);
document.getElementsByTagName("head")[0].appendChild(recaptchaScript);
window.handlePaymentErrors = function (data) {
  localStorage.setItem("ChaseErrorCode", data.errorCode);
  if (data.gatewayCode != null && data.gatewayCode != "undefined") {
    localStorage.setItem("gatewayCode", data.gatewayCode);
    localStorage.setItem("gatewayMessage", data.gatewayMessage);
  }
  document.getElementById("submitButton").click()(function () { });
};
window.completePayment = function (data) {
  const chasePaymentData = [
    {
      code : data.code,
      message : data.message,
      uID : data.uID,
    },
  ];
  localStorage.setItem(
    "CompleteChasePaymentData",
    JSON.stringify(chasePaymentData)
  );
  document.getElementById("submitButton").click()(function () { });
};
window.cancelPayment = function () {
  document.getElementById("cancelButton").click()(function () { });
};
window.hpfReady = function () {
  document.getElementById("loadButton").click()(function () { });
}
window.startPayment = function () {
  document.getElementById("loadLoader").click()(function () { });
}
import MdsAlert from "@mds/alert";
import MdsLoader from "@mds/loader";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsPageShell from '@mds/page-shell'
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import EncryptDecryptMixin  from "../../mixins/EncryptDecryptMixin";
Vue.use(VueAxios, axios);
export default {
  name: "review-subscription-confirmation",
  mixins:[EncryptDecryptMixin],
  components: {
    MdsPageShell,
    MdsButton,
    MdsButtonContainer,
    MdsLoader,
    MdsAlert
  },
  data() {
    return {
      isLoading: true,
      MFIProductPrice: 0.0,
      MSIProductPrice: 0.0,
      MDIProductPrice: 0.0,
      MEIProductPrice: 0.0,
      MFIProductSubscriptionTerm: null,
      MSIProductSubscriptionTerm: null,
      MDIProductSubscriptionTerm: null,
      MEIProductSubscriptionTerm: null,
      isMFISubscribe: false,
      isMSISubscribe: false,
      isMDISubscribe: false,
      isMEISubscribe: false,
      MFISubscriptionTax: 0.0,
      MSISubscriptionTax: 0.0,
      MDISubscriptionTax: 0.0,
      MEISubscriptionTax: 0.0,
      dueDate: "",
      dueToday: 0.00,
      totalPrice: 0.00,
      UserDetails: [],
      productSubscription: [],
      ChaseHPFUrl: process.env.VUE_APP_ChaseHPFUrl,
      sessionID: "",
      chaseError: false,
      ChaseErrorMessage: "",
      showbutton: true,
      isbackbuttonShow: false,
      isPaymentLoading: false,
      chaseURL: null,
      registrationError: false,
      MFIsubscrptionData: null,
      MSIsubscrptionData: null,
      MDIsubscrptionData: null,
      MEIsubscrptionData: null,
      isDueOnDisplay: true,
      chaseUIDResponse:[],
      uID:"",
    };
  },
  methods: {
    async closeAlert() {
      this.registrationError = false;
      await this.getChaseUrl();
    },
    getProductLogo() {
      var images = require.context(
        "../../../assets/Images",
        false,
        /\.svg$/
      );
      return images("./Common.svg");
    },
    LoadPaymentLoader() {
      this.isPaymentLoading = true;
    },
    LoadPage() {
      this.isbackbuttonShow = true;
      this.isLoading = false;
    },
    async createSubscription() {
      var userDigitalSubscriptionCommand = {
        address_1: this.UserDetails.Address_1,
        address_2: this.UserDetails.Address_2,
        amount: this.UserDetails.Amount,
        city: this.UserDetails.City,
        country: this.UserDetails.Country,
        email: this.UserDetails.Email,
        emailAlert: this.UserDetails.EmailAlert,
        firstName: this.UserDetails.FirstName,
        lastName: this.UserDetails.LastName,
        omahaCustID: this.UserDetails.OmahaCustID,
        password: this.UserDetails.Password,
        dPhone: this.UserDetails.DPhone,
        mphone: this.UserDetails.Mphone,
        phoneNumber: this.UserDetails.PhoneNumber,
        phoneType: this.UserDetails.PhoneType,
        state: this.UserDetails.State,
        totalPrice: this.UserDetails.TotalPrice,
        trialEndDate: this.UserDetails.TrialEndDate,
        userID: this.UserDetails.UserID,
        zip: this.UserDetails.Zip,
        creditCardNumber: this.UserDetails.ccNumber,
        creditCardType: this.UserDetails.ccType,
        creditCardexpDate: this.UserDetails.expDate,
        profileprocStatus: this.UserDetails.profileProcStatus,
        profileprocStatusMessage: this.UserDetails.profileProcStatusMessage,
        saveSessionValue: this.UserDetails.saveSessionValue,
        status: "Success",
        mobileAlert: this.UserDetails.MobileAlert
      }
      if (this.MFIsubscrptionData != null) {
        this.productSubscription.push({
          ProductCode: "NF00000001",
          IsQ: this.MFIsubscrptionData.IsQ,
          WebSitesCode: 12,
          WebSitesUrl: "mfi.morningstar.com",
          PromotionCode: this.MFIsubscrptionData.PromotionCode,
          SourceCode: this.MFIsubscrptionData.SourceCode,
          AutoRenew: this.MFIsubscrptionData.AutoRenew,
          DivisionID: this.MFIsubscrptionData.DivisionID,
          Price: this.MFIProductPrice + this.MFISubscriptionTax,
          PromoCodeType: this.MFIsubscrptionData.PromoCodeType,
          Term: this.MFIsubscrptionData.Term,
          UsedTrial: this.MFIsubscrptionData.UsedTrial,
        });
      }
      if (this.MSIsubscrptionData != null) {
        this.productSubscription.push({
          ProductCode: "NM00000001",
          IsQ: this.MSIsubscrptionData.IsQ,
          WebSitesCode: 13,
          WebSitesUrl: "msi.morningstar.com",
          PromotionCode: this.MSIsubscrptionData.PromotionCode,
          SourceCode: this.MSIsubscrptionData.SourceCode,
          AutoRenew: this.MSIsubscrptionData.AutoRenew,
          DivisionID: this.MSIsubscrptionData.DivisionID,
          Price: this.MSIProductPrice + this.MSISubscriptionTax,
          PromoCodeType: this.MSIsubscrptionData.PromoCodeType,
          Term: this.MSIsubscrptionData.Term,
          UsedTrial: this.MSIsubscrptionData.UsedTrial,
        });
      }
      if (this.MDIsubscrptionData != null) {
        this.productSubscription.push({
          ProductCode: "NDI0000001",
          IsQ: this.MDIsubscrptionData.IsQ,
          WebSitesCode: 20,
          WebSitesUrl: "mdi.morningstar.com",
          PromotionCode: this.MDIsubscrptionData.PromotionCode,
          SourceCode: this.MDIsubscrptionData.SourceCode,
          AutoRenew: this.MDIsubscrptionData.AutoRenew,
          DivisionID: this.MDIsubscrptionData.DivisionID,
          Price: this.MDIProductPrice + this.MDISubscriptionTax,
          PromoCodeType: this.MDIsubscrptionData.PromoCodeType,
          Term: this.MDIsubscrptionData.Term,
          UsedTrial: this.MDIsubscrptionData.UsedTrial,
        });
      }
      if (this.MEIsubscrptionData != null) {
        this.productSubscription.push({
          ProductCode: "NET0000001",
          IsQ: this.MEIsubscrptionData.IsQ,
          WebSitesCode: 19,
          WebSitesUrl: "etf.morningstar.com",
          PromotionCode: this.MEIsubscrptionData.PromotionCode,
          SourceCode: this.MEIsubscrptionData.SourceCode,
          AutoRenew: this.MEIsubscrptionData.AutoRenew,
          DivisionID: this.MEIsubscrptionData.DivisionID,
          Price: this.MEIProductPrice + this.MEISubscriptionTax,
          PromoCodeType: this.MEIsubscrptionData.PromoCodeType,
          Term: this.MEIsubscrptionData.Term,
          UsedTrial: this.MEIsubscrptionData.UsedTrial,
        });
      }
      var RegisterUserCommand = {};
      RegisterUserCommand.userDigitalSubscriptionCommand = userDigitalSubscriptionCommand;
      RegisterUserCommand.lstProductSubscriptionCommand =
        this.productSubscription;
      try {
        return Vue.axios
          .post(
            process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_CreateSubscription,
            RegisterUserCommand
          )
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var result = response.data;
                  if (result) {
                    this.$router.push({
                      name: "subscriptionconfirmation",
                    }).catch(err => {
                      if (
                        err.name !== 'NavigationDuplicated' &&
                        !err.message.includes('Avoided redundant navigation to current location')
                      ) {
                        console.log(err);
                      }
                    });
                    return result;
                  }
                }
              }
            }
          })
          .catch(() => {
            this.registrationError = true;
          });
      } catch (ex) {
        this.registrationError = true;
        console.log(ex.message);
      }
      this.productSubscription = [];
    },

    CancelEvent() {
      this.$router.push({
        name: "combined-home",
        params: { currentproduct: "Common" },
      }).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(err);
        }
      });
    },
    async ContinueEvent() {
      this.$gtag.event('purchase', {
        'product': this.currentproduct,
        'term': this.subscriptionterm,
        'userid': this.userID,
        'coupon': this.referid,
        'value': this.totalPrice,
        'currency': 'USD',

      })
      this.ChaseErrorMessage = "";
      var Errordata = localStorage.getItem("ChaseErrorCode");
      localStorage.removeItem("ChaseErrorCode");
      if (Errordata != null && Errordata != "") {
        this.isPaymentLoading = false;
        var ErrorCode = Errordata.split(",");
        for (var i = 0; i < ErrorCode.length; i++) {
          this.chaseError = true;
          switch (ErrorCode[i]) {
            case "200":
              this.ChaseErrorMessage += "* Name on card is required.</br>";
              break;
            case "310":
            case "315":
              this.ChaseErrorMessage +=
                "* Provided card number is not valid.</br>";
              break;
            case "320":
              this.ChaseErrorMessage += "* Please select a card type.</br>";
              break;
            case "330":
            case "340":
              this.ChaseErrorMessage +=
                "* Please provide card expiration month/year. </br>";
              break;
            case "350":
              this.ChaseErrorMessage +=
                "* Provided CVC number does not match card. </br>";
              break;
            case "355":
              this.ChaseErrorMessage += "* CVC number is required. </br>";
              break;
            case "357":
              this.ChaseErrorMessage +=
                "* One or more payment fields contain unsupported characters. </br>";
              break;
            case "360":
              this.ChaseErrorMessage +=
                "* Your financial institution has declined this transaction, or an error occurred validating your information. </br>";
              break;
            case "370":
              this.ChaseErrorMessage +=
                "* Provided expiration of card is not valid. </br>";
              break;
            case "380":
              this.ChaseErrorMessage +=
                "* Please complete the challenge. </br>";
              break;
          }
        }
      } else {
        var completePaymentData = localStorage.getItem(
          "CompleteChasePaymentData"
        );
        if (completePaymentData != "" && completePaymentData != null) {
          completePaymentData = JSON.parse(completePaymentData);
          if (completePaymentData[0].code != null && completePaymentData[0].code == "000") {
           await this.getComplatePaymentData(completePaymentData[0].uID);
          }
        }
        localStorage.removeItem("CompleteChasePaymentData");
        await this.createSubscription();
        this.isPaymentLoading = false;
      }
    },
    async getChaseUrl() {
      var sessionID = this.generateSessionID();
      var HPFUIDInitRequest = process.env.VUE_APP_ChaseHPFUIDInitUrl +
        sessionID +
        "&amount=2&css_url=" +
        process.env.VUE_APP_ChaseCSSURL +
        "&callback_url=" +
        process.env.VUE_APP_ChaseCallbackURL +
        "&trans_type=store_authorize&required=all" +
        "&payment_type=Credit_Card&currency_code=USD&allowed_types=American Express|Diners Club|Discover|MasterCard|Visa" +
        "&collectAddress=0&hosted_tokenize=store_authorize&formType=7&account_verification=yes&order_id=auth&max_user_retries=5" +
        "&name=" +
        this.UserDetails.FirstName + " " + this.UserDetails.LastName
      " &customer_phone=" +
        this.UserDetails.PhoneNumber +
        "&address=" +
        this.UserDetails.Address_1 +
        "&address2=" +
        this.UserDetails.Address_2 +
        "&city=" +
        this.UserDetails.City +
        "&state=" +
        this.UserDetails.State +
        "&postal_code=" +
        this.UserDetails.Zip +
        "&country=" +
        this.UserDetails.Country +
        "&customer_email=" +
        this.UserDetails.Email;
      await this.getChaseUID(HPFUIDInitRequest);
      this.chaseURL =
        process.env.VUE_APP_ChaseHPFUrl +
        this.uID;
    },
    async getChaseUID(HPFUIDInitRequest)
    {
      try
      {
        var getChaseUIDAPIURL =
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetChaseUID;
        getChaseUIDAPIURL = getChaseUIDAPIURL.replace(
        "{ChaseUIDRequestURL}",
        encodeURIComponent(HPFUIDInitRequest)
      )
        return Vue.axios
          .get(getChaseUIDAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.chaseUIDResponse = JSON.parse(response.data);
                  this.uID = this.chaseUIDResponse["uID"];
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (ex) {
        console.log(ex.message);
      }
    },
    async getComplatePaymentData(uID){
      try
      {
        var getChaseUIDQueryAPIURL = process.env.VUE_APP_ChaseHPFUIDQueryUrl;
        getChaseUIDQueryAPIURL = getChaseUIDQueryAPIURL.replace(
        "{uIDResponse}",
        uID);

        var getChaseUIDResponseAPIURL =
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_ChaseUIDResponse;
        getChaseUIDResponseAPIURL = getChaseUIDResponseAPIURL.replace(
        "{ChaseUIDResponseURL}",
        encodeURIComponent(getChaseUIDQueryAPIURL)
      )
        return Vue.axios
          .get(getChaseUIDResponseAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.chaseUIDResponse = JSON.parse(response.data);
                  this.UserDetails.saveSessionValue =
                  this.chaseUIDResponse["CustomerRefNum"];
                  this.UserDetails.Amount = this.chaseUIDResponse["TotalAmount"];
                  this.UserDetails.ccType = this.chaseUIDResponse["CreditCardType"];
                  this.UserDetails.ccNumber = this.chaseUIDResponse["CreditCardNumber"];
                  this.UserDetails.profileProcStatus = this.chaseUIDResponse["ProfileProcStatus"];
                  this.UserDetails.profileProcStatusMessage = this.chaseUIDResponse["ProfileProcStatusMessage"];
                  this.UserDetails.expDate = this.chaseUIDResponse["ExpiryDate"].replace(/\d{2}(?=.)/g, '$&/20');
                  this.UserDetails.TotalPrice = this.totalPrice;
                  this.UserDetails.TrialEndDate = this.dueDate;
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (ex) {
        console.log(ex.message);
      }
    },
    generateSessionID() {
      this.$session.start();
      return this.$session.id();
    },
    calculateTotalPrice(
      MFIProductPrice,
      MSIProductPrice,
      MDIProductPrice,
      MEIProductPrice,
      MFISubscriptionTax,
      MSISubscriptionTax,
      MDISubscriptionTax,
      MEISubscriptionTax
    ) {
      var totalPrice =
        MFIProductPrice +
        MSIProductPrice +
        MDIProductPrice +
        MEIProductPrice +
        MFISubscriptionTax +
        MSISubscriptionTax +
        MDISubscriptionTax +
        MEISubscriptionTax;
      return totalPrice;
    },
    async getDataFromlocalStorage() {
      var data = this.Decrypt(localStorage.getItem("userDetails"));
      if (data != "" && data != null) {
        data = JSON.parse(data);
        if (data.FirstName != null) {
          this.UserDetails.FirstName = data.FirstName;
          this.UserDetails.LastName = data.LastName;
          this.UserDetails.Address_1 = data.Address_1;
          if (data.Address_2 != null && data.Address_2 != undefined)
            this.UserDetails.Address_2 = data.Address_2;
          else
            this.UserDetails.Address_2 = "";
          this.UserDetails.State = data.State;
          this.UserDetails.Zip = data.Zip;
          this.UserDetails.Country = data.Country;
          this.UserDetails.Email = data.Email;
          this.UserDetails.City = data.City;
          this.UserDetails.Mphone = data.MPhone;
          this.UserDetails.DPhone = data.DPhone;
          if (data.UserID != null && data.UserID != undefined)
            this.UserDetails.UserID = data.UserID;
          else
            this.UserDetails.UserID = "";
          if (data.OmahaCustID != null && data.OmahaCustID != undefined)
            this.UserDetails.OmahaCustID = data.OmahaCustID;
          else
            this.UserDetails.OmahaCustID = "";
          this.UserDetails.EmailAlert = data.EmailAlert;
          if (data.Password != null) this.UserDetails.Password = data.Password;
          else this.UserDetails.Password = "";
          this.UserDetails.MobileAlert = data.MobileAlert;
          if (data.MPhone != null && data.MPhone != "") {
            this.UserDetails.PhoneType = "Mobile Phone";
            this.UserDetails.PhoneNumber = data.MPhone;
          } else {
            this.UserDetails.PhoneType = "Land Line";
            this.UserDetails.PhoneNumber = data.DPhone;
          }
        }
        this.MFIsubscrptionData = localStorage.getItem("MFIProductSubscription");
        if (this.MFIsubscrptionData != "" && this.MFIsubscrptionData != null) {
          this.MFIsubscrptionData = JSON.parse(this.MFIsubscrptionData);
          if (this.MFIsubscrptionData != null) {
            this.isMFISubscribe = true;
            this.MFIProductPrice = this.MFIsubscrptionData.Price;
            this.MFIProductSubscriptionTerm =
              this.MFIsubscrptionData.subscriptionterm;
          }
          await this.calculateTax(
            this.MFIsubscrptionData.SourceCode,
            this.MFIsubscrptionData.UsedTrial,
            this.MFIsubscrptionData.Term,
            this.UserDetails.State,
            this.UserDetails.Zip,
            this.UserDetails.Country,
            "MFI"
          );
        }
        this.MSIsubscrptionData = localStorage.getItem("MSIProductSubscription");
        if (this.MSIsubscrptionData != "" && this.MSIsubscrptionData != null) {
          this.MSIsubscrptionData = JSON.parse(this.MSIsubscrptionData);
          if (this.MSIsubscrptionData != null) {
            this.isMSISubscribe = true;
            this.MSIProductPrice = this.MSIsubscrptionData.Price;
            this.MSIProductSubscriptionTerm =
              this.MSIsubscrptionData.subscriptionterm;
          }
          await this.calculateTax(
            this.MSIsubscrptionData.SourceCode,
            this.MSIsubscrptionData.UsedTrial,
            this.MSIsubscrptionData.Term,
            this.UserDetails.State,
            this.UserDetails.Zip,
            this.UserDetails.Country,
            "MSI"
          );
        }
        this.MDIsubscrptionData = localStorage.getItem("MDIProductSubscription");
        if (this.MDIsubscrptionData != "" && this.MDIsubscrptionData != null) {
          this.MDIsubscrptionData = JSON.parse(this.MDIsubscrptionData);
          if (this.MDIsubscrptionData != null) {
            this.isMDISubscribe = true;
            this.MDIProductPrice = this.MDIsubscrptionData.Price;
            this.MDIProductSubscriptionTerm =
              this.MDIsubscrptionData.subscriptionterm;
          }
          await this.calculateTax(
            this.MDIsubscrptionData.SourceCode,
            this.MDIsubscrptionData.UsedTrial,
            this.MDIsubscrptionData.Term,
            this.UserDetails.State,
            this.UserDetails.Zip,
            this.UserDetails.Country,
            "MDI"
          );
        }
        this.MEIsubscrptionData = localStorage.getItem("MEIProductSubscription");
        if (this.MEIsubscrptionData != "" && this.MEIsubscrptionData != null) {
          this.MEIsubscrptionData = JSON.parse(this.MEIsubscrptionData);
          if (this.MEIsubscrptionData.Price != null) {
            this.isMEISubscribe = true;
            this.MEIProductPrice = this.MEIsubscrptionData.Price;
            this.MEIProductSubscriptionTerm =
              this.MEIsubscrptionData.subscriptionterm;
          }
          await this.calculateTax(
            this.MEIsubscrptionData.SourceCode,
            this.MEIsubscrptionData.UsedTrial,
            this.MEIsubscrptionData.Term,
            this.UserDetails.State,
            this.UserDetails.Zip,
            this.UserDetails.Country,
            "MEI"
          );
        }
        this.totalPrice = this.calculateTotalPrice(
          this.MFIProductPrice,
          this.MSIProductPrice,
          this.MDIProductPrice,
          this.MEIProductPrice,
          this.MFISubscriptionTax,
          this.MSISubscriptionTax,
          this.MDISubscriptionTax,
          this.MEISubscriptionTax
        );
        if (this.totalPrice - this.dueToday == 0.00) {
          this.isDueOnDisplay = false;
        }
        await this.getChaseUrl();
      }
    },
    async calculateTax(
      SourceCode,
      UsedTrial,
      Term,
      State,
      Zip,
      Country,
      Product
    ) {
      var getSubscriptiontaxAPIURL =
      process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetSubscriptiontax;
      getSubscriptiontaxAPIURL = getSubscriptiontaxAPIURL.replace(
        "{SourceCode}",
        SourceCode
      );
      getSubscriptiontaxAPIURL = getSubscriptiontaxAPIURL.replace(
        "{UsedTrial}",
        UsedTrial
      );
      getSubscriptiontaxAPIURL = getSubscriptiontaxAPIURL.replace(
        "{Term}",
        Term
      );
      if(State != "")
      {
      getSubscriptiontaxAPIURL = getSubscriptiontaxAPIURL.replace(
        "{State}",
        State
      );
      }
      else{
      getSubscriptiontaxAPIURL = getSubscriptiontaxAPIURL.replace(
        "{State}",
        "\"\""
      );
      }
      if(Zip != "")
      {
      getSubscriptiontaxAPIURL = getSubscriptiontaxAPIURL.replace("{Zip}", Zip);
      }
      else
      {
        getSubscriptiontaxAPIURL = getSubscriptiontaxAPIURL.replace("{Zip}", "\"\"");
      }
      getSubscriptiontaxAPIURL = getSubscriptiontaxAPIURL.replace(
        "{Country}",
        Country
      );
      try {
        return Vue.axios
          .get(getSubscriptiontaxAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.subscriptionTaxDetails = JSON.parse(response.data);
                  if (Product == "MFI")
                    this.MFISubscriptionTax =
                      this.subscriptionTaxDetails["Tax"];
                  else if (Product == "MSI")
                    this.MSISubscriptionTax =
                      this.subscriptionTaxDetails["Tax"];
                  else if (Product == "MDI")
                    this.MDISubscriptionTax =
                      this.subscriptionTaxDetails["Tax"];
                  else
                    this.MEISubscriptionTax =
                      this.subscriptionTaxDetails["Tax"];
                  this.dueDate = this.subscriptionTaxDetails["TrialEndDate"];
                  this.dueToday = this.dueToday + this.subscriptionTaxDetails["TotalDue"];
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (ex) {
        console.log(ex.message);
      }
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.$store.state.layout.isHeaderRequired = false;
    this.$store.state.layout.isFooterRequired = false;
    await this.getDataFromlocalStorage();
  },
};
</script>

<style scoped>
.review-form-container {
  width: 380px;
  margin: 0 auto;
}

.FormsError {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  color: #ca0000;
  display: block;
  margin-top: 5%;
}


.paymentformstyle {
  border: none;
  width: 100%;
  height: 550px;
}

.chase-integration {
  width: 100%;
  margin-top: 5%;
  height: 560px;
}

.review-subscription-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 38.92%;
  right: 37.92%;
  top: 50%;
}

.Paymentloader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 38.92%;
  right: 37.92%;
}

.tax-details {
  margin-top: 1.2%;
}

.review-price-summary-details {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.review-price-summary-info {
  margin-top: 8% !important;
  margin-bottom: 3% !important;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.review-due-price {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.review-due-today {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.review-total-price {
  float: right;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  color: #000000;
}

.review-due-today-price {
  float: right;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  color: #000000;
}

.review-form-value-price {
  float: right;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  color: #000000;
}

.review-form-value-term {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.review-form-value {
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  color: #000000;
  float: right;
  margin-top: -5%;
}

.review-form-field {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-top: 2%
}

.review-page-border {
  width: 100%;
  border-bottom: 1px solid #cccccc;
  margin-top: 8%;
}

.review-page-border-last {
  width: 100%;
  border-bottom: 1px solid #cccccc;
  margin-top: 5%;
}

.review-page-main-border {
  margin-top: 1px;
  width: 100%;
  border-bottom: 1px solid #cccccc;
}

.review-form-main {
  height: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}

.review-page-title {
  margin-top: 0px;
  margin-bottom: 15%;
  height: 44px;
  padding-top: 57px;
  font-weight: 250;
  font-size: 28px;
  line-height: 34px;
}

.review-form {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  background-color: #fff;
  height: 100%;
  min-height: 100vh;
}

.registrationError {
  margin-top: 5% !important;
  width: 100%;
}

.back-button {
  margin-top: -20.5%;
}

@media only screen and (max-width: 480px) {
  .review-form-container {
    width: 90%;
    margin: 0 auto;
  }
  .review-page-title {
    padding-top: 28px;
}
.review-page-border {
  margin-top: 10%;
}
}

@media only screen and (min-width: 321px) and (max-width: 360px) {
  .back-button {
    margin-top: -24%;
  }
}

@media only screen and (min-width: 361px) and (max-width: 393px) {
  .back-button {
    margin-top: -22%;
  }
}

@media only screen and (min-width: 394px) and (max-width: 414px) {
  .back-button {
    margin-top: -21%;
  }
}

@media only screen and (min-width: 415px) and (max-width: 428px) {
  .back-button {
    margin-top: -20%;
  }
}

@media only screen and (min-width: 429px) and (max-width: 460px) {
  .back-button {
    margin-top: -24%;
  }
}
</style>
