<template>
  <div>
    <mds-page-shell :logo="getProductLogo()" :logo-responsive="getProductLogo()" logo-href="/"
      logo-alt="Back to home page">
      <div class="billing-address-main">
        <div class="billingaddress-registration-form-billing-address">
          <div class="billingaddress-registration-form-billing-address-container">
            <div class="billing-address-form-title">
              <h1 class="billing-address-page-title">Billing address</h1>
            </div>
            <div class="billingaddress-loader" v-if="isLoading">
              <mds-loader aria-label="Medium Loader" size="medium"></mds-loader>
            </div>
            <div v-if="!isLoading">
              <div class="billing-address-form-fields">
                <mds-select v-model.trim="country" class="input-tab" size="large" label="Country" type="text"
                  @change="onChangeCountry" required>
                  <option v-bind:key="country.CountryCode" :value="country.Country" v-for="country in listCountry">
                    {{ country.Country }}
                  </option>
                </mds-select>
                <br />
                <mds-input v-model.trim="address1" type="text" size="large" :error="address1Error" label="Address 1"
                  required></mds-input>
                <span class="FormsError" v-if="address1Error">Address is required</span>
                <br />
                <mds-input v-model.trim="address2" size="large" label="Address 2"></mds-input>
                <br />
                <mds-input v-model.trim="city" :error="cityError" size="large" label="City" required></mds-input>
                <span class="FormsError" v-if="cityError">City is required</span>
                <br />
                <div v-if="isResponsive">
                  <div v-if="isStateUSA">
                    <mds-select v-model.trim="USAstate" :label="stateLable" size="large" required :error="stateError"
                      placeholder="Select...">
                      <option v-for="USAstate in listUSAState" :key="USAstate.StateName" :value="USAstate.StateAbbr">
                        {{ USAstate.StateName }}
                      </option>
                    </mds-select>
                  </div>
                  <div v-if="isStateCanada">
                    <mds-select v-if="isStateCanada" v-model.trim="Canadastate" :label="stateLable" size="large"
                      :error="stateError" placeholder="Select...">
                      <option v-for="Canadastate in listCanadaState" :key="Canadastate.StateName"
                        :value="Canadastate.StateAbbr">
                        {{ Canadastate.StateName }}
                      </option>
                    </mds-select>
                  </div>
                  <div v-if="isNotUSACanadaState">
                    <mds-input v-model.trim="stateprovince" :label="stateLable" size="large" :error="stateError"
                      v-if="isNotUSACanadaState"></mds-input>
                  </div>
                  <span class="FormsError" v-if="stateError">{{
                    stateErrorMessage
                  }}</span>
                  <br />
                  <mds-input v-model.trim="zipcode" label="Zip Code" size="large" required
                    :error="zipcodeError"></mds-input>
                  <span class="FormsError" v-if="zipcodeError">{{
                    zipcodeErrorMessage
                  }}</span>
                  <br />
                  <mds-select label="Phone Type" size="large" required v-model.trim="phonetype" @change="changePhoneType()">
                    <option v-for="phonetype in listphoneType" v-bind:key="phonetype.text" :value="phonetype.text">
                      {{ phonetype.text }}
                    </option>
                  </mds-select>
                  <br />
                  <mds-input label="Phone Number" size="large" type="text" required v-model.trim="phonenumber"
                    :error="phonenumberError"></mds-input>
                  <span class="FormsError" v-if="phonenumberError">{{
                    phoneErrorMessage
                  }}</span>
                </div>
              </div>
              <div v-if="!isResponsive">
                <div class="horizonatal-fieldset">
                  <div class="fieldset-first">
                    <div v-if="isStateUSA">
                      <mds-select v-model.trim="USAstate" :label="stateLable" size="large" required :error="stateError"
                        placeholder="Select...">
                        <option v-for="USAstate in listUSAState" :key="USAstate.StateName" :value="USAstate.StateAbbr">
                          {{ USAstate.StateName }}
                        </option>
                      </mds-select>
                    </div>
                    <div v-if="isStateCanada">
                      <mds-select v-if="isStateCanada" v-model.trim="Canadastate" :label="stateLable" size="large"
                        :error="stateError" placeholder="Select...">
                        <option v-for="Canadastate in listCanadaState" :key="Canadastate.StateName"
                          :value="Canadastate.StateAbbr">
                          {{ Canadastate.StateName }}
                        </option>
                      </mds-select>
                    </div>
                    <div v-if="isNotUSACanadaState">
                      <mds-input v-model.trim="stateprovince" :label="stateLable" size="large"
                        :error="stateError" v-if="isNotUSACanadaState"></mds-input>
                    </div>
                    <span class="FormsError" v-if="stateError">{{
                      stateErrorMessage
                    }}</span>
                  </div>
                  <div class="fieldset-second">
                    <mds-input v-model.trim="zipcode" :label="zipLable" size="large" :required="isStateUSA"
                      :error="zipcodeError"></mds-input>
                    <span class="FormsError" v-if="zipcodeError">{{
                      zipcodeErrorMessage
                    }}</span>
                  </div>
                </div>
                <br />
                <div class="horizonatal-fieldset">
                  <div class="fieldset-first">
                    <mds-select label="Phone Type" size="large" required v-model.trim="phonetype" @change="changePhoneType()">
                      <option v-for="phonetype in listphoneType" v-bind:key="phonetype.text" :value="phonetype.text">
                        {{ phonetype.text }}
                      </option>
                    </mds-select>
                  </div>
                  <div class="fieldset-second">
                    <mds-input label="Phone Number" size="large" type="text" required v-model.trim="phonenumber"
                      :error="phonenumberError"></mds-input>
                    <span class="FormsError" v-if="phonenumberError">{{
                      phoneErrorMessage
                    }}</span>
                  </div>
                </div>
              </div>
              <div v-if="isMobileChecked">
                <div class="billing-address-checkboxes">
                  <mds-checkbox size="small" value="accept" v-model="checked">By checking the box and submitting this
                    form, I
                    consent to receive
                    phone calls for special offers reserved for Morningstar customers at
                    any number I provide here using automated technology. I understand
                    my consent is not required in order to receive products or services
                    from Morningstar.
                  </mds-checkbox>
                </div>

                <div class="billing-address-popover">
                  <a type="button" @click="toggle = !toggle" id="default-trigger" class="popover-billing-address">Why do
                    we need
                    it?</a>
                </div>

                <mds-popover size="small" class="billingaddress-popover" triggered-by="default-trigger" v-model="toggle"
                  :position="['top-right']">
                  We use your phone number for Morningstar-related matters. Morningstar
                  does not share this contact information without your separate consent.
                  See our Privacy Policy for complete details.
                </mds-popover>
              </div>
              <div class="billingaddress-registration-button-container">
                <div>
                  <mds-button-container left-aligned>
                    <!-- Icon On Left -->
                    <mds-button variation="secondary" size="large" @click="
                      $router.push({
                        name: 'subscription',
                        params: { referid: $route.params.referid, productcode: $route.params.productcode },
                      })
                      ">
                      Back
                    </mds-button>
                    <div class="billing-address-cancel-button">
                      <mds-button variation="secondary" size="large" type="button" @click="
                        $router.push({
                          name: 'combined-home',
                          params: { currentproduct: 'Common' },
                        })
                        ">
                        Cancel
                      </mds-button>
                    </div>
                    <div class="billing-address-continue-button">
                      <!-- Icon On Both Sides -->
                      <mds-button variation="primary" size="large" class="continue-button" type="button" right-aligned
                        @click="ContinueForm">
                        Continue
                      </mds-button>
                    </div>
                  </mds-button-container>
                </div>
              </div>
              <div class="billingaddress-register-page-bottom"></div>
            </div>
          </div>
        </div>
      </div>
    </mds-page-shell>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(VueAxios, axios);
import MdsInput from "@mds/input";
import MdsCheckbox from "@mds/checkbox";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsSelect from "@mds/select";
import MdsPopover from "@mds/popover";
import MdsPageShell from '@mds/page-shell'
import MdsLoader from "@mds/loader";
import EncryptDecryptMixin  from "../../mixins/EncryptDecryptMixin";
export default {
  name: "billing-address-section",
  mixins:[EncryptDecryptMixin],
  components: {
    MdsInput,
    MdsCheckbox,
    MdsButton,
    MdsButtonContainer,
    MdsSelect,
    MdsPopover,
    MdsPageShell,
    MdsLoader,
  },
  data() {
    return {
      listCountry: this.$store.state.country.contryList,
      listCanadaState: this.$store.state.country.Canadastateslist,
      listUSAState: this.$store.state.country.USAstateslist,
      listphoneType: this.$store.state.country.phoneType,

      isMobileChecked: true,
      toggle: false,
      checked: false,
      countryError: false,
      cityError: false,
      address2Error: false,
      address1Error: false,
      stateError: false,
      zipcodeError: false,
      phonetypeError: false,
      phonenumberError: false,

      Canadastate: "",
      USAstate: "",
      stateprovince: "",

      zipcode: "",
      phonenumber: "",
      DPhone: "",
      MPhone: "",
      phonetype: "Mobile Phone",
      address2: "",
      country: "USA",
      address1: "",
      city: "",

      referID: this.$route.params.referid,
      productCode: this.$route.params.productcode,

      isLoading: true,

      stateLable: "State",
      zipLable:"Zip Code",

      isStateUSA: false,
      isStateCanada: false,
      isNotUSACanadaState: false,

      stateErrorMessage: null,
      phoneErrorMessage: null,
      zipcodeErrorMessage: null,
      firstName: null,
      lastName: null,
      emailAlert: null,
      password: null,
      email: null,
      userDetails: [],
      isResponsive: null,
      userID: null,
      termETFAnnual: this.$route.params.termETFAnnual,
      termMFIAnnual: this.$route.params.termMFIAnnual,
      termMDIAnnual: this.$route.params.termMDIAnnual,
      termMSIAnnual: this.$route.params.termMSIAnnual,
      termETFQuarterly: this.$route.params.termETFQuarterly,
      termMFIQuarterly: this.$route.params.termMFIQuarterly,
      termMDIQuarterly: this.$route.params.termMDIQuarterly,
      termMSIQuarterly: this.$route.params.termMSIQuarterly,
      productNameETFQuarterly: this.$route.params.productNameETFQuarterly,
      productNameMFIQuarterly: this.$route.params.productNameMFIQuarterly,
      productNameMSIQuarterly: this.$route.params.productNameMSIQuarterly,
      productNameMDIQuarterly: this.$route.params.productNameMDIQuarterly,
      productNameETFAnnual: this.$route.params.productNameETFAnnual,
      productNameMFIAnnual: this.$route.params.productNameMFIAnnual,
      productNameMSIAnnual: this.$route.params.productNameMSIAnnual,
      productNameMDIAnnual: this.$route.params.productNameMDIAnnual,




    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (this.$store.state.user.LoggedIn) {
      this.userID = this.$auth0.user["https://morningstar.com/mstar_id"];
    }
    this.$store.state.layout.isHeaderRequired = false;
    this.$store.state.layout.isFooterRequired = false;
    await this.getDataFromSessionStorage();
    this.isLoading = false;
  },
  created() {
    window.addEventListener("resize", this.checkViewPortSize);
    this.checkViewPortSize();
  },
  methods: {
    ChangeContryName(Name) {
      var ContryName = "";
      if (Name) {
        if (Name.toUpperCase() == "USA") {
          ContryName = Name.toUpperCase();
        } else {
          var lowerText = Name.toLowerCase();
          var temp = lowerText.split(' ');
          if (temp) {
            for (var i in temp) {
              var obj = temp[i];
              ContryName += obj.charAt(0).toUpperCase() + obj.slice(1) + " ";
            }
          }
          ContryName = ContryName.substring(0, ContryName.length - 1);
        }

      }
      return ContryName;
    },
    checkViewPortSize() {
      this.viewPortWidth = window.innerWidth;
      if (this.viewPortWidth < 600) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    getProductLogo() {
      var images = require.context(
        "../../../assets/Images",
        false,
        /\.svg$/
      );
      return images("./Common.svg");
    },
    saveDataToSessionStorage(data) {
      data = JSON.stringify(data);
      data = this.Encrypt(data);
      localStorage.setItem("userDetails", data);
    },
    async getDataFromSessionStorage() {
      this.userDetails = this.Decrypt(localStorage.getItem("userDetails"));
      if (this.userDetails != "" && this.userDetails != null) {
        this.userDetails = JSON.parse(this.userDetails);
        if (this.userDetails.Country != null && this.userDetails.Country != "") {
          this.checked = this.userDetails.MobileAlert;
          this.country = this.ChangeContryName(this.userDetails.Country);
          this.address1 = this.userDetails.Address_1;
          this.address2 = this.userDetails.Address_2;
          this.city = this.userDetails.City;
          this.zipcode = this.userDetails.Zip;
          if (this.userDetails.Country.toLowerCase() == "usa") {
            this.isStateCanada = false;
            this.isStateUSA = true;
            this.isNotUSACanadaState = false;
            this.stateLable = "State";
            this.USAstate = this.userDetails.State;
            this.zipLable = "Zip Code";
          }
          else if (this.userDetails.Country.toLowerCase() == "canada") {
            this.isStateCanada = true;
            this.isStateUSA = false;
            this.isNotUSACanadaState = false;
            this.stateLable = "Province";
            this.zipLable = "Zip / Postal Code";
            this.Canadastate = this.userDetails.State;
          }
          else {
            this.stateLable = "State/Province/APO";
            this.zipLable = "Zip / Postal Code";
            this.isStateCanada = false;
            this.isStateUSA = false;
            this.isNotUSACanadaState = true;
            this.stateprovince = this.userDetails.State
          }

          if (this.userDetails.MPhone != "" && this.userDetails.MPhone != null) {
            this.phonetype = "Mobile Phone";
            this.phonenumber = this.userDetails.MPhone;
            this.isMobileChecked = true;
            this.MPhone = this.userDetails.MPhone;
          }
          else {
            this.phonetype = "Home Phone";
            this.phonenumber = this.userDetails.DPhone;
            this.isMobileChecked = false;
          }
          if (this.userDetails.DPhone != "" && this.userDetails.DPhone != null) {
             this.DPhone = this.userDetails.DPhone;
          }
        }
        else {
          this.country = "USA";
          this.isStateUSA = true;
        }
      }
    },
    onChangeCountry(country) {
      this.zipcodeError = false;
      if (country.toLowerCase() == "usa") {
        this.USAstate = "";
        this.isStateCanada = false;
        this.isStateUSA = true;
        this.isNotUSACanadaState = false;
        this.stateLable = "State";
        this.zipLable = "Zip Code";

      } else if (country.toLowerCase() == "canada") {
        this.stateError = false;
        this.Canadastate = "";
        this.isStateCanada = true;
        this.isStateUSA = false;
        this.isNotUSACanadaState = false;
        this.stateLable = "Province";
        this.zipLable = "Zip / Postal Code";
      } else {
        this.stateError = false;
        this.stateprovince = "";
        this.stateLable = "State/Province/APO";
        this.isStateCanada = false;
        this.isStateUSA = false;
        this.isNotUSACanadaState = true;
        this.zipLable = "Zip / Postal Code";
      }
    },

    changePhoneType() {
            this.phonenumber = "";
            this.userDetails = this.Decrypt(localStorage.getItem("userDetails"));
            if (this.userDetails != "" && this.userDetails != null) {
            this.userDetails = JSON.parse(this.userDetails);
            if (this.userDetails.MPhone != "" && this.userDetails.MPhone != null) {
            if (this.phonetype.toLowerCase() == "mobile phone") {
                if (this.userDetails.MPhone) {
                    this.MPhone = this.userDetails.MPhone;
                    this.phonenumber = this.MPhone;
                }
            } 
          }
           if (this.userDetails.DPhone != "" && this.userDetails.DPhone != null) {
            if (this.phonetype.toLowerCase() != "mobile phone") {
                if (this.userDetails.DPhone) {
                    this.DPhone = this.userDetails.DPhone;
                    this.phonenumber = this.DPhone;
                }
            } 
          }
        }
      },
    ContinueForm() {
      if (this.address1 == null || this.address1 == "") {
        this.address1Error = true;
      }
      if (this.city == null || this.city == "") {
        this.cityError = true;
      }
      if (this.country.toLowerCase() == "usa") {
        if (this.USAstate == null || this.USAstate == "") {
          this.stateError = true;
          this.stateErrorMessage = "State is required";
        }
      }
       if (this.country.toLowerCase() == "usa") {
        if (this.zipcode == null || this.zipcode == "") {
        this.zipcodeError = true;
        this.zipcodeErrorMessage = "Zip code is required";
       } 
       else{
        if (!/^\d{5}(?:[-\s]\d{4})?$/.test(this.zipcode)) {
          this.zipcodeError = true;
          this.zipcodeErrorMessage = "Zip code is not valid";
        }
        else{
          this.zipcodeError = false;
        }
      }
      }
      else if (this.country.toLowerCase() == "canada") {
        if(this.zipcode != null && this.zipcode != "")
        {
        if (!/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[ ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/.test(this.zipcode)) {
          this.zipcodeError = true;
          this.zipcodeErrorMessage = "Zip code is not valid";
        }
        else
          {
            this.zipcode = this.zipcode.replace(/\s/g, '');
            this.zipcode = this.zipcode.replace(/.{3}/g, '$& ');
            this.zipcode = this.zipcode.trim();
          }
       }
       else {
        this.zipcodeError = false;
      }
      }
      else {
        this.zipcodeError = false;
      }
      if (this.phonenumber == null || this.phonenumber == "") {
        this.phonenumberError = true;
        this.phoneErrorMessage = "Phone is required";
      } else if (
        !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.phonenumber) ||
        this.phonenumber.length < 10
      ) {
        this.phonenumberError = true;
        this.phoneErrorMessage = " Phone number is not valid";
      }
      if (
        !this.address1Error &&
        !this.cityError &&
        !this.stateError &&
        !this.zipcodeError &&
        !this.phonenumberError
      ) {
        var selectedstate = "";

        if (this.country.toLowerCase() == "usa")
          selectedstate = this.USAstate;
        else if (this.country.toLowerCase() == "canada")
          selectedstate = this.Canadastate;
        else selectedstate = this.stateprovince;
        if (this.phonetype == "Mobile Phone") {
          this.MPhone = this.phonenumber;
          this.isMobileChecked = true;
        } else {
          this.DPhone = this.phonenumber;
          this.isMobileChecked = false;
        }

        if (this.userDetails != null) {
          const data = {
            FirstName: this.userDetails["FirstName"],
            LastName: this.userDetails["LastName"],
            Email: this.userDetails["Email"],
            Password: this.userDetails["Password"],
            EmailAlert: this.userDetails["EmailAlert"],
            Country: this.country,
            Address_1: this.address1,
            Address_2: this.address2,
            City: this.city,
            State: selectedstate,
            Zip: this.zipcode,
            MPhone: this.MPhone,
            DPhone: this.DPhone,
            MobileAlert: this.checked,
            UserID: this.userDetails["UserID"],
            OmahaCustID: this.userDetails["OmahaCustID"],
          };
          this.saveDataToSessionStorage(data);
        }
        this.$router.push({
          name: "autorenew",
          params: { referid: this.referID, productcode: this.productCode },
        }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
      }

      if (this.productNameETFAnnual != null & this.termETFAnnual != null) {
        this.$gtag.event('create_user_profile', {
          'product': 'MEI',
          'term': 'Annual Digital',
          'userid': this.userID,

        })
      }
      if (this.productNameETFQuarterly != null & this.productNameETFQuarterly != null) {
        this.$gtag.event('create_user_profile', {
          'product': 'MEI',
          'term': 'Quarterly Digital',
          'userid': this.userID,

        })
      }
      if (this.productNameMFIAnnual != null & this.termMFIAnnual != null) {
        this.$gtag.event('create_user_profile', {
          'product': 'MFI',
          'term': 'Annual Digital',
          'userid': this.userID,

        })
      }
      if (this.productNameMFIQuarterly != null & this.productNameMFIQuarterly != null) {
        this.$gtag.event('create_user_profile', {
          'product': 'MFI',
          'term': 'Quarterly Digital',
          'userid': this.userID,

        })
      }
      if (this.productNameMDIAnnual != null & this.termMDIAnnual != null) {
        this.$gtag.event('create_user_profile', {
          'product': 'MDI',
          'term': 'Annual Digital',
          'userid': this.userID,

        })
      }
      if (this.productNameMDIQuarterly != null & this.productNameMDIQuarterly != null) {
        this.$gtag.event('create_user_profile', {
          'product': 'MDI',
          'term': 'Quarterly Digital',
          'userid': this.userID,

        })
      }
      if (this.productNameMSIAnnual != null & this.termMSIAnnual != null) {
        this.$gtag.event('create_user_profile', {
          'product': 'MSI',
          'term': 'Annual Digital',
          'userid': this.userID,

        })
      }
      if (this.productNameMSIQuarterly != null & this.productNameMSIQuarterly != null) {
        this.$gtag.event('create_user_profile', {
          'product': 'MSI',
          'term': 'Quarterly Digital',
          'userid': this.userID,

        })
      }


    },
  },
  watch: {
    USAstate(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal != null && newVal != "") {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      }
    },
    address1(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == null || newVal == "") {
          this.address1Error = true;
        } else {
          this.address1Error = false;
        }
      }
    },
    city(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == null || newVal == "") {
          this.cityError = true;
        } else {
          this.cityError = false;
        }
      }
    },
    zipcode(newVal, oldVal) {
      if (newVal != oldVal) {
       if (this.country.toLowerCase() == "usa") {
        if (newVal == null || newVal == "") {
          this.zipcodeError = true;
          this.zipcodeErrorMessage = "Zip code is required";
        } 
        else if (!/^\d{5}(?:[-\s]\d{4})?$/.test(newVal)) {
            this.zipcodeError = true;
            this.zipcodeErrorMessage = "Zip code is not valid";
          }
          else {
            this.zipcodeError = false;
          }
        }
        else if (this.country.toLowerCase() == "canada") {
          if (newVal != null && newVal != "") {
          if (!/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[ ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/.test(newVal)) {
            this.zipcodeError = true;
            this.zipcodeErrorMessage = "Zip code is not valid";
          }
          else {
            this.zipcodeError = false;
          }
        }
        else {
            this.zipcodeError = false;
          }
        }
        else {
          this.zipcodeError = false;
        }
      }
    },
    phonenumber(newVal, oldVal) {
      if (newVal != oldVal) {
        if(newVal != null && newVal != "") {
        if (
          !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(
            this.phonenumber
          ) ||
          this.phonenumber.length < 10
        ) {
          this.phonenumberError = true;
          this.phoneErrorMessage = " Phone number is not valid";
        } else {
          this.phonenumberError = false;
        }
      }
    }

      if (this.phonenumber != null && this.phonenumber != "") {
      if (
        !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.phonenumber) ||
        this.phonenumber.length < 10
      ) {
        this.phoneErrorMessage = " Phone number is not valid";
      }
      else {
          this.phonenumberError = false;
        }
    }
    },
    phonetype(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal.toLowerCase() == "mobile phone") {
          this.isMobileChecked = true;
        }
        else {
          this.isMobileChecked = false;
        }
      }
    }

  },
};
</script>

<style scoped>
.billingaddress-loader {
  margin-top: 50%;
}

.billingaddress-popover {
  width: 300px;
}

.billingaddress-registration-form-billing-address-container {
  width: 380px;
  margin: 0 auto;
}

.billing-address-continue-button {
  margin-left: auto;
  order: 2;
}

.billing-address-cancel-button {
  margin-left: auto;
}

.billingaddress-register-page-bottom {
  background: linear-gradient(360deg,
      #ffffff 12.47%,
      rgba(255, 255, 255, 0) 112.75%);
  opacity: 1;
  z-index: 2;
  transform: rotate(180deg);
  height: 50px;
}

.billing-address-main {
  height: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}

.billingaddress-registration-form-billing-address {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  background-color: #fff;
  height: 100%;
  min-height: 100vh;
}

.billing-address-page-title {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 44px;
  padding-top: 57px;
  font-weight: 250;
  font-size: 28px;
  line-height: 34px;
}

.billing-address-form-title {
  width: 100%;
}

.billing-address-form-fields {
  width: 100%;
  margin-top: 15%;
}

.billing-address-checkboxes {
  margin-top: 5%;
  width: 100%;
}

.billingaddress-registration-button-container {
  margin-top: 8%;
  width: 100%;
}

.horizonatal-fieldset {
  display: flex;
}

.fieldset-first {
  width: 36.5%;
  flex-basis: 48%;
}

.fieldset-second {
  width: 37%;
  margin-left: auto;
  flex-basis: 48%;
}

.billing-address-popover {
  margin-left: 6%;
  margin-top: 1%;
}

.popover-billing-address {
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #5e5e5e;
}

.popover:content {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
}

.FormsError {
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  color: #ca0000;
  display: block;
  margin-top: 2%;
}

@media only screen and (max-width: 480px) {
  .billingaddress-registration-form-billing-address-container {
    width: 90%;
    margin: 0 auto;
  }
  .billing-address-page-title{
  padding-top: 28px;
  margin-bottom: 15%;
}
.billing-address-form-fields {
  margin-top: 10%;
}
}
</style>
<style>
.billing-address-main .mds-label.mds-label--large .mds-label__text {
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 21px !important;
  font-weight: 700 !important;
  margin-bottom: 2% !important
}
.billing-address-checkboxes .mds-checkbox__visible-wrap{
  box-shadow: none !important;
}
</style>