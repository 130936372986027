<template>
  <div>
    <h1 style="display: none">{{ categoryValue }}</h1>
    <div>
      <mds-notification-container position="top-center" class="fund-500-funds-notification">
        <mds-notification v-for="(notification, index) in notifications" :key="index" size="small"
          :variation="notification.variation" :tinted="notification.tinted" :title="notification.title" :dismissDelay=2000
          @mds-notification-dismissed="removeNotification(notification)">
          <span v-html="notification.text"></span>
        </mds-notification>
      </mds-notification-container>
    </div>
    <div class="fund-500-funds-loader">
      <mds-loader v-if="isLoadingFunds" aria-label="Medium Loader"></mds-loader>
    </div>
    <div v-if="is500Funds && !this.$store.state.fund500.Fund500CategoryVisible">
      <div class="fund-500-funds-header-text">
        <span class="fund-500-funds-title">FundInvestor 500</span>
        <div style="position: relative" v-if="!isDataTableResponsiveToggle">
          <div class="fund-500-funds-search">
            <mds-search-field placeholder="Find a Fund Report" label="Search" size="small" @keyup.enter="SearchFund()"
              v-model.trim="fundTickerText" maxlength="5"  @keyup="FilterTicker($event)">
            </mds-search-field>
          </div>
        </div>

        <div class="fund-500-funds-help-text">
          <mds-icon name="download" size="small"></mds-icon>
          <span class="fund-500-funds-download-icon">Click on icon to download PDF report.</span>
        </div>

        <div style="position: relative" v-if="isDataTableResponsiveToggle">
          <br />
          <div class="fund-500-funds-search">
            <mds-search-field placeholder="Find a Fund Report" label="Search" size="large" @keyup.enter="SearchFund()"
              v-model.trim="fundTickerText" maxlength="5"  @keyup="FilterTicker($event)">
            </mds-search-field>
          </div>
        </div>

        <div class="fund-500-funds-category-name" v-if="isCategoryDrpdwnVisible">
          <mds-form size="small" name="CategoriesList">
            <mdc-dropdown v-model="cmdCatId" id="CategoryDrpdwn" label="" :options="cmbData" />
          </mds-form>
        </div>
      </div>

      <div class="fund-500-funds-table-div">
        <mds-table fixed-first-col show-sortable class="fund-500-funds-table">
          <mds-thead :nested-headers="1">
            <template v-slot:mds-thead-group-1>
              <mds-th colspan="1" style="width: 80px"></mds-th>
              <mds-th colspan="1" style="width: 338px"></mds-th>
              <mds-th colspan="1" style="width: 120px"></mds-th>
              <mds-th colspan="1" style="width: 100px"></mds-th>
              <mds-th colspan="1" style="width: 120px"></mds-th>
              <mds-th colspan="1" style="width: 100px"></mds-th>
              <mds-th colspan="6" style="width: 500px">
                <span style="position: absolute; top: 5px" class="fund-500-funds-nested-header">Total Return %</span>
                <span class="fund-500-funds-total-returns-border"></span>
              </mds-th>
              <mds-th colspan="6" style="width: 500px">
                <span style="position: absolute; top: 5px" class="fund-500-funds-nested-header">Total Rtn Cat Rnk %
                  (1=Best 100=Worst)</span>
                <span class="fund-500-funds-total-returns-border"></span>
              </mds-th>
              <mds-th colspan="4" style="width: 400px">
                <span style="top: 4px; position: relative; margin-left: 40px"
                  class="fund-500-funds-nested-header">Investor Returns %</span>
                <span class="fund-500-funds-Investor-returns-border"></span>
              </mds-th>
              <mds-th colspan="1" style="width: 80px"></mds-th>
              <mds-th colspan="1" style="width: 120px"></mds-th>
              <mds-th colspan="1" style="width: 120px"></mds-th>
              <mds-th colspan="1" style="width: 100px"></mds-th>
            </template>
            <mds-th v-for="(header, index) in headers" :key="index" :sortable="header.sortable"
              :right-aligned="header.align === 'right'" :style="header.style" :sorted="header.sorted"
              @mds-th-sort-changed="
                              handleSortEvent(index, header.fieldName, $event)
                            ">
              <span v-html="header.text" class="funds-500-fund-header"></span>
            </mds-th>
          </mds-thead>
          <mds-tbody v-if="isDisplayFunds">
            <mds-tr v-for="(row, index) in rows" :key="index">
              <mds-td>
                <div v-if="
                                    row.Symbol == 'N/A' ||
                                    row.Symbol == 'NaN' ||
                                    row.Symbol == '0' ||
                                    row.Symbol == '' ||
                                    row.Symbol == '&mdash;'||
                                    row.Symbol == null||
                                    row.Symbol == '&nbsp;' ||
                                    row.Symbol == '-'
                                  ">
                  &mdash;
                </div>
                <div class="fund-500-funds-ticker" v-else>
                  <span @click="DownloadReport(row.Symbol)">
                    <mds-icon name="download" size="small"></mds-icon>
                    {{ row.Symbol }}</span>
                </div>
              </mds-td>
              <mds-td class="fund-500-funds-shortname">
                <div v-if="
                                    row.ShortName == 'N/A' ||
                                    row.ShortName == 'NaN' ||
                                    row.ShortName == '0' ||
                                    row.ShortName == '' ||
                                    row.ShortName == '&mdash;'||
                                    row.ShortName == null||
                                    row.ShortName == '&nbsp;' ||
                                    row.ShortName == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{ row.ShortName }}
                </div>
              </mds-td>

              <mds-td>
                <div class="fund-500-funds-medalist-rating" v-if="
                                    row.overall_rating == 'N/A' ||
                                    row.overall_rating == 'NaN' ||
                                    row.overall_rating == '0' ||
                                    row.overall_rating == '' ||
                                    row.overall_rating == '&mdash;'||
                                    row.overall_rating == null||
                                    row.overall_rating == '&nbsp;'||
                                    row.overall_rating == '-'">
                  &mdash;
                </div>
                <div v-else class="fund-500-funds-medalist-rating">
                  <mds-analyst-rating :rating="analystRatingClassMap[row.overall_rating]"></mds-analyst-rating>
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.overall_data_availability_percentage == 'N/A' ||
                                    row.overall_data_availability_percentage == 'NaN' ||
                                    row.overall_data_availability_percentage == '0' ||
                                    row.overall_data_availability_percentage == '' ||
                                    row.overall_data_availability_percentage == '&mdash;'||
                                    row.overall_data_availability_percentage == null||
                                    row.overall_data_availability_percentage == '&nbsp;'||
                                    row.overall_data_availability_percentage == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{
                                    row.overall_data_availability_percentage | customDataFilter
                                    }}
                </div>
              </mds-td>
              <mds-td right-aligned class="funds-500-fund-analyst-driven">
                <div v-if="
                                    row.overall_rating_analyst_driven_percentage == 'N/A' ||
                                    row.overall_rating_analyst_driven_percentage == 'NaN' ||
                                    row.overall_rating_analyst_driven_percentage == '0' ||
                                    row.overall_rating_analyst_driven_percentage == '' ||
                                    row.overall_rating_analyst_driven_percentage == '&mdash;'||
                                    row.overall_rating_analyst_driven_percentage == null||
                                    row.overall_rating_analyst_driven_percentage == '&nbsp;'||
                                    row.overall_rating_analyst_driven_percentage == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{ row.overall_rating_analyst_driven_percentage }}
                </div>
              </mds-td>
              <!-- <mds-td>
                            <div v-if="row.AnalystRating == '0'">&mdash;</div>
                            <div v-else>
                                <mds-analyst-rating :rating="analystRatingClassMap[row.AnalystRating]"></mds-analyst-rating>
                            </div>
                        </mds-td> -->
              <mds-td class="funds-500-fund-overall-rating">
                <div v-if="
                                    row.RatingOverall == 'N/A' ||
                                    row.RatingOverall == 'NaN' ||
                                    row.RatingOverall == '0' ||
                                    row.RatingOverall == '' ||
                                    row.RatingOverall == '&mdash;'||
                                    row.RatingOverall == null||
                                    row.RatingOverall == '&nbsp;'||
                                    row.RatingOverall == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  <mds-star-rating :rating="parseInt(row.RatingOverall)" :custom-size="14"></mds-star-rating>
                </div>
              </mds-td>

              <mds-td right-aligned>
                <div v-if="
                                    row.DailyReturn4Week == 'N/A' ||
                                    row.DailyReturn4Week == 'NaN' ||
                                    row.DailyReturn4Week == '0' ||
                                    row.DailyReturn4Week == '' ||
                                    row.DailyReturn4Week == '&mdash;'||
                                    row.DailyReturn4Week == null||
                                    row.DailyReturn4Week == '&nbsp;'||
                                    row.DailyReturn4Week == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.DailyReturn4Week }}</div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.DailyReturnYTD == 'N/A' ||
                                    row.DailyReturnYTD == 'NaN' ||
                                    row.DailyReturnYTD == '0' ||
                                    row.DailyReturnYTD == '' ||
                                    row.DailyReturnYTD == '&mdash;'||
                                    row.DailyReturnYTD == null||
                                    row.DailyReturnYTD == '&nbsp;'||
                                    row.DailyReturnYTD == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.DailyReturnYTD }}</div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.DailyReturn156Week == 'N/A' ||
                                    row.DailyReturn156Week == 'NaN' ||
                                    row.DailyReturn156Week == '0' ||
                                    row.DailyReturn156Week == '' ||
                                    row.DailyReturn156Week == '&mdash;'||
                                    row.DailyReturn156Week == null||
                                    row.DailyReturn156Week == '&nbsp;'||
                                    row.DailyReturn156Week == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.DailyReturn156Week }}</div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.DailyReturn260Week == 'N/A' ||
                                    row.DailyReturn260Week == 'NaN' ||
                                    row.DailyReturn260Week == '0' ||
                                    row.DailyReturn260Week == '' ||
                                    row.DailyReturn260Week == '&mdash;'||
                                    row.DailyReturn260Week == null||
                                    row.DailyReturn260Week == '&nbsp;'||
                                    row.DailyReturn260Week == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{ row.DailyReturn260Week }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.MonthlyReturn10Year == 'N/A' ||
                                    row.MonthlyReturn10Year == 'NaN' ||
                                    row.MonthlyReturn10Year == '0' ||
                                    row.MonthlyReturn10Year == '' ||
                                    row.MonthlyReturn10Year == '&mdash;'||
                                    row.MonthlyReturn10Year == null||
                                    row.MonthlyReturn10Year == '&nbsp;'||
                                    row.MonthlyReturn10Year == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.MonthlyReturn10Year }}</div>
              </mds-td>

              <mds-td right-aligned>
                <div v-if="
                                    row.MonthlyReturn15Year == 'N/A' ||
                                    row.MonthlyReturn15Year == 'NaN' ||
                                    row.MonthlyReturn15Year == '0' ||
                                    row.MonthlyReturn15Year == '' ||
                                    row.MonthlyReturn15Year == '&mdash;'||
                                    row.MonthlyReturn15Year == null||
                                    row.MonthlyReturn15Year == '&nbsp;'||
                                    row.MonthlyReturn15Year == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.MonthlyReturn15Year }}</div>
              </mds-td>

              <mds-td right-aligned>
                <div v-if="
                                    row.DailyReturn4WeekCategoryPercentileRank == 'N/A' ||
                                    row.DailyReturn4WeekCategoryPercentileRank == 'NaN' ||
                                    row.DailyReturn4WeekCategoryPercentileRank == '0' ||
                                    row.DailyReturn4WeekCategoryPercentileRank == '' ||
                                    row.DailyReturn4WeekCategoryPercentileRank == '&mdash;'||
                                    row.DailyReturn4WeekCategoryPercentileRank == null||
                                    row.DailyReturn4WeekCategoryPercentileRank == '&nbsp;'||
                                    row.DailyReturn4WeekCategoryPercentileRank == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{ row.DailyReturn4WeekCategoryPercentileRank }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.DailyReturnYTDCategoryPercentileRank == 'N/A' ||
                                    row.DailyReturnYTDCategoryPercentileRank == 'NaN' ||
                                    row.DailyReturnYTDCategoryPercentileRank == '0' ||
                                    row.DailyReturnYTDCategoryPercentileRank == '' ||
                                    row.DailyReturnYTDCategoryPercentileRank == '&mdash;'||
                                    row.DailyReturnYTDCategoryPercentileRank == null||
                                    row.DailyReturnYTDCategoryPercentileRank == '&nbsp;'||
                                    row.DailyReturnYTDCategoryPercentileRank == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.DailyReturnYTDCategoryPercentileRank }}</div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.DailyReturn156WeekCategoryPercentileRank == 'N/A' ||
                                    row.DailyReturn156WeekCategoryPercentileRank == 'NaN' ||
                                    row.DailyReturn156WeekCategoryPercentileRank == '0' ||
                                    row.DailyReturn156WeekCategoryPercentileRank == '' ||
                                    row.DailyReturn156WeekCategoryPercentileRank == '&mdash;'||
                                    row.DailyReturn156WeekCategoryPercentileRank == null||
                                    row.DailyReturn156WeekCategoryPercentileRank == '&nbsp;'||
                                    row.DailyReturn156WeekCategoryPercentileRank == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{ row.DailyReturn156WeekCategoryPercentileRank }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.DailyReturn260WeekCategoryPercentileRank == 'N/A' ||
                                    row.DailyReturn260WeekCategoryPercentileRank == 'NaN' ||
                                    row.DailyReturn260WeekCategoryPercentileRank == '0' ||
                                    row.DailyReturn260WeekCategoryPercentileRank == '' ||
                                    row.DailyReturn260WeekCategoryPercentileRank == '&mdash;'||
                                    row.DailyReturn260WeekCategoryPercentileRank == null||
                                    row.DailyReturn260WeekCategoryPercentileRank == '&nbsp;'||
                                    row.DailyReturn260WeekCategoryPercentileRank == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{ row.DailyReturn260WeekCategoryPercentileRank }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.MonthlyReturn10YearCategoryPercentileRank == 'N/A' ||
                                    row.MonthlyReturn10YearCategoryPercentileRank == 'NaN' ||
                                    row.MonthlyReturn10YearCategoryPercentileRank == '0' ||
                                    row.MonthlyReturn10YearCategoryPercentileRank == '' ||
                                    row.MonthlyReturn10YearCategoryPercentileRank == '&mdash;'||
                                    row.MonthlyReturn10YearCategoryPercentileRank == null||
                                    row.MonthlyReturn10YearCategoryPercentileRank == '&nbsp;'||
                                    row.MonthlyReturn10YearCategoryPercentileRank == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{ row.MonthlyReturn10YearCategoryPercentileRank }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.MonthlyReturn15YearCategoryPercentileRank == 'N/A' ||
                                    row.MonthlyReturn15YearCategoryPercentileRank == 'NaN' ||
                                    row.MonthlyReturn15YearCategoryPercentileRank == '0' ||
                                    row.MonthlyReturn15YearCategoryPercentileRank == '' ||
                                    row.MonthlyReturn15YearCategoryPercentileRank == '&mdash;'||
                                    row.MonthlyReturn15YearCategoryPercentileRank == null||
                                    row.MonthlyReturn15YearCategoryPercentileRank == '&nbsp;'||
                                    row.MonthlyReturn15YearCategoryPercentileRank == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{ row.MonthlyReturn15YearCategoryPercentileRank }}
                </div>
              </mds-td>

              <mds-td right-aligned>
                <div v-if="
                                    row.InvestorReturn3Year == 'N/A' ||
                                    row.InvestorReturn3Year == 'NaN' ||
                                    row.InvestorReturn3Year == '0' ||
                                    row.InvestorReturn3Year == '' ||
                                    row.InvestorReturn3Year == '&mdash;'||
                                    row.InvestorReturn3Year == null||
                                    row.InvestorReturn3Year == '&nbsp;' ||
                                    row.InvestorReturn3Year == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.InvestorReturn3Year }}</div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.InvestorReturn5Year == 'N/A' ||
                                    row.InvestorReturn5Year == 'NaN' ||
                                    row.InvestorReturn5Year == '0' ||
                                    row.InvestorReturn5Year == '' ||
                                    row.InvestorReturn5Year == '&mdash;'||
                                    row.InvestorReturn5Year == null||
                                    row.InvestorReturn5Year == '&nbsp;' ||
                                    row.InvestorReturn5Year == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.InvestorReturn5Year }}</div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.InvestorReturn10Year == 'N/A' ||
                                    row.InvestorReturn10Year == 'NaN' ||
                                    row.InvestorReturn10Year == '0' ||
                                    row.InvestorReturn10Year == '' ||
                                    row.InvestorReturn10Year == '&mdash;'||
                                    row.InvestorReturn10Year == null||
                                    row.InvestorReturn10Year == '&nbsp;' ||
                                    row.InvestorReturn10Year == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.InvestorReturn10Year }}</div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.InvestorReturn15Year == 'N/A' ||
                                    row.InvestorReturn15Year == 'NaN' ||
                                    row.InvestorReturn15Year == '0' ||
                                    row.InvestorReturn15Year == '' ||
                                    row.InvestorReturn15Year == '&mdash;'||
                                    row.InvestorReturn15Year == null||
                                    row.InvestorReturn15Year == '&nbsp;' ||
                                    row.InvestorReturn15Year == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.InvestorReturn15Year }}</div>
              </mds-td>

              <mds-td right-aligned>
                <div v-if="
                                    row.TaxCostRatio5Year == 'N/A' ||
                                    row.TaxCostRatio5Year == 'NaN' ||
                                    row.TaxCostRatio5Year == '0' ||
                                    row.TaxCostRatio5Year == '' ||
                                    row.TaxCostRatio5Year == '&mdash;'||
                                    row.TaxCostRatio5Year == null||
                                    row.TaxCostRatio5Year == '&nbsp;' ||
                                    row.TaxCostRatio5Year == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.TaxCostRatio5Year }}</div>
              </mds-td>
              <mds-td right-aligned>
                <div v-if="
                                    row.Worst3Month == 'N/A' ||
                                    row.Worst3Month == 'NaN' ||
                                    row.Worst3Month == '0' ||
                                    row.Worst3Month == '' ||
                                    row.Worst3Month == '&mdash;'||
                                    row.Worst3Month == null||
                                    row.Worst3Month == '&nbsp;' ||
                                    row.Worst3Month == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.Worst3Month }}</div>
              </mds-td>
              <mds-td>
                <div v-if="
                                    row.ManagerInvestment == 'N/A' ||
                                    row.ManagerInvestment == 'NaN' ||
                                    row.ManagerInvestment == '0' ||
                                    row.ManagerInvestment == '' ||
                                    row.ManagerInvestment == '&mdash;'||
                                    row.ManagerInvestment == null||
                                    row.ManagerInvestment == '&nbsp;' ||
                                    row.ManagerInvestment == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>
                  {{ row.ManagerInvestment }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-right: 30px;">
                <div v-if="
                                    row.Beta3Year == 'N/A' ||
                                    row.Beta3Year == 'NaN' ||
                                    row.Beta3Year == '0' ||
                                    row.Beta3Year == '' ||
                                    row.Beta3Year == '&mdash;'||
                                    row.Beta3Year == null||
                                    row.Beta3Year == '&nbsp;' ||
                                    row.Beta3Year == '-'
                                  ">
                  &mdash;
                </div>
                <div v-else>{{ row.Beta3Year }}</div>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
        <div class="funds-500-fund-footer">
          Data through {{ currentDate | formatDateValForDataTables }}. *Data through previous month end.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import MdsLoader from "@mds/loader";

Vue.use(VueAxios, axios);
import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd,
} from "@mds/data-table";
import MdsSearchField from "@mds/search-field";
import MdsIcon from "@mds/icon";
import MdsStarRating from "@mds/star-rating";
import MdsAnalystRating from "@mds/analyst-rating";
import MdcDropdown from "../CustomControls/MdcDropdown.vue";
import MdsForm from "@mds/form";
import { MdsNotification, MdsNotificationContainer } from "@mds/notification";
import { mapState } from "vuex";
import GA4TrackingMixin from "../mixins/GA4TrackingMixin";
export default {
  name: "fund500-funds",
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsSearchField,
    MdsIcon,
    MdsStarRating,
    MdsAnalystRating,
    MdsLoader,
    MdsNotification,
    MdsNotificationContainer,
    MdcDropdown,
    MdsForm,
  },
  props: {
    categoryValue: String,
    searchTickerName: String,
  },
  mixins: [GA4TrackingMixin],
  data() {
    return {
      fundURL: "",
      isDataTableResponsiveToggle: null,
      filldropdown: false,
      isDisplayFunds: false,
      isLoadingFunds: false,
      cmdCatId: [],
      is500Funds: false,
      isCategoryDrpdwnVisible: false,
      fundTickerText: "",
      sortOrder: 1,
      sortColIndex: 1,
      getCategoryValues: true,
      CurrentDate: new Date(),
      analystRatingClassMap: {
        1: "not-ratable",
        2: "under-review",
        3: "negative",
        4: "neutral",
        5: "bronze",
        6: "silver",
        7: "gold",
      },
      notifications: [],
      headers: [
        {
          text: "Ticker",
          fieldName: "Symbol",
          sortable: true,
          style: {
            //"padding-left": "4px",
          },
        },
        {
          text: "Name",
          fieldName: "ShortName",
          sortable: true,
          sorted: 1,
          style: {
            "padding-left": "16px",
          },
        },
        {
          text: "Morningstar Medalist Rating",
          fieldName: "overall_rating",
          sortable: true,
          align: "left",
          style: {
            "padding-left": "20px",
          },
        },
        {
          text: "Data Coverage %",
          fieldName: "overall_data_availability_percentage",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "Analyst-Driven %",
          fieldName: "overall_rating_analyst_driven_percentage",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "16px",
          },
        },
        {
          text: "Morningstar Rating",
          fieldName: "RatingOverall",
          sortable: true,
          style: {
            "padding-left": "5px",
          },
        },

        {
          text: "1 Month",
          fieldName: "DailyReturn4Week",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "YTD",
          fieldName: "DailyReturnYTD",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "3-Yr",
          fieldName: "DailyReturn156Week",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "5-Yr",
          fieldName: "DailyReturn260Week",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "10-Yr*",
          fieldName: "MonthlyReturn10Year",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "15-Yr*",
          fieldName: "MonthlyReturn15Year",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },

        {
          text: "1 Month",
          fieldName: "DailyReturn4WeekCategoryPercentileRank",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "YTD",
          fieldName: "DailyReturnYTDCategoryPercentileRank",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "3-Yr",
          fieldName: "DailyReturn156WeekCategoryPercentileRank",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "5-Yr",
          fieldName: "DailyReturn260WeekCategoryPercentileRank",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "10-Yr*",
          fieldName: "MonthlyReturn10YearCategoryPercentileRank",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "15-Yr*",
          fieldName: "MonthlyReturn15YearCategoryPercentileRank",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },

        {
          text: "3-Yr",
          fieldName: "InvestorReturn3Year",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "5-Yr",
          fieldName: "InvestorReturn5Year",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "10-Yr*",
          fieldName: "InvestorReturn10Year",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "15-Yr*",
          fieldName: "InvestorReturn15Year",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },

        {
          text: "5-Yr <br/> Tax Cost",
          fieldName: "TaxCostRatio5Year",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "Worst 3 <br /> Month Return",
          fieldName: "Worst3Month",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "8px",
          },
        },
        {
          text: "Manager <br />Investment",
          fieldName: "ManagerInvestment",
          sortable: true,
          style: {
            "padding-left": "8px",
          },
        },
        {
          text: "Beta",
          fieldName: "Beta3Year",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "30px",
          },
        },
      ],
      rows: [],
      FundData: [],
      cmbData: [],
      M500CategoryGroupListURL:
        process.env.VUE_APP_BackEndAPI +
        process.env.VUE_APP_M500CategoryGroupList,
      DownloadPdfUrl:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DownloadPdfUrl,
      GetM500FundsURL:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_M500FundsURL,
      FundSearchURL:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_Search_FUND,
        CurrentProduct: this.$session.get("current-product"),
    };
  },
  methods: {
    FilterTicker(event) {
      var alphabetsOnly = /^[A-Za-z]+$/;
      if (!event.key.match(alphabetsOnly)) {
        this.fundTickerText = this.fundTickerText.substring(0,this.fundTickerText.length - 1);
      } else {
        this.fundTickerText = this.fundTickerText.toUpperCase();
      }
    },
    removeNotification(notification) {
      var removeIndex = this.notifications
        .map(function (item) {
          return item.id;
        })
        .indexOf(notification.id);

      ~removeIndex && this.notifications.splice(removeIndex, 1);
    },
    addNotification(isSuccess) {
      this.notifications = [];
      if (isSuccess) {
        this.notifications.push({
          id: "1",
          size: "small",
          variation: "success",
          text: "Fund Report has downloaded.",
          handler: "removeNotification",
        });
      } else {
        this.notifications.push({
          id: "2",
          size: "small",
          variation: "error",
          text:"The fund is not currently included in the FundInvestor 500. The FundInvestor 500 covers 500 of the largest, most popular and best mutual funds",
          // text: this.$store.state.fund500.Fund500CategoryVisible ? this.searchTickerName + " is not a valid ticker. Please enter a valid ticker." :
          //   this.fundTickerText + " is not a valid ticker. Please enter a valid ticker.",
          handler: "removeNotification",
        });
      }
    },
    GetM500CategoryGroupList() {
      try {
        Vue.axios
          .get(this.M500CategoryGroupListURL)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  for (var i = 0; i < response.data.length; i++) {
                    var obj = response.data[i];
                    this.cmbData.push({
                      text: obj.CategoryName,
                      value: obj.ShortCode,
                    });
                  }
                  return true;
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
      } catch (ex) {
        console.log(ex.message);
      }
    },
    GetM500Funds(shortCode, tickerName, isSearchQuery) {
      if (isSearchQuery && (tickerName.length != 5 || tickerName[4] != 'X')) {
        this.isLoadingFunds = false;
        this.addNotification(false);
      } else {
        this.isLoadingFunds = true;
        if (isSearchQuery) {
          this.fundURL = this.FundSearchURL;
          this.fundURL = this.fundURL.replace("{TickerName}", tickerName);
        } else {
          this.fundURL = this.GetM500FundsURL + shortCode;
        }
        try {
          Vue.axios
            .get(this.fundURL)
            .then((response) => {
              if (response) {
                if (response.status == 200) {
                  if (response.data) {
                    let FundData = [];
                    if (isSearchQuery) {
                      this.$store.state.fund500.Fund500CategoryVisible = false;
                      this.isCategoryDrpdwnVisible = false;
                      FundData[0] = response.data;
                      var currentCategory = this.cmbData.filter(function (item) {
                        if (item.text === FundData[0].GroupName) {
                          return item.value;
                        }
                      });
                      this.cmdCatId = currentCategory[0].value;
                      this.getCategoryValues = false;
                    } else {
                      FundData = response.data;
                    }
                    for (var i = 0; i < FundData.length; i++) {
                      var obj = FundData[i];
                      if (obj.ShortName.includes("?")) {
                        FundData[i].ShortName = FundData[i].ShortName.split('?').join('™');
                      }
                      if (obj.DailyReturn4Week == null) {
                        FundData[i].DailyReturn4Week = "-";
                      }
                      if (FundData[i].DailyReturn4Week != "-") {
                        FundData[i].DailyReturn4Week = parseFloat(
                          obj.DailyReturn4Week
                        );
                      }

                      if (obj.DailyReturnYTD == null) {
                        FundData[i].DailyReturnYTD = "-";
                      }
                      if (FundData[i].DailyReturnYTD != "-") {
                        FundData[i].DailyReturnYTD = parseFloat(
                          obj.DailyReturnYTD
                        );
                      }

                      if (obj.DailyReturn156Week == null) {
                        FundData[i].DailyReturn156Week = "-";
                      }
                      if (FundData[i].DailyReturn156Week != "-") {
                        FundData[i].DailyReturn156Week = parseFloat(
                          obj.DailyReturn156Week
                        );
                      }

                      if (obj.DailyReturn260Week == null) {
                        FundData[i].DailyReturn260Week = "-";
                      }
                      if (FundData[i].DailyReturn260Week != "-") {
                        FundData[i].DailyReturn260Week = parseFloat(
                          obj.DailyReturn260Week
                        );
                      }

                      if (obj.MonthlyReturn10Year == null) {
                        FundData[i].MonthlyReturn10Year = "-";
                      }
                      if (FundData[i].MonthlyReturn10Year != "-") {
                        FundData[i].MonthlyReturn10Year = parseFloat(
                          obj.MonthlyReturn10Year
                        );
                      }

                      if (obj.MonthlyReturn15Year == null) {
                        FundData[i].MonthlyReturn15Year = "-";
                      }
                      if (FundData[i].MonthlyReturn15Year != "-") {
                        FundData[i].MonthlyReturn15Year = parseFloat(
                          obj.MonthlyReturn15Year
                        );
                      }

                      if (obj.DailyReturn4WeekCategoryPercentileRank == null) {
                        FundData[i].DailyReturn4WeekCategoryPercentileRank = "-";
                      }
                      if (
                        FundData[i].DailyReturn4WeekCategoryPercentileRank != "-"
                      ) {
                        FundData[i].DailyReturn4WeekCategoryPercentileRank =
                          parseFloat(obj.DailyReturn4WeekCategoryPercentileRank);
                      }

                      if (obj.DailyReturnYTDCategoryPercentileRank == null) {
                        FundData[i].DailyReturnYTDCategoryPercentileRank = "-";
                      }
                      if (
                        FundData[i].DailyReturnYTDCategoryPercentileRank != "-"
                      ) {
                        FundData[i].DailyReturnYTDCategoryPercentileRank =
                          parseFloat(obj.DailyReturnYTDCategoryPercentileRank);
                      }

                      if (obj.DailyReturn156WeekCategoryPercentileRank == null) {
                        FundData[i].DailyReturn156WeekCategoryPercentileRank =
                          "-";
                      }
                      if (
                        FundData[i].DailyReturn156WeekCategoryPercentileRank !=
                        "-"
                      ) {
                        FundData[i].DailyReturn156WeekCategoryPercentileRank =
                          parseFloat(
                            obj.DailyReturn156WeekCategoryPercentileRank
                          );
                      }

                      if (obj.DailyReturn260WeekCategoryPercentileRank == null) {
                        FundData[i].DailyReturn260WeekCategoryPercentileRank =
                          "-";
                      }
                      if (
                        FundData[i].DailyReturn260WeekCategoryPercentileRank !=
                        "-"
                      ) {
                        FundData[i].DailyReturn260WeekCategoryPercentileRank =
                          parseFloat(
                            obj.DailyReturn260WeekCategoryPercentileRank
                          );
                      }

                      if (obj.MonthlyReturn10YearCategoryPercentileRank == null) {
                        FundData[i].MonthlyReturn10YearCategoryPercentileRank =
                          "-";
                      }
                      if (
                        FundData[i].MonthlyReturn10YearCategoryPercentileRank !=
                        "-"
                      ) {
                        FundData[i].MonthlyReturn10YearCategoryPercentileRank =
                          parseFloat(
                            obj.MonthlyReturn10YearCategoryPercentileRank
                          );
                      }

                      if (obj.MonthlyReturn15YearCategoryPercentileRank == null) {
                        FundData[i].MonthlyReturn15YearCategoryPercentileRank =
                          "-";
                      }
                      if (
                        FundData[i].MonthlyReturn15YearCategoryPercentileRank !=
                        "-"
                      ) {
                        FundData[i].MonthlyReturn15YearCategoryPercentileRank =
                          parseFloat(
                            obj.MonthlyReturn15YearCategoryPercentileRank
                          );
                      }

                      if (obj.InvestorReturn3Year == null) {
                        FundData[i].InvestorReturn3Year = "-";
                      }
                      if (FundData[i].InvestorReturn3Year != "-") {
                        FundData[i].InvestorReturn3Year = parseFloat(
                          obj.InvestorReturn3Year
                        );
                      }

                      if (obj.InvestorReturn5Year == null) {
                        FundData[i].InvestorReturn5Year = "-";
                      }
                      if (FundData[i].InvestorReturn5Year != "-") {
                        FundData[i].InvestorReturn5Year = parseFloat(
                          obj.InvestorReturn5Year
                        );
                      }

                      if (obj.InvestorReturn10Year == null) {
                        FundData[i].InvestorReturn10Year = "-";
                      }
                      if (FundData[i].InvestorReturn10Year != "-") {
                        FundData[i].InvestorReturn10Year = parseFloat(
                          obj.InvestorReturn10Year
                        );
                      }

                      if (obj.DailyReturn260Week == null) {
                        FundData[i].DailyReturn260Week = "-";
                      }
                      if (FundData[i].DailyReturn260Week != "-") {
                        FundData[i].DailyReturn260Week = parseFloat(
                          obj.DailyReturn260Week
                        );
                      }

                      if (obj.InvestorReturn15Year == null) {
                        FundData[i].InvestorReturn15Year = "-";
                      }
                      if (FundData[i].InvestorReturn15Year != "-") {
                        FundData[i].InvestorReturn15Year = parseFloat(
                          obj.InvestorReturn15Year
                        );
                      }

                      if (obj.TaxCostRatio5Year == null) {
                        FundData[i].TaxCostRatio5Year = "-";
                      }
                      if (FundData[i].TaxCostRatio5Year != "-") {
                        FundData[i].TaxCostRatio5Year = parseFloat(
                          obj.TaxCostRatio5Year
                        );
                      }

                      if (obj.Worst3Month == null) {
                        FundData[i].Worst3Month = "-";
                      }
                      if (FundData[i].Worst3Month != "-") {
                        FundData[i].Worst3Month = parseFloat(obj.Worst3Month);
                      }

                      if (obj.Beta3Year == null) {
                        FundData[i].Beta3Year = "-";
                      }
                      if (FundData[i].Beta3Year != "-") {
                        FundData[i].Beta3Year = parseFloat(obj.Beta3Year);
                      }

                      if (FundData[i].overall_rating) {
                        if (obj.overall_rating != 0) {
                          FundData[i].overall_rating = parseInt(
                            obj.overall_rating
                          );
                        }
                      }
                      if (FundData[i].overall_data_availability_percentage) {
                        if (obj.overall_data_availability_percentage == 0) {
                          FundData[i].overall_data_availability_percentage = 0.0;
                        } else {
                          // (customData[i].overall_data_availability_percentage != 0) {
                          //customData[i].overall_data_availability_percentage = 0;
                          FundData[i].overall_data_availability_percentage =
                            parseFloat(
                              obj.overall_data_availability_percentage
                            ).toFixed(2);
                        }
                      }
                      if (FundData[i].overall_rating_analyst_driven_percentage) {
                        if (obj.overall_rating_analyst_driven_percentage != 0) {
                          FundData[i].overall_rating_analyst_driven_percentage =
                            parseInt(
                              obj.overall_rating_analyst_driven_percentage
                            );
                        }
                      }
                    }
                    if (isSearchQuery && FundData.length == 0) {
                      this.addNotification(false);
                    }
                    this.rows = FundData;
                    this.isCategoryDrpdwnVisible = true;
                  }
                  else if (isSearchQuery) {
                    // this.cmdCatId = "AllCat";
                    // this.rows = []; 
                    this.getCategoryValues = false;
                    this.addNotification(false);
                  }
                  return true;
                }

              }
            })
            .catch(() => {
              if (isSearchQuery) {
                if (this.cmdCatId == "") {
                  this.cmdCatId = "AllCat";
                }
                // this.cmdCatId = "AllCat";
                // this.rows = [];
                this.getCategoryValues = false;
                this.addNotification(false);
              }
              this.errored = true;
            })
            .finally(
              () => (
                (this.isLoadingFunds = false),
                (this.isDisplayFunds = true),
                (this.getCategoryValues = true),
                (this.isCategoryDrpdwnVisible = true)
              )
            );
        } catch (ex) {
          if (isSearchQuery) {
            this.isLoadingFunds = false;
            this.addNotification(false);
          }
        } finally {
          this.isDisplayFunds = true;
          this.getCategoryValues = true;
          this.isCategoryDrpdwnVisible = true;
        }
      }
    },
    handleSortEvent(colIndex, colName) {
      // if your tracked sort order is 0 (none), set it 1 (ascending)
      // if there is already an order being tracked inverse it
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

      // if the table was already sorted by a different column, then reset that column to 0 (none)
      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.headers[this.sortColIndex], "sorted", 0);
        this.sortOrder = 1;
      }

      // track the current column as the one being sorted on
      this.sortColIndex = colIndex;
      this.$set(this.headers[colIndex], "sorted", this.sortOrder);

      // sort the row data by column clicked on

      // const IncludeDash = this.rows.filter(colName => colName == "-");
      // let col = colName.toString();
      var IncludeDash = this.rows.filter(function (el) {
        return el[colName] == "-";
      });

      var WithOutDash = this.rows.filter(function (el) {
        return el[colName] != "-";
      });

      WithOutDash.sort((a, b) => {
        if (
          colName == "DailyReturn4Week" ||
          colName == "DailyReturnYTD" ||
          colName == "DailyReturn156Week" ||
          colName == "DailyReturn260Week" ||
          colName == "MonthlyReturn10Year" ||
          colName == "MonthlyReturn15Year" ||
          colName == "DailyReturn4WeekCategoryPercentileRank" ||
          colName == "DailyReturnYTDCategoryPercentileRank" ||
          colName == "DailyReturn156WeekCategoryPercentileRank" ||
          colName == "DailyReturn260WeekCategoryPercentileRank" ||
          colName == "MonthlyReturn10YearCategoryPercentileRank" ||
          colName == "MonthlyReturn15YearCategoryPercentileRank" ||
          colName == "InvestorReturn3Year" ||
          colName == "InvestorReturn5Year" ||
          colName == "InvestorReturn10Year" ||
          colName == "InvestorReturn15Year" ||
          colName == "TaxCostRatio5Year" ||
          colName == "Worst3Month" ||
          colName == "Beta3Year" ||
          colName == "overallRating" ||
          colName == "overallDataAvailabilityPercentage" ||
          colName == "overallRatingAnalystDrivenPercentage"
        ) {
          return parseFloat(a[colName]) >= parseFloat(b[colName])
            ? this.sortOrder
            : -this.sortOrder;
        } else if (colName == "ManagerInvestment") {
          let s = 0;
          const aval = a[colName];
          const bval = b[colName];
          if (aval === bval) {
            s = 0;
          } else if (aval === "More Than $1Mil") {
            s = 1;
          } else if (bval === "More Than $1Mil") {
            s = -1;
          } else if (aval === "$500K-$1Mil") {
            s = 1;
          } else if (bval === "$500K-$1Mil") {
            s = -1;
          } else if (aval === "$100K-$500K") {
            s = 1;
          } else if (bval === "$100K-$500K") {
            s = -1;
          } else if (aval === "$50K-$100K") {
            s = 1;
          } else if (bval === "$50K-$100K") {
            s = -1;
          } else if (aval === "$10K-$50K") {
            s = 1;
          } else if (bval === "$10K-$50K") {
            s = -1;
          } else if (aval === "$1-$10K") {
            s = 1;
          } else if (bval === "$1-$10K") {
            s = -1;
          } else if (aval === "None") {
            s = 1;
          } else if (bval === "None") {
            s = -1;
          } else if (aval === "N/A") {
            s = 1;
          } else if (bval === "N/A") {
            s = -1;
          } else if (aval === "-") {
            s = 1;
          } else if (bval === "-") {
            s = -1;
          }
          if (this.sortOrder != 1) {
            s *= -1;
          }

          return s;
        } else {
          return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
        }

      });
      let mergeArr = [];
      if (this.sortOrder == 1) {
        mergeArr = IncludeDash.concat(WithOutDash);
      } else {
        mergeArr = WithOutDash.concat(IncludeDash);
      }
      this.rows = mergeArr;
    },
    DownloadReport(Ticker) {
      this.$gtag.event('download_pdf', {
        'product': 'MFI',
        'issue_date': this.CurrentDate,
        'issue_title': Ticker + '.pdf',
        'type': 'fund500',
        'user_type':localStorage.getItem("userType_MFI")
      })
      try {
        let Url = this.DownloadPdfUrl;
        Url = Url.replace("{ProductCode}", "MFI");
        Url = Url.replace("{FileName}", Ticker + ".pdf");
        Url = Url.replace("{Type}", "FundReport500");

        Vue.axios
          .get(Url)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var results = response.data;
                  if (results) {
                    if (results.FilePath) {
                      const link = document.createElement("a");
                      link.setAttribute("href", results.FilePath);
                      link.setAttribute("target", "_blank");
                      link.setAttribute("download", this.FileName);
                      link.click();
                      this.addNotification(true);
                    }
                  }
                }
                return true;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },
    SearchFund() {
      if (this.fundTickerText != "" && this.fundTickerText != undefined) {
        this.fundTicker = this.fundTickerText;
        this.$store.state.fund500.Fund500CategoryVisible = false;
      }
    },
  },
  mounted() {
    this.isDataTableResponsiveToggle =
      this.$store.state.responsive.isMobileContent;
    this.filldropdown = false;
    var categoryValue = this.categoryValue.replace("\r", "");
    this.cmdCatId = categoryValue;

    const date = new Date();
    // let day = ("0" + date.getDate()).slice(-2);
    // let month = ("0" + (date.getMonth() + 1)).slice(-2);
    // let year = date.getFullYear();
    // let twoDigitYear = year.toString().substr(-2);

    date.setDate(date.getDate() - 1);
    this.currentDate = date;
    this.GetM500CategoryGroupList();
    this.getUserDetailsMixin(this.CurrentProduct);
  },
  updated() {
    this.isDataTableResponsiveToggle =
      this.$store.state.responsive.isMobileContent;
    if (this.categoryValue) {
      if (!this.filldropdown) {
        this.cmdCatId = this.categoryValue;
      }
      this.is500Funds = true;
    }
  },
  watch: {
    isDataTableResponsiveState() {
      this.isDataTableResponsiveToggle = this.isDataTableResponsiveState;
    },
    searchTickerName(newValue) {
      if (newValue.length > 0) {
        this.is500Funds = true;
        this.GetM500Funds("", newValue, true);
      }
    },
    cmdCatId(newValue) {
      if (newValue.length == 0) {
        var categoryValue = this.categoryValue.replace("\r", "");
        this.cmdCatId = categoryValue;
      }

      if (newValue.length > 0) {
        this.filldropdown = true;
        this.cmdCatId = newValue;
        if (this.getCategoryValues) {
          if (newValue == "AllCat") {
            location.reload();
          } else {
            this.isCategoryDrpdwnVisible = true;
            this.fundTicker = "";
            this.GetM500Funds(newValue, "", false);
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      isDataTableResponsive: (state) => state.responsive.isMobileContent,
      isDataTableResponsiveAlias: "isMobileContent",
      isDataTableResponsiveState(state) {
        return state.responsive.isMobileContent;
      },
    }),
    fundTicker: {
      get() {
        return this.searchTickerName;
      },
      set(newValue) {
        if (newValue.length > 0) {
          this.is500Funds = true;
          this.GetM500Funds("", newValue, true);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@mds/constants";
$mds-data-table-border-fixed-col: 2px solid $mds-color-neutral-90 !default;


::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}


.fund-500-funds-table.mds-table__scroller .mds-table--fixed-column {
  th {
    position: sticky;
    top: 0;
  }

  th:nth-child(1) {
    z-index: $mds-z-index-low + 1;
  }
}

.fund-500-funds-table {
  width: 100%;
  max-height: 600px;
  box-shadow: none;
}

.fund-500-funds-table-div {
  width: 100%;
  margin-top: 20px
}

.mds-td {
  line-height: 25px;
}




.fund-500-funds-notification {
  margin-top: 170px;
}

.fund-500-funds-title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  color: #1e1e1e;
}

.fund-500-funds-header-text {
  width: 100%;
  padding-top: 48px;
  margin-left: 2px;
}

.fund-500-funds-download-icon {
  margin-left: 5px;
  font-size: 16px;
  color: #5e5e5e;
  flex: none;
  order: 0;
  flex-grow: 1;
  line-height: 25px;
}

.fund-500-funds-help-text {
  padding-top: 24px;
}

.fund-500-funds-search {
  position: absolute;
  width: 195px;
  height: 29px;
  right: 1%;
  top: -31px;
}

.fund-500-funds-category-name {
  width: 200px;
  margin-top: 20px;
}

.fund-500-funds-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 42.92%;
  right: 37.92%;
  top: 60%;
  z-index: 10;
}

.fund-500-funds-medalist-rating {
  padding-left: 13px;
  margin-top: 5px
}

.fund-500-funds-shortname {
  margin-left: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1e1e1e;
  line-height: 25px;
  flex: none;
  order: 0;
  flex-grow: 1;
  padding-left: 16px;
}

.funds-500-fund-analyst-driven {
  padding-right: 16px;
}

.funds-500-fund-overall-rating {
  padding-left: 5px;
}


.fund-500-funds-nested-header {
  color: #5e5e5e;
  font-size: 16px;
}

.funds-500-fund-header {
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  color: #5e5e5e;
  flex: none;
  order: 0;
  flex-grow: 1;
  line-height: 18px;
}

.funds-500-fund-footer {
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  color: #5e5e5e;
  flex: none;
  order: 0;
  flex-grow: 1;
  line-height: 18px;
  margin-top: 42px;
  margin-bottom: 56px
}


.fund-500-funds-total-returns-border {
  border-bottom: 1px solid #808080;
  width: 98%;
  position: absolute;
  top: 24px;
}

.fund-500-funds-ticker:hover {
  cursor: pointer;
}



.fund-500-funds-Investor-returns-border {
  border-bottom: 1px solid #808080;
  width: 400px;
  position: absolute;
  top: 24px;
  left: 8px;
  margin-left: 35px;
}
</style>

<style lang="scss">
@import "@mds/constants";

button {
  box-shadow: none !important;
}

.fund-500-funds-category-name>form>div>div>button {
  background-color: $mds-interactive-color-secondary-active !important;
  color: #ffffff !important;
}



.fund-500-funds-category-name>form>div>div>button>span:nth-child(2)>svg>path {
  stroke: #ffffff !important;
}
</style>

<style scoped>
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .fund-500-funds-help-text {
    padding-top: 4px;
  }

  .fund-500-funds-search {
    width: 90vw;
    padding-top: 60px;
  }

  .fund-500-funds-notification {
    margin-top: 100px;
    margin-left: 9px;
    width: 370px;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .fund-500-funds-header-text {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 600px) {

  .fund-500-funds-search {
    right: -1%;
  }


}
</style>
<style>
.fund-500-funds-category-name>form>div>div:nth-child(2)>div>div::-webkit-scrollbar {
  width: 6px;
  overflow-x: overlay;
}

.fund-500-funds-category-name>form>div>div:nth-child(2)>div>div::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}



@media only screen and (min-width: 320px) and (max-width: 600px) {
  .fund-500-funds-category-name>form>div>div {
    padding-top: 50px;
  }

  .fund-500-funds-search>div>span {
    left: 24px !important;
  }

  .fund-500-funds-search>div {
    padding-left: 4px;
    padding-right: 0px;
  }

  .fund-500-funds-search>div {
    width: 90vw;
    margin: 0 auto;
  }



  .fund-500-funds-category-name>form>div>div>button {
    width: 90vw;
    height: 42px;
    margin: 0 auto;
  }

  .fund-500-funds-category-name>form>div>div:nth-child(2) {
    width: 90vw;
    height: 60vh;
    overflow-y: scroll;
    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
  }

  .fund-500-funds-category-name>form>div>div>button {
    font-size: 20px;
  }
}
</style>