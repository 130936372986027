<template>
    <mds-layout-grid class="analyst-report-section">
        <mds-row>
            <mds-col>
                <div class="product-analyst-report-name">
                    <mds-section border="none" :size="7" title="StockInvestor Analyst Reports" bold v-if="CurrentProduct.toUpperCase() == 'MSI'"></mds-section>
                    <mds-section border="none" :size="7" title="DividendInvestor Analyst Reports" bold v-else-if="CurrentProduct.toUpperCase() == 'MDI'"></mds-section>
                    <mds-section border="none" :size="7" title="ETFInvestor Analyst Reports" bold v-else-if="CurrentProduct.toUpperCase() == 'MEI'|| this.CurrentProduct.toUpperCase() == 'ETF'"></mds-section>
                </div>
            </mds-col>
        </mds-row>
        <mds-row>
            <div v-if="isMobileContentToggle">
                <mds-col  class="analyst-reports-responsive-left-panel" :cols="12">
                <mds-button variation="secondary" size="large" icon-right="caret-down" @click="analystLeftPanelPopOVer = !analystLeftPanelPopOVer" id="analyst-left-collapse-trigger" class="analyst-responsive-left-panel-button"> Analyst Archive </mds-button>
                <mds-popover class="analyst-responsive-left-panel-popover" triggered-by="analyst-left-collapse-trigger" v-model="analystLeftPanelPopOVer" :position="['bottom-right']">
                    <analyst-reports-left-panel :left-panel-data="this.leftPanelData" :is-mobile-content-toggle=this.isMobileContentToggle @responsive-report-clicked="changeReport" @responsive-left-nav-clicked="getLeftPanelData"></analyst-reports-left-panel>
                </mds-popover>
            </mds-col>
            </div>
           <div v-if="!isMobileContentToggle">
            <mds-col  class="analyst-reports-left-panel" :cols="2" :cols-at-l="3" :cols-at-xl="3">
            <analyst-reports-left-panel :left-panel-data="this.leftPanelData" :is-mobile-content-toggle=this.isMobileContentToggle @report-clicked="changeReport" @left-nav-clicked="getLeftPanelData"></analyst-reports-left-panel>
            </mds-col>
           </div>
           <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="9" :cols-at-xl="9" class="analyst-reports-right-panel">
                <analyst-reports-right-panel :on-load-state="this.onLoad" :medalist-reports-data="this.medalistReportsData" :is-loading-state="this.isLoading" :is-error-state="this.isError"></analyst-reports-right-panel>
            </mds-col>
        </mds-row>
    </mds-layout-grid>
    </template>
    
        
    <script>
    import Vue from "vue";
    import VueAxios from "vue-axios";
    import axios from "axios";
    import {
        MdsLayoutGrid,
        MdsRow,
        MdsCol
    } from "@mds/layout-grid";
    import MdsSection from "@mds/section";
    import AnalystReportsLeftPanel from "./AnalystReportsLeftPanel";
    import AnalystReportsRightPanel from "./AnalystReportsRightPanel";
    import {
        MdsButton
    } from '@mds/button'
    import MdsPopover from '@mds/popover';
    import EncryptDecryptMixin  from "../../mixins/EncryptDecryptMixin.js";
    import { mapState } from "vuex";
    Vue.use(VueAxios, axios);
    export default {
        name: "analyst-reports",
        props: [],
        mounted() {
            this.isMobileContentToggle=this.$store.state.responsive.isMobileHeader;
            const secId = this.Decrypt(this.$session.get("CurrentSecID"));
            if (!secId) {
                this.onLoad = true;
            } else {
                this.getMedalistReport(secId);
            }
        },
        
      computed: mapState({
        isMobileHeader: state => state.responsive.isMobileHeader,
        isMobileContentAlias:'isMobileHeader',
        isMobileContentState(state){
          return state.responsive.isMobileHeader
        }
      }),
        components: {
            AnalystReportsLeftPanel,
            AnalystReportsRightPanel,
            MdsSection,
            MdsLayoutGrid,
            MdsRow,
            MdsCol,
            MdsButton,
            MdsPopover
        },
        mixins: [
            EncryptDecryptMixin
        ],
        data() {
            return {
                isMobileContentToggle: null,
                analystLeftPanelPopOVer: false,
                isLoading: false,
                isError: false,
                onLoad: false,
                portfolioURL: null,
                CurrentProduct: this.$session.get("current-product"),
                medalistReportsData: {},
                leftPanelData: [],
                valuesNeeded: [{
                        fieldName: "HoldingName",
                    },
                    {
                        fieldName: "SecId",
                    },
                    {
                        fieldName: "isActive",
                    },
                    {
                        fieldName: "Ticker",
                    },
                    {
                        fieldName: "MorningstarRating",
                    },
                ],
                medalistReportUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_MedalistReports,
                DividendSelectUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DividendSelectURL,
                DividendDeferUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DividendDeferURL,
                IncomeBellwethersURL: process.env.VUE_APP_BackEndAPI +
                    process.env.VUE_APP_IncomeBellwethersURL,
                TortoiseURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_TortoiseURL,
                HareURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_HareURL,
                TortoiseAndHareWatchlistURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_TortoiseHareURL,
                EquityWatchlistURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_EquityWatchlistUrl,
                BondWatchlistURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_BondWatchlistURL,
                ModelPortfolioURL: process.env.VUE_APP_BackEndAPI +
                    process.env.VUE_APP_ModelPortfoliosDetailsURL,
            };
        },
        methods: {
            getMedalistReport(secId) {
                this.isLoading = true;
                if (this.CurrentProduct.toUpperCase() == "MEI") {
                    this.CurrentProduct = "ETF";
                }
                const getMedalistReport = this.medalistReportUrl + "SecId=" + secId + "&ProductCode=" + this.CurrentProduct;
                //const getMedalistReport = "https://localhost:50377/analyst-report-and-note?SecId=" +secId +"&ProductCode=" +this.CurrentProduct;
                try {
                    Vue.axios
                        .get(getMedalistReport)
                        .then((response) => {
                            if (response) {
                                if (response.status == 200) {
                                    if (response.data) {
                                        this.medalistReportsData = response.data;
    
                                        if (this.medalistReportsData != null) {
                                            if (this.medalistReportsData.AnalystNote_Update_Date && this.medalistReportsData.Date) {
                                                if (this.medalistReportsData.AnalystNote_Update_Date != null && this.medalistReportsData.Date != null) {
    
                                                    var split_AnalystNote_Update_Date = this.medalistReportsData.AnalystNote_Update_Date.split(' ');
                                                    var split_Date = this.medalistReportsData.Date.split(' ');
    
                                                    if (split_AnalystNote_Update_Date && split_Date) {
    
                                                        if (split_AnalystNote_Update_Date[0] && split_Date[0]) {
                                                            let date1 = new Date(split_Date[0]).getTime();
                                                            let date2 = new Date(split_AnalystNote_Update_Date[0]).getTime();
    
                                                            if (date1 <= date2) {
                                                                Object.assign(this.medalistReportsData, {
                                                                    bCheckAnalystDate: true
                                                                });
                                                            }
    
                                                        }
    
                                                    }
                                                }
                                            }
                                        }
    
                                        for (let i = 0; i < this.leftPanelData.length; i++) {
                                            if (secId == this.leftPanelData[i].SecId) {
                                                Object.assign(this.medalistReportsData, {
                                                    HoldingName: this.leftPanelData[i].HoldingName,
                                                    Ticker: this.leftPanelData[i].Ticker,
                                                    MorningstarRating: this.leftPanelData[i].MorningstarRating
                                                });
                                            }
                                        }
                                        // this.isLoading = false;
                                        this.isError = false;
                                        this.onLoad = false;
                                    }
                                } else {
                                    this.medalistReportsData = "";
                                }
    
                            } else {
                                this.medalistReportsData = "";
                            }
                        })
                        .catch(() => {
                            //console.log(error);
                            //this.isLoading = false;
                            this.isError = true;
                            this.medalistReportsData = "";
                        }).finally(() => (this.isLoading = false));
                } catch (ex) {
                   // console.log(ex.message);
                }
            },
            getLeftPanelData(portfolioTag) {
                if (portfolioTag == "divSelect") {
                    this.portfolioURL = this.DividendSelectUrl;
                } else if (portfolioTag == "divDifferSelect") {
                    this.portfolioURL = this.DividendDeferUrl;
                } else if (portfolioTag == "incomeBell") {
                    this.portfolioURL = this.IncomeBellwethersURL;
                } else if (portfolioTag == "tortoisePort") {
                    this.portfolioURL = this.TortoiseURL;
                } else if (portfolioTag == "harePort") {
                    this.portfolioURL = this.HareURL;
                } else if (portfolioTag == "tortHarePort") {
                    this.portfolioURL = this.TortoiseAndHareWatchlistURL;
                } else if (portfolioTag == "equityPort") {
                    this.portfolioURL = this.EquityWatchlistURL;
                } else if (portfolioTag == "bondPort") {
                    this.portfolioURL = this.BondWatchlistURL;
                } else if (
                    portfolioTag == "Basic" ||
                    portfolioTag == "Defensive" ||
                    portfolioTag == "Factor" ||
                    portfolioTag == "Income" ||
                    portfolioTag == "ESG"
                ) {
                    this.portfolioURL = this.ModelPortfolioURL;
                } else {
                    this.portfolioURL = null;
                }
                try {
                    Vue.axios
                        .get(this.portfolioURL)
                        .then((response) => {
                            if (response) {
                                if (response.status == 200) {
                                    if (response.data) {
                                        if (
                                            this.CurrentProduct.toUpperCase() == "MEI" ||
                                            this.CurrentProduct.toUpperCase() == "ETF"
                                        ) {
                                            if (
                                                portfolioTag == "Basic" ||
                                                portfolioTag == "Defensive" ||
                                                portfolioTag == "Factor" ||
                                                portfolioTag == "Income" ||
                                                portfolioTag == "ESG"
                                            ) {
                                                if (this.leftPanelData.length > 0) {
                                                    this.leftPanelData.length = 0;
                                                }
                                                for (let i = 0; i < response.data.length; i++) {
                                                    if (response.data[i].PortfolioName == portfolioTag) {
                                                        this.leftPanelData.push({
                                                            HoldingName: response.data[i].Name.replace("&amp;", "&").replace("Default Cash", "Cash").replace("&#39;", "'").replace('?', '®'),
                                                            SecId: response.data[i].SecId,
                                                            Ticker: response.data[i].Ticker,
                                                            MorningstarRating: response.data[i].MorningstarRating,
                                                            isActive: false,
                                                        });
                                                    }
                                                }
                                            } else {
                                                if (this.leftPanelData.length > 0) {
                                                    this.leftPanelData.length = 0;
                                                }
                                                for (let i = 0; i < response.data.length; i++) {
                                                        this.leftPanelData.push({
                                                            HoldingName: response.data[i].Name.replace("&amp;", "&").replace("Default Cash", "Cash").replace("&#39;", "'").replace('?', '®'),
                                                            SecId: response.data[i].SecId,
                                                            Ticker: response.data[i].Ticker,
                                                            MorningstarRating: response.data[i].MorningstarRating,
                                                            isActive: false,
                                                        });
                                                }
                                            }
                                        } else {
                                            if (this.leftPanelData.length > 0) {
                                                this.leftPanelData.length = 0;
                                            }
                                            for (let i = 0; i < response.data.length; i++) {
                                                if(response.data[i].StandardName && (response.data[i].StandardName != "Default Cash" && response.data[i].StandardName != "Cash")){
                                                    this.leftPanelData.push({
                                                        HoldingName: response.data[i].StandardName.replace("&amp;", "&").replace("Default Cash", "Cash").replace("&#39;", "'").replace('?', '®'),
                                                        SecId: response.data[i].SecId,
                                                        Ticker: response.data[i].Symbol,
                                                        MorningstarRating: response.data[i].MorningstarRating,
                                                        isActive: false,
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } catch (ex) {
                    console.log(ex.message);
                }
            },
            changeReport(report) {
                this.getMedalistReport(report.SecId);
                this.analystLeftPanelPopOVer = false;
                this.$router.replace({ name: 'analyst', params: { ticker: report.Ticker } })
                        .catch(err => {
                          if (
                            err.name !== 'NavigationDuplicated' &&
                            !err.message.includes('Avoided redundant navigation to current location')
                          ) {
                            console.log(err);
                          }
                        });
            },
        },
        watch: {
        isMobileContentState(){
          this.isMobileContentToggle = this.isMobileContentState;
        },
       
      },
    };
    </script>
    
        
    <style scoped>
    .analyst-report-section {
        overflow-x: hidden;
        max-width: 1280px;
        margin: 0 auto;
    }
    
    .product-analyst-report-name {
        margin-top: 16px;
        /*margin-left: 123px;*/
        width: 613px;
        height: 45px;
    }
    
    .analyst-reports-left-panel {
        margin-top: 31px;
        /*margin-left: 123px;*/
        margin-bottom: 40px;
    }
    
    .analyst-reports-responsive-left-panel {
        display: none;
    }
    
    @media only screen and (max-width:1400px) {
        .analyst-report-section {
            width: 90%;
        }
    }
    
    @media only screen and (min-width:320px) and (max-width:1000px) {
        .analyst-reports-left-panel {
            display: none;
        }
    
        .product-analyst-report-name {
            display: none;
        }
    
        .analyst-reports-responsive-left-panel {
            display: block;
        }
    
        .analyst-report-section {
            margin-top: 15px;
        }
    
        .analyst-responsive-left-panel-popover {
            max-height: 70vh;
            overflow: hidden;
            overflow-y: scroll;
        }
    
        ::-webkit-scrollbar {
            height: 6px;
            width: 6px;
            overflow-x: overlay;
        }
    
        ::-webkit-scrollbar-thumb {
            background: rgba(171, 171, 171, 0.3);
            border-radius: 6px;
        }
    }
    
    @media only screen and (min-width:600px) and (max-width:1000px) {
        .analyst-report-section {
            margin-top: 30px;
        }
    }
    
    @media only screen and (min-width:1001px) and (max-width:1200px) {
        .analyst-report-section {
            margin-top: 0px;
        }
        .analyst-reports-right-panel{
            flex-basis: 72%;
        }
    
    }
    </style><style>
    .analyst-responsive-left-panel-button:focus {
        box-shadow: none !important;
        outline: none !important;
    }
    </style>