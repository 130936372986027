<template>
<div class="combine-video-main">
    <div v-if="isResponsiveToggle">
        <div v-if="isLoading">
            <mds-loader size="small" aria-label="Small Loader"></mds-loader>
        </div>
        <div v-else class="combine-video-mobile-main-container">
            <div class="Combine-mobile-featured-videos-heading">Featured Videos</div>
            <mds-layout-grid class="combine-video-mobile-container-grid" gutters="none">
                <mds-row class="combine-video-mobile-container-grid-row">
                    <mds-col v-for="video in videos" :key="video.ref" :cols="12" :cols-at-m="6" :cols-at-s="6" class="combine-video-mobile-container-grid-col">
                        <div class="combine-mobile-video-player-main-container">
                            <div class="combine-mobile-video-player-container">
                                <div class="combine-video-watch-button">
                                    <mds-button variation="primary" @click="onClick(video)" :id="video.buttonWatchId">
                                        <span style="position: absolute; top: 18%">
                                            <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                                        </span>
                                        <span style="margin-left: 20px">Watch</span>
                                    </mds-button>
                                </div>
                                <video crossorigin="anonymous"  :ref="video.ref" :src="video.VideoURL" type="video/mp4" preload="auto" height="203" data-setup="{}" v-bind:poster="video.ImageThumb" :id="video.VideoId">
                                    <track kind="subtitles" label="English" :src="video.closedCaptions" srclang="en" default>
                                </video>
                            </div>
                            <div class="combine-mobile-video-details-container">
                                <div class="combine-mobile-video-title">{{video.Title}}</div>
                                <div class="combine-mobile-video-description">{{video.VideoDescription}}</div>
                                <div class="combine-video-publish-date">{{video.videoPublishDate}}</div>
                            </div>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
            <div class="combine-more-videos-link-container">
                <router-link target="_blank" class="combine-videos-more-videos-link" :to="{ name: 'videos',params: { currentproduct: 'Common'}}">
                    More Videos
                    <span class="combine-video-more-videos-icon">
                        <mds-icon name="angle-right" size="small"></mds-icon>
                    </span>
                </router-link>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="combine-videos-heading-and-link-container">
            <div class="combine-videos-features-text">
                <span> Featured Videos</span>
            </div>
            <div class="combine-videos-more-videos">
                <router-link target="_blank" :to="{ name: 'videos',params: { currentproduct: 'Common'}}">
                    More Videos <mds-icon name="angle-right" size="small" class="combine-video-icon"></mds-icon>
                </router-link>
            </div>
        
        </div>
        <div class="combine-video-loader">
            <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
        </div>
        <div class="combine-video-container" v-if="isDisplay">
            <div class="combine-video-left-container">
                <div class="combine-video-active-video">
                    <div class="combine-video-active-video-button">
                        <mds-button variation="primary" id="DefaltVideoButton" style="display:none" @click="FirstVideoClick()">
                            <span style="position: absolute; top: 18%">
                                <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                            </span>
                            <span style="margin-left: 20px">Watch</span>
                        </mds-button>
                    </div>
                    <video class="combine-video-active-video-size" crossorigin="anonymous"  :ref="ref" type="video/mp4" preload="auto" data-setup="{}" v-bind:poster="ImageThumb" :src="VideoURL" :id="VideoId">
                        <track kind="subtitles" label="English" :src="closedCaptions" srclang="en" default>
                    </video>
                </div>
                <div class="combine-video-active-video-details">
                    <div class="combine-video-active-title">{{activeTitle}}</div>
                    <div class="combine-video-active-description">{{activeDescription}}</div>
                    <div class="combine-video-active-publish-date">{{videoPublishDate}}</div>
                </div>
            </div>
            <div class="combine-video-right-container">
                <div v-for="video in videos" :key="video.ref" :id="video.VideoClass" class="combine-video-right-image-and-description-container" @click="onVideoClick(video.buttonWatchId,video.ImageThumb,video.VideoURL,video.VideoId,video.Title,video.VideoDescription,video.ref,video.VideoClass,video.videoPublishDate,video.closedCaptions)">
                    <div class="combine-video-right-image-container">
                        <div class="combine-video-right-image-play-button">
                            <mds-button variation="primary" @click="onVideoClick(video.buttonWatchId,video.ImageThumb,video.VideoURL,video.VideoId,video.Title,video.VideoDescription,video.ref,video.VideoClass,video.videoPublishDate,video.closedCaptions)" :id="video.buttonWatchId">
                                <span class="combine-videos-right-panel-watch-button-icon">
                                    <mds-icon name="play-circle" size="small" class="play-circle"></mds-icon>
                                </span>
                                <span style="margin-left: 20px">Watch</span>
                            </mds-button>
                        </div>
                        <img :src="video.ImageThumb" class="combine-videos-right-img" />
                    </div>
                    <div class="combine-video-right-video-details-container">
                        <div class="combine-video-right-panel-title">{{video.Title}}</div>
                        <div class="combine-video-right-panel-description">{{video.VideoDescription}}</div>
                        <div class="combine-video-right-panel-publish-date">{{video.videoPublishDate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import MdsIcon from '@mds/icon';
import {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
} from "@mds/layout-grid";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import {
    MdsButton
} from "@mds/button";
import MdsLoader from "@mds/loader";
import { mapState } from "vuex";
Vue.use(VueAxios, axios);
export default {
    name: "combine-videos",
    components: {
        MdsIcon,
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        MdsButton,
        MdsLoader
    },
    data() {
        return {
            CombineVideoUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_CombineVideoUrl,
            videos: [],
            ImageThumb: "",
            VideoURL: "",
            VideoId: "",
            activeTitle: "",
            activeDescription: "",
            ref: "",
            buttonWatchId: "",
            isLoading:true,
            isDisplay:false,
            id:"",
            isResponsiveToggle:null,
            videoPublishDate:"",
            closedCaptions:null,
        }
    },
    mounted() {
        this.isResponsiveToggle=this.$store.state.responsive.isResponsive;
        this.getCombineVideo();
    },
    methods: {
        getCombineVideo() {
            try {
                var Url = this.CombineVideoUrl;
                Vue.axios.get(Url).then((response) => {
                        if (response) {
                            if (response.status) {
                                if (response.status == 200) {
                                    if (response.data) {
                                        var lstData = response.data;
                                        for (var i = 0; i < lstData.length; i++) {
                                            var obj = lstData[i];

                                            var ref = "";
                                            var VideoURL = "";
                                            var VideoDescription = "";
                                            var Title = "";
                                            var ImageThumb = "";
                                            var videoDate = "";
                                            var subtitles = "";

                                            if (obj.Ref_Id) {
                                                ref = obj.Ref_Id;
                                            }

                                            if (obj.VideoURL) {
                                                VideoURL = obj.VideoURL;
                                            }

                                            if (obj.VideoDescription) {
                                                VideoDescription = obj.VideoDescription;
                                            }
                                            if (obj.Title) {
                                                Title = obj.Title;
                                            }

                                            if (obj.ImageThumb) {
                                                ImageThumb = obj.ImageThumb;
                                            }
                                            
                                            if(obj.PublishDate)
                                            {
                                                videoDate = obj.PublishDate
                                            }
                                            if(obj.ClosedCaptions)
                                            {
                                                subtitles = obj.ClosedCaptions;
                                            }
                                            this.videos.push({
                                                ref: ref,
                                                VideoURL: VideoURL,
                                                ImageThumb: ImageThumb,
                                                Title: Title,
                                                VideoDescription: VideoDescription,
                                                buttonWatchId: "WatchId_" + ref,
                                                buttonStopId: "StopId_" + ref,
                                                VideoId: "VideoId_" + ref,
                                                VideoClass:"VideoCls_"+ref,
                                                videoPublishDate: videoDate,
                                                closedCaptions : subtitles,
                                            });
                                        }
                                    }
                                    if(!this.isResponsiveToggle)
                                    {
                                        this.getFirstVideoData();
                                    }                                   
                                }
                            }
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
            } catch (ex) {
                console.log(ex.message);
            }
        },
        onVideoClick(buttonWatchId, ImageThumb, VideoURL, VideoId, Title, VideoDescription, ref,VideoClass,videoDate,closedCaptions) {
            try {
                //console.log("buttonWatchId =" + buttonWatchId);
                document.getElementById("DefaltVideoButton").style.display = "none";
                this.ImageThumb = ImageThumb;
                this.VideoURL = VideoURL;
                this.VideoId = VideoId;
                this.activeTitle = Title;
                this.activeDescription = VideoDescription;
                this.ref = ref;
                this.buttonWatchId = buttonWatchId;
                this.id=VideoId;
                this.videoPublishDate = videoDate;
                this.closedCaptions = closedCaptions;

                for (var i = 0; i < this.videos.length; i++) {
                    var obj = this.videos[i];
                    if (obj.buttonWatchId) {
                        if (this.buttonWatchId == obj.buttonWatchId) {
                            document.getElementById(this.buttonWatchId).style.display = "none";
                            var element = document.getElementById(VideoClass);
                            element.classList.add("combine-overlay-effect");
                            
                        } else {
                            document.getElementById(obj.buttonWatchId).style.display = "block";
                            var element1 = document.getElementById(obj.VideoClass);
                            element1.classList.remove("combine-overlay-effect");
                        }
                    }
                }
                try{
                //const $video = this.VideoId;
                //var oVideoTag = document.getElementById(this.VideoId);
                //oVideoTag.play();
                var oVideoTag;
                setTimeout(() => {
                    oVideoTag=document.getElementById(this.id);
                    }, 500);

                setTimeout(() => {
                        oVideoTag.play();
                        oVideoTag.controls = "controls";
                }, 500);
                    
                }
                catch(ex){
                    console.log(ex.message);
                }

            } catch (ex) {
                console.log(ex.message);
            }
        },
        getFirstVideoData() {
            for (var l = 0; l < this.videos.length; l++) {
                var videoObj = this.videos[l];
                if (l == 0) {
                    this.ImageThumb = videoObj.ImageThumb;
                    this.VideoURL = videoObj.VideoURL;
                    this.VideoId = videoObj.VideoId;
                    this.activeTitle = videoObj.Title;
                    this.activeDescription = videoObj.VideoDescription;
                    this.ref = videoObj.ref;
                    this.buttonWatchId = videoObj.buttonWatchId;
                    this.id=videoObj.VideoId;
                    this.videoPublishDate = videoObj.videoPublishDate;
                    this.closedCaptions = videoObj.closedCaptions;
                    try {
                        //document.getElementById("WatchId_1").style.display = "none";
                        setTimeout(() => {
                            if (this.buttonWatchId && this.buttonWatchId !=null ) {
                                if(document.getElementById(this.buttonWatchId) != null && document.getElementById(this.buttonWatchId).style != null){
                                    document.getElementById(this.buttonWatchId).style.display = "none";
                                    document.getElementById("DefaltVideoButton").style.display = "block";
                                }
                            }
                        }, 2000);
                        setTimeout(() => {
                            if (videoObj.VideoClass && videoObj.VideoClass != null) {
                                var element = document.getElementById(videoObj.VideoClass);
                                if(element != null && element.classList != null){
                                    element.classList.add("combine-overlay-effect");
                                }
                            }
                        }, 2000);
                    } catch (ex) {
                        //console.log(ex.message);
                    }
                    break;
                }
            }
        },
        FirstVideoClick(){
           
            document.getElementById("DefaltVideoButton").style.display = "none";
            var oVideoTag;
            oVideoTag=document.getElementById(this.id);
                setTimeout(() => {
                        oVideoTag.play();
                        oVideoTag.controls = "controls";
                }, 500);
        },
        redirectVideosPage(){
            this.$router.push({ path: '/videos', params: { currentproduct: 'Common' } }).catch(err => {
                if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                ) {
                    console.log(err);
                }
            });
        },
        onClick({
            ref
        }) {
            this.videos.forEach((video) => {
                const $video = this.$refs[video.ref][0];
                var oVideoTag = document.getElementById("VideoId_" + video.ref);
                if (video.ref === ref) {
                    if ($video.paused) {
                        $video.play();
                        document.getElementById("WatchId_" + video.ref).style.display = "none";
                        setTimeout(function () {
                            oVideoTag.controls = "controls";
                        }, 100);
                    } else {
                        $video.pause();
                        document.getElementById(
                            "WatchId_" + video.ref
                        ).style.display = "block";
                        setTimeout(function () {
                            oVideoTag.controls = false;
                        }, 100);
                    }
                } else {
                    $video.pause();
                    document.getElementById("WatchId_" + video.ref).style.display =
                        "block";
                    setTimeout(function () {
                        oVideoTag.controls = false;
                    }, 100);
                }
            });
        },
    },    
    computed:mapState({
        isResponsive: state => state.responsive.isResponsive,
        isResponsiveAlias:'isResponsive',
        isResponsiveState(state){
        return state.responsive.isResponsive
        }
        
    }),
    watch:{
        isResponsiveState(){
        this.isResponsiveToggle = this.isResponsiveState;
            if(!this.isResponsiveToggle){
                this.getFirstVideoData();
            }
        }
    }
}
</script>

<style scoped>
/*=============== Desktop View ====================*/
.combine-video-main {
    height:auto;
    margin:13px auto 0 auto;
}
.combine-overlay-effect {
    background-color: #F2F2F2;
    box-shadow: 5px 0px #000000;
}
.combine-videos-heading-and-link-container{
    display:flex;
}

.combine-videos-features-text {
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 18px;
    color: #1E1E1E;
}

.combine-videos-more-videos {
    width: 85px;
    text-decoration: none;
    margin-left: auto;
    order: 2;
}
.combine-videos-more-videos a{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5E5E5E;
    text-decoration: none;
}
.combine-videos-more-videos a:hover{
    cursor: pointer;
}
.combine-video-container{
    width:100%;
    margin-top:42px;
    display:flex;
    gap:32px;
}
.combine-video-left-container{
    width:60%;
    max-width:747px;
}
.combine-video-right-container{
    width:40%;
    max-width:501px;
    margin-left:auto;
    margin-right:5px;
}
.combine-video-active-video{
    width:100%;
    position:relative;
    z-index:1;
}
.combine-video-active-video-button{
    position:absolute;
    bottom:13px;
    left:13px;
    right:0%;
    margin:auto;
    cursor:pointer;
    z-index:2;
}
.combine-video-active-video-button button{
    cursor:pointer;
}
.combine-video-active-video video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.combine-video-active-video-details{
    width:100%;
    height:100px;
    margin-top:23px;
}
.combine-video-active-title{
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    cursor:pointer;
}
.combine-video-active-description{
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 26px;
}
.combine-video-active-publish-date{
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    padding-top:10px;
}
.combine-video-right-image-and-description-container{
    width:100%;
    height:120px;
    max-height:120px;
    margin-bottom:15px;
    display:flex;
    
    cursor:pointer;
}
.combine-video-right-image-container{
    width:100%;
    max-width:214px;
    height:100%;
    position:relative;
}
.combine-video-right-image-play-button{
    position:absolute;
    bottom:13px;
    left:13px;
    right:0%;
    margin:auto;
}
.combine-videos-right-panel-watch-button-icon{
    position:absolute;
    top:18%;
}
.combine-video-right-video-details-container{
    width:100%;
    max-width:100%;
    height:100%;
    padding:15px;
    box-sizing: border-box;
}
.combine-videos-right-img{
    width:100%;
    height:100%;
    object-fit:cover;
}
.combine-video-right-panel-title{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.combine-video-right-panel-description{
    margin-top:4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*-webkit-line-clamp: 3;*/
    -webkit-box-orient: vertical;
}
.combine-video-right-panel-publish-date{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color:#5E5E5E;
    margin-top:8px;
}

/*==============================Responsive Common View ===============================================*/
@media only screen and (min-width:320px) and (max-width:900px){
    .combine-video-main{
        width:90%;
    }
    .combine-video-mobile-main-container{
        width:100%;
    }
    .Combine-mobile-featured-videos-heading{
        font-style: normal;
        font-weight: 300;
        font-size: 23px;
        line-height: 27px;
        color:#1E1E1E;
    }
    .combine-video-mobile-container-grid{
        width:100%;
        margin-top:24px;
    }
    .combine-video-mobile-container-grid-row{
        margin:0;
        padding:0;
        row-gap:38px;
    }
    .combine-video-mobile-container-grid-col{
        margin:0;
        padding:0 !important;
    }
    .combine-mobile-video-player-main-container {
        width: 100%;
    }
    .combine-mobile-video-player-container{
        position:relative;
        width:100%;
        z-index:1;
    }
    .combine-mobile-video-player-container video{
        width:100%;
        height:100%;
        object-fit:cover;
    }
    .combine-video-watch-button {
        position: absolute;
        bottom:13px;
        left:13px;
        right:0%;
        margin:auto;
        z-index:2;
    }

    .combine-video-watch-button button{
        cursor:pointer;
    }
    .combine-mobile-video-details-container{
        width:100%;
        margin-top:18px;
    }
    .combine-mobile-video-title{
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 23px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .combine-mobile-video-description{
        margin-top:8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .combine-video-publish-date{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #5E5E5E;
        margin-top:8px;
    }
    .combine-more-videos-link-container{
        margin-top:10px;
    }
    .combine-more-videos-link-container a{
        text-decoration:none;
        color:#5E5E5E;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
    }
}

/*==============================Tablet View===============================================*/
@media only screen and (min-width:600px) and (max-width:900px){
    .combine-video-mobile-container-grid-col{
        max-width:47%;
    }
    .combine-video-mobile-container-grid-row{
        column-gap:6%;
    }
    .combine-mobile-video-player-container{
        height:203px;
    }
}
@media only screen and (min-width:901px) and (max-width:1290px){
    .combine-video-main {
        width:90%;
    }
}
</style>
<style>
.combine-video-icon > svg {
    margin-top:3px;
}
@media only screen and (max-width: 900px){
    .combine-video-more-videos-icon > span > svg{
        margin-top:3px;
    }
}
</style>
