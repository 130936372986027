<template>
<div class="mei-model-portfolio-container">
    <div>
        <portfolio-performance></portfolio-performance>
        <portfolio-details></portfolio-details>
        <div>
            <model-portfolio-footer></model-portfolio-footer>
        </div>
    </div>
    <div>
    </div>
</div>
</template>

<script>
import PortfolioPerformance from "./PortfolioPerformance.vue";
import PortfolioDetails from "./PortfolioDetails.vue";
import ModelPortfolioFooter from "./ModelPortfolioFooter.vue";
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";

Vue.use(VueAxios, axios);

export default {
    name: "model-portfolios",
    components: {
        PortfolioPerformance,
        PortfolioDetails,
        ModelPortfolioFooter,
    },
    mounted () {
      window.scrollTo(0, 0);
    },
};
</script>

<style scoped>
body {
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
}
/* .mei-model-portfolio-container {
    width: 1280px;
    margin: 0 auto;
} */

@media only screen and (min-width: 320px) and (max-width: 1415px) { 

    /* .mei-model-portfolio-container {
        width: 90%;
        margin-left:5%;
        margin-right:5%;
    } */
}
</style>
