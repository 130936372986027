<template>
    <div class="banner-cover-image-main-container">
        <mds-loader class="banner-cover-image-loader" v-if="isLoadingImage" aria-label="On Dark Loader"></mds-loader>
        <div>
          <transition-group name="fade" tag="div">
            <div class="landing-image-top" v-for="i in [currentIndex]" :key="i">
              <img v-show="!isLoadingImage" class="landing-image-top-part" :src="currentImg" />
            </div>
          </transition-group>
        </div>
        
      </div>
  </template>
  <script>
  import Vue from "vue";
  import axios from "axios";
  import VueAxios from "vue-axios";
  Vue.use(VueAxios, axios);
  import MdsLoader from "@mds/loader";
  export default {
    name: "banner-cover-images",
    components: {
      MdsLoader,
    },
    data() {
      return {
        latestCoverImagesUrl:
          process.env.VUE_APP_BackEndAPI +
          process.env.VUE_APP_LatestCoverImagesUrl,
        latestCoverImages: [],
        isLoadingImage: true,
        timer: null,
        currentIndex: 0,
        isImagesLoad:false
      };
    },
    mounted() {
      this.startSlide();
    },
    methods: {
      startSlide() {
        this.timer = setInterval(this.next, 6000);
      },
      next: function () {
        this.currentIndex += 1;
      },
      getLatestCoverImages() {
        try {
          var URL = this.latestCoverImagesUrl;
          Vue.axios
            .get(URL)
            .then((response) => {
              if (response) {
                if (response.status == 200) {
                  if (response.data) {
                    this.isLoadingImage = false;
                    var imageData = response.data;
                    for (var i = 0; i < imageData.length; i++) {
                      this.latestCoverImages[i] = imageData[i].ImageId;
                    }
                  }
                }
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => (this.isLoadingImage = false,this.isImagesLoad=true));
        } catch (ex) {
          console.log(ex.message);
        }
      },
    },
    computed: {
      currentImg(){
        if(!this.isImagesLoad){
          this.getLatestCoverImages();
        }
        return this.latestCoverImages[
          Math.abs(this.currentIndex) % this.latestCoverImages.length
        ];
      },
    },
  };
  </script>
  
  <style scoped>
  .banner-cover-image-main-container{
    width:100%;
    height:100%;
  }
  .landing-image-top-part {
    width: 350px;
    height: 456px;
  }

  .fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  position: absolute;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  opacity: 0;
}
.banner-cover-image-loader {
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 360px) and (max-width:1280px){
  .landing-image-top-part{
    width:300px;
    height:400px;
  }
}
@media only screen and (max-width:738px){
  .landing-image-top-part{
    width:230px;
    height:300px;
  }
}
@media only screen and (max-width:525px){
  .landing-image-top-part{
    width:200px;
    height:280px;
  }
}
@media only screen and (max-width: 480px)
    and (orientation: portrait) {
    .landing-image-top-part{
      width:144px;
      height:187px;
    }
}
  </style>
  