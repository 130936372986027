<template lang="html">
  <div class="blog-menu">
    <mds-button-container class="commentary-blog-menu-action-button">
      <mds-button variation="secondary" :icon="buttons.icon" type="button" @click="createPost($event)" :id="buttons.operation" v-for="(buttons, index) in generateButtons" :key="index"> {{ buttons.text }}</mds-button>
    </mds-button-container>
    <mds-dialog v-model="deleteConfirmationToggle" action-required title="Are you sure, you want to delete this post ?" class="article-delete-confirmation">
      <p>{{ postSubject }}</p>
      <mds-input label="Confirm" placeholder="delete" microcopy-above="To delete the post, type delete." v-model="deleteArticleConfirmationText" :error-text="['Please enter delete']" :error="deleteErrorMessage"  @input="deleteArticleButtonState()" class="delete-post-input"></mds-input>
        <template #mds-dialog-actions-right>
            <mds-button-container right-aligned>
                <mds-button type="button" variation="secondary" @click="deleteArticleReset('cancel')"> Cancel </mds-button>
                <mds-button type="button" variation="primary" @click="deleteArticleAction('delete')" :disabled="deletePostbuttonDisabled"> Yes, Proceed </mds-button>
            </mds-button-container>
        </template>
        <mds-alert :variation="deleteArticleAlertVariationType" tinted mds-alert-dismissed :text="deleteArticleMessage" v-if="displayDeleteArticleAlert">
        </mds-alert>
    </mds-dialog>

    <send-post :send-post-toggle-state="sendPostToggle" :send-post-id="postId" @sendPostToggleUpdated="changeStateOfSendPostToggle"></send-post>
  </div>
</template>

<script lang="js">
  import SendPost from "@/components/Common/Commentary/Blog/SendPost";
  import { MdsButton, MdsButtonContainer } from '@mds/button'
  import MdsDialog from '@mds/dialog';
  import MdsInput from '@mds/input';
  import MdsAlert from '@mds/alert';
  import Vue from "vue";
  import axios from "axios";
  import VueAxios from "vue-axios";
  Vue.use(VueAxios, axios);
  export default  {
    name: 'blog-menu',
    props:{
      postSubject: {
        type: String,
        required: true
      },
      postId: {
        type: Number,
        required: true
      }
    },
    components:{
      MdsButton,
      MdsButtonContainer,
      MdsDialog,
      MdsInput,
      MdsAlert,
      SendPost,
    },
    data () {
      return {
        generateButtons:[
          {
            icon:'create',
            operation:'create-post',
            text:'New Post',
          },
          {
            icon:'undo',
            operation:'edit-post',
            text:'Edit Post',
          },
          {
            icon:'trash',
            operation:'delete-post',
            text:'Delete Post',
          },
          {
            icon:'share',
            operation:'send-post',
            text:'Send Post',
          },
          {
            icon:'eye',
            operation:'draft-post',
            text:'Drafts',
          },

        ],
        deleteConfirmationToggle:false,
        deleteArticleConfirmationText:null,
        deleteErrorMessage:false,
        deletePostbuttonDisabled:true,
        deleteArticleURL:process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DeleteArticle,
        displayDeleteArticleAlert:false,
        deleteArticleAlertVariationType:null,
        deleteArticleMessage:null,
        sendPostToggle:false,
      }
    },
    methods: {
      createPost: function(event){
        var selectdOperation = event.currentTarget.id;
        if(selectdOperation == 'create-post'){
          this.$router.push({ name: 'newPost' }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
        }
        else if(selectdOperation == 'edit-post')
        {
          this.$router.push({ name: 'updatePost', params: { pid: this.postId } }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
        }
        else if(selectdOperation == 'delete-post')
        {
          this.deleteConfirmationToggle=true
        }
        else if(selectdOperation == 'draft-post')
        {
          this.$router.push({ name: 'draftsPost' }).catch(err => {
            if (
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
            ) {
              console.log(err);
            }
          });
        }
        else
        {
          this.sendPostToggle = true;
        }
      },
      deleteArticleAction(opsType){
        if(!this.deleteArticleConfirmationText || this.deleteArticleConfirmationText !='delete')
        {
          this.deleteErrorMessage=true;
        }
        else
        {
          const pid = this.postId;
          const deletePostURL = this.deleteArticleURL + pid;
          this.displayDeleteArticleAlert = true;
          this.deletePostbuttonDisabled = true;
          this.deleteArticleAlertVariationType="informational";
          this.deleteArticleMessage = "Please wait while the record is getting deleted.";
          try{
            Vue.axios
            .delete(deletePostURL)
            .then((response) =>{
              if(response){
                if(response.status == 200){
                  this.displayDeleteArticleAlert = true;
                  this.deleteArticleAlertVariationType="success";
                  this.deleteArticleMessage = "Post deleted successfully.";
                  var self = this;
                  setTimeout(function () { self.deleteArticleReset(opsType) } , 2000)
                }
              }
            })
            .catch((error) => {
              this.displayDeleteArticleAlert = true;
              this.deletePostbuttonDisabled = false;
              this.deleteArticleAlertVariationType="error";
              this.deleteArticleMessage = "Unable to delete the post, try again later.";
              console.log(error);
            })
          }catch (ex) {
            console.log(ex.message);
          }
          this.deleteErrorMessage=false;
        }
      },
      deleteArticleButtonState(){
        if(!this.deleteArticleConfirmationText)
        {
          this.deletePostbuttonDisabled=true;
        }
        else
        {
          this.deletePostbuttonDisabled=false;
        }
      },
      deleteArticleReset(opsType){
        this.deletePostbuttonDisabled=true;
        this.deleteArticleConfirmationText = "";
        this.deleteErrorMessage = false;
        this.deleteConfirmationToggle=!this.deleteConfirmationToggle;
        this.displayDeleteArticleAlert=false;
        if(opsType === 'delete')
        {
          this.$router.push({ name: 'commentary' }).catch(err => {
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(err);
          }
        });
          location.reload();
        }
      },
      changeStateOfSendPostToggle(toggleState){
        this.sendPostToggle = toggleState;
      }
    },
}


</script>

<style scoped>
  .delete-post-input{
    margin-bottom:5px;
    margin-top:10px;
  }
  @media only screen and (min-width:320px) and (max-width:650px){
    .commentary-blog-menu-action-button{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      row-gap: 10px;
      column-gap: 10px;
    }
    .commentary-blog-menu-action-button.mds-button-container .mds-button + .mds-button{
      margin-left:0px !important;
    }
    #delete-post{
      margin-left:0px;
    }
  }
</style>
<style>
  .article-delete-confirmation .mds-dialog {
    max-width: 500px!important;
  }
  .article-delete-confirmation .mds-input{
    box-shadow: none!important;
    border-bottom: 1px solid #808080!important;
  }
</style>