<template>
  <div>
    <div class="mei-model-portfolio-performance-container">
      <div class="loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
      </div>
      <div class="mei-portfolio-performance-header-text">
        <div>
          <h1 class="mei-portfolio-performance-title">Model Portfolio Performance</h1>
        </div>
      </div>
      <div v-if="isDisplay" class="mei-portfolio-performance-table">
        <mds-table :fixed-first-col="addScroll" class="mei-portfolio-performance-data-table">
          <mds-thead :nested-headers="1">
            <template v-slot:mds-thead-group-1>
              <mds-th colspan="1" class="mei-portfolio-performance-portfolio-name" >
                <div style="width: auto;">
                  <div class="mei-portfolio-performance-portfolio-type">Portfolio Type</div>
                  <div class="mei-portfolio-performance-border-bottom"></div>
                </div>
              </mds-th>
              <mds-th colspan="4" style="width: 260px">
                <div class="mei-portfolio-performance-return">Return %<sup
                    class="mei-portfolio-performance-superscript">1</sup></div>
                <span class="mei-portfolio-performance-border-bottom"></span>
              </mds-th>
              <mds-th colspan="4" style="width: 260px">
                <div class="mei-portfolio-performance-portfolio-sharpe">Sharpe Ratio</div>
                <span class="mei-portfolio-performance-border-bottom"></span>
              </mds-th>
              <mds-th colspan="4" style="width: 260px">
                <div class="mei-portfolio-performance-portfolio-standard">Standard Deviation %</div>
                <span class="mei-portfolio-performance-border-bottom"></span>
              </mds-th>
            </template>
          </mds-thead>

          <mds-thead :fixed-header="addScroll" >
            <mds-th v-for="(header, index) in headers" :key="index" :right-aligned="header.align === 'right'">
              <div :style="header.style" v-html="header.text"></div>
            </mds-th>
          </mds-thead>
          <mds-tbody>
            <mds-tr v-for="(row, index) in rows" :key="index">
              <mds-td>
                <div v-if="index == 0">
                  <p class="mei-portfolio-performance-table-basic">
                    <img src="../../assets/Images/mei/basic.svg" class="mei-port-img" />
                    {{ row.PortfolioName }} Portfolio
                  </p>
                </div>
                <div v-if="index == 1">
                  <p class="mei-portfolio-performance-table-all">
                    <img src="../../assets/Images/mei/defensive.svg" class="mei-port-img" />
                    {{ row.PortfolioName }} Portfolio
                  </p>
                </div>
                <div v-if="index == 2">
                  <p class="mei-portfolio-performance-table-all">
                    <img src="../../assets/Images/mei/factor.svg" class="mei-port-img" />
                    {{ row.PortfolioName }} Portfolio
                  </p>
                </div>
                <div v-if="index == 3">
                  <p class="mei-portfolio-performance-table-all">
                    <img src="../../assets/Images/mei/income.svg" class="mei-port-img" />
                    {{ row.PortfolioName }} Portfolio
                  </p>
                </div>
                <div v-if="index == 4">
                  <p class="mei-portfolio-performance-table-ESG">
                    <img src="../../assets/Images/mei/esg.svg" class="mei-port-img" />
                    {{ row.PortfolioName }} Portfolio
                  </p>
                </div>
              </mds-td>
              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.Return1Yr == 0 ? '&mdash;' : row.Return1Yr }}</span>
                  </p>
                </div>
              </mds-td>
              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.Return3Yr == 0 ? '&mdash;' : row.Return3Yr }}</span>
                  </p>
                </div>
              </mds-td>
              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.Return5Yr == 0 ? '&mdash;' : row.Return5Yr }}</span>
                  </p>
                </div>
              </mds-td>
              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.Return10Yr == 0 ? '&mdash;' : row.Return10Yr }}</span>
                  </p>
                </div>
              </mds-td>
              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.SharpeRatio1Yr == 0 ? '&mdash;' : row.SharpeRatio1Yr }}</span>
                  </p>
                </div>
              </mds-td>
              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.SharpeRatio3Yr == 0 ? '&mdash;' : row.SharpeRatio3Yr }}</span>
                  </p>
                </div>
              </mds-td>
              <mds-td>

                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.SharpeRatio5Yr == 0 ? '&mdash;' : row.SharpeRatio5Yr }}</span>
                  </p>
                </div>
              </mds-td>
              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.SharpeRatio10Yr == 0 ? '&mdash;' : row.SharpeRatio10Yr }}</span>
                  </p>
                </div>

              </mds-td>

              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.StdDev1Yr == 0 ? '&mdash;' : row.StdDev1Yr }}</span>
                  </p>
                </div>
              </mds-td>
              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.StdDev3Yr == 0 ? '&mdash;' : row.StdDev3Yr }}</span>
                  </p>
                </div>
              </mds-td>
              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.StdDev5Yr == 0 ? '&mdash;' : row.StdDev5Yr }}</span>
                  </p>
                </div>

              </mds-td>
              <mds-td>
                <div>
                  <p :class="index == 0 ? 'mei-portfolio-performance-first-rowdata' : 'mei-portfolio-performance-rowdata'">
                    <span>{{ row.StdDev10Yr == 0 ? '&mdash;' : row.StdDev10Yr }}</span>
                  </p>
                </div>

              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>
      </div>
    </div>
  </div>
</template>
  
<script>
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import MdsLoader from "@mds/loader";
import {
  mapState
} from "vuex";

Vue.use(VueAxios, axios);

import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd,
} from "@mds/data-table";

export default {
  name: "portfolio-performance-responsive",
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsLoader,
  },
  data() {
    return {
      isDividentDeferred: true,
      isRearchDisplay: false,
      addScroll: false,
      headers: [
        {
          text: "",
          fieldName: "PortfolioName",
          style: {
            "margin-left": "5px",
            "width": "200px",
          },
        },
        {
          text: "1 Year",
          fieldName: "Return1Yr",
          style: {
            "width": "65px",
            "margin-left": "20px",
          },
        },
        {
          text: "3 Year",
          fieldName: "Return3Yr",
          style: {
            "width": "65px",
            "margin-left": "20px",
          },
        },
        {
          text: "5 Year",
          fieldName: "Return5Yr",
          style: {
            "width": "65px",
            "margin-left": "20px",
          },
        },
        {
          text: "10 Year",
          fieldName: "Return10Yr",
          style: {
            "width": "65px",
            "margin-left": "10px",
          },
        },
        {
          text: "1 Year",
          fieldName: "SharpeRatio1Yr",
          style: {
            "width": "65px",
            "margin-left": "20px",
          },
        },
        {
          text: "3 Year",
          fieldName: "SharpeRatio3Yr",
          style: {
            "width": "65px",
            "margin-left": "20px",
          },
        },
        {
          text: "5 Year",
          fieldName: "SharpeRatio5Yr",
          style: {
            "width": "65px",
            "margin-left": "20px",
          },
        },
        {
          text: "10 Year",
          fieldName: "SharpeRatio10Yr",
          style: {
            "width": "65px",
            "margin-left": "10px",
          },
        },
        {
          text: "1 Year",
          fieldName: "StdDev1Yr",
          style: {
            "width": "65px",
            "margin-left": "20px",
          },
        },
        {
          text: "3 Year",
          fieldName: "StdDev3Yr",
          style: {
            "width": "65px",
            "margin-left": "20px",
          },
        },
        {
          text: "5 Year",
          fieldName: "StdDev5Yr",
          style: {
            "width": "65px",
            "margin-left": "20px",
          },
        },
        {
          text: "10 Year",
          fieldName: "StdDev10Yr",
          style: {
            "width": "65px",
            "padding-right": "0px",
            "margin-left": "10px",
          },
        },
      ],

      rows: [],
      PortfoliosPerformanceURL: 
       process.env.VUE_APP_BackEndAPI +
        process.env.VUE_APP_ModelPortfoliosPerformanceURL,
      isLoading: true,
      isDisplay: false,
    };
  },
  methods: {
    GetPortfoliosPerformanceData() {
      try {
        Vue.axios
          .get(this.PortfoliosPerformanceURL)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.rows = response.data;
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
      } catch (ex) {
        console.log(ex.message);
      }
    },
  },
  mounted() {
    this.addScroll = this.$store.state.responsive.isModelPortfolioResponsive;
    this.GetPortfoliosPerformanceData();
  },
  computed: mapState({
    isModelPortfolioResponsive: state => state.responsive.isModelPortfolioResponsive,
    isModelPortfolioResponsiveAlias: 'isModelPortfolioResponsive',
    isModelPortfolioResponsiveState(state) {
      return state.responsive.isModelPortfolioResponsive
    }

  }),
  watch: {
    isModelPortfolioResponsiveState() {
      this.addScroll = this.isModelPortfolioResponsiveState;
    }
  }
};
</script>
  
<style lang="scss" scoped>
@import "@mds/constants";

body {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.mei-model-portfolio-performance-container {
    width: 1280px;
    margin: 0 auto;
} 

@media only screen and (min-width: 320px) and (max-width: 1415px) { 

.mei-model-portfolio-performance-container {
    width: 90%;
    margin-left:5%;
    margin-right:5%;
} 
}


.mei-portfolio-performance-title {
  font-weight: 700;
  width: 613px;
  height: 45px;
  font-size: 32px;
  margin-top: 48px;
  margin-bottom: 20px;
  line-height: 36px;
  color: #1e1e1e;
}

.mei-portfolio-performance-border-bottom {
  border-bottom: 1px solid #808080;
  margin-top: 4px;
  width: 95%;
  position: absolute;
  top: 23px;
}

.mei-port-img {
  margin-right: 10px;
}

.mei-portfolio-performance-portfolio-type {
  font-size: 16px;
  margin-top: 0px;
  width: 200px;
  height: 21px;
}

.mei-portfolio-performance-portfolio-name{
  width:200px;
}

.mei-portfolio-performance-return {
  font-size: 16px;
  margin-bottom: 5px;
  width: 260px;
  height: 21px;
  margin-left:5px;
}

.mei-portfolio-performance-portfolio-sharpe {
  font-size: 16px;
  margin-top: 0px;
  width: 260px;
  height: 21px;
  margin-left:5px;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.mei-portfolio-performance-portfolio-standard {
  font-size: 16px;
  margin-top: 0px;
  width: 260px;
  height: 21px;
  margin-left:5px;
}

.mei-portfolio-performance-table-all {
  margin-bottom: 11px;
  margin-top: 11px;

}

.mei-portfolio-performance-table-basic {
  margin-bottom: 11px;
}

.mei-portfolio-performance-table-ESG {
  margin-top: 12px;
  margin-bottom: -6px;
}

.mei-portfolio-performance-rowdata {
  margin-top: 8px;
  margin-left: 20px,
}

.mei-portfolio-performance-first-rowdata { 
  margin-left: 20px
}

.mei-portfolio-performance-superscript {
  font-size: 12px;
}

.mei-portfolio-performance-table {
  width: 100%;
  height: 90%;
  margin-top: 20px;

}

.mei-portfolio-performance-data-table {
  width: 99.5%;
  box-shadow: none;
}

.mei-portfolio-performance-data-table.mds-table__scroller .mds-table.mds-table--fixed-column {
  th {
    position: sticky;
    top: 0;
  }

  th:nth-child(1) {
    z-index: $mds-z-index-low + 1;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .mei-portfolio-performance-header-text {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 428px) { 

  .mei-portfolio-performance-title {
    font-size: 28px;
  }

  .mei-portfolio-performance-portfolio-name{
    width:160px;
  }

  .mei-portfolio-performance-portfolio-type{
    width:160px;
  }
}
</style>
  