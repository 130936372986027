
<template>
  <div>
    <div class="mei-equity-watchlist-container">
      <div class="mei-equity-watchlist-table-loader">
        <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
      </div>
      <div class="mei-equity-watchlist-header-text">
        <div class="mei-equity-watchlist-middle-content">
        <div class="mei-equity-watchlist-title">Equity Watchlist</div>
        <div class="mei-equity-watchlist-download-holding">
          <mds-button
            variation="flat"
            size="small"
            icon="document"
            @click="ExportExcel"
            class="mei-equity-watchlist-download-button"
          >
            Download data
          </mds-button>
        </div>
        </div>
        <div class="mei-equity-watchlist-analyst-text">
          Click on company name for latest Morningstar Analyst Report.
        </div>
        <div class="mei-equity-watchlist-analyst-text-sec">
          Click on ticker for additional ETF data from Morningstar.com.
        </div>
      </div>

      <div class="mei-equity-watchlist-table">
        <mds-table
          fixedFirstCol
          show-sortable
          class="mei-equity-watchlist-data-table"
        >
          <mds-thead :nested-headers="1">
            <template v-slot:mds-thead-group-1>
              <mds-th colspan="1" style="width: 65px"></mds-th>
              <mds-th colspan="1" style="width: 350px"></mds-th>
              <mds-th colspan="1" style="width: 200px"></mds-th>
              <mds-th colspan="1" style="width: 102px"></mds-th>
              <mds-th colspan="3" style="width: 300px"></mds-th>
              <mds-th colspan="2" style="width: 110px">
                <span style="" class="mei-equity-watchlist-nestedheader"
                  >Nav Return %</span
                >
                <span class="mei-equity-watchlist-borderbottom"></span>
              </mds-th>

              <mds-th colspan="3" style="width: 180px">
                <span                  
                  class="mei-equity-watchlist-nestedheader-annualized"
                >
                  Nav Return % Annualized</span
                >
                <span
                  class="mei-equity-watchlist-borderbottom-annualized"
                ></span>
              </mds-th>

              <mds-th colspan="1" style="width: 80px"></mds-th>
              <mds-th colspan="1" style="width: 70px"></mds-th>
              <mds-th colspan="1" style="width: 80px"></mds-th>
              <mds-th colspan="1" style="width: 90px"></mds-th>
              <mds-th colspan="1" style="width: 60px"></mds-th>
              <mds-th colspan="1" style="width: 130px"></mds-th>
              <mds-th colspan="1" style="width: 70px"></mds-th>
              <mds-th colspan="1" style="width: 115px"></mds-th>
              <mds-th colspan="4" style="width: 250px"></mds-th>
              <mds-th colspan="1" style="width: 85px"></mds-th>
              <mds-th colspan="1" style="width: 100px"></mds-th>
              <mds-th colspan="1" style="width: 85px"></mds-th>
              <mds-th colspan="1" style="width: 75px"></mds-th>
              <mds-th colspan="1" style="width: 115px"></mds-th>
              <mds-th colspan="5" style="width: 360px"></mds-th>
              <mds-th colspan="1" style="width: 115px"></mds-th>
              <mds-th colspan="2" style="width: 205px"></mds-th>
            </template>
          </mds-thead>

          <mds-thead fixedHeader style="height: 36px">
            <mds-th
              v-for="(header, index) in headers"
              :key="index"
              :right-aligned="header.align === 'right'"
              :sortable="header.sortable"
              :sorted="header.sorted"
              :style="header.style"
              @mds-th-sort-changed="
                handleSortEvent(index, header.fieldName, $event)
              "
            >
              <span
                v-html="header.text"
                class="mei-equity-watchlist-table-header"
              ></span>
            </mds-th>
          </mds-thead>
          <mds-tbody v-if="isDisplay">
            <mds-tr v-for="(row, index) in rows" :key="index">
              <mds-td left-align style="padding-left: 5px">
                <div
                  style="padding-left: 20px"
                  v-if="
                    row.Ticker == '0' ||
                    row.Ticker == '' ||
                    row.Ticker == 'NaN' ||
                    row.Ticker == '&mdash;' ||
                    row.Ticker == null ||
                    row.Ticker == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else @click="redirectToStockQuote(row.ExchangeId,row.Ticker)" class="mei-equity-watchlist-table-ticker">
                  {{ row.Ticker }}
                </div>
              </mds-td>
              <mds-td style="padding-left: 16px">
                <div
                  style="padding-left: 20px"
                  v-if="
                    row.Name == '0' ||
                    row.Name == '' ||
                    row.Name == 'NaN' ||
                    row.Name == '&mdash;' ||
                    row.Name == null ||
                    row.Name == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <a
                    class="mei-equity-watchlist-tbl-First-Row"
                    v-html="row.Name"
                    @mouseover="openMedalistReport(row.SecId,row.Ticker)"
                    @touchstart="openMedalistReport(row.SecId,row.Ticker)"
                    target="_blank"
                    :href="CurrentURL"
                  ></a>
                </div>
              </mds-td>

              <mds-td left-aligned>
                <div
                  style="padding-left: 20px"
                  v-if="
                    row.MorningstarCategory == '0' ||
                    row.MorningstarCategory == '' ||
                    row.MorningstarCategory == 'NaN' ||
                    row.MorningstarCategory == '&mdash;' ||
                    row.MorningstarCategory == null ||
                    row.MorningstarCategory == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span
                    style="width: 187px; padding-left: 20px; display: block"
                    >{{ row.MorningstarCategory }}</span
                  >
                </div>
              </mds-td>
              <mds-td>
                <div
                  style="padding-left: 2px"
                  v-if="
                    row.MorningstarMedalistRating == '0' ||
                    row.MorningstarMedalistRating == '' ||
                    row.MorningstarMedalistRating == 'NaN' ||
                    row.MorningstarMedalistRating == '&mdash;' ||
                    row.MorningstarMedalistRating == null ||
                    row.MorningstarMedalistRating == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div style="padding-left: 2px; margin-top: 4px" v-else>
                  <mds-analyst-rating
                    :rating="
                      analystRatingClassMap[row.MorningstarMedalistRating]
                    "
                  >
                  </mds-analyst-rating>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-right: 9px">
                <div
                  v-if="
                    row.DataCoverage == '0' ||
                    row.DataCoverage == '' ||
                    row.DataCoverage == 'NaN' ||
                    row.DataCoverage == '&mdash;' ||
                    row.DataCoverage == null ||
                    row.DataCoverage == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>{{ row.DataCoverage | dataCoverageFilter }}</div>
              </mds-td>
              <mds-td right-aligned style="padding-right: 15px">
                <div
                  v-if="
                    row.AnalystDriven == '0' ||
                    row.AnalystDriven == '' ||
                    row.AnalystDriven == 'NaN' ||
                    row.AnalystDriven == '&mdash;' ||
                    row.AnalystDriven == null ||
                    row.AnalystDriven == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>{{ row.AnalystDriven }}</div>
              </mds-td>
              <mds-td>
                <div
                  v-if="
                    row.MorningstarRating == '0' ||
                    row.MorningstarRating == '' ||
                    row.MorningstarRating == 'NaN' ||
                    row.MorningstarRating == '&mdash;' ||
                    row.MorningstarRating == null ||
                    row.MorningstarRating == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <mds-star-rating
                    :rating="parseInt(row.MorningstarRating)"
                    :custom-size="14"
                  ></mds-star-rating>
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div
                  v-if="
                    row.YTD == '0' ||
                    row.YTD == '' ||
                    row.YTD == 'NaN' ||
                    row.YTD == '&mdash;' ||
                    row.YTD == null ||
                    row.YTD == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.YTD | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div
                  v-if="
                    row.Return1Yr == '0' ||
                    row.Return1Yr == '' ||
                    row.Return1Yr == 'NaN' ||
                    row.Return1Yr == '&mdash;' ||
                    row.Return1Yr == null ||
                    row.Return1Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.Return1Yr | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div
                  v-if="
                    row.Return3Yr == '0' ||
                    row.Return3Yr == '' ||
                    row.Return3Yr == 'NaN' ||
                    row.Return3Yr == '&mdash;' ||
                    row.Return3Yr == null ||
                    row.Return3Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.Return3Yr | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div
                  v-if="
                    row.Return5Yr == '0' ||
                    row.Return5Yr == '' ||
                    row.Return5Yr == 'NaN' ||
                    row.Return5Yr == '&mdash;' ||
                    row.Return5Yr == null ||
                    row.Return5Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.Return5Yr | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned>
                <div
                  v-if="
                    row.Return10Yr == '0' ||
                    row.Return10Yr == '' ||
                    row.Return10Yr == 'NaN' ||
                    row.Return10Yr == '&mdash;' ||
                    row.Return10Yr == null ||
                    row.Return10Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.Return10Yr | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.SinceInception == '0' ||
                    row.SinceInception == '' ||
                    row.SinceInception == 'NaN' ||
                    row.SinceInception == '&mdash;' ||
                    row.SinceInception == null ||
                    row.SinceInception == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.SinceInception | amountFilter }}
                </div>
              </mds-td>
              <mds-td left-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.InceptionDate == '0' ||
                    row.InceptionDate == '' ||
                    row.InceptionDate == 'NaN' ||
                    row.InceptionDate == '&mdash;' ||
                    row.InceptionDate == null ||
                    row.InceptionDate == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span>{{ row.InceptionDate | formatDateValForDataTables }}</span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.MarketPrice == '0' ||
                    row.MarketPrice == '' ||
                    row.MarketPrice == 'NaN' ||
                    row.MarketPrice == '&mdash;' ||
                    row.MarketPrice == null ||
                    row.MarketPrice == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.MarketPrice | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.FundYield == '0' ||
                    row.FundYield == '' ||
                    row.FundYield == 'NaN' ||
                    row.FundYield == '&mdash;' ||
                    row.FundYield == null ||
                    row.FundYield == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.FundYield | amountFilter }}
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.Assets == '-' ||
                    row.Assets == '0' ||
                    row.Assets == '' ||
                    row.Assets == null ||
                    row.Assets == 'NaN' ||
                    row.Assets == '&mdash;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  <span> {{ row.Assets  }}</span>
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.AvgShareVolume == '0' ||
                    row.AvgShareVolume == '' ||
                    row.AvgShareVolume == 'NaN' ||
                    row.AvgShareVolume == '&mdash;' ||
                    row.AvgShareVolume == null ||
                    row.AvgShareVolume == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.AvgShareVolume | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.ExpenseRatio == '0' ||
                    row.ExpenseRatio == '' ||
                    row.ExpenseRatio == 'NaN' ||
                    row.ExpenseRatio == '&mdash;' ||
                    row.ExpenseRatio == null ||
                    row.ExpenseRatio == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.ExpenseRatio | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.TaxCostRatio == '0' ||
                    row.TaxCostRatio == '' ||
                    row.TaxCostRatio == 'NaN' ||
                    row.TaxCostRatio == '&mdash;' ||
                    row.TaxCostRatio == null ||
                    row.TaxCostRatio == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.TaxCostRatio | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.PricePerBookRatio == '0' ||
                    row.PricePerBookRatio == '' ||
                    row.PricePerBookRatio == 'NaN' ||
                    row.PricePerBookRatio == '&mdash;' ||
                    row.PricePerBookRatio == null ||
                    row.PricePerBookRatio == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.PricePerBookRatio | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.PricePerEarning == '0' ||
                    row.PricePerEarning == '' ||
                    row.PricePerEarning == 'NaN' ||
                    row.PricePerEarning == '&mdash;' ||
                    row.PricePerEarning == null ||
                    row.PricePerEarning == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.PricePerEarning | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.PricePerFCF == '0' ||
                    row.PricePerFCF == '' ||
                    row.PricePerFCF == 'NaN' ||
                    row.PricePerFCF == '&mdash;' ||
                    row.PricePerFCF == null ||
                    row.PricePerFCF == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.PricePerFCF | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.PricePerShare == '0' ||
                    row.PricePerShare == '' ||
                    row.PricePerShare == 'NaN' ||
                    row.PricePerShare == '&mdash;' ||
                    row.PricePerShare == null ||
                    row.PricePerShare == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.PricePerShare | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.FairValueEstimate == '0' ||
                    row.FairValueEstimate == '' ||
                    row.FairValueEstimate == 'NaN' ||
                    row.FairValueEstimate == '&mdash;' ||
                    row.FairValueEstimate == null ||
                    row.FairValueEstimate == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.FairValueEstimate | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.AnalystPriceToFairValue == '0' ||
                    row.AnalystPriceToFairValue == '' ||
                    row.AnalystPriceToFairValue == 'NaN' ||
                    row.AnalystPriceToFairValue == '&mdash;' ||
                    row.AnalystPriceToFairValue == null ||
                    row.AnalystPriceToFairValue == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.AnalystPriceToFairValue | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 20px">
                <div
                  v-if="
                    row.AvgMarketCap == '0' ||
                    row.AvgMarketCap == '' ||
                    row.AvgMarketCap == 'NaN' ||
                    row.AvgMarketCap == '&mdash;' ||
                    row.AvgMarketCap == null ||
                    row.AvgMarketCap == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.AvgMarketCap | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.NumberOfAssets == '0' ||
                    row.NumberOfAssets == '' ||
                    row.NumberOfAssets == 'NaN' ||
                    row.NumberOfAssets == '&mdash;' ||
                    row.NumberOfAssets == null ||
                    row.NumberOfAssets == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.NumberOfAssets | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.PercentAssets == '0' ||
                    row.PercentAssets == '' ||
                    row.PercentAssets == 'NaN' ||
                    row.PercentAssets == '&mdash;' ||
                    row.PercentAssets == null ||
                    row.PercentAssets == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.PercentAssets | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.TurnoverRatio == '0' ||
                    row.TurnoverRatio == '' ||
                    row.TurnoverRatio == 'NaN' ||
                    row.TurnoverRatio == '&mdash;' ||
                    row.TurnoverRatio == null ||
                    row.TurnoverRatio == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.TurnoverRatio | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.MoatWide == '0' ||
                    row.MoatWide == '' ||
                    row.MoatWide == 'NaN' ||
                    row.MoatWide == '&mdash;' ||
                    row.MoatWide == null ||
                    row.MoatWide == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.MoatWide | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.MoatNarrow == '0' ||
                    row.MoatNarrow == '' ||
                    row.MoatNarrow == 'NaN' ||
                    row.MoatNarrow == '&mdash;' ||
                    row.MoatNarrow == null ||
                    row.MoatNarrow == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.MoatNarrow | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.MoatNone == '0' ||
                    row.MoatNone == '' ||
                    row.MoatNone == 'NaN' ||
                    row.MoatNone == '&mdash;' ||
                    row.MoatNone == null ||
                    row.MoatNone == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.MoatNone | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.Worst3MonthReturn == '0' ||
                    row.Worst3MonthReturn == '' ||
                    row.Worst3MonthReturn == 'NaN' ||
                    row.Worst3MonthReturn == '&mdash;' ||
                    row.Worst3MonthReturn == null ||
                    row.Worst3MonthReturn == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.Worst3MonthReturn | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-right: 1px">
                <div
                  v-if="
                    row.DownsideMarketCapture == '0' ||
                    row.DownsideMarketCapture == '' ||
                    row.DownsideMarketCapture == 'NaN' ||
                    row.DownsideMarketCapture == '&mdash;' ||
                    row.DownsideMarketCapture == null ||
                    row.DownsideMarketCapture == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.DownsideMarketCapture | amountFilter }}
                </div>
              </mds-td>
              <mds-td right-aligned style="padding-left: 16px">
                <div
                  v-if="
                    row.StdDev5Yr == '0' ||
                    row.StdDev5Yr == '' ||
                    row.StdDev5Yr == 'NaN' ||
                    row.StdDev5Yr == '&mdash;' ||
                    row.StdDev5Yr == null ||
                    row.StdDev5Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.StdDev5Yr | amountFilter }}
                </div>
              </mds-td>

              <mds-td right-aligned style="padding-right: 10px">
                <div
                  v-if="
                    row.SharpeRatio5Yr == '0' ||
                    row.SharpeRatio5Yr == '' ||
                    row.SharpeRatio5Yr == 'NaN' ||
                    row.SharpeRatio5Yr == '&mdash;' ||
                    row.SharpeRatio5Yr == null ||
                    row.SharpeRatio5Yr == '&nbsp;'
                  "
                >
                  &mdash;
                </div>
                <div v-else>
                  {{ row.SharpeRatio5Yr | amountFilter }}
                </div>
              </mds-td>
            </mds-tr>
          </mds-tbody>
        </mds-table>

        <span class="mei-equity-watchlist-data">
          <p style="text-align: justify; margin-top: 0px; margin-bottom: 0px">
            Data through {{ new Date() | formatDateValForDataTables }} |*The downside capture
            ratio is calculated against Morningstar category benchmark.
            <br />
            <br v-if="isDataTableResponsiveToggle" />
            Portfolio, tax, and risk data displayed through previous month end.
            Return and transaction data displayed through previous day end.
          </p>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import MdsStarRating from "@mds/star-rating";
import MdsAnalystRating from "@mds/analyst-rating";
import MdsLoader from "@mds/loader";
import { MdsButton } from "@mds/button";
import moment from "moment";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { mapState } from "vuex";
import {
  MdsTable,
  MdsThead,
  MdsTh,
  MdsTbody,
  MdsTr,
  MdsTd,
} from "@mds/data-table";
import EncryptDecryptMixin  from "../mixins/EncryptDecryptMixin.js";
export default {
  name: "equity-watchlist",
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsStarRating,
    MdsAnalystRating,
    MdsLoader,
    MdsButton,
  },
  mixins: [
		EncryptDecryptMixin
	],
  mounted() {
    this.isDataTableResponsiveToggle =
      this.$store.state.responsive.isDataTableResponsive;
    window.scrollTo(0, 0);
  },
  data() {
    return {
      CurrentURL :  "#",
      EquityWatchlistURL:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_EquityWatchlistUrl,
      analystRatingClassMap: {
        1: "not-ratable",
        2: "under-review",
        3: "negative",
        4: "neutral",
        5: "bronze",
        6: "silver",
        7: "gold",
      },
      headers: [
        {
          text: "Ticker",
          fieldName: "Ticker",
          class: "mei-equity-watchlist-Ticker",
          align: "left",
          sortable: true,
          style: {
            //"padding-left": "5px",
          },
        },
        {
          text: "Name",
          fieldName: "Name",
          class: "mei-equity-watchlist-Name",
          sortable: true,
          sorted: 1,
          style: {
            "padding-left": "16px",
          },
        },
        {
          text: "Morningstar<br/>Category",
          fieldName: "MorningstarCategory",
          // class:'mei-equity-watchlist-Category',
          align: "left",
          style: {
            "margin-left": "20px",
            "padding-left": "20px",
          },
          sortable: true,
        },
        {
          text: "Morningstar Medalist Rating",
          fieldName: "MorningstarMedalistRating",
          style: {},
          class: "mei-equity-watchlist-medalistRating",
          sortable: true,
        },

        {
          text: "Data Coverage %",
          fieldName: "DataCoverage",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "10px",
          },
        },
        {
          text: "Analyst-Driven %",
          fieldName: "AnalystDriven",
          sortable: true,
          align: "right",
          style: {
            "padding-right": "16px",
          },
        },
        {
          text: "Morningstar Rating",
          fieldName: "MorningstarRating",
          class: "mei-equity-watchlist-Rating",
          sortable: true,
        },
        {
          text: "YTD",
          fieldName: "YTD",
          class: "mei-equity-watchlist-YTD",
          sortable: true,
          align: "right",
        },
        {
          text: "1-Yr",
          fieldName: "Return1Yr",
          class: "mei-equity-watchlist-1Yr",
          sortable: true,
          align: "right",
        },
        {
          text: "3-Yr",
          fieldName: "Return3Yr",
          class: "mei-equity-watchlist-3Yr",
          sortable: true,
          align: "right",
        },
        {
          text: "5-Yr",
          fieldName: "Return5Yr",
          class: "mei-equity-watchlist-5Yr",
          sortable: true,
          align: "right",
        },
        {
          text: "10-Yr",
          fieldName: "Return10Yr",
          class: "mei-equity-watchlist-10Yr",
          sortable: true,
          align: "right",
        },
        {
          text: "Since Inception",
          fieldName: "SinceInception",
          class: "mei-equity-watchlist-Inception",
          sortable: true,
          style: {
            "padding-right": "10px",
          },
          align: "right",
        },
        {
          text: "Inception Date",
          fieldName: "InceptionDate",
          align: "right",
          class: "mei-equity-watchlist-Inception",
          sortable: true,
        },
        {
          text: "Market Price $",
          fieldName: "MarketPrice",
          class: "mei-equity-watchlist-mktPrice",
          sortable: true,
          align: "right",
        },
        {
          text: "12-Month Yield %",
          fieldName: "FundYield",
          class: "mei-equity-watchlist-12MnthYield",
          sortable: true,
          align: "right",
        },
        {
          text: "Assets ($Bil)",
          fieldName: "Assets",
          class: "mei-equity-watchlist-Assets",
          sortable: true,
          align: "right",
        },
        {
          text: "Trailing 3-Mo Avg Share Volume",
          fieldName: "AvgShareVolume",
          class: "mei-equity-watchlist-AvgShareVol",
          sortable: true,
          align: "right",
        },
        {
          text: "Expense Ratio %",
          fieldName: "ExpenseRatio",
          class: "mei-equity-watchlist-ExpenseRatio",
          sortable: true,
          align: "right",
        },
        {
          text: "Trailing 3-Yr Tax Cost Ratio %",
          fieldName: "TaxCostRatio",
          class: "mei-equity-watchlist-TaxCostRatio",
          sortable: true,
          align: "right",
        },
        {
          text: "P/B",
          fieldName: "PricePerBookRatio",
          class: "mei-equity-watchlist-PricePerBookRatio",
          sortable: true,
          align: "right",
        },
        {
          text: "P/E",
          fieldName: "PricePerEarning",
          class: "mei-equity-watchlist-PricePerEarning",
          sortable: true,
          align: "right",
        },
        {
          text: "P/FCF",
          fieldName: "PricePerFCF",
          class: "mei-equity-watchlist-PricePerFCF",
          sortable: true,
          align: "right",
        },
        {
          text: "P/S",
          fieldName: "PricePerShare",
          class: "mei-equity-watchlist-PricePerShare",
          sortable: true,
          align: "right",
        },
        {
          text: "Fair Value Estimate $",
          fieldName: "FairValueEstimate",
          class: "mei-equity-watchlist-FairValueEstimate",
          sortable: true,
          align: "right",
        },
        {
          text: "Analyst Price</br>/Fair Value",
          fieldName: "AnalystPriceToFairValue",
          class: "mei-equity-watchlist-AnalystPriceToFairValue",
          sortable: true,
          align: "right",
        },
        {
          text: "Avg Mkt Cap ($Mil)",
          fieldName: "AvgMarketCap",
          class: "mei-equity-watchlist-AvgMarketCap",
          sortable: true,
          align: "right",
        },
        {
          text: "# of Holdings",
          fieldName: "NumberOfAssets",
          class: "mei-equity-watchlist-NumberOfAssets",
          sortable: true,
          align: "right",
        },
        {
          text: "% Assets in Top 10 Holdings",
          fieldName: "PercentAssets",
          class: "mei-equity-watchlist-PercentAssets",
          sortable: true,
          align: "right",
        },
        {
          text: "Turnover Ratio %",
          fieldName: "TurnoverRatio",
          class: "mei-equity-watchlist-TurnoverRatio",
          sortable: true,
          align: "right",
        },
        {
          text: "Moat % Wide",
          fieldName: "MoatWide",
          class: "mei-equity-watchlist-MoatWide",
          sortable: true,
          align: "right",
        },
        {
          text: "Moat % Narrow",
          fieldName: "MoatNarrow",
          class: "mei-equity-watchlist-MoatNarrow",
          sortable: true,
          align: "right",
        },
        {
          text: "Moat % None",
          fieldName: "MoatNone",
          class: "mei-equity-watchlist-MoatNone",
          sortable: true,
          align: "right",
        },
        {
          text: "Worst 3-Mth Rtn",
          fieldName: "Worst3MonthReturn",
          class: "mei-equity-watchlist-Worst3MonthReturn",
          sortable: true,
          align: "right",
        },
        {
          text: "5-Yr* Downside Capture Ratio",
          fieldName: "DownsideMarketCapture",
          class: "mei-equity-watchlist-DownsideMarketCapture",
          sortable: true,
          align: "right",
        },
        {
          text: "5-Yr Standard Deviation",
          fieldName: "StdDev5Yr",
          class: "mei-equity-watchlist-StdDev5Yr",
          sortable: true,
          align: "right",
        },
        {
          text: "5-Yr Sharpe Ratio",
          fieldName: "SharpeRatio5Yr",
          // class:'mei-equity-watchlist-SharpeRatio5Yr',
          sortable: true,
          style: {
            "padding-right": "10px",
          },
          align: "right",
        },
      ],
      rows: [],
      sortOrder: 1,
      sortColIndex: 1,
      isLoading: true,
      isDisplay: false,
      excelColumn: [{ add: "", delete: "" }],
      isDataTableResponsiveToggle: null,
    };
  },

  methods: {
    handleSortEvent(colIndex, colName) {
      // if your tracked sort order is 0 (none), set it 1 (ascending)
      // if there is already an order being tracked inverse it
      this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

      // if the table was already sorted by a different column, then reset that column to 0 (none)
      if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
        this.$set(this.headers[this.sortColIndex], "sorted", 0);
        this.sortOrder = 1;
      }

      // track the current column as the one being sorted on
      this.sortColIndex = colIndex;
      this.$set(this.headers[colIndex], "sorted", this.sortOrder);

      // sort the row data by column clicked on
      this.rows.sort((a, b) => {
        if (colName == "Name") {
          const name1 = a.Name.toUpperCase();
          const name2 = b.Name.toUpperCase();

          if (name1 > name2) {
            return this.sortOrder;
          } else if (name1 < name2) {
            return -this.sortOrder;
          }
        }else if (
            colName == "Return10Yr" ||
            colName == "Return5Yr" ||
            colName == "Return1Yr" ||
            colName == "Return3Yr" ||
            colName == "AvgMarketCap" ||
            colName == "Assets" ||
            colName == "AvgShareVolume" ||
            colName == "MoatNone" ||
            colName == "MarketPrice" ||
            colName == "SupSecGovt" ||
            colName == "SupSecCorp" ||
            colName == "SupSecStruc" ||
            colName == "SupSecMuni" ||
            colName == "SupSecCash" ||
            colName == "CreditQualityAAA" ||
            colName == "CreditQualityAA" ||
            colName == "CreditQualityA" ||
            colName == "CreditQualityBBB" ||
            colName == "CreditQualityBB" ||
            colName == "CreditQualityB" ||
            colName == "CreditQualityBelowB" ||
            colName == "AverageDuration" ||
            colName == "Worst3MonthReturn" ||
            colName == "DownsideMarketCapture" ||
            colName == "StdDev5Yr" ||
            colName == "SharpeRatio5Yr"
        ) {
          return parseFloat(a[colName]) >= parseFloat(b[colName])
            ? this.sortOrder
            : -this.sortOrder;
        } else if (colName == "InceptionDate") {
          var date1 = a[colName];
          var date2 = b[colName];

          var tempDate1 = date1.split("/");
          var tempDate2 = date2.split("/");

          var sortDate1 = "";
          var sortDate2 = "";

          if (tempDate1) {
            sortDate1 = tempDate1[2] + "-" + tempDate1[0] + "-" + tempDate1[1];
          }
          if (tempDate2) {
            sortDate2 = tempDate2[2] + "-" + tempDate2[0] + "-" + tempDate2[1];
          }

          return new Date(sortDate1) >= new Date(sortDate2)
            ? this.sortOrder
            : -this.sortOrder;
        } else {
          return a[colName] >= b[colName] ? this.sortOrder : -this.sortOrder;
        }
      });
    },

    changeDateFormatETF(value) {
      var result = "";
      if (value) {
        var split = value.split("/");
        if (split[2].length == 4) {
          result = split[0] + "/" + split[1] + "/" + split[2].substring(2, 4);
        }
        else {
          result = value;
        }
      }
      return result;
    },

    ExportExcel() {
      const today = moment();
      let excelData = [];

      for (var z = 0; z < this.rows.length; z++) {
        var obj1 = this.rows[z];
        if (obj1.InceptionDate) {
          if (obj1.InceptionDate != "&mdash;" || obj1.InceptionDate != "") {
            obj1.InceptionDate = this.changeDateFormatETF(obj1.InceptionDate);
          }
        }
        excelData.push(obj1);
      }
      saveExcel({
        data: excelData,
        fileName: "MEI_EquityWatchlist_" + today.format("MMDDYYYY") + ".xlsx",
        columns: [
          {
            field: "Ticker",
            title: "Ticker",
          },
          {
            field: "Name",
            title: "Name",
          },
          {
            field: "MorningstarCategory",
            title: "Morningstar Category",
          },
          {
            field: "MorningstarMedalistRating",
            title: "Morningstar Medalist Rating",
          },

          {
            field: "DataCoverage",
            title: "Data Coverage",
            cellOptions: { format: "#,##0.00" },
          },

          {
            field: "AnalystDriven",
            title: "Analyst Driven",
          },
          {
            field: "MorningstarRating",
            title: "Morningstar Rating",
          },

          {
            field: "YTD",
            title: "YTD",
          },
          {
            field: "Return1Yr",
            title: "1-Yr",
          },
          {
            field: "Return3Yr",
            title: "3-Yr",
          },
          {
            field: "Return5Yr",
            title: "5-Yr",
          },
          {
            field: "Return10Yr",
            title: "10-Yr",
          },

          {
            field: "SinceInception",
            title: "Since Inception",
          },
          {
            field: "InceptionDate",
            title: "Inception Date",
          },
          {
            field: "MarketPrice",
            title: "Market Price $",
          },
          {
            field: "FundYield",
            title: "12-Month Yield %",
          },
          {
            field: "Assets",
            title: "Assets ($Bil)",
          },

          {
            field: "AvgShareVolume",
            title: "Trailing 3-Mo Avg Share Volume",
          },
          {
            field: "ExpenseRatio",
            title: "Expense Ratio %",
          },
          {
            field: "TaxCostRatio",
            title: "Trailing 3-Yr Tax Cost Ratio %",
          },

          {
            field: "PricePerBookRatio",
            title: "P/B",
          },
          {
            field: "PricePerEarning",
            title: "P/E",
          },
          {
            field: "PricePerFCF",
            title: "P/FCF",
          },
          {
            field: "PricePerShare",
            title: "P/S",
          },
          {
            field: "FairValueEstimate",
            title: "Fair Value Estimate $",
          },

          {
            field: "AnalystPriceToFairValue",
            title: "Analyst Price/Fair Value",
          },
          {
            field: "AvgMarketCap",
            title: "Avg Mkt Cap ($Mil)",
          },
          {
            field: "NumberOfAssets",
            title: "# of Holdings",
          },
          {
            field: "PercentAssets",
            title: "% Assets in Top 10 Holdings",
          },
          {
            field: "TurnoverRatio",
            title: "Turnover Ratio %",
          },
          {
            field: "MoatWide",
            title: "Moat % Wide",
          },
          {
            field: "MoatNarrow",
            title: "Moat % Narrow",
          },

          {
            field: "MoatNone",
            title: "Moat % None",
          },
          {
            field: "Worst3MonthReturn",
            title: "Worst 3-Mth Rtn",
          },
          {
            field: "DownsideMarketCapture",
            title: "5-Yr* Downside Capture Ratio",
          },
          {
            field: "StdDev5Yr",
            title: "5-Yr Standard Deviation",
          },
          {
            field: "SharpeRatio5Yr",
            title: "5-Yr Sharpe Ratio",
          },
        ],
      });
    },
    openMedalistReport(secId,Symbol) {
      this.$session.set("CurrentSecID",this.Encrypt(secId));
      this.$session.set("CurrentPortfolioName",this.Encrypt("equityPort"));
      if(this.$store.state.responsive.isMobileContent)
      { 
        // this.$router
        // .push({ name: "analyst", params: { currentproduct: "mei",ticker:Symbol}})
        // .catch((err) => {
        //   if (
        //     err.name !== "NavigationDuplicated" &&
        //     !err.message.includes(
        //       "Avoided redundant navigation to current location"
        //     )
        //   ) {
        //     console.log(err);
        //   }
        // });
        let resolvedRoute = this.$router.resolve({
        name: "analyst",
        params: {currentproduct: "mei",ticker:Symbol}
        });
        this.CurrentURL = resolvedRoute.href;
      }
      else
      {
        let resolvedRoute = this.$router.resolve({
        name: "analyst",
        params: {currentproduct: "mei",ticker:Symbol}
        });
        // window.open(resolvedRoute.href, '_blank');
        this.CurrentURL = resolvedRoute.href;
      }
    },
    redirectToStockQuote(exchangeId,ticker){
      var quotePageURL = "https://www.morningstar.com/etfs/"+exchangeId.toLowerCase()+"/"+ticker.toLowerCase()+"/quote";
      window.open(quotePageURL,"_blank");
    }
  },
  created() {
    axios
      .get(this.EquityWatchlistURL)
      .then((response) => {
        var tempdata = [];
        var customData = response.data;
        if (customData) {
          for (var i = 0; i < customData.length; i++) {
            if (customData[i].Ticker != null) {
              customData[i].Name = customData[i].Name.replace('?', '®');
              if (customData[i].MarketPrice == 0) {
                customData[i].MarketPrice = "00.00";
              } else {
                customData[i].MarketPrice = parseFloat(
                  customData[i].MarketPrice
                ).toFixed(2);
              }
              if (customData[i].Assets == 0 || customData[i].Assets == null) {
                customData[i].Assets = "00.00";
              } else {
                customData[i].Assets = customData[i].Assets.replaceAll(',', '');
                customData[i].Assets = (customData[i].Assets / 1000000000).toFixed(2);
              }

              if (customData[i].AvgShareVolume == 0 || customData[i].AvgShareVolume == null) {
                customData[i].AvgShareVolume = "00.00";
              } else {
                customData[i].AvgShareVolume = Math.round(
                  customData[i].AvgShareVolume);
              }
              if (customData[i].FundYield == 0) {
                customData[i].FundYield = "0.00";
              } else {
                customData[i].FundYield = parseFloat(
                  customData[i].FundYield
                ).toFixed(2);
              }
              if (customData[i].Return10Yr == 0) {
                customData[i].Return10Yr = "0.00";
              } else {
                customData[i].Return10Yr = parseFloat(
                  customData[i].Return10Yr
                ).toFixed(2);
              }
              if (customData[i].Return5Yr == 0) {
                customData[i].Return5Yr = "0.00";
              } else {
                customData[i].Return5Yr = parseFloat(
                  customData[i].Return5Yr
                ).toFixed(2);
              }
              if (customData[i].MoatNone == 0) {
                customData[i].MoatNone = "0.00";
              } else {
                customData[i].MoatNone = parseFloat(
                  customData[i].MoatNone
                ).toFixed(2);
              }
              if (customData[i].MoatNarrow == 0) {
                customData[i].MoatNarrow = "0.00";
              } else {
                customData[i].MoatNarrow = parseFloat(
                  customData[i].MoatNarrow
                ).toFixed(2);
              }
              if (customData[i].MoatWide == 0) {
                customData[i].MoatWide = "0.00";
              } else {
                customData[i].MoatWide = parseFloat(
                  customData[i].MoatWide
                ).toFixed(2);
              }
              if (customData[i].PricePerEarning == 0) {
                customData[i].PricePerEarning = "0.00";
              } else {
                customData[i].PricePerEarning = parseFloat(
                  customData[i].PricePerEarning
                ).toFixed(2);
              }
              if (customData[i].TaxCostRatio == 0) {
                customData[i].TaxCostRatio = "0.00";
              } else {
                customData[i].TaxCostRatio = parseFloat(
                  customData[i].TaxCostRatio
                ).toFixed(2);
              }
              if (customData[i].Worst3MonthReturn == 0) {
                customData[i].Worst3MonthReturn = "0.00";
              } else {
                customData[i].Worst3MonthReturn = parseFloat(
                  customData[i].Worst3MonthReturn
                ).toFixed(2);
              }
              if (customData[i].PricePerBookRatio == 0) {
                customData[i].PricePerBookRatio = "0.00";
              } else {
                customData[i].PricePerBookRatio = parseFloat(
                  customData[i].PricePerBookRatio
                ).toFixed(2);
              }
              if (customData[i].PricePerFCF == 0) {
                customData[i].PricePerFCF = "0.00";
              } else {
                customData[i].PricePerFCF = parseFloat(
                  customData[i].PricePerFCF
                ).toFixed(2);
              }
              if (customData[i].PricePerShare == 0) {
                customData[i].PricePerShare = "0.00";
              } else {
                customData[i].PricePerShare = parseFloat(
                  customData[i].PricePerShare
                ).toFixed(2);
              }
              if (customData[i].FairValueEstimate == 0) {
                customData[i].FairValueEstimate = "0.00";
              } else {
                customData[i].FairValueEstimate = parseFloat(
                  customData[i].FairValueEstimate
                ).toFixed(2);
              }
              if (customData[i].ExpenseRatio == 0) {
                customData[i].ExpenseRatio = "0.00";
              } else {
                customData[i].ExpenseRatio = parseFloat(
                  customData[i].ExpenseRatio
                ).toFixed(2);
              }
              if (customData[i].AnalystPriceToFairValue == 0) {
                customData[i].AnalystPriceToFairValue = "0.00";
              } else {
                customData[i].AnalystPriceToFairValue = parseFloat(
                  customData[i].AnalystPriceToFairValue
                ).toFixed(2);
              }
              if (customData[i].AvgMarketCap == 0) {
                customData[i].AvgMarketCap = "0.00";
              } else {
                customData[i].AvgMarketCap = parseFloat(
                  customData[i].AvgMarketCap
                ).toFixed(2);
              }
              if (customData[i].SharpeRatio5Yr == 0) {
                customData[i].SharpeRatio5Yr = "0.00";
              } else {
                customData[i].SharpeRatio5Yr = parseFloat(
                  customData[i].SharpeRatio5Yr
                ).toFixed(2);
              }
              if (customData[i].StdDev5Yr == 0) {
                customData[i].StdDev5Yr = "0.00";
              } else {
                customData[i].StdDev5Yr = parseFloat(
                  customData[i].StdDev5Yr
                ).toFixed(2);
              }
              if (customData[i].DownsideMarketCapture == 0) {
                customData[i].DownsideMarketCapture = "0.00";
              } else {
                customData[i].DownsideMarketCapture = parseFloat(
                  customData[i].DownsideMarketCapture
                ).toFixed(2);
              }
              if (customData[i].AverageDuration == 0) {
                customData[i].AverageDuration = "0.00";
              } else {
                customData[i].AverageDuration = parseFloat(
                  customData[i].AverageDuration
                ).toFixed(2);
              }

              if (customData[i].TaxCostRatio == 0) {
                customData[i].TaxCostRatio = "0.00";
              } else {
                customData[i].TaxCostRatio = parseFloat(
                  customData[i].TaxCostRatio
                ).toFixed(2);
              }
              if (customData[i].DataCoverage != 0) {
                customData[i].DataCoverage = parseInt(customData[i].DataCoverage);
              }
              if (customData[i].AnalystDriven != 0) {
                customData[i].AnalystDriven = parseInt(
                  customData[i].AnalystDriven
                );
              }

              tempdata.push(customData[i]);
            }
          }
        }
        this.rows = tempdata;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => ((this.isLoading = false), (this.isDisplay = true)));
  },
  computed: mapState({
    isDataTableResponsive: (state) => state.responsive.isDataTableResponsive,
    isDataTableResponsiveAlias: "isDataTableResponsive",
    isDataTableResponsiveState(state) {
      return state.responsive.isDataTableResponsive;
    },
    isMobileContent: state => state.responsive.isMobileContent,
      isMobileContentAlias:'isMobileContent',
      isMobileContentState(state){
      return state.responsive.isMobileContent
    }
  }),
  watch: {
    isDataTableResponsiveState() {
      this.isDataTableResponsiveToggle = this.isDataTableResponsiveState;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@mds/constants";

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.mei-equity-watchlist-nestedheader {
  color: #5e5e5e;
  font-weight: 700;
  font-size: 16px;
  margin-left: 12px;
  padding-bottom: -8px;
  line-height: 25px;
}

.mei-equity-watchlist-nestedheader-annualized {
  color: #5e5e5e;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  margin-left: 10px;
}

.mei-equity-watchlist-borderbottom-annualized {
  border-bottom: 1px solid #808080;
  width: 98%;
  position: absolute;
  top: 24px;
  left: 12px;
}

.mei-equity-watchlist-borderbottom {
  border-bottom: 1px solid #808080;
  width: 97%;
  position: absolute;
  margin-left: 14px;
  top: 24px;
  left: 0px;
}

.mei-equity-watchlist-table-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 42.92%;
  right: 37.92%;
  top: 82%;
}

.mei-equity-watchlist-data {
  font-family: "UniversNext for MORN";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 56px;
  margin-top: 19px;
  display: flex;
  align-items: flex-end;
  color: #5e5e5e;
}

.mei-equity-watchlist-title {
  font-weight: 700;
  font-size: 32px;
  color: #1e1e1e;
  line-height: 36px;
}

.mei-equity-watchlist-middle-content{
  display:flex;
  width:125%
}

.mei-equity-watchlist-header-text {
  width: 78%;
  padding-top: 48px;
  margin-left: 0px;
}

$mds-data-table-border-fixed-col: 2px solid $mds-color-neutral-90 !default;

body {
  padding: 1em;
}

.mei-equity-watchlist-data-table {
  width: 100%;
  height: 600px;
  box-shadow: none;
}
.mei-equity-watchlist-data-table.mds-table__scroller .mds-table--fixed-column {
  th {
    position: sticky;
    top: 0;
  }
  th:nth-child(1) {
    z-index: $mds-z-index-low + 1;
  }

  th:nth-child(1) {
    padding-left: 2px;
  }

  td {
    padding: 7px 2px;
  }

  td:nth-child(1) {
    padding: 5px 2px;
  }

  td:nth-child(35) {
    padding: 7px 10px;
  }
}
</style>
<style scoped>
.aColor {
  color: blue;
  text-decoration: none;
}

.aColor:hover {
  text-decoration: underline;
}

.mei-equity-watchlist-analyst-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5e5e5e;
  margin-top: 24px;
}
.mei-equity-watchlist-analyst-text-sec {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #5e5e5e;
  margin-top: 6px;
}
.mei-equity-watchlist-tbl-First-Row {
  font-style: normal;
  height: 100px;
  font-weight: 700;
  font-size: 16px;
  color: #1e1e1e;
  line-height: 25px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.mei-equity-watchlist-tbl-First-Row:hover {
  cursor: pointer;
  color: #006fba;
}

.mei-equity-watchlist-table-header {
  color: #5e5e5e;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 0px;
}

body {
  font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.mei-equity-watchlist-download-holding {
  margin-top: 30px;
  cursor: pointer;
  order:2;
  margin-left: auto;
  float:right;
}

.mei-equity-watchlist-download-holding:hover {
  cursor: pointer;
}

.mei-equity-watchlist-container { 
  width: 1280px;
  margin: 0 auto;
}

.mds-td {
  line-height: 25px;
}

.mds-th mds-th--sorted-descending mds-th--right mds-th--sortable {
  border-bottom: 1px solid rgb(30, 30, 30);
}

.mds-th mds-th--sorted-ascending mds-th--right mds-th--sortable {
  border-bottom: 1px solid rgb(30, 30, 30);
}

.mds-table--show-sortable .mds-th--sortable .mds-th__inner {
  border-bottom: 1px solid rgb(30, 30, 30);
}

.mds-th--sortable.mds-th--sorted-ascending .mds-th__inner {
  border-bottom: 1px solid rgb(30, 30, 30);
}

.mei-equity-watchlist-border-bottom-two {
  border-bottom: 1px solid #808080;
  width: 90%;
  left: 16px;
  margin-bottom: 0px;
  position: absolute;
  top: 23px;
}

.mei-equity-watchlist-border-bottom-three {
  border-bottom: 1px solid #808080;
  width: 90%;
  left: 16px;
  position: absolute;
  top: 23px;
}

.mei-equity-watchlist-table {
  width: 100%;
  height: 90%;
  margin-top: 25px;
}

.mei-equity-watchlist-nesting-headers {
  width: 140px;
  padding-left: 16px;
}

.mei-equity-watchlist-nesting-headers-one {
  width: 186px;
  padding-left: 16px;
}

.mei-equity-watchlist-nested-header-first {
  font-size: 16px;
  left: 20px;
}

.mei-equity-watchlist-nested-header-second {
  font-size: 16px;
  left: 20px;
}

.mei-equity-watchlist-download-button {
  font-size: 14px;
  margin-left: 5px;
  color: #5e5e5e;
}

.data-table {
  width: 100%;
  height: 1085px;
}

.mei-equity-watchlist-Name {
  width: "300px";
  height: "21px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
}

.mei-equity-watchlist-Ticker {
  width: "65px";
  height: "21px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "9px";
}

.mei-equity-watchlist-Category {
  width: "80px";
  height: "42px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "24px";
  margin-left: "24px";
}

.mei-equity-watchlist-medalistRating {
  width: "103px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "0px";
}

.mei-equity-watchlist-Rating {
  width: "120px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "16px";
}

.mei-equity-watchlist-YTD {
  width: "48px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "16px";
}

.mei-equity-watchlist-1Yr {
  width: "63px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "15px";
}

.mei-equity-watchlist-3Yr {
  width: "59px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "10px";
}

.mei-equity-watchlist-5Yr {
  width: "53px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "0px";
}

.mei-equity-watchlist-10Yr {
  width: "60px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "16px";
}

.mei-equity-watchlist-Inception {
  width: "83px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "16px";
}

.mei-equity-watchlist-Date {
  width: "83px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "16px";
}

.mei-equity-watchlist-mktPrice {
  width: "107px";
  font-family: "UniversNext for MORN";
  font-style: "normal";
  font-weight: "700";
  font-size: "16px";
  line-height: "21px";
  color: "#5E5E5E";
  padding-left: "16px";
}

.mei-equity-watchlist-12MnthYield {
  width: "87px";
  padding-left: "16px";
}

.mei-equity-watchlist-Assets {
  width: "111px";
  padding-left: "16px";
}

.mei-equity-watchlist-AvgShareVol {
  width: "135px";
  padding-left: "16px";
}

.mei-equity-watchlist-ExpenseRatio {
  width: "133px";
  padding-left: "16px";
}

.mei-equity-watchlist-TaxCostRatio {
  width: "126px";
  padding-left: "16px";
}

.mei-equity-watchlist-PricePerBookRatio {
  width: "55px";
  padding-left: "16px";
}

.mei-equity-watchlist-PricePerEarning {
  width: "53px";
  padding-left: "16px";
}

.mei-equity-watchlist-PricePerFCF {
  width: "66px";
  padding-left: "16px";
}

.mei-equity-watchlist-PricePerShare {
  width: "53px";
  padding-left: "16px";
}

.mei-equity-watchlist-FairValueEstimate {
  width: "94px";
  padding-left: "16px";
}

.mei-equity-watchlist-AnalystPriceToFairValue {
  width: "125px";
  padding-left: "16px";
}

.mei-equity-watchlist-AvgMarketCap {
  width: "149px";
  padding-left: "20px";
}

.mei-equity-watchlist-NumberOfAssets {
  width: "110px";
  padding-left: "16px";
}

.mei-equity-watchlist-PercentAssets {
  width: "126px";
  padding-left: "16px";
}

.mei-equity-watchlist-TurnoverRatio {
  width: "82px";
  padding-left: "16px";
}

.mei-equity-watchlist-MoatWide {
  width: "78px";
  padding-left: "16px";
}

.mei-equity-watchlist-MoatNarrow {
  width: "80px";
  padding-left: "16px";
}

.mei-equity-watchlist-MoatNone {
  width: "78px";
  padding-left: "16px";
}

.mei-equity-watchlist-Worst3MonthReturn {
  width: "82px";
  padding-left: "16px";
}

.mei-equity-watchlist-DownsideMarketCapture {
  width: "125px";
  padding-left: "16px";
}

.mei-equity-watchlist-StdDev5Yr {
  width: "112px";
  padding-left: "16px";
}

.mei-equity-watchlist-SharpeRatio5Yr {
  width: "98px";
  padding-left: "0px";
  padding-right: "10px";
}
.mei-equity-watchlist-table-ticker{
  cursor:pointer;
}
.mei-equity-watchlist-table-ticker:hover {
  color:#006fba;
}
@media only screen and (min-width: 320px) and (max-width: 1415px) {
  .mei-equity-watchlist-container { 
    width:90%;
    margin-left: 5%;
    margin-right: 5%;
  } 
}



@media only screen and (min-width: 320px) and (max-width: 428px) {
  .mei-equity-watchlist-analyst-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5e5e5e;
    margin-top: 54px;
    width: 90vw;
  }
  .mei-equity-watchlist-analyst-text-sec {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5e5e5e;
    width: 90vw;
  }
  .mei-equity-watchlist-middle-content{
    display: block;
  }
  .mei-equity-watchlist-download-holding {
    margin-top: 16px;
  }

  .mei-equity-watchlist-title {
    font-weight: 700;
    font-size: 28px;
    color: #1e1e1e;
    line-height: 36px;
  }
}

@media only screen and (min-width: 1537px) and (max-width: 3840px) {  

.mei-equity-watchlist-middle-content{
  display: block;
}

.mei-equity-watchlist-download-holding {
  margin-top: 16px;   
}
}
.mei-equity-watchlist-data-table > table > tbody > tr > td:nth-child(2) > div > a:focus{ 
    box-shadow:none;
}
.mei-equity-watchlist-data-table > table > tbody > tr > td:nth-child(2) > div > a:hover{ 
    cursor:pointer;
}
</style>