<template>
<div id="app">
    <mds-list-group class="glowEffect">
        <mds-list-group-item v-for="(year, parentIndex) in articleData" :key="parentIndex" collapsible :expanded="year.expanded" @click.native.stop="toggleAction(year.title, parentIndex)" :class="[year.isActiveClass ? yearClasswithNoRule : '', yearClassWithRule]">{{ year.title }}
            <template #mds-list-item-children>
                <mds-list-group-item v-for="(month, childIndex) in year.months" :key="childIndex" collapsible :expanded="month.expanded" :text="month.Month" @click.native.stop="
              toggleChildItem($event, parentIndex, childIndex);
              getPostDataByMonth(month.Month, year.title);
            " class="month-title-design">
                    <template #mds-list-item-children>
                        <mds-loader v-if="isLoading" size="small" aria-label="Small Loader"></mds-loader>
                        <mds-list-group-item v-else v-for="(post, index) in posts" :key="index" @click.native.stop="activeTab(index)" :active="post.isActive" class="monthly-article-data-design">{{ post.Subject }}
                        </mds-list-group-item>
                    </template>
                </mds-list-group-item>
            </template>
        </mds-list-group-item>
    </mds-list-group>
</div>
</template>

<script lang="js">
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import {
    MdsListGroup,
    MdsListGroupItem
} from "@mds/list-group";
import MdsLoader from "@mds/loader";
Vue.use(VueAxios, axios);
export default {
    name: 'collapsible-article',
    props: {
        articleDate: {
            type: String,
            required: true
        },
        articleId: {
            type: Number,
            required: true
        }
    },
    components: {
        MdsListGroup,
        MdsListGroupItem,
        MdsLoader,
    },
    data() {
        return {
            listItems: [],
            posts: [],
            CurrentProduct: this.$session.get("current-product"),
            latestPostCount: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_LatestPostCount,
            latestPostByMonth: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_LatestPostByMonth,
            isLoading: false,
            articleIdMutate: this.articleId,
            yearClassWithRule: 'year-title-design',
            yearClasswithNoRule: 'no-rule-design'
        }
    },
    methods: {
        async toggleAction(year, index) {
            await this.getMonthData(year, index);
        },
        toggleChildItem(el, parentIndex, childIndex) {
            let month = this.listItems[parentIndex].months[childIndex];
            if (!month.expanded) {
                this.listItems[parentIndex].months.map((parentIndex) => (parentIndex.expanded = false));
            }
            month.expanded = !month.expanded;
            this.$set(this.listItems, parentIndex, {
                ...this.listItems[parentIndex],
                month: month,
            });
        },
        async getMonthData(year, parentIndex) {
            const getMonthName =
                this.latestPostCount +
                "ProductCode=" +
                this.CurrentProduct +
                "&Year=" +
                year;
            const response = await Vue.axios.get(getMonthName);
            if (response && response.status === 200 && response.data) {
                if (!this.listItems[parentIndex].expanded) {
                    this.listItems.map((parentIndex) => (parentIndex.expanded = false));
                    this.listItems.map((parentIndex) => (parentIndex.isActiveClass = false));
                }
                this.listItems[parentIndex].months = response.data;
                this.listItems[parentIndex].expanded = !this.listItems[parentIndex].expanded;
                this.listItems[parentIndex].isActiveClass = !this.listItems[parentIndex].isActiveClass;
            }
        },
        async getPostDataByMonth(mn, yr) {
            this.isLoading = true;
            var mon;
            if (isNaN(mn)) {
                mon = this.numericMonth(mn);
            } else {
                mon = mn;
            }
            const getPostByMonth =
                this.latestPostByMonth +
                "ProductCode=" +
                this.CurrentProduct +
                "&Month=" +
                mon +
                "&Year=" +
                yr;
            try {
                Vue.axios
                    .get(getPostByMonth)
                    .then((response) => {
                        if (response) {
                            if (response.status == 200) {
                                if (response.data) {
                                    this.posts = response.data.map((obj) => ({
                                        ...obj,
                                        isActive: false,
                                    }));
                                    this.checkPost();
                                    this.isLoading = false;
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (ex) {
                console.log(ex.message);
            }
        },
        checkPost() {
            for (let i = 0; i < this.posts.length; i++) {
                if (this.posts[i].PostID == this.articleIdMutate) {
                    this.articleIdMutate = i;
                    this.activeTab(this.articleIdMutate);
                    break;
                }
            }
        },
        activeTab(index) {
            this.posts.map((p) => (p.isActive = false));
            this.posts[index].isActive = true;
            this.$emit("post-clicked", this.posts[index].PostID);
        },
        numericMonth(mn) {
            var monthNum;
            switch (mn) {
                case "January":
                    monthNum = 1;
                    break;
                case "February":
                    monthNum = 2;
                    break;
                case "March":
                    monthNum = 3;
                    break;
                case "April":
                    monthNum = 4;
                    break;
                case "May":
                    monthNum = 5;
                    break;
                case "June":
                    monthNum = 6;
                    break;
                case "July":
                    monthNum = 7;
                    break;
                case "August":
                    monthNum = 8;
                    break;
                case "September":
                    monthNum = 9;
                    break;
                case "October":
                    monthNum = 10;
                    break;
                case "November":
                    monthNum = 11;
                    break;
                case "December":
                    monthNum = 12;
                    break;
            }
            return monthNum;
        },
        calculateYearRange(yearRange, fromYear) {
            for (let i = yearRange; i >= 0; i--) {
                this.listItems.push({
                    title: fromYear + i,
                    expanded: false,
                    isActiveClass: false,
                    months: [],
                });
            }
        },
        async pageLoadData(d) {
            var fetchDate = new Date(d);
            var month = fetchDate.toLocaleString('default', {
                month: 'long'
            });
            const articleYear = new Date(d).getFullYear();
            const articleMonth = new Date(d).getMonth();
            const articleMonthNew = articleMonth + 1;
            for (let i = 0; i < this.listItems.length; i++) {
                if (this.listItems[i].title == articleYear) {
                    await this.toggleAction(this.listItems[i].title, i);
                    for (let j = 0; j < this.listItems[i].months.length; j++) {
                        if (this.listItems[i].months[j].Month == month) {
                            this.toggleChildItem(this.el, i, j);
                            break;
                        }
                    }
                    await this.getPostDataByMonth(articleMonthNew, this.listItems[i].title);
                    break;
                }
            }
        }
    },
    computed: {
        articleData() {
            return this.listItems;
        },
    },
    mounted() {
        this.pageLoadData(this.articleDate);
    },
    created() {
        let currentYear = new Date().getFullYear();
        if (this.CurrentProduct.toUpperCase() == 'MDI' || this.CurrentProduct.toUpperCase() == 'MEI') {
            let fromYear = 2008;
            let dateRangeToDisplay = currentYear - fromYear;
            this.calculateYearRange(dateRangeToDisplay, fromYear);
        } else {
            let fromYear = 2009;
            let dateRangeToDisplay = currentYear - fromYear;
            this.calculateYearRange(dateRangeToDisplay, fromYear);
        }
    },
};
</script>

<style>
.glowEffect span:focus {
    box-shadow: none !important;
}

.glowEffect {
    width: 240px;
    margin-top: 8px;
}

.glowEffect .year-title-design {
    width: 240px;
    border: none;
    border-bottom: solid 1px white;
}

.glowEffect .no-rule-design {
    border: none;
}

.glowEffect>li>span>div>div:first-child {
    margin-left: -12px;
}

.glowEffect .year-title-design>span>div>div:nth-child(2)>div>span {
    font-weight: 700;
}

.glowEffect .month-title-design>span>div>div:nth-child(2)>div>span {
    color: #1E1E1E;
}

.glowEffect .month-title-design>span>div>div:first-child {
    margin-left: -10px;
}

.glowEffect .monthly-article-data-design>span>div {
    margin-left: -10px;
}
</style>
