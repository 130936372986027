<template>
  <div>
    <div v-if="this.$route.name === 'combined-home'">
      <div class="banner-alerts-main-div" v-for="(json, index) in JsonData" :key="index">
        <div v-if="isBeforeCurrentTime(json.StartDate)"></div>
        <div v-else-if="isBeforeCurrentTime(json.EndDate)">
          <mds-modal aria-label="Default Modal" actionRequired :width="'100%'" v-model="bannerToggle">
            <div class="content">
              <mds-alert variation="informational" :persistent="json.Persistent == 'true' ? true : false"
                @mds-alert-dismissed="CloseAlert">
                <div v-html="json.Description"></div>
              </mds-alert>
            </div>
          </mds-modal>
        </div>
        <div v-else></div>
      </div>
    </div>
    <div class="landing-combine">
      <div class="add-main-class">
        <ad currentPage="homepage"></ad>
      </div>
      <div class="landing-page-middle">
        <div class="landing-page-middle-container">
          <div class="landing-page-middle-right-div">
            <div class="landing-page-middle-text-div">
              <p class="landing-middle-text">
                Morningstar Investor Newsletters
              </p>
            </div>
            <div v-if="!this.$store.state.user.LoggedIn ||
      productsubscriptionoverlayStatus == true
      ">
              <p class="border-line"></p>
            </div>
            <div v-if="!this.$store.state.user.LoggedIn ||
      productsubscriptionoverlayStatus == true
      " class="combine-hero-image-buttons">
              <mds-button-container>
                <mds-button variation="secondary" :size="learnMoreButtonSize" icon-right="caret-down"
                  @click="toggle = !toggle" id="learnmore-trigger" class="button-learn-more">Learn More
                </mds-button>
                <mds-popover width="300px" triggered-by="learnmore-trigger" v-model="toggle"
                  class="hero-image-learn-more-popover">
                  <p class="list-product-trial">START A NEWSLETTER TRIAL</p>
                  <mds-list-group size="medium" rightAligned>
                    <mds-list-group-item :to="{
      name: 'marketing-landing',
      params: { currentproduct: 'mfi' },
    }"><span class="list-product-name">
                        Morningstar FundInvestor
                      </span></mds-list-group-item>
                    <mds-list-group-item :to="{
      name: 'marketing-landing',
      params: { currentproduct: 'msi' },
    }"><span class="list-product-name">
                        Morningstar StockInvestor
                      </span></mds-list-group-item>
                    <mds-list-group-item :to="{
      name: 'marketing-landing',
      params: { currentproduct: 'mdi' },
    }"><span class="list-product-name">
                        Morningstar DividendInvestor
                      </span></mds-list-group-item>
                    <mds-list-group-item :to="{
      name: 'marketing-landing',
      params: { currentproduct: 'mei' },
    }"><span class="list-product-name">
                        Morningstar ETFInvestor
                      </span></mds-list-group-item>
                  </mds-list-group>
                </mds-popover>
                <mds-button on-dark variation="secondary" size="large" class="button-sign-in"
                  v-if="!this.$store.state.user.LoggedIn" @click="login()">
                  Sign In</mds-button>
              </mds-button-container>
            </div>
            <div v-if="!this.$store.state.user.LoggedIn" class="landing-bottom-text-div">
              <span class="landing-bottom-text">
                If you are a print subscriber visiting for the first time,
                complete a
              </span>
              <br v-if="!isResponsiveToggle" />
              <router-link class="registration-link" :to="{
      name: 'print-registration',
      params: {
        currentproduct: this.$store.state.product.CurrentProduct,
      },
    }">
                one-time registration.
              </router-link>
            </div>
            <div v-if="this.$store.state.user.LoggedIn &&
      productsubscriptionoverlayStatus == false
      " class="landing-page-after-login">
              <p class="landing-text-after-login">
                Navigate to your newsletter’s homepage:
              </p>
              <div>
                <p class="border-line-after-login"></p>
              </div>
              <div class="common-login-product-navigation" v-if="!isResponsiveToggle">
                <div v-for="(product, index) in this.products" :key="index">
                  <mds-icon name="angle-double-right" class="common-product-access-icon"
                    v-if="product.access"></mds-icon>
                  <mds-icon name="lock-close" class="common-product-access-icon" v-if="!product.access"></mds-icon>
                  <span>
                    <router-link class="landingpage-navgation-button product-navigate-style" :to="{
      name: product.access ? 'home' : 'marketing-landing',
      params: {
        currentproduct: product.access
          ? product.name.toLowerCase()
          : product.name.toLowerCase(),
      },
    }">{{ product.product }}</router-link>
                  </span>
                </div>
              </div>
              <div v-else class="common-login-product-navigation">
                <div v-for="(product, index) in this.products" :key="index">
                  <mds-icon name="angle-double-right" class="common-product-access-icon"
                    v-if="product.access"></mds-icon>
                  <mds-icon name="lock-close" class="common-product-access-icon" v-if="!product.access"></mds-icon>
                  <router-link class="landingpage-navgation-button product-navigate-style" :to="{
      name: product.access ? 'home' : 'marketing-landing',
      params: {
        currentproduct: product.access
          ? product.name.toLowerCase()
          : product.name.toLowerCase(),
      },
    }">{{ product.product }}</router-link>
                </div>
              </div>
            </div>

            <div v-if="productsubscriptionoverlayStatus == true"
              class="landing-page-after-login-for-non-subcribed-user">
              <p class="landing-text-after-login-for-non-subcribed-user">
                Navigate to your newsletter’s homepage:
              </p>
              <div>
                <p class="border-line-after-login"></p>
              </div>
              <div class="common-login-product-navigation" v-if="!isResponsiveToggle &&
      productsubscriptionoverlayStatus == false
      ">
                <div v-for="(product, index) in this.products" :key="index">
                  <mds-icon name="angle-double-right" class="common-product-access-icon"
                    v-if="product.access"></mds-icon>
                  <mds-icon name="lock-close" class="common-product-access-icon" v-if="!product.access"></mds-icon>
                  <span>
                    <router-link class="landingpage-navgation-button product-navigate-style" :to="{
      name: product.access ? 'home' : 'marketing-landing',
      params: {
        currentproduct: product.access
          ? product.name.toLowerCase()
          : product.name.toLowerCase(),
      },
    }">{{ product.product }}</router-link>
                  </span>
                </div>
              </div>
              <div v-if="isResponsiveToggle &&
      productsubscriptionoverlayStatus == false
      " class="common-login-product-navigation">
                <div v-for="(product, index) in this.products" :key="index">
                  <mds-icon name="angle-double-right" class="common-product-access-icon"
                    v-if="product.access"></mds-icon>
                  <mds-icon name="lock-close" class="common-product-access-icon" v-if="!product.access"></mds-icon>
                  <router-link class="landingpage-navgation-button product-navigate-style" :to="{
      name: product.access ? 'home' : 'marketing-landing',
      params: {
        currentproduct: product.access
          ? product.name.toLowerCase()
          : product.name.toLowerCase(),
      },
    }">{{ product.product }}</router-link>
                </div>
              </div>
              <div v-if="productsubscriptionoverlayStatus == true"
                class="common-login-product-navigation-for-none-product-access">
                <div v-for="(product, index) in this.products" :key="index">
                  <mds-icon name="angle-double-right" class="common-product-access-icon"
                    v-if="product.access"></mds-icon>
                  <mds-icon name="lock-close" class="common-product-access-icon" v-if="!product.access"></mds-icon>
                  <router-link class="landingpage-navgation-button product-navigate-style" :to="{
      name: product.access ? 'home' : 'marketing-landing',
      params: {
        currentproduct: product.access
          ? product.name.toLowerCase()
          : product.name.toLowerCase(),
      },
    }">{{ product.product }}</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="landing-page-middle-left-div">
            <banner-cover-images></banner-cover-images>
          </div>
        </div>
      </div>
      <individual-newsletter-images></individual-newsletter-images>
      <editor-featured-commentary></editor-featured-commentary>
      <combine-videos></combine-videos>
      <banner-bottom-cover-image></banner-bottom-cover-image>

      <mds-modal v-model="RedirectFromLagecy" width="600px" size="small" aria-label="Default Modal"
        class="modal-For-Redirect-from-lagecy">
        <mds-section bold>
          <template>
            <mds-button-container right-aligned class="legacy-modal-close-button-div">
              <mds-button @click="ClosingTheWelcomeModel()" variation="icon-only" icon="remove">Close</mds-button>
            </mds-button-container>
          </template>
          <template>
            <h1 class="title-for-lagecy-modal">
              Welcome to the new Investor Newsletters website!
            </h1>
            <h1 class="modal-lagecy-para-one-border"></h1>
            <p class="modal-lagecy-para-one">
              We have taken the four, individual websites and combined them into
              one experience and homepage. The new homepage will give you easy
              access to your current issues, updated portfolio data, archives,
              and bonus reports.
            </p>

            <p class="modal-lagecy-para-second">
              Check out our
              <a href="https://www.morningstar.com/company/newsletters-help-center" target="_blank"
                class="getting-start-modal-anchor-tag">Getting Started</a>
              page for additional details.
            </p>
          </template>
        </mds-section>
      </mds-modal>
      <product-subscription-overlay v-if="productsubscriptionoverlayStatus"
        :subscriptionoverlayStatus="productsubscriptionoverlayStatus"
        @resetOverlayStatusValue="resetOverlayStatus"></product-subscription-overlay>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
import MdsIcon from "@mds/icon";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsPopover from "@mds/popover";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import BannerCoverImages from "@/components/Common/CombinedHome/BannerCoverImages.vue";
import IndividualNewsletterImages from "@/components/Common/CombinedHome/IndividualNewsletterImages.vue";
import EditorFeaturedCommentary from "@/components/Common/CombinedHome/EditorFeaturedCommentary.vue";
import CombineVideos from "@/components/Common/CombinedHome/CombineVideos.vue";
import BannerBottomCoverImage from "@/components/Common/CombinedHome/BannerBottomCoverImage.vue";
import ProductSubscriptionOverlay from "@/components/Common/Overlay/ProductSubscriptionOverlay.vue";
import { mapState } from "vuex";
import MdsModal from "@mds/modal";
import Ad from "@/components/Common/Ad/Ad.vue";
import MdsAlert from "@mds/alert";
export default {
  name: "landing-page",
  components: {
    MdsIcon,
    MdsButton,
    MdsButtonContainer,
    BannerCoverImages,
    IndividualNewsletterImages,
    EditorFeaturedCommentary,
    MdsPopover,
    MdsListGroup,
    MdsListGroupItem,
    CombineVideos,
    BannerBottomCoverImage,
    Ad,
    ProductSubscriptionOverlay,
    MdsModal,
    MdsAlert,
  },
  data() {
    return {
      PersistentForbanner: true,
      toggle: false,
      UlinData: false,
      bannerToggle: true,
      bannerToggleError: true,
      bannerToggleWarning: true,
      bannerToggleInfo: true,
      products: [],
      MEIAccess: false,
      MDIAccess: false,
      MSIAccess: false,
      MFIAccess: false,
      isResponsiveToggle: null,
      isNewRegister: false,
      learnMoreButtonSize: "large",
      productsubscriptionoverlayStatus: false,
      RedirectFromLagecy: false,
      bannerAlertBox: true,
      allBannersData:
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GET_ALL_BANNERS,
      JsonData: [],
      JsonDataForAlert: [],
      saveBannerDescription: "",
      AlertPersitent: false,
    };
  },
  created() {
    this.openmodelForNewLegacyUser();
    this.GetAllBannersData();
  },

  methods: {

    isBeforeCurrentTime(dateTime) {
      return new Date() < new Date(dateTime);
    },
    CloseAlert() {
      this.bannerToggle = false;
      // this.$store.state.bannerstate.AlertBox = false;
    },

    trimContent(content) {
      if (content != null) {
        var htmlData = content.replace(
          /<((?!p)(?!ul)(?!ol)(?!a)(?!li)(?!b)(?!BR)(?!span)(?!sup)(?!sub)(?!i)(?!strong)(?!CENTER)(?!h)(?!em)(?!table)(?!tbody)(?!tr)(?!td)(?!th)(?!hr)\s*\b\/?)[^>]+>/g,
          ""
        );
        //eslint-disable-next-line
        var trimInlineCss = htmlData.replace(/(style=\"[^\"]*\")/g, "");
        return trimInlineCss;
        //return htmlData;
      }
    },
    GetAllBannersData() {
      if (this.$store.state.bannerstate.AlertBox == true) {
        Vue.axios.get(this.allBannersData).then((response) => {
          //this.JSonData = response.data;
          var lstData = response.data;
          for (var i = 0; i < lstData.length; i++) {
            var obj = lstData[i];
            
            if (
              obj.IsDraft == "No" &&
              obj.Combined == "Yes" &&
              obj.Type == "Alert"
            ) {
              var Description = "";
              var Alert = "";
              var Persistent = "";
              var StartDate = "";
              var EndDate = "";
              if (obj.Description) {
                Description = obj.Description;
              }

              if (obj.Alert) {
                Alert = obj.Alert;
              }
              if (obj.Persistent) {
                Persistent = obj.Persistent;
              }
              if (obj.StartDate) {
                StartDate = obj.StartDate;
              }
              if (obj.EndDate) {
                EndDate = obj.EndDate;
              }
              this.JsonData.push({
                //Description: MainDescription,
                Description: Description,
                Alert: Alert,
                Persistent: Persistent,
                StartDate: StartDate,
                EndDate: EndDate,
              });
            }
          }
        });
      }
    },
    ClosingTheWelcomeModel() {
      this.RedirectFromLagecy = false;
      this.$router.replace("/");
    },

    openmodelForNewLegacyUser() {
      if (this.$route.query.redirect == "true") this.RedirectFromLagecy = true;
      this.$store.state.product.CurrentProduct = "Common";
      this.$session.set("current-product", "Common");
    },

    login() {
      this.$auth0.loginWithRedirect();
    },
    addProductOrder() {
      if (this.$store.state.user.LoggedIn) {
        if (this.MFIAccess) {
          this.products.push({
            access: true,
            product: "FundInvestor",
            name: "MFI",
          });
        }
        if (this.MSIAccess) {
          this.products.push({
            access: true,
            product: "StockInvestor",
            name: "MSI",
          });
        }
        if (this.MDIAccess) {
          this.products.push({
            access: true,
            product: "DividendInvestor",
            name: "MDI",
          });
        }
        if (this.MEIAccess) {
          this.products.push({
            access: true,
            product: "ETFInvestor",
            name: "MEI",
          });
        }
        if (!this.MFIAccess) {
          this.products.push({
            access: false,
            product: "FundInvestor",
            name: "MFI",
          });
        }
        if (!this.MSIAccess) {
          this.products.push({
            access: false,
            product: "StockInvestor",
            name: "MSI",
          });
        }
        if (!this.MDIAccess) {
          this.products.push({
            access: false,
            product: "DividendInvestor",
            name: "MDI",
          });
        }
        if (!this.MEIAccess) {
          this.products.push({
            access: false,
            product: "ETFInvestor",
            name: "MEI",
          });
        }
      }
    },
    resetOverlayStatus(toggleValue) {
      this.productsubscriptionoverlayStatus = toggleValue;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.$store.state.responsive.isMobileContent) {
      this.learnMoreButtonSize = "medium";
    }
    if (this.$store.state.user.NewRegister) {
      //this.isNewRegister= this.$store.state.user.NewRegister;
      location.reload();
    }
    this.isResponsiveToggle = this.$store.state.responsive.isResponsive;
    if (this.$store.state.user.LoggedIn) {
      this.productDataRoles =
        this.$auth0.user["https://morningstar.com/uim_roles"];
      if (this.productDataRoles != undefined && this.productDataRoles != null) {
        this.MEIAccess = this.productDataRoles.includes("EI_MEMBER");
        this.MDIAccess = this.productDataRoles.includes("DI_MEMBER");
        this.MSIAccess = this.productDataRoles.includes("SI_MEMBER");
        this.MFIAccess = this.productDataRoles.includes("FI_MEMBER");
      }
      if (
        !this.MEIAccess &&
        !this.MFIAccess &&
        !this.MSIAccess &&
        !this.MDIAccess
      ) {
        this.productsubscriptionoverlayStatus = true;
        this.RedirectFromLagecy = false;
      }
    }
    if (
      this.$session.exists() &&
      this.$session.get("current-product") != "Common"
    ) {
      if (this.$route.fullPath == "/") {
        this.$session.set("current-product", "Common");
        this.$store.state.product.CurrentProduct = "Common";
      }
    }
    this.addProductOrder();
    this.$forceUpdate();
    localStorage.removeItem("userDetails");
    localStorage.removeItem("MFIProductSubscription");
    localStorage.removeItem("MSIProductSubscription");
    localStorage.removeItem("MDIProductSubscription");
    localStorage.removeItem("MEIProductSubscription");
    localStorage.removeItem("ChaseErrorCode");
    localStorage.removeItem("CompleteChasePaymentData");
    localStorage.removeItem("PrintRegistrationDetails");
    localStorage.setItem("MoreVideosSelectedProduct", "");
  },
  computed: mapState({
    isResponsive: (state) => state.responsive.isResponsive,
    isResponsiveAlias: "isResponsive",
    isResponsiveState(state) {
      return state.responsive.isResponsive;
    },
    isMobileContent: (state) => state.responsive.isMobileContent,
    isMobileContentAlias: "isMobileContent",
    isMobileContentState(state) {
      return state.responsive.isMobileContent;
    },

    extractNames() {
      // Extract names from JsonData array
      return this.JsonData.map((record) => record.Description);
    },
  }),
  watch: {
    isResponsiveState() {
      this.isResponsiveToggle = this.isResponsiveState;
    },
    isMobileContentState() {
      if (this.isMobileContentState) {
        this.learnMoreButtonSize = "medium";
      } else {
        this.learnMoreButtonSize = "large";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@mds/constants";
@import "@mds/icon/src/icon_mixins";
@import "@mds/link";

@media only screen and (min-width: 0px) and (max-width: 320px) {
  .content {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -0%);
    /* Add styles for the content div */
  }
}

@media only screen and (min-width: 320px) and (max-width: 450px) {
  .content {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -5%);
    /* Add styles for the content div */
  }
}

@media only screen and (min-width: 450px) and (max-width: 660px) {
  .content {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -15%);
    /* Add styles for the content div */
  }
}

@media only screen and (min-width: 660px) and (max-width: 60000px) {
  .content {
    position: absolute;
    width: 500px;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -22%);
    /* Add styles for the content div */
  }
}

.landing-combine {
  max-width: 1280px;
  margin: 0 auto;
}

.main-class-for-banner-alerts {
  //width: 100%;
  align-content: center;
}

.banner-alerts-main-div {
  width: 100%;
  margin-top: 0px;
  margin-left: 0%;
  align-content: center;
  position: relative;
  //z-index: 810;
}

.add-main-class {}

@media only screen and (min-width: 660px) and (max-width: 60000px) {
  .main-alert-dismiss-button {
    margin-right: -80%;
  }
}

@media only screen and (min-width: 0px) and (max-width: 660px) {
  .main-alert-dismiss-button {
    margin-right: -50%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 60000px) {
  .title-for-lagecy-modal {
    width: 100%;
    font-size: 45px;
    line-height: 50px;
    color: #1e1e1e !important;
    font-weight: 250;
    padding-left: 40px;
    padding-right: 25px;
  }

  .modal-lagecy-para-one {
    width: 100%;
    font-size: 23px;
    line-height: 27px;
    color: #1e1e1e;
    font-weight: 300;
    padding-left: 40px;
    padding-right: 25px;
  }

  .modal-lagecy-para-second {
    width: 100%;
    margin-top: 20px;
    font-size: 23px;
    line-height: 27px;
    color: #1e1e1e;
    font-weight: 300;
    padding-left: 40px;
    padding-right: 25px;
    padding-bottom: 26px;
  }

  .modal-lagecy-para-one-border {
    width: 90%;
    margin-top: 42.5px;
    margin-bottom: 12px;
    border-top: #808080 solid 1px;
    margin-left: 40px;
  }
}

@media only screen and (min-width: 370px) and (max-width: 600px) {
  .title-for-lagecy-modal {
    width: 100%;
    font-size: 30px;
    line-height: 40px;
    color: #1e1e1e !important;
    font-weight: 250;
    padding-left: 40px;
    padding-right: 25px;
  }

  .modal-lagecy-para-one {
    width: 100%;
    font-size: 20px;
    line-height: 27px;
    color: #1e1e1e;
    font-weight: 300;
    padding-left: 40px;
    padding-right: 25px;
  }

  .modal-lagecy-para-second {
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    line-height: 27px;
    color: #1e1e1e;
    font-weight: 300;
    padding-left: 40px;
    padding-right: 25px;
    padding-bottom: 26px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 370px) {
  .title-for-lagecy-modal {
    width: 100%;
    font-size: 28px;
    line-height: 40px;
    color: #1e1e1e !important;
    font-weight: 250;
    padding-left: 20px;
    padding-right: 25px;
  }

  .modal-lagecy-para-one {
    width: 100%;
    font-size: 18px;
    line-height: 27px;
    color: #1e1e1e;
    font-weight: 300;
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-lagecy-para-second {
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    line-height: 27px;
    color: #1e1e1e;
    font-weight: 300;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 26px;
  }

  .modal-lagecy-para-one-border {
    width: 88%;
    margin-top: 42.5px;
    margin-bottom: 12px;
    border-top: #808080 solid 1px;
    margin-left: 20px;
  }
}

.modal-For-Redirect-from-lagecy {
  width: 100%;
}

.modal-For-Redirect-from-lagecy>div>div>mds-section {
  overflow-y: scroll;
}

.modal-For-Redirect-from-lagecy ::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.modal-For-Redirect-from-lagecy ::-webkit-scrollbar-thumb {
  background: rgba(171, 171, 171, 0.3);
  border-radius: 6px;
}

.getting-start-modal-anchor-tag {
  color: #1e1e1e;
}

.landing-page-middle {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  /*box-sizing:border-box;*/
  height: 447px;
  background-color: #1e1e1e;
}

.landing-page-middle-container {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
}

.landing-page-middle-right-div {
  /*width:38%;*/
  width: 52%;
  height: 320px;
  margin-top: 52px;
  /*margin-left:8.4%;*/
}

.landing-page-middle-left-div {
  width: 350px;
  height: 456px;
  margin-top: 41px;
  /*margin-right:8.6%;*/
  margin-left: auto;
  order: 2;
}

.landing-page-middle-text-div {
  width: 100%;
  height: 134px;
}

.landing-middle-text {
  font-weight: 250;
  font-size: 64px;
  line-height: 75px;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.border-line {
  border-bottom: #f2f2f2 solid 1px;
  width: 90%;
  margin: 48px 0px 0px 0px;
}

.combine-hero-image-buttons {
  margin-top: 20px;
}

.button-learn-more.mds-button.mds-button--secondary {
  background: #ffffff;
  color: #1e1e1e;
}

.button-sign-in.mds-button.mds-button--secondary {
  color: #ffffff;
  border: 1px solid #ffffff;
}

.list-product-trial {
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 12px;
  letter-spacing: 1.67px !important;
  text-transform: uppercase !important;
  color: #5e5e5e;
  padding-bottom: 4px;
}

.landing-bottom-text-div {
  margin: 26px 0px 0px 0px;
}

.landing-bottom-text {
  color: #ffffff;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  padding: 0;
}

.registration-link {
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  text-decoration: none;
  border-bottom: 1px solid #ffffff;
}

.landing-page-after-login {
  margin-top: 96px;
}

.landing-text-after-login {
  margin-top: 96px;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 27px;
  color: #ffffff;
}

.landing-page-after-login-for-non-subcribed-user {
  margin-top: 20px;
}

.landing-text-after-login-for-non-subcribed-user {
  margin-top: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 27px;
  color: #ffffff;
}

.border-line-after-login {
  border-bottom: #e5e5e5 solid 1px;
  margin-top: 22px;
}

.common-login-product-navigation {
  display: inline-flex;
  margin-top: 16px;
}

.common-login-product-navigation-for-none-product-access {
  display: inline-flex;
  margin-top: 16px;
}

.common-product-access-icon {
  @include mds-icon-color($mds-background-color-white);
  width: 20.03px;
  height: 17.64px;
}

.landingpage-navgation-button {
  color: #ffffff !important;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 27px;
  margin-left: 5px !important;
  text-decoration: none;
  margin-right: 28px !important;
}

@media only screen and (min-width: 320px) and (max-width: 390px) {
  .landing-page-middle-right-div {
    width: 45%;
  }
}

@media only screen and (min-width: 360px) and (max-width: 750px) {
  .combine-hero-image-buttons {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 360px) {
  .combine-hero-image-buttons {
    margin-top: 5px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 1280px) {
  .landing-combine {
    width: 100%;
  }

  .landing-page-middle {
    height: 400px;
    display: flex;
  }

  .landing-page-middle-text-div {
    height: auto;
  }

  .landing-page-middle-left-div {
    width: 300px;
    height: 400px;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 370px) and (max-width: 600px) {
  .modal-lagecy-para-one-border {
    width: 83%;
    margin-top: 42.5px;
    margin-bottom: 12px;
    border-top: #808080 solid 1px;
    margin-left: 40px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1280px) {
  .border-line {
    border-bottom: #f2f2f2 solid 1px;
    width: 90%;
    margin: 30px 0px 0px 0px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 1000px) {
  .border-line {
    display: none !important;
  }
}

@media only screen and (max-width: 738px) {
  .landing-page-middle {
    height: 320px;
    display: flex;
  }

  .landing-page-middle-right-div {
    height: 250px;
  }

  .landing-middle-text {
    color: #ffffff;
    font-weight: 250;
    font-style: normal;
    font-size: 28px;
    line-height: 35px;
  }

  .landing-page-middle-left-div {
    width: 230px;
    height: 300px;
    margin-top: 50px;
    margin-right: 5.5%;
  }

  .landing-bottom-text {
    color: #ffffff;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 23px;
    padding: 0;
  }

  .registration-link {
    font-weight: 300;
    font-size: 17px;
    line-height: 23px;
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid #ffffff;
  }
}

@media only screen and (max-width: 600px) {
  .landing-page-middle {
    height: 320px;
    display: flex;
  }

  .landing-middle-text {
    color: #ffffff;
    font-weight: 250;
    font-style: normal;
    font-size: 28px;
    line-height: 35px;
  }

  .border-line {
    display: none;
  }

  .button-sign-in {
    display: none;
  }
}

@media only screen and (max-width: 525px) {
  .landing-page-middle {
    height: 300px;
    display: flex;
  }

  .landing-page-middle-right-div {
    height: 200px;
  }

  .landing-page-middle-left-div {
    width: 200px;
    height: 280px;
    margin-top: 50px;
    margin-right: 5.5%;
  }

  .landing-bottom-text-div {
    margin: 13px 0px 0px 0px;
  }

  .landing-bottom-text {
    color: #ffffff;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;
    padding: 0;
  }

  .registration-link[data-v-08f6935f] {
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid #ffffff;
  }
}

@media only screen and (max-width: 480px) {
  .landing-combine {
    width: 100%;
    overflow-x: hidden;
  }

  .landing-page-middle {
    width: 100%;
    height: 241px;
    display: flex;
  }

  .landing-page-middle-right-div {
    margin-top: 18px;
    margin-left: 25px;
    height: 210px;
  }

  .landing-page-middle-text-div {
    width: 329px;
    height: auto;
  }

  .landing-middle-text {
    color: #ffffff;
    font-weight: 250;
    font-style: normal;
    font-size: 28px;
    line-height: 34px;
  }

  .landing-bottom-text-div {
    width: 125px;
    /*width:100%;*/
    height: 86px;
  }

  .landing-bottom-text,
  .registration-link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 18px;
    color: #ffffff;
  }

  .landing-page-middle-left-div {
    width: 144px;
    height: 187px;
    margin-top: 70px;
  }
}

@media only screen and (max-width: 360px) {
  .landing-page-middle-text-div {
    width: 300px;
    height: auto;
  }

  .landing-middle-text {
    color: #ffffff;
    font-weight: 250;
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
  }

  .registration-link {
    font-weight: 300;
    font-size: 14px !important;
    line-height: 23px;
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid #ffffff;
  }
}

@media only screen and (max-width: 340px) {
  .landing-page-middle-right-div {
    margin-top: 18px;
    margin-left: 12px;
    height: 210px;
  }

  .landing-bottom-text-div {
    text-wrap: balance;
  }

  .landing-page-middle-text-div {
    width: 300px;
    height: auto;
  }

  .landing-middle-text {
    color: #ffffff;
    font-weight: 250;
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
  }

  .landing-bottom-text {
    font-style: normal;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 18px;
    color: #ffffff;
  }

  .registration-link {
    font-weight: 300;
    font-size: 14px !important;
    line-height: 23px;
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid #ffffff;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .common-product-access-icon {
    @include mds-icon-color($mds-background-color-white);
    width: 10.85px;
    height: 11.88px;
  }

  .landingpage-navgation-button {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    text-decoration: none;
  }

  .landing-middle-text {
    color: #ffffff;
    font-weight: 250;
    font-style: normal;
    font-size: 28px;
    line-height: 34px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 999px) {
  .border-line-after-login {
    display: none;
  }

  .landing-text-after-login {
    display: none;
  }

  .landing-text-after-login-for-non-subcribed-user {
    display: none;
  }

  .common-login-product-navigation {
    margin-top: -80px;
    line-height: 30px;
    display: block;
  }

  .common-login-product-navigation-for-none-product-access {
    line-height: 30px;
    display: block;
    margin-top: -8px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 999px) {
  .common-login-product-navigation-for-none-product-access {
    line-height: 30px;
    display: block;
    margin-top: -8px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 360px) {
  .common-login-product-navigation-for-none-product-access {
    line-height: 30px;
    display: block;
    margin-top: -20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1199px) {
  .landing-page-middle-container {
    width: 100vw;
  }

  .landing-page-middle-left-div {
    margin-right: 5.5%;
  }

  .landing-page-middle-right-div {
    margin-left: 5%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1290px) {
  .landing-page-middle-container {
    width: 90vw;
  }
}

@media only screen and (min-width: 1195px) and (max-width: 1290px) {
  .landing-page-middle-right-div {
    width: 64%;
  }

  .landing-middle-text {
    line-height: 66px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1088px) {
  .landing-page-middle-right-div {
    width: 57%;
  }

  .landing-middle-text {
    line-height: 66px;
  }

  .landingpage-navgation-button {
    font-size: 20px;
    line-height: 27px;
    margin-right: 22px !important;
  }

  .common-product-access-icon {
    width: 17.03px;
    height: 14.64px;
  }
}

@media only screen and (min-width: 1088px) and (max-width: 1199px) {
  .landing-page-middle-right-div {
    width: 61%;
  }

  .landing-middle-text {
    line-height: 66px;
  }
}

@media only screen and (min-width: 739px) and (max-width: 999px) {
  .landing-middle-text {
    font-size: 46px;
    line-height: 54px;
  }

  .landing-page-middle-right-div {
    width: 45%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 999px) {
  .landing-combine {
    width: 100%;
    /*margin-top: 110px;*/
  }
}

@media only screen and (min-width: 631px) and (max-width: 738px) {
  .landing-middle-text {
    font-size: 35px;
    line-height: 38px;
  }
}
</style>
<style>
.content>div>div {
  margin-right: -5px;
  background: white;
}

.content>div>div:nth-child(2) {
  background: white;
  padding-left: 10px;
}

.content {
  margin-top: 30px;
}

.button-learn-more.mds-button.mds-button--secondary>span:nth-child(2)>svg>path {
  stroke: #1e1e1e;
}

.hero-image-learn-more-popover>div>ul>li>span>div,
.hero-image-learn-more-popover>div>ul>li>a>div {
  padding: 4px 0px !important;
}

.banner-alerts-main-div .mds-modal__content {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
}

.banner-alerts-main-div .mds-modal {
  top: -37%;
  position: relative !important;
  z-index: 1;
}

.banner-alerts-main-div .mds-banner__message {
  padding-left: 30px;
}

.banner-alerts-main-div .mds-modal .mds-modal__wrapper {
  box-shadow: none !important;
  width: 100%;
  border-radius: 0px;
  border: none !important;
}

.banner-alerts-main-div .mds-modal>div:nth-child(2) {
  background: none;
  position: absolute;
  left: none !important;
  top: none !important;
  right: none !important;

  visibility: hidden;
  z-index: 1;
}

.main-alert-class-for-banners .mds-alert__list li::before {
  content: none;
}

.main-alert-class-for-banners .mds-alert__dismiss-button {
  top: 17px;
}

.main-alert-class-for-banners .mds-alert__body {
  padding-top: 6px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  color: FF1E1E1E;
}

.main-alert-class-for-banners .mds-alert__variation {
  padding-left: 17px;
  padding-right: 17px;
}

.main-alert-class-for-banners .mds-alert__list {
  padding-left: 0px;
}

.main-alert-class-for-banners-bullet-point .mds-alert__dismiss-button {
  top: 17px;
}

.main-alert-class-for-banners-bullet-point .mds-alert__body {
  padding-top: 6px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
  color: FF1E1E1E;
}

.main-alert-class-for-banners-bullet-point .mds-alert__variation {
  padding-left: 17px;
  padding-right: 17px;
}
</style>