<template>
    <div>
        <div class="my-profile-loader">
            <mds-loader v-if="isLoading" aria-label="Medium Loader"></mds-loader>
        </div>
        <mds-layout-grid>
            <mds-row>
                <mds-col :cols="12">
                    <div class="my-profile-alert" v-if="customAlertSuccess" tinted>
                        <mds-alert variation="success" size="small" :list="lstSuccess" @mds-alert-dismissed="customAlertSuccess=false">
                        </mds-alert>
                    </div>
                    <div class="my-profile-alert" v-if="customAlertError" tinted>
                        <mds-alert variation="error" title="Form Error" size="small" :list="lstError" @mds-alert-dismissed="customAlertError=false">
                        </mds-alert>
                    </div>
                    <div class="my-profile-alert" v-if="OmahaCustomer" tinted>
                        <mds-alert variation="error" title="" size="small" :list="lstOmahaCustomerError" @mds-alert-dismissed="OmahaCustomer=false">
                        </mds-alert>
                    </div>
                </mds-col>
            </mds-row>
        </mds-layout-grid>
    
        <mds-layout-grid>
            <mds-row>
                <mds-col :cols="12">
                    <!--  Name  -->
                    <div v-if="NameDisplay">
                        <div class="my-profile-box">
                            <div style="margin:23px;">
                                <mds-layout-grid>
                                    <mds-row>
                                        <mds-col :cols="9">
                                            <div class="my-profile-heading">Name</div>
                                            <div class="my-profile-text">{{Name}}</div>
                                        </mds-col>
                                        <mds-col :cols="3" style="float: right;">
                                            <div style="float: inherit;" class="my-profile-secondarytext" @click="NameClick">Edit &nbsp;<mds-icon name="pencil" size="small"></mds-icon>
                                            </div>
                                        </mds-col>
                                    </mds-row>
                                </mds-layout-grid>
                            </div>
                        </div>
                    </div>
    
                    <div style="margin-top: 0px;" v-if="NameEdit">
                        <div class="my-profile-box-name-edit" id="my-profile-name">
                            <div style="margin:23px;">
                                <mds-layout-grid>
                                    <mds-row>
                                        <mds-col :cols="6" :cols-at-l="6" :cols-at-xl="4" :cols-at-m="6" :cols-at-s="6">
                                            <mds-input size="medium" :error="FirstNameError" v-model="FirstName" label="First Name" type="text" required class="my-profile-heading-textbox"></mds-input>
                                            <span class="my-profile-forms-error" v-if="FirstNameError">{{FirstNameErrorMsg}}</span>
                                        </mds-col>
                                        <mds-col :cols="6" :cols-at-l="6" :cols-at-xl="4" :cols-at-m="6" :cols-at-s="6">
                                            <mds-input size="medium" :error="LastNameError" v-model="LastName" label="Last Name" type="text" required class="my-profile-heading-textbox"></mds-input>
                                            <span class="my-profile-forms-error" v-if="LastNameError">{{LastNameErrorMsg}}</span>
                                        </mds-col>
                                        <mds-col :cols="12" :cols-at-l="12" :cols-at-xl="4" :cols-at-m="12" :cols-at-s="12" style="float: left;">
                                            <div style="float: inherit;margin-top: 27px;">
                                                <mds-button variation="secondary" @click="CancelName()"> Cancel </mds-button>
                                                <mds-button variation="primary" v-if="btnNameSave" style="margin-left: 7px;" @click="SaveName()"> Save </mds-button>
                                                <mds-button v-if="btnNameLoading" style="margin-left:7px; width: 60px;" variation="primary" loading>Loading</mds-button>
                                            </div>
                                        </mds-col>
                                    </mds-row>
                                </mds-layout-grid>
                            </div>
                        </div>
                    </div>
                    <!--  Name  -->
    
                    <!--  PhoneNumber  -->
                    <div style="margin-top: 24px;" v-if="PhoneNumberDisplay">
                        <div class="my-profile-phonenumber-box">
                            <div style="margin:23px;">
                                <mds-layout-grid>
                                    <mds-row>
                                        <mds-col :cols="9" :cols-at-l="10" :cols-at-xl="10" :cols-at-m="9" :cols-at-s="9">
                                            <div class="my-profile-secondarytext">This is the information we'll use to contact you about matters related to your subscription and/or account, as well as special offers reserved for Morningstar subscribers.</div>
                                        </mds-col>
                                        <mds-col :cols="3" :cols-at-l="2" :cols-at-xl="2" :cols-at-m="3" :cols-at-s="3" style="float: right;">
                                            <div style="float: inherit;" class="my-profile-secondarytext" @click="PhoneNumberClick">Edit &nbsp;<mds-icon name="pencil" size="small"></mds-icon>
                                            </div>
                                        </mds-col>
                                    </mds-row>
                                </mds-layout-grid>
                                <div style="margin-top: 29px;">
                                    <div class="my-profile-heading">Email</div>
                                    <div class="my-profile-text">{{Email}}</div>
                                </div>
                                <div style="margin-top: 23px;">
                                    <div v-if="Mphone" class="my-profile-contact-number-container">
                                        <div class="my-profile-heading">Mobile Phone</div>
                                        <div class="my-profile-text">{{Mphone}}</div>
                                    </div>
                                    <div v-if="Dphone" class="my-profile-contact-number-container">
                                        <div class="my-profile-heading">Home Phone</div>
                                        <div class="my-profile-text">{{Dphone}}</div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
    
                    <div style="margin-top: 24px;" v-if="PhoneNumberEdit">
                        <div class="my-profile-phonenumber-box-edit" id="my-profile-phone-number-id">
                            <div style="margin:23px;">
                                <mds-layout-grid>
                                    <mds-row>
                                        <mds-col :cols="12" :cols-at-l="12" :cols-at-xl="12" :cols-at-m="12" :cols-at-s="12">
                                            <div class="my-profile-secondarytext">
                                                This is the information we'll use to contact you about matters related to your subscription and/or account, as well as special offers reserved for Morningstar subscribers.
                                            </div>
                                        </mds-col>
                                    </mds-row>
                                </mds-layout-grid>
                                <div style="margin-top: 29px;">
                                    <mds-layout-grid>
                                        <mds-row>
                                            <mds-col :cols="12" :cols-at-l="8" :cols-at-xl="8" :cols-at-m="12" :cols-at-s="8">
                                                <mds-input size="medium" :error="EmailError" v-model="txtEmail" label="Email" type="text" required class="my-profile-heading-textbox"></mds-input>
                                                <span class="my-profile-forms-error" v-if="EmailError">{{EmailErrorMsg}}</span>
                                            </mds-col>
                                        </mds-row>
                                        <mds-row>
                                            <mds-col :cols="12" :cols-at-l="8" :cols-at-xl="8" :cols-at-m="12" :cols-at-s="8">
                                                <div style="margin-top: 24px;">
                                                    <mds-input :error="PasswordError" password value="password" label="Password" v-model="txtVerifyPassword" required class="my-profile-heading-textbox"></mds-input>
                                                    <!-- <span class="my-profile-forms-error" v-if="PasswordError">{{PasswordErrorMsg}}</span> -->
                                                </div>
                                                <div :class="divpassError">
                                                    Please re-enter your password before saving changes.
                                                </div>
                                            </mds-col>
                                        </mds-row>
                                        <mds-row v-if="isMphonePresent">
                                            <mds-col :cols="phoneTypeNameSizeDefault" :cols-at-l="phoneTypeNameSizeLarge" :cols-at-xl="phoneTypeNameSizeLarge" :cols-at-m="phoneTypeNameSizeMedium" :cols-at-s="phoneTypeNameSizeSmall" class="my-profile-phone-number-type-container">
                                                <div class="my-profile-phone-number-type-label">
                                                    Phone Type
                                                </div>
                                                <div class="my-profile-phone-number-type-name">Mobile</div>
                                            </mds-col>
                                            <mds-col :cols="phoneInputSizeDefault" :cols-at-l="phoneInputSizeLarge" :cols-at-xl="phoneInputSizeLarge" :cols-at-m="phoneInputSizeMedium" :cols-at-s="phoneInputSizeSmall" class="my-profile-phone-number-input-box">
                                                <div class="my-profile-phone-number-input-box-container">
                                                    <mds-input :required="isPhoneRequired" size="medium" :error="mobilePhoneError" v-model="Mphone" label="Phone Number" type="text" class="my-profile-heading-textbox"></mds-input>
                                                    <span class="my-profile-forms-error" v-if="mobilePhoneError">{{mobilePhoneErrorMsg}}</span>
                                                </div>
                                            </mds-col>
    
                                            <mds-col :cols="1" :cols-at-l="4" :cols-at-xl="4" :cols-at-m="2" :cols-at-s="4" v-if="showRemoveNumberButton">
                                                <mds-button :disabled="disableRemoveButton" :size="removeNumberButtonSize" variation="flat" icon="remove" type="button" class="add-secondary-number-button remove-number-button" @click="removeNumber('mobileNumber')">{{ removeNumberText }}</mds-button>
                                            </mds-col>
                                        </mds-row>
    
                                        <mds-row v-if="isMphonePresent">
                                            <mds-col :cols="12" :cols-at-l="12" :cols-at-xl="12" :cols-at-m="12" :cols-at-s="12">
                                                <div style="margin-top: 28px;">
                                                    <mds-checkbox value="accept" v-model="chkPhoneCall">
                                                        <span class="my-profile-secondarytext">By checking this box and submitting this form, I give Morningstar consent to call me at any number provided here using automated technology to discuss matters related to my subscription and/or account and tell me about special offers reserved for Morningstar subscribers. I understand my consent is not required to receive products and/or services from Morningstar.</span>
                                                    </mds-checkbox>
                                                </div>
                                            </mds-col>
                                        </mds-row>
    
                                        <mds-row v-if="isDphonePresent">
                                            <mds-col :cols="phoneTypeNameSizeDefault" :cols-at-l="phoneTypeNameSizeLarge" :cols-at-xl="phoneTypeNameSizeLarge" :cols-at-m="phoneTypeNameSizeMedium" :cols-at-s="phoneTypeNameSizeSmall" class="my-profile-phone-number-type-container">
                                                <div class="my-profile-phone-number-type-label">
                                                    Phone Type
                                                </div>
                                                <div class="my-profile-phone-number-type-name">Home</div>
                                            </mds-col>
                                            <mds-col :cols="phoneInputSizeDefault" :cols-at-l="phoneInputSizeLarge" :cols-at-xl="phoneInputSizeLarge" :cols-at-m="phoneInputSizeMedium" :cols-at-s="phoneInputSizeSmall" class="my-profile-phone-number-input-box">
                                                <div class="my-profile-phone-number-input-box-container">
                                                    <mds-input :required="isPhoneRequired" size="medium" :error="homePhoneError" v-model="Dphone" label="Phone Number" type="text" class="my-profile-heading-textbox"></mds-input>
                                                    <span class="my-profile-forms-error" v-if="homePhoneError">{{homePhoneErrorMsg}}</span>
                                                </div>
                                            </mds-col>
                                            <mds-col :cols="1" :cols-at-l="4" :cols-at-xl="4" :cols-at-m="2" :cols-at-s="4" v-if="showRemoveNumberButton">
                                                <mds-button :disabled="disableRemoveButton" :size="removeNumberButtonSize" variation="flat" icon="remove" type="button" class="add-secondary-number-button remove-number-button" @click="removeNumber('homeNumber')">{{ removeNumberText }}</mds-button>
                                            </mds-col>
                                        </mds-row>
    
                                        <mds-row v-if="showSecondaryNumberButton">
                                            <mds-col>
                                                <mds-button variation="flat" icon="plus" type="button" class="add-secondary-number-button" @click="addSecondaryNumberToggle('fromButton')">Add Secondary Number</mds-button>
                                            </mds-col>
                                        </mds-row>
                                        <mds-row v-if="showSecondaryNumberInput">
                                            <mds-col :cols="3" :cols-at-l="2" :cols-at-xl="2" :cols-at-m="3" :cols-at-s="2">
                                                <div class="my-profile-phone-number-type-label">
                                                    Phone Type
                                                </div>
                                                <div class="my-profile-phone-number-type-name">{{secondaryPhoneTyeName}}</div>
                                            </mds-col>
                                            <mds-col :cols="9" :cols-at-l="6" :cols-at-xl="6" :cols-at-m="9" :cols-at-s="6" class="my-profile-phone-number-input-box">
                                                <div class="my-profile-phone-number-input-box-container">
                                                    <mds-input size="medium" :error="secondaryPhoneError" v-model="secondaryNumberValue" label="Phone Number" type="text" class="my-profile-heading-textbox"></mds-input>
                                                    <span class="my-profile-forms-error" v-if="secondaryPhoneErrorMsg">{{secondaryPhoneErrorMsg}}</span>
                                                </div>
                                            </mds-col>
                                            <mds-col v-if="isMobileConsentOn" :cols="12" :cols-at-l="12" :cols-at-xl="12" :cols-at-m="12" :cols-at-s="12">
                                                <div style="margin-top: 28px;">
                                                    <mds-checkbox value="accept" v-model="chkPhoneCall">
                                                        <span class="my-profile-secondarytext">By checking this box and submitting this form, I give Morningstar consent to call me at any number provided here using automated technology to discuss matters related to my subscription and/or account and tell me about special offers reserved for Morningstar subscribers. I understand my consent is not required to receive products and/or services from Morningstar.</span>
                                                    </mds-checkbox>
                                                </div>
                                            </mds-col>
                                        </mds-row>
                                        <mds-row>
                                            <mds-col :cols="12" style="float: right;">
                                                <div style="float: inherit;margin-top: 24px;">
                                                    <mds-button variation="secondary" @click="CancelPhoneNumber()"> Cancel </mds-button>
                                                    <mds-button v-if="btnPhone" variation="primary" style="margin-left: 10px;" @click="SavePhoneNumber()"> Save </mds-button>
                                                    <mds-button v-if="btnPhoneLoading" style="margin-left: 10px; width: 60px;" variation="primary" loading>Loading</mds-button>
                                                </div>
                                            </mds-col>
                                        </mds-row>
                                    </mds-layout-grid>
                                </div>
                            </div>
    
                        </div>
                    </div>
                    <!--  PhoneNumber  -->
    
                    <!-- Password -->
                    <div style="margin-top: 24px;" v-if="PasswordDisplay">
                        <div class="my-profile-box">
                            <div style="margin:23px;">
                                <mds-layout-grid>
                                    <mds-row>
                                        <mds-col :cols="9">
                                            <div class="my-profile-heading">Password</div>
                                            <div class="my-profile-text">•••••••••••</div>
                                        </mds-col>
                                        <mds-col :cols="3" style="float: right;">
                                            <div style="float: inherit;" class="my-profile-secondarytext" @click="PasswordClick()">Edit &nbsp;<mds-icon name="pencil" size="small"></mds-icon>
                                            </div>
                                        </mds-col>
                                    </mds-row>
                                </mds-layout-grid>
                            </div>
                        </div>
                    </div>
    
                    <div style="margin-top: 24px;" v-if="PasswordEdit">
                        <div class="my-profile-box-name-edit" id="my-profile-password-id">
                            <div style="margin:23px;">
                                <mds-layout-grid>
                                    <mds-row>
                                        <mds-col :cols="6" :cols-at-l="4" :cols-at-xl="4" :cols-at-m="6" :cols-at-s="6">
                                            <mds-input :error="OldPasswordError" password value="password" label="Old Password" v-model="txtOldPassword" required class="my-profile-heading-textbox"></mds-input>
                                            <span class="my-profile-forms-error" v-if="OldPasswordError">{{OldPasswordErrorMsg}}</span>
                                        </mds-col>
                                        <mds-col :cols="6" :cols-at-l="4" :cols-at-xl="4" :cols-at-m="6" :cols-at-s="6">
                                            <mds-input :error="NewPasswordError" password value="password" label="New Password" v-model="txtNewPassword" required class="my-profile-heading-textbox"></mds-input>
                                            <span class="my-profile-forms-error" v-if="NewPasswordError">{{NewPasswordErrorMsg}}</span>                                        
                                        </mds-col>                                
                                        <mds-col :cols="12" :cols-at-l="12" :cols-at-xl="4" :cols-at-m="12" :cols-at-s="12" style="float: right;">
                                            <div style="float: inherit;margin-top: 27px;">
                                                <mds-button variation="secondary" @click="CancelPassword()"> Cancel </mds-button>
                                                <mds-button variation="primary" v-if="btnPasswordSave" style="margin-left: 7px;" @click="UpdatePassword()"> Save </mds-button>
                                                <mds-button v-if="btnPasswordLoading" style="margin-left: 7px; width: 60px;" variation="primary" loading>Loading</mds-button>
                                            </div>
                                        </mds-col>
                                    </mds-row>
                                    <mds-row>
                                        <mds-col :cols="9" :cols-at-l="10" :cols-at-xl="10" :cols-at-m="9" :cols-at-s="9">
                                            <p v-bind:class="[
                                                this.validatePasswordError
                                                  ? 'my-profile-password-text-error'
                                                  : 'my-profile-password-text',
                                                'my-profile-password-text-error',
                                              ]">
                                                Your password must be 8 or more characters, with one upper or lower case letter, one special character (e.g., ?$%!@#) AND one number (1-9). It cannot contain more than 3 sequential or repeating characters, username, mstar, or Morningstar.
                                        </p>
                                        </mds-col>
                                    </mds-row>
                                </mds-layout-grid>
                            </div>
                        </div>
                    </div>
                    <!-- Password -->
    
                    <!-- Address -->
                    <div style="margin-top: 24px;" v-if="AddressDisplay">
                        <div class="my-profile-box">
                            <div style="margin:23px;">
                                <mds-layout-grid>
                                    <mds-row>
                                        <mds-col :cols="9">
                                            <div class="my-profile-heading">Address</div>
                                            <div class="my-profile-text">{{Address}}</div>
                                        </mds-col>
                                        <mds-col :cols="3" style="float: right;">
                                            <div style="float: inherit;" class="my-profile-secondarytext" @click="AddressClick()">Edit &nbsp;<mds-icon name="pencil" size="small"></mds-icon>
                                            </div>
                                        </mds-col>
                                    </mds-row>
                                </mds-layout-grid>
                            </div>
                        </div>
                    </div>
                    <!-- Address -->
                    <div style="margin-top: 24px;" v-if="EditAddress">
                        <div class="my-profile-address-editbox" id="my-profile-address-id">
                            <div style="margin: 23px 23px 23px 0px;">
                                <div class="my-profile-address-editdiv">
                                    <div class="my-profile-address-inside-flex">
                                        <mds-select :error="CountryError" size="medium" v-model="txtCountry" label="Country" placeholder="Please select country" @change="countryChange()" required class="my-profile-heading-textbox">
                                            <option v-bind:key="country.CountryCode" :value="country.Country" v-for="country in lstCountry">
                                                {{ country.Country }}
                                            </option>
                                        </mds-select>
                                        <span class="my-profile-forms-error" v-if="CountryError">{{CountryErrorMsg}}</span>
                                    </div>
                                </div>
                                <div class="my-profile-address-editdiv">
                                    <div class="my-profile-address-inside-flex">
                                        <mds-input size="medium" :error="Address1Error" v-model="txtAddress1" label="Address 1" type="text" required class="my-profile-heading-textbox"></mds-input>
                                        <span class="my-profile-forms-error" v-if="Address1Error">{{txtAddress1ErrorMsg}}</span>
                                    </div>
                                </div>
                                <div class="my-profile-address-editdiv">
                                    <div class="my-profile-address-inside-flex">
                                        <mds-input size="medium" v-model="txtAddress2" label="Address 2" type="text" class="my-profile-heading-textbox"></mds-input>
                                        <!-- <span class="my-profile-forms-error" v-if="Address2Error">{{txtAddress2ErrorMsg}}</span> -->
                                    </div>
                                </div>
                                <div class="my-profile-address-editdiv my-profile-city">
                                    <div class="my-profile-address-inside-flex">
                                        <mds-input size="medium" :error="cityError" v-model="txtCity" label="City" type="text" required class="my-profile-heading-textbox"></mds-input>
                                        <span class="my-profile-forms-error" v-if="cityError">{{txtCityErrorMsg}}</span>
                                    </div>
                                </div>
                                <div class="my-profile-address-editdiv my-profile-city">
                                    <div class="my-profile-address-inside-flex">
                                        <div v-if="UsaState">
                                            <mds-select :error="USAStateError" size="medium" v-model="txtUsaState" label="State" placeholder="Select state" required class="my-profile-heading-textbox">
                                                <option v-bind:key="usa.StateAbbr" :value="usa.StateAbbr" v-for="usa in lstUsaState">
                                                    {{ usa.StateName }}
                                                </option>
                                            </mds-select>
                                            <span class="my-profile-forms-error" v-if="USAStateError">Select State</span>
                                        </div>
                                        <div v-if="CanadaState">
                                            <mds-select :error="CanadaStateError" size="medium" v-model="txtCanadaState" label="Province" placeholder="Select Province" class="my-profile-heading-textbox">
                                                <option v-bind:key="can.StateAbbr" :value="can.StateAbbr" v-for="can in lstCanadaState">
                                                    {{ can.StateName }}
                                                </option>
                                            </mds-select>
                                            <span class="my-profile-forms-error" v-if="CanadaStateError">Select Province</span>
                                        </div>
                                        <div v-if="OtherState">
                                            <mds-input size="medium" v-model="txtOtherState" label="State/Province/APO" type="text" class="my-profile-heading-textbox"></mds-input>
                                            <!-- <span class="my-profile-forms-error" v-if="OtherStateError">{{txtStateErrorMsg}}</span> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="my-profile-address-editdiv">
                                    <div class="my-profile-address-inside-flex">
                                        <mds-input size="medium" :error="zipCodeError" v-model="txtZipCode" :label="lblZipCode" type="text" required class="my-profile-heading-textbox"></mds-input>
                                        <span class="my-profile-forms-error" v-if="zipCodeError">{{txtZipCodeErrorMsg}}</span>
                                    </div>
                                </div>
    
                                <mds-layout-grid>
                                    <mds-row>
                                        <mds-col :cols="12" style="float: right;">
                                            <div class="my-profile-address-btn">
                                                <mds-button variation="secondary" @click="CancelAddress()"> Cancel </mds-button>
                                                <mds-button v-if="btnAddressSave" variation="primary" style="margin-left: 10px;" @click="SaveAddress('Address')"> Save </mds-button>
                                                <mds-button v-if="btnAddressLoading" style="margin-left: 10px; width: 60px;" variation="primary" loading>Loading</mds-button>
                                            </div>
                                        </mds-col>
                                    </mds-row>
                                </mds-layout-grid>
    
                            </div>
                        </div>
                    </div>
                </mds-col>
            </mds-row>
        </mds-layout-grid>
    </div>
    </template>
    
    <script>
    import Vue from "vue";
    import VueAxios from "vue-axios";
    import axios from "axios";
    Vue.use(VueAxios, axios);
    import {
        MdsLayoutGrid,
        MdsRow,
        MdsCol
    } from "@mds/layout-grid";
    import MdsIcon from "@mds/icon";
    import MdsInput from "@mds/input";
    import MdsLoader from "@mds/loader";
    import MdsSelect from "@mds/select";
    import MdsCheckbox from '@mds/checkbox';
    import MdsAlert from '@mds/alert';
    import {
        MdsButton
    } from "@mds/button";
    export default {
        name: "my-profile",
        components: {
            MdsLayoutGrid,
            MdsRow,
            MdsCol,
            MdsIcon,
            MdsInput,
            MdsButton,
            MdsLoader,
            MdsSelect,
            MdsCheckbox,
            MdsAlert
        },
        data() {
            return {
    
                customAlertError: false,
                lstError: [],
                customAlertSuccess: false,
                lstSuccess: [],
                lstOmahaCustomerError: [],
                OmahaCustomer: false,
    
                isLoading: false,
    
                Name: "",
                Address: "",
                Email: "",
    
                NameDisplay: true,
                NameEdit: false,
                FirstName: "",
                LastName: "",
                userID: "",
    
                userDetails: "",
    
                FirstNameError: false,
                FirstNameErrorMsg: "Please enter first name",
    
                LastNameError: false,
                LastNameErrorMsg: "Please enter last name",
                btnNameSave: true,
                btnNameLoading: false,
    
                // Phone Number
                Mphone: "",
                Dphone: "",
                PhoneNumberDisplay: true,
                PhoneNumberEdit: false,
                isMphonePresent:false,
                isMobileConsentOn:false,
                isDphonePresent:false,
                isPhoneRequired:true,
                showRemoveNumberButton:false,
                disableRemoveButton:true,
                showSecondaryNumberButton:true,
                secondaryPhoneTyeName:"",
                showSecondaryNumberInput:false,
                secondaryNumberValue:"",
                mobilePhoneError:false,
                mobilePhoneErrorMsg:"",
                homePhoneError:false,
                homePhoneErrorMsg:"",
                secondaryPhoneError:false,
                secondaryPhoneErrorMsg:"",
                isRemoveNumberActive:false,
                removeNumberText:"Remove Number",
                viewPortWidth:null,
                removeNumberButtonSize:"small",
                phoneTypeNameSizeDefault:2,
                phoneTypeNameSizeSmall:1,
                phoneTypeNameSizeMedium:2,
                phoneTypeNameSizeLarge:2,
                phoneInputSizeDefault:8,
                phoneInputSizeSmall:8,
                phoneInputSizeMedium:8,
                phoneInputSizeLarge:6,
    
                txtEmail: "",
                EmailError: false,
                EmailErrorMsg: "Email is not in correct format",
                changeEmailId: false,
    
                chkPhoneCall: false,
                chkApiPhoneCallValue: false,
    
                btnPhone: true,
                btnPhoneLoading: false,
                //PasswordErrorMsg: "Please enter password",
                txtVerifyPassword: "",
                PasswordError: false,
                // Address
                AddressDisplay: true,
                EditAddress: false,
                CountryErrorMsg: "Please select country",
                CountryError: false,
                txtCountry: "",
                lstCountry: this.$store.state.country.contryList,
    
                Address1Error: false,
                txtAddress1: "",
                txtAddress1ErrorMsg: "Please enter address 1",
                Address2Error: false,
                txtAddress2: "",
                txtAddress2ErrorMsg: "Please enter address 2",
                cityError: false,
                txtCity: "",
                txtCityErrorMsg: "Please enter city",
                zipCodeError: false,
                txtZipCode: "",
                txtZipCodeErrorMsg: "",
    
                lstUsaState: this.$store.state.country.USAstateslist,
                lstCanadaState: this.$store.state.country.Canadastateslist,
    
                UsaState: false,
                CanadaState: false,
                OtherState: false,
    
                txtUsaState: "",
                txtCanadaState: "",
                txtOtherState: "",
    
                USAStateError: false,
                CanadaStateError: false,
                OtherStateError: false,
    
                txtStateErrorMsg: "Please enter State/Province/APO",
                //txtStateSelectErrorMsg: "Select state",
                txtStateSelectErrorMsg: "",
                SaveStateValue: "",
                btnAddressLoading: false,
                btnAddressSave: true,
    
                // Password
                PasswordDisplay: true,
                PasswordEdit: false,
                btnPasswordSave: true,
                btnPasswordLoading: false,
                txtOldPassword: "",
                OldPasswordError: false,
                OldPasswordErrorMsg: "Please enter old password",
    
                txtNewPassword: "",
                NewPasswordError: false,
                NewPasswordErrorMsg: "",
                validatePasswordError: false,
    
                lblZipCode: "",
                divpassError: "my-profile-email-static-text"
            }
        },
        mounted() {
            this.userID = this.$auth0.user["https://morningstar.com/mstar_id"];
            if (this.userID) {
                this.getUserDetails();
            }
    
        },
        methods: {
            getUserDetails() {
                window.scrollTo(0, 0);
                this.isLoading = true;
                var getUserDetailsAPIURL = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetUserDetails;
                getUserDetailsAPIURL = getUserDetailsAPIURL.replace("{userId}", this.userID);
                try {
                    Vue.axios
                        .get(getUserDetailsAPIURL, {
                            headers: {
                                Accept: "application/json",
                            },
                        })
                        .then((response) => {
                            if (response) {
                                if (response.status == 200) {
                                    if (response.data) {
                                        this.userDetails = JSON.parse(response.data);
                                        if (this.userDetails) {
    
                                            if (this.userDetails["CustomerID"]) {
                                                this.getPhoneCallValue(this.userDetails["CustomerID"]);
                                            }
                                            if (this.userDetails["FirstName"]) {
                                                this.FirstName = this.userDetails["FirstName"];
                                            }
                                            if (this.userDetails["LastName"]) {
                                                this.LastName = this.userDetails["LastName"];
                                            }
                                            if (this.FirstName || this.LastName) {
                                                this.Name = this.FirstName + " " + this.LastName;
                                            }
                                            if (this.userDetails["Email"]) {
                                                this.Email = this.userDetails["Email"];
                                                this.txtEmail = this.userDetails["Email"];
                                            }
    
                                            if (this.userDetails["Dphone"]) {
                                                this.Dphone = this.userDetails["Dphone"];
                                            }
                                            if (this.userDetails["Mphone"]) {
                                                this.Mphone = this.userDetails["Mphone"];
                                            }
    
                                            var Address_1 = "";
                                            var Address_2 = "";
                                            var City = "";
                                            var State = "";
                                            var ZipCode = "";
                                            var Country = "";
                                            if (this.userDetails["Address_1"]) {
                                                Address_1 = this.userDetails["Address_1"];
                                                this.txtAddress1 = this.userDetails["Address_1"];
                                            }
                                            if (this.userDetails["Address_2"]) {
                                                Address_2 = this.userDetails["Address_2"];
                                                this.txtAddress2 = this.userDetails["Address_2"];
                                            }
                                            if (this.userDetails["City"]) {
                                                City = this.userDetails["City"];
                                                this.txtCity = this.userDetails["City"];
                                            }
    
                                            if (this.userDetails["ZipCode"]) {
                                                ZipCode = this.userDetails["ZipCode"];
                                                this.txtZipCode = this.userDetails["ZipCode"];
    
                                            }
                                            if (this.userDetails["Country"]) {
                                                Country = this.userDetails["Country"];
                                                this.txtCountry = this.ChangeContryName(this.userDetails["Country"]);
                                            }
                                            if (this.userDetails["State"] != null) {
                                                State = this.userDetails["State"];
                                                if (this.txtCountry == "USA") {
                                                    this.UsaState = true;
                                                    this.txtUsaState = State;
                                                    this.lblZipCode = "Zip Code";
                                                } else if (this.txtCountry == "Canada") {
                                                    this.CanadaState = true;
                                                    this.txtCanadaState = State;
                                                    this.lblZipCode = "Zip / Postal Code";
                                                } else {
                                                    this.OtherState = true;
                                                    this.txtOtherState = State;
                                                    this.lblZipCode = "Zip / Postal Code";
                                                }
                                            }
                                            if (Address_1) {
                                                this.Address = Address_1 + " " + Address_2 + " " + City + " " + State + ", " + this.ChangeContryName(Country) + " " + ZipCode;
                                            } else {
                                                this.Address = this.ChangeContryName(Country) + " " + ZipCode;
                                            }
                                            this.ContentVisiblityCheck();
                                        }
                                    }
    
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => (this.isLoading = false));
                } catch (ex) {
                    console.log(ex.message);
                }
            },
            getPhoneCallValue(customerId) {
                var GetPhoneCallUrl = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetPhoneCall;
                GetPhoneCallUrl = GetPhoneCallUrl.replace('{customerId}', customerId);
    
                try {
                    Vue.axios
                        .get(GetPhoneCallUrl)
                        .then((response) => {
                            if (response) {
                                if (response.status == 200) {
                                    if (response.data) {
                                        if (response.data.PhoneCallCheckbox) {
                                            this.chkPhoneCall = true;
                                            this.chkApiPhoneCallValue = true;
                                        }else{
                                            this.chkPhoneCall = false;
                                            this.chkApiPhoneCallValue = false;
                                        }
                                    }
    
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally();
                } catch (ex) {
                    console.log(ex.message);
                }
            },
            isNumberValue(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            AddClass(id){
                try{
                    setTimeout(() =>{
                        var element = document.getElementById(id);
                        element.classList.add("my-profile-active-color");
                    },100);
                }
                catch(ex){
                    //
                }
            },
            RemoveClass(id){
                try{
                    var element = document.getElementById(id);
                    element.classList.remove("my-profile-active-color");
                }
                catch(ex){
                    //
                }
            },
            NameClick() {
                this.NameDisplay = false;
                this.NameEdit = true;
                this.AddClass("my-profile-name");
                this.CancelPhoneNumber();
                this.CancelPassword();
                this.CancelAddress();
            },
            CancelName() {
                this.NameEdit = false;
                this.NameDisplay = true;
                if (this.userDetails["FirstName"]) {
                    this.FirstName = this.userDetails["FirstName"];
                }
                if (this.userDetails["LastName"]) {
                    this.LastName = this.userDetails["LastName"];
                }
                this.RemoveClass("my-profile-name");
            },
            SaveName() {
                if (this.FirstName.trim()) {
                    this.FirstNameError = false;
                } else {
                    this.FirstNameError = true;
                }
    
                if (this.LastName.trim()) {
                    this.LastNameError = false;
                } else {
                    this.LastNameError = true;
                }
    
                if (this.FirstName.trim() && this.LastName.trim()) {
                    this.CommonSave("Name");
                }
    
            },
    
            PhoneNumberClick() {
                this.PhoneNumberDisplay = false;
                this.PhoneNumberEdit = true;
                this.PasswordError = false;
                this.divpassError="my-profile-email-static-text";
                this.AddClass("my-profile-phone-number-id");
                this.CancelName();
                this.CancelPassword();
                this.CancelAddress();
                this.divpassError="my-profile-email-static-text";
                this.ContentVisiblityCheck();
            },
            CancelPhoneNumber() {
                this.divpassError="my-profile-email-static-text";
                this.PhoneNumberDisplay = true;
                this.PhoneNumberEdit = false;
                if (this.userDetails["Email"]) {
                    this.txtEmail = this.userDetails["Email"];
                }
                if (this.userDetails["Dphone"]) {
                    this.Dphone = this.userDetails["Dphone"];
                    this.chkPhoneCall = false;
                }
                else{
                    this.Dphone = "";
                }
                if (this.userDetails["Mphone"]) {
                    if (this.userDetails["Mphone"]) {
                        this.Mphone = this.userDetails["Mphone"];
                        if (this.chkApiPhoneCallValue) {
                            this.chkPhoneCall = true;
                        } else {
                            this.chkPhoneCall = false;
                        }
                    }
                }
                else{
                    this.Mphone ="";
                }
                this.addSecondaryNumberToggle("externalCall");
                this.txtVerifyPassword = "";
                if(this.userDetails["Mphone"] && this.userDetails["Dphone"]){
                    this.showRemoveNumberButton = true;
                }
                this.RemoveClass("my-profile-phone-number-id");
            },
            SavePhoneNumber() {
                if (this.txtEmail.trim() && /^[^@]+@\w+(\.\w+)+\w$/.test(this.txtEmail.trim())) {
                    this.EmailError = false;
                } else {
                    this.EmailError = true;
                    return false;
                }
    
                if (this.txtVerifyPassword.trim()) {
                    this.PasswordError = false;
                    this.divpassError="my-profile-email-static-text";
                } else {
                    this.PasswordError = true;
                    this.divpassError="my-profile-email-static-text-red";
                    if(this.userDetails["Dphone"] && this.userDetails["Mphone"]){
                        this.isRemoveNumberActive=false;
                        this.disableRemoveButton=false;
                    }
                }
                if(!this.isRemoveNumberActive){
                    if(this.userDetails["Mphone"] && this.isMphonePresent){
                        if(this.Mphone.trim() == null || this.Mphone.trim() == ""){
                            this.mobilePhoneError = true;
                            this.mobilePhoneErrorMsg = "Phone number is required";
                            return false;
                        }
                        else if(!/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.Mphone.trim()) || this.Mphone.length < 10){
                            this.mobilePhoneError = true;
                            this.mobilePhoneErrorMsg = "Phone number is not valid";
                            return false; 
                        }
                    }
    
                    if(this.userDetails["Dphone"] && this.isDphonePresent){
                        if(this.Dphone.trim() == null || this.Dphone.trim() == ""){
                            this.homePhoneError = true;
                            this.homePhoneErrorMsg = "Phone number is required";
                            return false;
                        }
                        else if(!/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.Dphone.trim()) || this.Dphone.length < 10){
                            this.homePhoneError = true;
                            this.homePhoneErrorMsg = "Phone number is not valid";
                            return false; 
                        }
    
                    }
                }
    
                if(this.secondaryNumberValue){
                    if(this.secondaryNumberValue.trim() == null || this.secondaryNumberValue.trim() == ""){
                        this.secondaryPhoneError = true;
                        this.secondaryPhoneErrorMsg = "Phone number is required";
                        return false;
                    }
                    else if(!/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.secondaryNumberValue.trim()) || this.secondaryNumberValue.length < 10){
                        this.secondaryPhoneError = true;
                        this.secondaryPhoneErrorMsg = "Phone number is not valid";
                        return false; 
                    }
                }else{
                    if(this.showSecondaryNumberInput){
                        this.addSecondaryNumberToggle("externalCall");
                    }
                }
    
    
                if (this.txtEmail.trim() && this.txtVerifyPassword.trim()) {
                    if(this.secondaryNumberValue){
                        if(this.Mphone){
                            this.Dphone = this.secondaryNumberValue;
                        }
                        else{
                            this.Mphone = this.secondaryNumberValue;
                        }
                    }
                    this.CommonSave("Phone");
                }
    
            },
            AddressClick() {
                this.AddressDisplay = false;
                this.EditAddress = true;
                var State = "";
                if (this.userDetails["State"]) {
                    State = this.userDetails["State"];
                    if (this.txtCountry == "USA") {
                        this.UsaState = true;
                        this.txtUsaState = State;
                    } else if (this.txtCountry == "Canada") {
                        this.CanadaState = true;
                        this.txtCanadaState = State;
                    } else {
                        this.OtherState = true;
                        this.txtOtherState = State;
                    }
    
                }
                this.CanadaStateError = false;
                this.OtherStateError = false;
                this.USAStateError = false;
                this.CancelName();
                this.CancelPhoneNumber();
                this.CancelPassword();
                this.AddClass("my-profile-address-id");
            },
            CancelAddress() {
                this.AddressDisplay = true;
                this.EditAddress = false;
                if (this.userDetails["Address_1"]) {
                    this.txtAddress1 = this.userDetails["Address_1"];
                }
                if (this.userDetails["Address_2"]) {
                    this.txtAddress2 = this.userDetails["Address_2"];
                }
                if (this.userDetails["City"]) {
                    this.txtCity = this.userDetails["City"];
                }
                // if (this.userDetails["State"]) {
                //     State = this.userDetails["State"];
                // }
                if (this.userDetails["ZipCode"]) {
                    this.txtZipCode = this.userDetails["ZipCode"];
                }
    
                if (this.userDetails["Country"]) {
                    //  Country = this.userDetails["Country"];
                    this.txtCountry = this.ChangeContryName(this.userDetails["Country"]);
                }
                var State = "";
                if (this.userDetails["State"]) {
                    State = this.userDetails["State"];
                    if (this.txtCountry == "USA") {
                        this.UsaState = true;
                        this.txtUsaState = State;
                    } else if (this.txtCountry == "Canada") {
                        this.CanadaState = true;
                        this.txtCanadaState = State;
                    } else {
                        this.OtherState = true;
                        this.txtOtherState = State;
                    }
                    this.UsaState = false;
                    this.CanadaState = false;
                    this.OtherState = false;
                } else {
                    if (this.txtCountry == "USA") {
                        this.txtUsaState = State;
                    } else if (this.txtCountry == "Canada") {
                        this.txtCanadaState = State;
                    } else {
                        this.txtOtherState = State;
                    }
                }
                this.CanadaStateError = false;
                this.OtherStateError = false;
                this.USAStateError = false;
                this.RemoveClass("my-profile-address-id");
            },
            SaveAddress() {
                if (this.txtAddress1.trim()) {
                    this.Address1Error = false;
                } else {
                    this.Address1Error = true;
                }
    
                // if (this.txtAddress2) {
                //     this.Address2Error = false;
                // } else {
                //     this.Address2Error = true;
                // }
    
                if (this.txtCity.trim()) {
                    this.cityError = false;
                } else {
                    this.cityError = true;
                }
    
                if (this.txtZipCode.trim()) {
                    if (this.txtCountry.toUpperCase() == 'USA') {
                        if (this.txtZipCode.length == 5) {
                            this.zipCodeError = false;
                        } else {
                            this.zipCodeError = true;
                            this.txtZipCodeErrorMsg = "Zip code is not valid";
                            return false;
                        }
                    } else if (this.txtCountry.toUpperCase() == 'CANADA') {
                        // if (this.txtZipCode.length == 6) {
                        //     this.zipCodeError = false;
                        // } else {
                        //     this.zipCodeError = true;
                        //     this.txtZipCodeErrorMsg = "Zip / Postal code is not valid";
                        //     return false;
                        // }
                        if (!/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[ ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/.test(this.txtZipCode.trim())) {
                                this.zipCodeError = true;
                                this.txtZipCodeErrorMsg = "Zip / Postal code is not valid";
                                return false;
                            }
                            else{
                                this.txtZipCode = this.txtZipCode.replace(/\s/g, '');
                                this.txtZipCode = this.txtZipCode.replace(/.{3}/g, '$& ');
                                this.txtZipCode = this.txtZipCode.trim();
                                this.zipCodeError = false;
                            }
                    } else if (this.txtCountry.toUpperCase() != 'CANADA' && this.txtCountry.toUpperCase() != 'USA') {
                        if (this.txtZipCode.trim()) {
                            this.zipCodeError = false;
                        } else {
                            this.zipCodeError = true;
                            this.txtZipCodeErrorMsg = "Zip / Postal code is not valid";
                            return false;
                        }
                    }
                } else {
                    this.zipCodeError = true;
                    //this.txtZipCodeErrorMsg = "Please enter a zip code";
                    if (this.txtCountry.toUpperCase() == 'USA') {
                        this.txtZipCodeErrorMsg = "Please enter a zip code";
                    } else {
                        this.txtZipCodeErrorMsg = "Please enter a zip / postal code";
                    }
                    return false;
                }
    
                if (this.txtCountry) {
                    this.CountryError = false;
                } else {
                    this.CountryError = true;
                    return false;
                }
    
                if (this.txtCountry) {
                    if (this.txtCountry == "USA") {
                        this.SaveStateValue = this.txtUsaState;
                        if (this.txtUsaState) {
                            this.USAStateError = false;
                        } else {
                            this.USAStateError = true;
                            return false;
                        }
                    } else if (this.txtCountry == "Canada") {
                        this.SaveStateValue = this.txtCanadaState;
                        // if (this.txtCanadaState) {
                        //     this.CanadaStateError = false;
                        // } else {
                        //     this.CanadaStateError = true;
                        //     return false;
                        // }
                    } else {
                        this.SaveStateValue = this.txtOtherState.trim();
                        // if (this.txtOtherState.trim()) {
                        //     this.OtherStateError = false;
                        // } else {
                        //     this.OtherStateError = true;
                        //     return false;
                        // }
                    }
                }
                // var state="";
    
                if (this.txtCountry.trim() && this.txtAddress1.trim() && this.txtZipCode.trim() && this.txtCity.trim()) {
                    this.CommonSave("Address");
                }
            },
    
            countryChange() {
                if (this.txtCountry) {
                    this.UsaState = false;
                    this.CanadaState = false;
                    this.OtherState = false;
                    this.txtUsaState = "";
                    this.txtCanadaState = "";
                    this.txtOtherState = "";
                    if (this.txtCountry == "USA") {
                        this.UsaState = true;
                        this.lblZipCode = "Zip Code";
                    } else if (this.txtCountry == "Canada") {
                        this.CanadaState = true;
                        this.lblZipCode = "Zip / Postal Code";
                    } else {
                        this.OtherState = true;
                        this.lblZipCode = "Zip / Postal Code";
                    }
                }
            },
            ChangeContryName(Name) {
                var ContryName = "";
                if (Name) {
                    if (Name.toUpperCase() == "USA") {
                        ContryName = Name.toUpperCase();
                    } else {
                        var lowerText = Name.toLowerCase();
                        var temp = lowerText.split(' ');
                        if (temp) {
                            for (var i in temp) {
                                var obj = temp[i];
                                ContryName += obj.charAt(0).toUpperCase() + obj.slice(1) + " ";
                            }
                        }
                        ContryName = ContryName.substring(0, ContryName.length - 1);
                    }
    
                }
                return ContryName;
            },
            UpdatePassword() {
                if (this.txtOldPassword.trim()) {
                    this.OldPasswordError = false;
                } else {
                    this.OldPasswordError = true;
                }
    
                if (this.txtNewPassword.trim()) {
                    this.NewPasswordError = false;
                    if (!this.validatePassword(this.txtNewPassword.trim())) {
                        this.validatePasswordError = true;
                        return false;
                    } else {
                        this.validatePasswordError = false;
                    }
                } else {
                    this.NewPasswordError = true;
                    this.NewPasswordErrorMsg = "Please enter new password";
                    return false;
    
                }
                if (this.txtOldPassword.trim() == this.txtNewPassword.trim()) {
                    this.NewPasswordError = true;
                    this.NewPasswordErrorMsg = "The new password cannot be same as the old password.";
                    return false;
                }
    
                if (this.txtOldPassword.trim() && this.txtNewPassword.trim()) {
                    //
                    this.btnPasswordSave = false;
                    this.btnPasswordLoading = true;
    
                    var UpdateUserPassword = {};
                    UpdateUserPassword.oldPassword = this.txtOldPassword.trim();
                    UpdateUserPassword.NewPassword = this.txtNewPassword.trim();
                    UpdateUserPassword.userID = this.userID;
    
                    try {
                        var UpdateUserPasswordUrl = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_UpdatePassword;
                        Vue.axios
                            .put(UpdateUserPasswordUrl, UpdateUserPassword)
                            .then((response) => {
                                if (response) {
                                    window.scrollTo(0, 0);
                                    if (response.status == 200) {
                                        if (response.data) {
                                            var result = response.data;
                                            if (result) {
                                                if (result.StatusCode == 200) {
                                                    this.btnPasswordSave = true;
                                                    this.btnPasswordLoading = false;
                                                    this.txtOldPassword = "";
                                                    this.txtNewPassword = "";
                                                    this.PasswordDisplay = true;
                                                    this.PasswordEdit = false;
                                                    //this.showSuccessMsg(result.ResponseMessage);
                                                    this.showStateMsg(result.ResponseMessage);
    
                                                } else {
                                                    this.btnPasswordSave = true;
                                                    this.btnPasswordLoading = false;
                                                    //this.showErrorMsg(result.ResponseMessage);
                                                    this.showErrorStateMsg(result.ResponseMessage);
    
                                                }
    
                                            }
    
                                        }
    
                                    }
    
                                }
                            })
                            .catch((error) => {
                                console.log(error);
    
                            })
                            .finally();
                    } catch (ex) {
                        console.log(ex.message);
                    }
    
                }
    
            },
            validatePassword(password) {
                if (password.length >= 8 && password.length <= 24) {
                    if (/\d+/.test(password) && /[A-Za-z]/.test(password)) {
                        if (/[^\da-zA-Z]/.test(password)) {
                            if (/(.)\1{3}/.test(password)) {
                                return false;
                            } else if (/(?:abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/.test(password)) {
                                return false;
                            } else if (password.toLowerCase().includes("morningstar".toLowerCase())) {
                                return false;
                            } else if (password.toLowerCase().includes("mstar".toLowerCase())) {
                                return false;
                            } else if (password.toLowerCase().includes(this.FirstName.toLowerCase())) {
                                return false;
                            } else {
                                return true;
                            }
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            CommonSave(Type) {
                var omahaCustomerID = "";
                if (this.userDetails["CustomerID"]) {
                    omahaCustomerID = this.userDetails["CustomerID"];
                }
                // if (!omahaCustomerID) {
                //     this.showErrorCustomerMsg();
                //     return false;
                // }
    
                if (Type == "Name") {
                    this.btnNameSave = false;
                    this.btnNameLoading = true;
                } else if (Type == "Phone") {
                    this.btnPhone = false;
                    this.btnPhoneLoading = true;
                } else if (Type == "Address") {
                    this.btnAddressSave = false;
                    this.btnAddressLoading = true;
                }
    
                if (this.txtEmail == this.userDetails["Email"]) {
                    this.changeEmailId = false;
                } else {
                    this.changeEmailId = true;
                }
    
                if (this.txtCountry) {
                    if (this.txtCountry == "USA") {
                        this.SaveStateValue = this.txtUsaState;
    
                    } else if (this.txtCountry == "Canada") {
                        this.SaveStateValue = this.txtCanadaState;
    
                    } else {
                        this.SaveStateValue = this.txtOtherState;
                    }
                }
    
                var UpdateUserData = {};
                UpdateUserData.companyId = "";
                UpdateUserData.companyName = "";
                UpdateUserData.jobTitle = "";
                UpdateUserData.branch = "";
                UpdateUserData.firstName = this.FirstName.trim();
                UpdateUserData.midInitial = "";
                UpdateUserData.lastName = this.LastName.trim();
                UpdateUserData.salutation = "";
                UpdateUserData.address1 = this.txtAddress1.trim();
                UpdateUserData.address2 = this.txtAddress2.trim();
                UpdateUserData.city = this.txtCity.trim();
                UpdateUserData.state = this.SaveStateValue.trim();
                UpdateUserData.zipCode = this.txtZipCode.trim();
                UpdateUserData.region = "";
                UpdateUserData.country = this.txtCountry;
                UpdateUserData.phoneHome = this.Dphone.trim();
                UpdateUserData.phoneMobile = this.Mphone.trim();
                UpdateUserData.pager = "";
                UpdateUserData.fax = "";
                UpdateUserData.email = this.txtEmail.trim();
                UpdateUserData.login = "";
                UpdateUserData.password = this.txtVerifyPassword.trim();
                UpdateUserData.chkPhoneCall = this.chkPhoneCall;
                UpdateUserData.userID = this.userID;
                UpdateUserData.changeEmailId = this.changeEmailId;
    
                var EditUserCommand = {};
                EditUserCommand.omahaCustomerID = omahaCustomerID;
                EditUserCommand.updateData = UpdateUserData;
                try {
                    var UserDataUrl = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_UpdateUser;
                    Vue.axios
                        .put(UserDataUrl, EditUserCommand)
                        .then((response) => {
                            if (response) {
                                window.scrollTo(0, 0);
                                if (response.status == 200) {
                                    if (response.data) {
                                        var result = response.data;
                                        if (result) {
                                            if (result.StatusCode == 200) {
                                                //this.showSuccessMsg(result.ResponseMessage);
                                                this.showStateMsg(result.ResponseMessage)
                                                if (Type == "Name") {
                                                    this.btnNameSave = true;
                                                    this.btnNameLoading = false;
                                                    this.NameEdit = false;
                                                    this.NameDisplay = true;
                                                    this.Name = "";
                                                    this.FirstName = "";
                                                    this.LastName = "";
    
                                                } else if (Type == "Phone") {
                                                    this.btnPhone = true;
                                                    this.btnPhoneLoading = false;
                                                    this.PhoneNumberEdit = false;
                                                    this.PhoneNumberDisplay = true;
                                                    this.txtEmail = "";
                                                    this.Mphone = "";
                                                    this.Dphone = "";
                                                    this.secondaryNumberValue="";
                                                    this.Email = "";
                                                    this.txtVerifyPassword = "";
                                                    this.isRemoveNumberActive=false;
                                                    this.addSecondaryNumberToggle("externalCall");
                                                } else if (Type == "Address") {
                                                    //
                                                    this.txtUsaState = "";
                                                    this.txtCanadaState = "";
                                                    this.txtOtherState = "";
                                                    this.UsaState = false;
                                                    this.CanadaState = false;
                                                    this.OtherState = false;
                                                    this.txtAddress1 = "";
                                                    this.txtAddress2 = "";
                                                    this.txtCity = "";
                                                    this.txtZipCode = "";
                                                    this.txtCountry = "";
                                                    this.SaveStateValue = "";
                                                    this.btnAddressLoading = false;
                                                    this.btnAddressSave = true;
                                                    this.AddressDisplay = true;
                                                    this.EditAddress = false;
    
                                                }
                                                this.getUserDetails();                                            
                                                this.getPhoneCallValue(this.userDetails["CustomerID"]);
                                            } else {
                                                this.getPhoneCallValue(this.userDetails["CustomerID"]);
                                                if(result.ResponseMessage.includes("Change Unsuccessful")){
                                                    this.Mphone=this.userDetails["Mphone"];
                                                    this.Dphone=this.userDetails["Dphone"];
                                                    if(this.userDetails["Mphone"] && this.userDetails["Dphone"]){
                                                        this.isRemoveNumberActive=false;
                                                        this.disableRemoveButton=false;
                                                    }
                                                }
                                                
                                                this.showErrorStateMsg(result.ResponseMessage);
                                                if (Type == "Name") {
                                                    this.btnNameSave = true;
                                                    this.btnNameLoading = false;
                                                } else if (Type == "Phone") {
                                                    this.btnPhone = true;
                                                    this.btnPhoneLoading = false;
                                                } else if (Type == "Address") {
                                                    this.btnAddressLoading = false;
                                                    this.btnAddressSave = true;
                                                }
    
                                            }
    
                                        }
    
                                    }
    
                                }
    
                            }
                        })
                        .catch((error) => {
                            console.log(error);
    
                        })
                        .finally();
                } catch (ex) {
                    console.log(ex.message);
                }
    
            },
            showSuccessMsg(str) {
                this.lstSuccess = [];
                if (str) {
                    this.lstSuccess.push(str);
                }
                this.customAlertSuccess = true;
                this.customAlertError = false;
                setTimeout(() => {
                    this.customAlertSuccess = false;
                }, 5000);
            },
            showErrorMsg(str) {
                this.lstError = [];
                if (str) {
                    this.lstError.push(str);
                }
                this.customAlertError = true;
                this.customAlertSuccess = false;
                setTimeout(() => {
                    this.customAlertError = false;
                }, 5000);
            },
            showErrorCustomerMsg() {
                this.lstOmahaCustomerError = [];
    
                this.lstOmahaCustomerError.push("Your subscription is not valid. Please call us to know more.");
                this.lstOmahaCustomerError.push("Phone: 1-800-957-6021 | Mon-Fri 8:30AM-5PM CST");
    
                this.customAlertError = false;
                this.customAlertSuccess = false;
                this.OmahaCustomer = true;
                setTimeout(() => {
                    this.OmahaCustomer = false;
                }, 5000);
            },
            PasswordClick() {
                this.PasswordDisplay = false;
                this.PasswordEdit = true;
                this.OldPasswordError = false;
                this.NewPasswordError = false;
                this.CancelName();
                this.CancelPhoneNumber();
                this.CancelAddress();
                this.AddClass("my-profile-password-id");
            },
            CancelPassword() {
                this.PasswordDisplay = true;
                this.PasswordEdit = false;
                this.txtOldPassword = "";
                this.txtNewPassword = "";
                this.RemoveClass("my-profile-password-id");
            },
            showStateMsg(str) {
                this.$store.state.product.lstAlertMsg = [];
                this.$store.state.product.lstAlertMsg.push(str);
    
                this.$store.state.product.SuccessAlert = true;
                this.$store.state.product.ErrorAlert = false;
                setTimeout(() => {
                    this.$store.state.product.SuccessAlert = false;
                }, 5000);
            },
            showErrorStateMsg(str) {
                this.$store.state.product.lstAlertMsg = [];
                this.$store.state.product.lstAlertMsg.push(str);
    
                this.$store.state.product.SuccessAlert = false;
                this.$store.state.product.ErrorAlert = true;
                setTimeout(() => {
                    this.$store.state.product.ErrorAlert = false;
                }, 5000);
            },
            ContentVisiblityCheck(){
                if(this.userDetails["Mphone"]){
                    this.isMphonePresent = true;
                    this.secondaryPhoneTyeName="Home";
                }else{
                    this.isMphonePresent=false;
                }
    
                if(this.userDetails["Dphone"]){
                    this.isDphonePresent = true;
                    this.secondaryPhoneTyeName="Mobile";
                    this.isMobileConsentOn=true;
                }else{
                    this.isDphonePresent=false;
                    this.isMobileConsentOn=false;
                }
    
                if(this.userDetails["Dphone"] && this.userDetails["Mphone"]){
                    this.isPhoneRequired=false;
                    this.showSecondaryNumberButton=false;
                    this.showRemoveNumberButton=true;
                    this.disableRemoveButton=false;
                }else{
                    this.isPhoneRequired=true;
                    this.showSecondaryNumberButton=true;
                    this.showRemoveNumberButton=false;
                    this.disableRemoveButton=true;
                }
                this.fillPhoneNumberColumnSize();
    
                
            },
            removeNumber(typeOfNumber){
                if(this.mobilePhoneErrorMsg=="" && this.homePhoneErrorMsg=="" && this.homePhoneError==false && this.mobilePhoneError==false){
                   // if(this.txtVerifyPassword != "" && this.txtVerifyPassword !=null){
                        this.PasswordError = false;
                        this.divpassError="my-profile-email-static-text"; 
                        this.showRemoveNumberButton=false;          
                        if(typeOfNumber === 'mobileNumber'){
                            this.Mphone="";
                            this.getPhoneCallValue(this.userDetails["CustomerID"]);
                            if(this.chkPhoneCall){
                                this.chkPhoneCall=false;
                            }
                            this.isMphonePresent=false;
                                          
                        }else if(typeOfNumber === 'homeNumber'){
                            this.Dphone="";
                            this.isDphonePresent=false;
                        }
                        this.disableRemoveButton=true;
                        //this.SavePhoneNumber();
                    // }else{
                    //     this.PasswordError = true;
                    //     this.divpassError="my-profile-email-static-text-red";
                    //     //this.showErrorStateMsg("Your change was unsuccessful. Please enter your current password.");
                    // }
                }
            },
            checkViewPortSize(){
                this.viewPortWidth = window.innerWidth;
            },
            fillPhoneNumberColumnSize(){
                if(this.userDetails["Dphone"] && this.userDetails["Mphone"]){
                     if(window.innerWidth <= 999){
                        this.removeNumberText = '';
                        this.removeNumberButtonSize="large";
    
                        this.phoneTypeNameSizeDefault=3;
                        this.phoneTypeNameSizeSmall=2;
                        this.phoneTypeNameSizeMedium=3;
                        this.phoneTypeNameSizeLarge=2;
    
                        this.phoneInputSizeDefault=7;
                        this.phoneInputSizeSmall=6;
                        this.phoneInputSizeMedium=7;
                        this.phoneInputSizeLarge=6;
    
                        if(window.innerWidth >=600 && window.innerWidth <= 768){
                            this.removeNumberText = 'Remove Number';
                            this.removeNumberButtonSize = "small";
                        }else{
                            this.removeNumberText = '';
                            this.removeNumberButtonSize="large";
                        }
                    }else{
                    this.removeNumberText = 'Remove Number';
                    this.removeNumberButtonSize = "small";
    
                    this.phoneTypeNameSizeDefault=2;
                    this.phoneTypeNameSizeSmall=2;
                    this.phoneTypeNameSizeMedium=2;
                    this.phoneTypeNameSizeLarge=2;
    
                    this.phoneInputSizeDefault=10;
                    this.phoneInputSizeSmall=6;
                    this.phoneInputSizeMedium=8;
                    this.phoneInputSizeLarge=6;
                }
                }else{
                    this.phoneTypeNameSizeDefault=3;
                    this.phoneTypeNameSizeSmall=2;
                    this.phoneTypeNameSizeMedium=3;
                    this.phoneTypeNameSizeLarge=2;
    
                    this.phoneInputSizeDefault=9;
                    this.phoneInputSizeSmall=6;
                    this.phoneInputSizeMedium=9;
                    this.phoneInputSizeLarge=6;
                }
                document.body.onresize = this.checkViewPortSize;
            },
            addSecondaryNumberToggle(eventType){
                this.showSecondaryNumberButton =false;
                if(eventType === 'fromButton'){
                    this.showSecondaryNumberInput = !this.showSecondaryNumberInput;
                }else{
                    this.showSecondaryNumberInput = false;
                }
                
                if(!this.showSecondaryNumberInput){
                    this.secondaryNumberValue="";
                    this.secondaryPhoneError=false;
                    this.secondaryPhoneErrorMsg="";
                    if(!this.userDetails["Mphone"]){
                        this.chkPhoneCall=false;
                    }
                }
            }
    
        },
        watch: {
            FirstName(newVal, oldVal) {
                if (newVal.trim() != oldVal.trim()) {
                    if (newVal.trim()) {
                        this.FirstNameError = false;
                    } else {
                        this.FirstNameError = true;
                    }
                }
            },
            LastName(newVal, oldVal) {
                if (newVal.trim() != oldVal.trim()) {
                    if (newVal.trim()) {
                        this.LastNameError = false;
                    } else {
                        this.LastNameError = true;
                    }
                }
            },
            txtEmail(newVal, oldVal) {
                if (newVal.trim() != oldVal.trim()) {
                    if (
                        newVal.trim() == "" ||
                        newVal.length > 60 ||
                        newVal.trim() == null ||
                        !/^[^@]+@\w+(\.\w+)+\w$/.test(newVal.trim())
                    ) {
                        this.EmailError = true;
                        //this.emailErrorMessage = "Email is not in correct format";
                    } else {
                        this.EmailError = false;
                    }
                }
            },
            Mphone(newVal,oldVal){
                if(!this.isRemoveNumberActive || this.Mphone.length > 0){
                    if (newVal.trim() != oldVal.trim()) {
                        if (newVal.trim() == null || newVal.trim() == "") {
                            this.mobilePhoneError = true;
                            this.mobilePhoneErrorMsg = "Phone number is required";
                        } else if ( !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.Mphone.trim()) || this.Mphone.length < 10) {
                            this.mobilePhoneError = true;
                            this.mobilePhoneErrorMsg = " Phone number is not valid";
                        } else {
                            this.mobilePhoneError = false;
                            this.mobilePhoneErrorMsg="";
                        }
                    }
    
                    if (this.Mphone.trim() == null || this.Mphone.trim() == "") {
                        this.mobilePhoneError = true;
                        this.mobilePhoneErrorMsg = "Phone number is required";
                    } else if (!/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.Mphone.trim()) || this.Mphone.length < 10) {
                        this.mobilePhoneErrorMsg = " Phone number is not valid";
                    }else{
                        this.mobilePhoneErrorMsg="";
                    }
                }
            },
            Dphone(newVal,oldVal){
                if(!this.isRemoveNumberActive || this.Dphone.length > 0){
                    if (newVal.trim() != oldVal.trim()) {
                        if (newVal.trim() == null || newVal.trim() == "") {
                            this.homePhoneError = true;
                            this.homePhoneErrorMsg = "Phone number is required";
                        } else if ( !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.Dphone.trim()) || this.Dphone.length < 10) {
                            this.homePhoneError = true;
                            this.homePhoneErrorMsg = " Phone number is not valid";
                        } else {
                            this.homePhoneError = false;
                            this.homePhoneErrorMsg="";
                        }
                    }
    
                    if (this.Dphone.trim() == null || this.Dphone.trim() == "") {
                        this.homePhoneError = true;
                        this.homePhoneErrorMsg = "Phone number is required";
                    } else if (!/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.Dphone.trim()) || this.Dphone.length < 10) {
                        this.homePhoneErrorMsg = " Phone number is not valid";
                    }else{
                        this.homePhoneErrorMsg="";
                    }
                }
            },
            secondaryNumberValue(newVal,oldVal){
                if(this.showSecondaryNumberInput){
                    if (newVal.trim() != oldVal.trim()) {
                        if (newVal.trim() == null || newVal.trim() == "") {
                            this.secondaryPhoneError = true;
                            this.secondaryPhoneErrorMsg = "Phone number is required";
                        } else if ( !/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.secondaryNumberValue.trim()) || this.secondaryNumberValue.length < 10) {
                            this.secondaryPhoneError = true;
                            this.secondaryPhoneErrorMsg = " Phone number is not valid";
                        } else {
                            this.secondaryPhoneError = false;
                            this.secondaryPhoneErrorMsg="";
                        }
                    }
    
                    if (this.secondaryNumberValue.trim() == null || this.secondaryNumberValue.trim() == "") {
                        this.secondaryPhoneError = true;
                        this.secondaryPhoneErrorMsg = "Phone number is required";
                    } else if (!/^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/.test(this.secondaryNumberValue.trim()) || this.secondaryNumberValue.length < 10) {
                        this.secondaryPhoneErrorMsg = " Phone number is not valid";
                    }else{
                        this.secondaryPhoneErrorMsg="";
                    }
                }
            },
            txtAddress1(newVal, oldVal) {
                if (newVal.trim() != oldVal.trim()) {
                    if (newVal.trim()) {
                        this.Address1Error = false;
                    } else {
                        this.Address1Error = true;
                    }
                }
            },
            txtAddress2(newVal, oldVal) {
                if (newVal.trim() != oldVal.trim()) {
                    if (newVal.trim()) {
                        this.Address2Error = false;
                    } else {
                        this.Address2Error = true;
                    }
                }
            },
            txtCity(newVal, oldVal) {
                if (newVal.trim() != oldVal.trim()) {
                    if (newVal.trim()) {
                        this.cityError = false;
                    } else {
                        this.cityError = true;
                    }
                }
            },
            txtZipCode(newVal, oldVal) {
                if (newVal.trim() != oldVal.trim()) {
                    if (newVal.trim() && this.txtCountry) {
                        if (this.txtCountry.toUpperCase() == 'USA') {
                            if (newVal.length == 5) {
                                this.zipCodeError = false;
                            } else {
                                this.zipCodeError = true;
                                this.txtZipCodeErrorMsg = "Zip code is not valid";
                            }
                        } else if (this.txtCountry.toUpperCase() == 'CANADA') {
                            // if (newVal.length == 6) {
                            //     this.zipCodeError = false;
                            // } else {
                            //     this.zipCodeError = true;
                            //     this.txtZipCodeErrorMsg = "Zip / Postal code is not valid";
                            // }
                            if (!/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[ ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/.test(newVal.trim())) {
                                this.zipCodeError = true;
                                this.txtZipCodeErrorMsg = "Zip / Postal code is not valid";
                            }
                            else{
                                this.zipCodeError = false;
                            }
                        } else if (this.txtCountry.toUpperCase() != 'CANADA' && this.txtCountry.toUpperCase() != 'USA') {
                            if (newVal.trim()) {
                                this.zipCodeError = false;
                            } else {
                                this.zipCodeError = true;
                                this.txtZipCodeErrorMsg = "Zip / Postal code is not valid";
                            }
                        }
    
                    } else {
                        if (newVal.trim()) {
                            this.zipCodeError = false;
                        } else {
                            this.zipCodeError = true;
                            if (this.txtCountry.toUpperCase() == 'USA') {
                                this.txtZipCodeErrorMsg = "Please enter a zip code";
                            } else {
                                this.txtZipCodeErrorMsg = "Please enter a zip / postal code";
                            }
                        }
                    }
    
                }
            },
            txtCountry(newVal, oldVal) {
                if (newVal != oldVal) {
                    if (newVal) {
                        this.CountryError = false;
                        if (newVal.toUpperCase() == 'USA') {
                            this.txtZipCodeErrorMsg = "Please enter a zip code";
                        } else {
                            this.txtZipCodeErrorMsg = "Please enter a zip / postal code";
                        }
                    } else {
                        this.CountryError = true;
                    }
    
                }
                //this.txtZipCodeErrorMsg = "";
            },
            txtUsaState(newVal, oldVal) {
                if (newVal != oldVal) {
                    if (newVal) {
                        this.USAStateError = false;
                    } else {
                        this.USAStateError = true;
                    }
                }
            },
            // txtCanadaState(newVal, oldVal) {
            //     if (newVal != oldVal) {
            //         if (newVal) {
            //             this.CanadaStateError = false;
            //         } else {
            //             this.CanadaStateError = true;
            //         }
            //     }
            // },
            // txtOtherState(newVal, oldVal) {
            //     if (newVal.trim() != oldVal.trim()) {
            //         if (newVal.trim()) {
            //             this.OtherStateError = false;
            //         } else {
            //             this.OtherStateError = true;
            //         }
            //     }
            // },
            txtVerifyPassword(newVal, oldVal) {
                if (newVal.trim() != oldVal.trim()) {
                    if (newVal.trim()) {
                        this.PasswordError = false;
                        this.divpassError="my-profile-email-static-text";
                    } else {
                        this.PasswordError = true;
                        this.divpassError="my-profile-email-static-text-red";
                        if(this.userDetails["Mphone"] && this.userDetails["Dphone"]){
                            this.isRemoveNumberActive=false;
                            this.disableRemoveButton=false;
                        }
                    }
                }
    
            },
            txtOldPassword(newVal, oldVal) {
                if (newVal.trim() != oldVal.trim()) {
                    if (newVal.trim()) {
                        this.OldPasswordError = false;
                    } else {
                        this.OldPasswordError = true;
                    }
                }
            },
            // txtNewPassword(newVal, oldVal) {
            //     if (newVal != oldVal) {
            //         if (newVal) {
            //             this.NewPasswordError = false;
            //         } else {
            //             this.NewPasswordError = true;
            //         }
            //     }
            // },
            txtNewPassword(newVal, oldVal) {
                if (newVal.trim() != oldVal.trim()) {
                    if (newVal.trim() == null || newVal.trim() == "") {
                        this.NewPasswordError = true;
                        this.NewPasswordErrorMsg = "Please enter new password";
                        this.validatePasswordError = false;
                    } else if (!this.validatePassword(newVal.trim())) {
                        this.NewPasswordError = false;
                        this.validatePasswordError = true;
                    } else if (newVal.trim() == this.txtOldPassword.trim()) {
                        this.NewPasswordError = true;
                        this.NewPasswordErrorMsg = "The new password cannot be same as the old password.";
                        this.validatePasswordError = false;
                    } else {
                        this.validatePasswordError = false;
                        this.NewPasswordErrorMsg = "";
                        this.NewPasswordError = false;
                    }
                }
            },
            viewPortWidth(){
                this.fillPhoneNumberColumnSize();
            }
    
        }
    }
    </script>
    
    <style scoped>
    body,
    body * {
        font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
            sans-serif;
    }
    
    .my-profile-loader {
        position: absolute; /* or absolute, depending on your layout */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999; /* Adjust this value as needed */
    }
    
    .my-profile-box {
        border-radius: 6px;
        border: 1px solid #CCC;
        background: var(--background-primary, #FFF);
        width: 100%;
        /*  height: 88px;*/
    }
    
    .my-profile-box-name-edit {
        border-radius: 6px;
        border: 1px solid #CCC;
        background: var(--background-primary, #FFF);
        width: 100%;
        /* height: 102px;*/
    }
    
    .my-profile-phonenumber-box {
        border-radius: 6px;
        border: 1px solid #CCC;
        background: var(--background-primary, #FFF);
        width: 100%;
        /*height: 206px;*/
    }
    
    .my-profile-phonenumber-box-edit {
        border-radius: 6px;
        border: 1px solid #CCC;
        background: var(--background-primary, #FFF);
        width: 100%;
        /*height: 440px;*/
    }
    
    .my-profile-heading {
        color: var(--text-primary, #1E1E1E);
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
    }
    
    .my-profile-heading-textbox {
        font-size: 14px;
    }
    
    .my-profile-text {
        color: var(--text-primary, #1E1E1E);
        font-size: 16px;
        font-weight: 300;
        line-height: 21px;
    }
    
    .my-profile-secondarytext {
        color: var(--text-secondary, #5E5E5E);
        font-size: 14px;
        line-height: 18px;
    }
    
    .my-profile-secondarytext:hover {
        cursor: pointer;
    }
    
    .my-profile-forms-error {
        color: #ca0000;
    }
    
    .my-profile-password-text-error {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: red;
        margin-top: 5px;
    }
    
    .my-profile-password-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-secondary, #5E5E5E);
        margin-top: 12px;
    }
    
    
    
    .my-profile-address-inside-flex {
        width: 100%;
    }
    
    .my-profile-city {
        margin-top: 36px;
    }
    
    
    .my-profile-address-editbox {
        border-radius: 6px;
        border: 1px solid #CCC;
        background: var(--background-primary, #FFF);
        width: 100%;
        /* height: 280px;*/
        /*  height: 88px;*/
    }
    
    .my-profile-email-static-text {
        color: var(--text-secondary, #5E5E5E);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-top: 5px;
    }
    .my-profile-email-static-text-red {
        color: var(--text-secondary, #ca0000);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-top: 5px;
    }
    .my-profile-contact-number-container{
        margin-top:10px;
    }
    
    .add-secondary-number-button{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-top:30px;
    }
    .remove-number-button{
        margin-top:53px;
    }
    .my-profile-phone-number-input-box{
        padding-right: 6px !important;
    }
    .my-profile-phone-number-input-box-container{
        margin-top:24px;
    
    }
    .my-profile-phone-number-type-label{
        margin-top: 24px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
        color: #1e1e1e;
        width: max-content;
    }
    .my-profile-phone-number-type-name{
        margin-top: 15px;
        font-size: 16px;
        line-height: 21px;
        font-weight: 300;
        color: #1e1e1e;
    }
    @media only screen and (min-width: 0px) and (max-width: 1200px){
        .my-profile-phone-number-input-box{
            padding-right:16px !important;
            padding-left: 24px !important;
        }
        .my-profile-phone-number-type-container{
            padding-right:0px;
        }
    }
    @media only screen and (min-width: 0px) and (max-width: 999px){
        .remove-number-button{
            margin-top:46px;
        }
    }
    @media only screen and (min-width: 0px) and (max-width: 600px) {
        .my-profile-alert {
            position: absolute;
            top: 19%;
            left: 10px;
            width: 95%;
            z-index: 99999999999;
        }
    
        .my-profile-address-editdiv {
            width: 125px;
            display: inline-flex;
            margin-left: 27px;
            margin-bottom: 10px;
          /*  margin-left: 23px;*/
           /* margin-right: 23px;*/
    
        }
    
        .my-profile-city {
            margin-top: 10px;
        }
    
        .my-profile-address-editbox {
            border-radius: 6px;
            border: 1px solid #CCC;
            background: var(--background-primary, #FFF);
            width: 100%;
            /* height: 380px;*/
            /*  height: 88px;*/
        }
        .my-profile-address-btn {
            float: inherit;
            margin-top: 40px;
            margin-right: 17px;
        }
    /*
        .my-profile-address-btn {
            margin-top: 40px;
            margin-right: 27px;
            position: relative;
            float: right;
    
        } 
        */
    }
    /*
     @media only screen and (min-width: 701px) and (max-width: 750px) {
        .my-profile-alert {
            position: absolute;
            top: 19%;
            left: 10px;
            width: 95%;
            z-index: 99999999999;
        }
    } 
    */
    
    @media only screen and (min-width: 0px) and (max-width: 390px) {
        .my-profile-address-editdiv {
            width: 92% !important;
            display: inline-flex  !important;
            margin-left: 23px  !important;   
        }
    
        .remove-number-button{
            margin-left: -13px;
        }
    
    }
    
    @media only screen and (min-width: 390px) and (max-width: 400px) {
        .my-profile-address-editdiv {
            width: 92% !important;
            display: inline-flex  !important;
            margin-left: 23px  !important;   
        }
        .my-profile-address-btn {
            float: inherit  !important;
            margin-top: 40px  !important;
            margin-right: 17px  !important;
        }
    }
    @media only screen and (min-width: 401px) and (max-width: 430px) {
        .my-profile-address-editdiv {
            width: 92% !important;
            display: inline-flex  !important;
            margin-left: 25px  !important;   
        }
        .my-profile-address-btn {
            float: inherit  !important;
            margin-top: 40px  !important;
            margin-right: 17px  !important;
        }
    }
    
    @media only screen and (min-width: 601px) and (max-width: 768px) {
       
    
        .my-profile-address-editdiv {
            width: 125px;
            display: inline-flex;
            margin-left: 23px;   
        }
        .my-profile-address-btn {
            float: inherit;
            margin-top: 40px;
            margin-right: 17px;
        }
    
        .my-profile-city {
            margin-top: 10px;
        }
    
        .my-profile-address-editbox {
            border-radius: 6px;
            border: 1px solid #CCC;
            background: var(--background-primary, #FFF);
            width: 100%;
            /* height: 400px;*/
        }
        .remove-number-button{
            margin-top: 53px;
        }
    }
    
    @media only screen and (min-width: 650px) and (max-width: 700px) {
        .my-profile-address-editdiv {
            width: 150px !important;
            display: inline-flex  !important;
            margin-left: 23px  !important;   
        }
        .my-profile-address-btn {
            float: inherit  !important;
            margin-top: 40px  !important;
            margin-right: 17px  !important;
        }
    }
    @media only screen and (min-width: 730px) and (max-width: 790px) {
        .my-profile-address-editdiv {
            width: 140px !important;
            display: inline-flex  !important;
            margin-left: 23px  !important;   
        }
        .my-profile-address-btn {
            float: inherit  !important;
            margin-top: 40px  !important;
            margin-right: 17px  !important;
        }
    }
    
    @media only screen and (min-width: 769px) and (max-width: 1000px) {
        .my-profile-address-editdiv {
            width: 150px;
            display: inline-flex;
            margin-left: 23px;   
        }
        .my-profile-address-btn {
            float: inherit;
            margin-top: 40px;
            margin-right: 17px;
        }
    }
    
    @media only screen and (min-width: 1001px) and (max-width: 1200px) {
        .my-profile-address-editdiv {
            width: 125px;
            display: inline-flex;
            margin-left: 23px;   
        }
        .my-profile-address-btn {
            float: inherit;
            margin-top: 40px;
            margin-right: 17px;
        }
    }
    
    @media only screen and (min-width: 1201px) and (max-width: 6000px) {
        .my-profile-address-editbox {
            border-radius: 6px;
            border: 1px solid #CCC;
            background: var(--background-primary, #FFF);
            width: 100%;
            /*height: 360px;*/
        }
        .my-profile-address-editdiv {
            width: 160px;
            display: inline-flex;
            margin-left: 23px;
           /* margin-left: 23px;*/
            /*margin-right: 23px;*/
        }
        .my-profile-address-btn {
            float: inherit;
            margin-top: 40px;
            margin-right: 12px;
        }
    }
    .my-profile-active-color{
        border-color: #006fba;
    }
    .my-profile-box-name-edit .mds-layout-grid .mds-layout-grid__col {
        padding: 0 8px;
    }
    </style>
    <style>
    .my-profile-alert .mds-alert .mds-alert__list li::before {
        content: none;
    }
    
    .my-profile-heading-textbox .mds-label__text {
        margin-bottom: 8px !important;
    }
    </style>