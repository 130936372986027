<template>
  <div class="send-post">
    <mds-modal v-model="sendPostModalActive" :width="'1200px'" aria-label="Send Post Modal" action-required class="send-post-modal">
        <mds-section border="none" title="Send Post" bold class="send-post-header-and-close">
          <mds-button-container right-aligned>
            <mds-button @click="cancelSendPost()" variation="icon-only" icon="remove">Close</mds-button>
          </mds-button-container>
        </mds-section>
        <mds-section border="none">
          <mds-alert class="send-post-alert" :variation="sendPostAlertVariation" tinted @mds-alert-dismissed ="dismissAlert" :text="sendPostAlertMessage" v-if="sendPostAlertStatus">
          </mds-alert>
        <div class="send-post-main-container">
          <mds-layout-grid>
            <mds-row class="send-post-action-container">
              <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="3" :cols-at-xl="3">
                <div class="send-post-id-text-box-container">
                  <mds-form>
                    <mds-input disabled label="Post ID" v-model="sendPostId"></mds-input>
                  </mds-form>
                </div>
              </mds-col>
              <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="4" :cols-at-xl="4">
                <mds-button-group class="send-post-button-group"
                    v-on:mds-button-group-item-active="setActiveItem"
                    :content="sendPostButtonGroupContent"
                ></mds-button-group>
              </mds-col>
              <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="3" :cols-at-xl="3">
                <div class="send-post-id-text-box-container">
                  <mds-form>
                    <mds-input label="Pre-Send To" placeholder="Email Address" type="email" v-model="preSendemailAddress" @keyup="checkEmailValidation" class="send-post-pre-send-email-input" :error="preSendError" :error-text="[`Pre send email field cannot be empty`]"></mds-input>
                    <span class="send-post-pre-send-email-error">{{emailErrorMessage}}</span>
                  </mds-form>
                </div>
              </mds-col>
              <mds-col :cols="12" :cols-at-s="12" :cols-at-m="12" :cols-at-l="2" :cols-at-xl="2">
                <div class="pre-send-button-container">
                  <mds-button @click="sendPost('toIndividual')" variation="primary" icon="share" :disabled="emailErrorState">Pre-Send</mds-button>
                </div>
              </mds-col>
            </mds-row>
          </mds-layout-grid>

          <div class="send-post-preview-main-container">
            <div class="send-post-preview-container">
              <div class="send-post-text-preview-container" v-if="textFormatViewToggle">
                <div class="send-post-text-preview-content">
                  <p>Dear {{productName}} Subscriber,</p>
                  <p>A new {{productName}} post is available  at {{productUrl}}</p>
                  <p>Please visit the website now to read my latest post.</p>
                  <p>If you have any problems with your electronic subscription, please contact us at newslettersupport@morningstar.com.</p>
                  <p>Thank you,</p>
                </div>
                <div>
                  <p>{{editorName}}</p>
                  <p v-html="editorDesignation"></p>
                </div>
              </div>
              <div v-if="htmlFormatViewToggle">
                <email-template :send-post-id="sendPostId"></email-template>
              </div>
              <!--<div v-if="viewHistoryFormatViewToggle">History Tab</div> -->
            </div>
          </div>
        </div>
        <mds-button-container right-aligned>
          <mds-button @click="cancelSendPost()" variation="secondary" icon="remove">Cancel</mds-button>
          <mds-button @click="sendPost('toALL')" variation="primary" icon="share" :disabled="sendPostToAllButton">Send Post</mds-button>
        </mds-button-container>
    </mds-section>
    </mds-modal>
  </div>
</template>

<script lang="js">
  import EmailTemplate from "@/components/Common/Commentary/Blog/EmailTemplate";
  import MdsModal from '@mds/modal';
  import MdsSection from '@mds/section';
  import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid'
  import { MdsButton, MdsButtonContainer } from '@mds/button';
  import MdsForm from '@mds/form';
  import MdsInput from '@mds/input';
  import MdsButtonGroup from '@mds/button-group';
  import MdsAlert from '@mds/alert';
  import Vue from "vue";
  import VueAxios from "vue-axios";
  import axios from "axios";
  Vue.use(VueAxios, axios);
  export default  {
    name: 'send-post',
    props: {
      sendPostToggleState:{
        type:Boolean,
        required:true,
      },
      sendPostId:{
        type:Number,
        required:true,
      }
    },
    components:{
      EmailTemplate,
      MdsModal,
      MdsSection,
      MdsButton,
      MdsButtonContainer,
      MdsLayoutGrid,
      MdsRow,
      MdsCol,
      MdsForm,
      MdsInput,
      MdsButtonGroup,
      MdsAlert,
    },
    mounted () {
      this.getProductName();
    },
    data () {
      return {
        sendPostButtonGroupContent: [
            { id: '1', text: 'Html Format', active: true, icon: 'document' ,'aria-controls': 'panel-1', identifier:'html' },
            { id: '2', text: 'Text Format', active: false, icon: 'create' , 'aria-controls': 'panel-2', identifier:'text' },
            //{ id: '3', text: 'View History', active: false, icon: 'eye' , 'aria-controls': 'panel-3', identifier:'history' },
        ],
        textFormatViewToggle:false,
        htmlFormatViewToggle:true,
        //viewHistoryFormatViewToggle:false,
        currentProduct: this.$session.get("current-product"),
        productName:null,
        productUrl:null,
        editorName:null,
        editorDesignation:null,
        preSendemailAddress:'',
        preSendError:false,
        emailErrorMessage:'',
        emailErrorState:true,
        sendPostToAllButton:false,
        emailList:{},
        getEmailList:{},
        sendPostURL : process.env.VUE_APP_Newsletters_Utilities + process.env.VUE_APP_SendArticle,
        sendPostAlertVariation:null,
        sendPostAlertMessage:null,
        sendPostAlertStatus:false,

      }
    },
    methods: {
      cancelSendPost(){
        this.$emit('sendPostToggleUpdated', false);
        this.resetActionButtonGroup();
        this.dismissAlert();
      },
      setActiveItem(event) {
        this.sendPostButtonGroupContent.forEach(item => {
            if (item.id === event.currentTarget.id) {
                item.active = true;
                if(item.identifier == 'text'){
                  this.textFormatViewToggle=true;
                  this.htmlFormatViewToggle=false;
                  //this.viewHistoryFormatViewToggle=false;
                }
                else if(item.identifier == 'html'){
                  this.textFormatViewToggle=false;
                  this.htmlFormatViewToggle = true;
                  //this.viewHistoryFormatViewToggle= false;
                }
                else{
                  this.textFormatViewToggle=false;
                  this.htmlFormatViewToggle = false;
                  //this.viewHistoryFormatViewToggle= true;
                }
            } else {
                item.active = false;
            }
        });
      },
      getProductName(){
        var baseUrl = "https://newsletters-dev.morningstar.com/";
        if(this.currentProduct.toUpperCase() == 'MFI'){
          this.productName = 'FundInvestor'
          this.productUrl = baseUrl + "mfi";
          this.editorName = "Russel Kinnel";
          this.editorDesignation = "Director of Manager Research <br> Editor, Morningstar FundInvestor";
        }
        else if(this.currentProduct.toUpperCase() == 'MSI'){
          this.productName = 'StockInvestor';
          this.productUrl = baseUrl + "msi";
          this.editorName = "David Harrell";
          this.editorDesignation = "Editor, Morningstar StockInvestor";
        }
        else if(this.currentProduct.toUpperCase() == 'MDI'){
          this.productName = 'DividendInvestor';
          this.productUrl = baseUrl + "mdi";
          this.editorName = "David Harrell";
          this.editorDesignation = "Editor, Morningstar DividendInvestor";
        }
        else if(this.currentProduct.toUpperCase() == 'MEI'){
          this.productName = 'ETFInvestor';
          this.productUrl = baseUrl + "mei";
          this.editorName = "Bryan Armour, CFA";
          this.editorDesignation = "Director, Passive Strategies North America <br> Editor, Morningstar ETFInvestor";
        }
      },
      resetActionButtonGroup(){
        this.textFormatViewToggle=false;
        this.htmlFormatViewToggle=true;
        //this.viewHistoryFormatViewToggle=false;
        this.sendPostButtonGroupContent[0].active = true;
        this.sendPostButtonGroupContent[1].active = false;
        //this.sendPostButtonGroupContent[2].active = false;
        this.emailErrorState=true;
        this.sendPostToAllButton=false;
        this.preSendError=false;
      },
      sendPost(typeOfSendPost){
        if(typeOfSendPost == 'toIndividual'){
          if(this.preSendemailAddress == ''){
            this.preSendError=true;
          }else{
            this.preSendError=false;
          }
        }
        if((!this.emailErrorState && !this.preSendError) || (!this.sendPostToAllButton))
        {
          this.sendPostAlertStatus=true;
          this.sendPostAlertVariation="informational";
          this.sendPostAlertMessage = "Please wait email is being sent";
          this.getEmailList = this.populateEmail(typeOfSendPost);
          const sendPostCompleteURL = this.sendPostURL + "postId=" + this.sendPostId ;
          try{
            Vue.axios.post(sendPostCompleteURL,this.getEmailList
            )
            .then((response) =>{
              if(response){
                if(response.status == 200){
                  if(response.data.responseMessage == 'Unsuccessful')
                  {
                    this.sendPostAlertStatus=true;
                    this.sendPostAlertVariation="error";
                    this.sendPostAlertMessage = "Unable to send the email, please try again";
                  }
                  else
                  {
                    this.sendPostAlertStatus=true;
                    this.sendPostAlertVariation="success";
                    this.sendPostAlertMessage = "Email sent successfully";
                    this.sendPostToAllButton = false;
                    if(this.preSendemailAddress != '')
                    {
                      this.preSendemailAddress = '';
                      this.emailErrorState=true;
                      this.sendPostToAllButton=false;
                    }
                    this.getEmailList.length = 0;
                    this.emailList.length = 0;
                    setTimeout(() => {
                      this.cancelSendPost();
                    }, 2000);
                  }
                }else{
                  console.log("returned reponse status : " + response.status);
                }
              }
            })
            .catch((error) => {
              console.log(error);
              this.sendPostAlertStatus=true;
              this.sendPostAlertVariation="error";
              this.sendPostAlertMessage = "Unable to send the email, please try again";
            })
          }
          catch (ex) {
            console.log(ex.message);
            this.sendPostAlertStatus=true;
            this.sendPostAlertVariation="error";
            this.sendPostAlertMessage = "Unable to send the email, please try again";
          }
        }
      },
      checkEmailValidation(){
        //eslint-disable-next-line
        var emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        if(this.preSendemailAddress != ''){
          this.sendPostToAllButton=true;
          if(!emailValidator.test(this.preSendemailAddress))
          {
            this.emailErrorMessage = "Please enter valid email address.";
            this.emailErrorState = true;
            this.preSendError = false;
          }
          else
          {
            this.emailErrorMessage = "";
            this.emailErrorState = false;
          }
        }
        else
        {
          this.emailErrorMessage = "";
          this.emailErrorState = true;
          this.sendPostToAllButton = false;
        }
      },
      populateEmail(typeOfSendPost){
        if(!this.emailErrorState || !this.sendPostToAllButton)
        {
          if(typeOfSendPost == "toIndividual")
          {
            this.emailList = [{
              "name" : "string",
              "email" : this.preSendemailAddress
            }];
            return this.emailList;
          }
          else
          {
            this.emailList = [
              {
                "name" : "string",
                "email" : "sendPostToMultipleReceipients"
              },
            ];
            if(typeOfSendPost == 'toALL'){
              this.sendPostToAllButton = true;
            }
            return this.emailList;
          }
        }
      },
      dismissAlert(){
        this.sendPostAlertStatus = false;
        if(this.sendPostAlertVariation !="informational"){
          this.sendPostToAllButton = false;
        }
      }
    },
    computed: {
      sendPostModalActive(){
        return this.sendPostToggleState;
      }
    },
}


</script>

<style scoped>
  .send-post {
    
  }
  .send-post-main-container{
    width:100%;
    height:500px;
    background-color:#f2f2f2;
    margin-bottom:20px;
    overflow-y:scroll;
    overflow-x:hidden;
  }
  .send-post-main-container::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    overflow-x: overlay;
  }

  .send-post-main-container::-webkit-scrollbar-thumb {
    background: rgba(171, 171, 171, 0.3);
    border-radius: 6px;
  }
  .send-post-action-container{
    justify-content:center;
  }
  .send-post-id-text-box-container{
    width:100%;
  }
  .send-post-button-group{
    margin-bottom:10px;
    margin-top:30px;
    justify-content:center;
  }
  .pre-send-button-container{
    margin-top:29px;
  }
  .send-post-preview-main-container{
    width:100%;
    margin-top:20px;
    margin-bottom:20px;
  }
  .send-post-preview-container{
    width:90%;
    margin:0 auto;
  }
  .send-post-text-preview-container{
    width:100%;
    font-size:18px;
  }
  .send-post-text-preview-content{
    line-height:45px;
    margin-bottom:20px;
  }
  .send-post-pre-send-email-input{
    margin-bottom:0px;
  }
  .send-post-pre-send-email-error{
    color:red;
    font-size:13px;
    line-height:normal;
  }
  .send-post-alert{
    margin-bottom:20px;
  }
  @media only screen and (min-width:1001px) and (max-width:1280px){
    .send-post-main-container{
      height:400px;
    }
  }
</style>
<style>
  .send-post-button-group button:focus{
    box-shadow: none !important;
    outline: none !important;
  }
  .send-post-modal > div:first-child > div {
    overflow-y: scroll;
  }
  .send-post-modal > div:first-child > div::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    overflow-x: overlay;
  }

  .send-post-modal > div:first-child > div::-webkit-scrollbar-thumb {
    background: rgba(171, 171, 171, 0.3);
    border-radius: 6px;
  }
  .send-post-header-and-close {
    display: flex;
  }
  .send-post-header-and-close .mds-section__content{
    margin-left: auto;
    order: 2;
  }
  @media only screen and (max-width:320px){
    .send-post-modal > div:first-child > div {
      max-height: 80vh !important;
    }
  }
</style>
